import React, { useState } from 'react';
import UploadedMediaSection from '../LMS/UploadedMediaSection';
import UploadMediaSection from '../LMS/UploadMediaSection';
import { useEffect } from 'react';
import Select from 'react-select';

const CreateCampaignWizardForm1 = ({ handleNextButton, recurrenceConditionsDropdownValues, allCoursesDropdownValues, setCampaignData, campaignData, setTaskData, taskData, close, thumbnailData, setThumbnailData, selectedCourses, setSelectedCourses, isNextLoading }) => {

    // PC_PCDFE_NO_11
    const campaignErrorObject = {
        campaignThumbnailError: "",
        recursiveEndCycleError: "",
    };

    /*
    * PC_PCDFE_NO_12 - Defining the state variable
    */
    const [validateError, setValidateError] = useState(campaignErrorObject);
    const [isValidCampaignData, setIsValidCampaignData] = useState(false);
    const [uploadedFileValue, setUploadedFileValue] = useState("");

    /*
    * PC_PCDFE_NO_13 - PC_PCDFE_NO_19
    * Defining useEffect hook with state variable campaignData
    */
    useEffect(() => {
        const isValid = Object.entries(campaignData)
            .filter(([key]) => key !== "campaignId" && key !== "campaignThumbnail")
            .every(([key, value]) => {
                if (key === "recurrenceEndDate" || key === "nextRecurrenceDate") {
                    return campaignData.isRecursive === "0" ? value !== "" : true;
                }
                return value !== "";
            });

        setIsValidCampaignData(isValid);
    }, [campaignData]);

    /*
    * PC_PCDFE_NO_20 - PC_PCDFE_NO_21
    * Dynamic generation of recurrence condition dropdown
    */
    const generateRecurrenceConditionsDropdown = () => {
        return recurrenceConditionsDropdownValues?.map((item) => {
            return <option key={item.recurrenceId} value={item.recurrenceId} selected={item.recurrenceId == campaignData.recurrenceId}>{item.recurrenceCondition}</option>;
        });
    };


    /*
    * PC_PCDFE_NO_23 - PC_PCDFE_NO_39
    * handling upload thumbnail validation
    */
    const handleThumbnailChange = (event) => {
        const image = event.target.files[0];
        setValidateError(validateError);
        const updatedError = { ...validateError };
        updatedError.campaignThumbnailError = "";

        if (image) {
            const imageExtension = image.name.split('.').pop().toLowerCase();
            const imageSizeInMB = (image.size / (1024 * 1024)).toFixed(2);
            const allowedExtensions = ['jpg', 'jpeg', 'png'];

            if (!allowedExtensions.includes(imageExtension)) {
                updatedError.campaignThumbnailError = "Invalid file format. Please upload a valid file (e.g., JPEG, PNG, JPG)";
                setValidateError(updatedError);
                setUploadedFileValue("");
                setThumbnailData(null);
                setCampaignData({ ...campaignData, campaignThumbnail: "" });
                return;
            }
            // Check file size (max size in bytes, here 5MB)
            const maxFileSize = 5 * 1024 * 1024;
            if (image.size > maxFileSize) {
                updatedError.campaignThumbnailError = "Thumbnail size exceeds the limit";
                setValidateError(updatedError);
                setUploadedFileValue("");
                setThumbnailData(null);
                setCampaignData({ ...campaignData, campaignThumbnail: "" });
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const uploadedImage = e.target.result;
                setThumbnailData({ name: image.name, size: imageSizeInMB, extension: imageExtension, src: uploadedImage });
                setUploadedFileValue(uploadedImage);
            };
            reader.readAsDataURL(image);
            setCampaignData({ ...campaignData, campaignThumbnail: image });
            setValidateError(updatedError);
        }
    };

    /*
    * PC_PCDFE_NO_40 - PC_PCDFE_NO_69
    * Handling user input change and date validation
    */
    const handleInputChange = (event) => {
        let updatedCampaignData = { ...campaignData };
        let updatedTaskData = { ...taskData };
        let currentDate = new Date();

        if (event.target.value.trim() === "") {
            updatedCampaignData[event.target.name] = "";
            setCampaignData(updatedCampaignData);
            return;
        }

        if (event.target.name === "recurrenceCondition") {
            if (event.target.value.trim() === "-1") {
                updatedCampaignData.isRecursive = "";
                updatedCampaignData.nextRecurrenceDate = "";
                updatedCampaignData.recurrenceEndDate = "";
                updatedTaskData.startDate = "";
                updatedTaskData.endDate = "";
            } else if (event.target.value.trim() === "0") {
                updatedCampaignData.isRecursive = 0;
                updatedCampaignData.nextRecurrenceDate = null;
                updatedCampaignData.recurrenceEndDate = null;
                updatedTaskData.startDate = "";
                updatedTaskData.endDate = "";
            } else {
                updatedCampaignData.isRecursive = 1;

                if (event.target.value.trim() !== "" && event.target.value.trim() !== "0") {
                    let recurrenceDays = parseInt(event.target.value);
                    let startDate = new Date(currentDate);
                    let endDate = new Date(currentDate);
                    let nextRecurrenceDate = new Date(currentDate);

                    endDate.setDate(endDate.getDate() + recurrenceDays);
                    nextRecurrenceDate.setDate(currentDate.getDate() + recurrenceDays + 1);

                    updatedTaskData.startDate = startDate.toISOString().slice(0, 10);
                    updatedTaskData.endDate = endDate.toISOString().slice(0, 10);
                    updatedCampaignData.nextRecurrenceDate = nextRecurrenceDate.toISOString().slice(0, 10);
                }
            }
        }

        if (
            event.target.name === "recurrenceEndDate" &&
            (
                event.target.value.trim() <= taskData.endDate ||
                event.target.value.trim() <= taskData.startDate ||
                (campaignData.campaignId !== "" && event.target.value.trim() < currentDate) ||
                (campaignData.campaignId === "" && event.target.value.trim() <= currentDate)
            )
        ) {
            setValidateError({ ...validateError, recursiveEndCycleError: "Please enter a valid date" });
            updatedCampaignData.recurrenceEndDate = "";
            setIsValidCampaignData(false);
            return;
        } else {
            setValidateError({ ...validateError, recursiveEndCycleError: "" });
        }

        updatedCampaignData[event.target.name] = event.target.value;

        setCampaignData(updatedCampaignData);
        setTaskData(updatedTaskData);
    };

    /*
    * PC_PCDFE_NO_71 - PC_PCDFE_NO_74
    * Deleting the uploaded thumbnail
    */
    const handleDeleteThumbnail = () => {
        setThumbnailData(null);
        setUploadedFileValue("");
        setCampaignData({ ...campaignData, campaignThumbnail: "" });
    };

    /*
    * PC_PCDFE_NO_76 - PC_PCDFE_NO_79
    * handling the user selected course options
    */
    const handleSelectedCourses = (value) => {
        setSelectedCourses(value);
        setCampaignData({ ...campaignData, courseId: value.value });
    }

    return (
        <>
            <div
                className="modal fade show"
                id="add-audit"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-0 pb-3 px-4">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">
                                {campaignData.campaignId !== "" ? campaignData.campaignName : "Create Campaign"}
                            </h5>
                            <button
                                type="button"
                                className="btn-close ms-auto pointer"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { setUploadedFileValue(""); close(); }}
                            />
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="row">
                                {/* wizard starts here */}
                                <div className="d-flex position-relative justify-content-center mb-4">
                                    <div className="wizard-list active">
                                        <div className="wizard-img font-medium font-12 mb-2">1</div>
                                        <div className="wizard-img wizard-img-completed font-medium font-12 mb-2 position-relative">
                                            <img src="/images/completed-vector.svg" />
                                        </div>
                                        <span className="wizard-text font-medium font-12">Campaign</span>
                                    </div>
                                    <div className="progress wizard-progress ">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "0%" }}
                                        />
                                    </div>
                                    <div className="wizard-list">
                                        <div className="wizard-img font-medium font-12 mb-2">2</div>
                                        <div className="wizard-img wizard-img-completed font-medium font-12 mb-2 position-relative">
                                            <img src="/images/completed-vector.svg" />
                                        </div>
                                        <span className="wizard-text font-medium font-12">Task</span>
                                    </div>
                                </div>
                                {/* wizard sends here */}
                                <div className="col-md-12 mb-4">
                                    <label
                                        htmlFor="campaign-name"
                                        className="form-label font-medium font-14 primary-textcolor"
                                    >
                                        Campaign Name<span className="required-text">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control font-regular font-14 custom-form-control py-2"
                                        id="campaign-name"
                                        placeholder="Enter Campaign Name"
                                        name="campaignName"
                                        maxLength={100}
                                        value={campaignData.campaignName}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label
                                        htmlFor="description"
                                        className="form-label font-medium font-14 primary-textcolor"
                                    >
                                        Description<span className="required-text">*</span>
                                    </label>
                                    <textarea
                                        className="form-control font-regular font-14 custom-form-control py-2 resize-none"
                                        rows={5}
                                        id="description"
                                        placeholder="Enter Description"
                                        // defaultValue={""}
                                        name="campaignDescription"
                                        value={campaignData.campaignDescription}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                {campaignData.campaignId === "" ? <div className="col-md-6 mb-4">
                                    <div className="dropdown">
                                        <label className="form-label text-black font-14 font-medium">
                                            Recursive Condition<span className="required">*</span>
                                        </label>
                                        <select
                                            className="form-select custom-select font-12 font-regular py-2"
                                            aria-label="Default select example"
                                            name="recurrenceCondition"
                                            value={campaignData.recurrenceCondition}
                                            onChange={(e) => handleInputChange(e)}
                                        >
                                            <option value="-1">Select</option>
                                            {generateRecurrenceConditionsDropdown()}
                                        </select>
                                    </div>
                                </div> : <div className="col-md-6 mb-4">
                                    <div>
                                        <label className="form-label text-black font-14 font-medium">
                                            Recursive Condition
                                        </label>
                                        <span className="d-block font-13 font-regular py-2">
                                            {campaignData.recurrenceConditionName}
                                        </span>
                                    </div>
                                </div>}
                                {campaignData.isRecursive == 1 ? <div className="col-md-6 mb-4">
                                    <div className="dropdown">
                                        <label
                                            className="form-label text-black font-14 font-medium"
                                            htmlFor="date"
                                        >
                                            Recursive End Cycle<span className="required">*</span>
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control font-regular font-14 custom-form-control py-2"
                                            id="date"
                                            name="recurrenceEndDate"
                                            value={campaignData.recurrenceEndDate}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        <div>
                                            <span style={{ color: 'red' }}>{validateError.recursiveEndCycleError}</span>
                                        </div>
                                    </div>
                                </div> : <></>}
                                {campaignData.campaignId === "" ? <div className="col-md-12 mb-4">
                                    <div className="dropdown">
                                        <label className="form-label text-black font-14 font-medium">
                                            Select Courses<span className="required">*</span>
                                        </label>
                                        <Select
                                            options={allCoursesDropdownValues}
                                            onChange={(courses) => handleSelectedCourses(courses)}
                                            name="selectedCourseDetails"
                                            value={selectedCourses}
                                        />
                                    </div> </div> : <div className="col-md-6 mb-4">
                                    <label
                                        htmlFor="select-courses"
                                        className="form-label font-medium font-14 primary-textcolor"
                                    >
                                        Select Courses
                                    </label>
                                    <div>
                                        <span className="videos-tag me-2">
                                            {campaignData.courseName}
                                            <span />
                                        </span>
                                    </div>
                                </div>}
                                <div className="col-md-12 mb-4">
                                    <label
                                        htmlFor="upload-thumbnail"
                                        className="form-label font-medium font-14 primary-textcolor"
                                    >
                                        Upload Thumbnail
                                    </label>
                                    <span className="upload-btn-wrapper me-2 d-block cursor-pointer ">
                                        {thumbnailData ? (
                                            <UploadedMediaSection mediaData={thumbnailData} onDelete={handleDeleteThumbnail} isMedia={false} />
                                        ) : (
                                            <UploadMediaSection
                                                handleUpload={handleThumbnailChange}
                                                allowedExtensions=".jpg, .png, .jpeg"
                                                error={validateError.campaignThumbnailError}
                                                fileValue={uploadedFileValue}
                                            />
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 mt-2 mb-3 px-4">
                            <button
                                type="button"
                                className="primary-btn font-14 font-medium py-2 me-2 pointer"
                                data-bs-dismiss="modal"
                                onClick={() => { setUploadedFileValue(""); close(); }}
                            >
                                Cancel
                            </button>
                            <button disabled={!isValidCampaignData} type="button" className={isValidCampaignData ? "dark-btn" : "disabled-btn" + " font-14 font-medium py-2 pointer"} onClick={() => handleNextButton()}>
                                {isNextLoading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Next"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </>
    );
};

export default CreateCampaignWizardForm1;