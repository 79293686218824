import { client } from "../../Client/Client"
import { backendUrl as URL, governanceService } from "../../Constant"

export const getLogsData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getLogsData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
