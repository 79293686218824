import React, { useState } from "react";
import ToastMessageComponent from "../ToastMessageComponent/ToastMessageComponent";

const AddPolicyPopup = ({ onClose, onSave }) => {
    // PC_AP_02
    // create the respective state variables
    const [addPolicyData, setAddPolicyData] = useState({ policyTitle: null, description: null })
    const [isToastVisible, setIsToastVisible] = useState(false)
    const [toastMessage, setToastMessage] = useState({
        path: null,
        header: null,
        message: null,
        color: null
    })

    /**
  * PC_AP_26
  * Emit the user entered data to the parent component after validation
  */
    const handleAddPolicy = () => {
        if (addPolicyData.policyTitle && addPolicyData.description) {
            onSave(addPolicyData)
        }
        else {
            setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Missing Fields', message: 'Please fill all the required fields', color: 'error-toast' })
            setIsToastVisible(true)
        }
    }

    const handleChange = (e) => {
        const value = e.target.value;
        const filteredValue = value.replace(/[^a-zA-Z ]/g, '');
        setAddPolicyData({ ...addPolicyData, policyTitle: filteredValue })
    };


    return (
        <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
            <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                <div className="modal-content">
                    {/*modal content starts here*/}
                    <div className="modal-header border-0 pb-2 px-4">
                        <h5 className="modal-title font-bold font-20" id="exampleModalLabel">Add New Policy</h5>
                        <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={() => onClose()} />
                    </div>
                    <div className="modal-body border-0 font-16 px-4">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label className="form-label font-medium font-14 primary-textcolor w-100">Policy Title<span className="required-text">*</span></label>
                                <input type="text" className="form-control font-regular font-14 custom-form-control py-2" placeholder="Enter Policy Title" maxLength={200} value={addPolicyData.policyTitle} onChange={(event) => handleChange(event)} />
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="description" className="form-label font-medium font-14 primary-textcolor w-100">Description<span className="required-text">*</span></label>
                                <textarea type="text" className="form-control font-regular font-14 custom-form-control py-2 resize-none" rows={4} id="description" placeholder="Enter Description" defaultValue={""} onChange={(event) => setAddPolicyData({ ...addPolicyData, description: event.target.value })} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 mb-3 px-4">
                        <button type="button" className="primary-btn font-14 font-medium py-2 me-2" onClick={() => onClose()}>Cancel</button>
                        <button type="button"
                            className={!addPolicyData.policyTitle || !addPolicyData.description ? "disabled-btn font-14 font-medium py-2" : "dark-btn font-14 font-medium py-2"}
                            disabled={!addPolicyData.policyTitle || !addPolicyData.description} onClick={() => handleAddPolicy()}>Add</button>
                    </div>
                    {/*modal content ends here*/}
                </div>
            </div>
            {isToastVisible && <ToastMessageComponent
                toastResponse={toastMessage}
                setIsShowToastMessage={setIsToastVisible}
            />}
        </div>
    )
}

export default AddPolicyPopup;