import React from 'react';

const AddZero = ({ number }) => {
    // Function to add leading zero if number is less than 10 
    /**PS_TI_24 */
    const addZero = (num) => {
        if (num == null || num === undefined || num === '' || num === 0 || typeof num !== "number") {
            return '0';
        }
        if (num < 10) {
            return `0${num}`;
        }
        return num;
    };
    return addZero(number)
};

export default AddZero;

//how to use
// <div>
// {/* Bind recorded counts data from API to AddZero component */}
// <AddZero number={recordedCounts} />
//</div>
