// PC_COM_GRC_4 to PC_COM_GRC_5 
// Import statements
import { useEffect, useState } from "react"
import React from "react";
import { grcDashboardDetailsApiResponse } from "../../Services/GRC/GrcService";
import { Loader } from "../Loader/Loader";
import { useNavigate } from "react-router-dom";

// PC_COM_GRC_12 -> Component declaration
export default function GrcDetailsComponent({ data }) {

    // PC_COM_GRC_14 to PC_COM_GRC_19 -> state variable creation
    const [controlDashboardData, setControlDashboardData] = useState({})
    const [policyDashboardData, setPolicyDashboardData] = useState({
        policyCount: 0,
        inProgressPolicyCount: 0,
        acceptedPolicyCount: 0,
        unAssignedPolicyCount: 0
    })
    const [evidenceDashboardData, setEvidenceDashboardData] = useState({})

    const [controlDashboardDataPercentage, setControlDashboardDataPercentage] = useState({
        completedPercentage: '',
        inProgressPercentage: '',
        unAssignedPercentage: '',
        frameWorkWidgetRatio :  0
    })
    const [policyDashboardDataPercentage, setpolicyDashboardDataPercentage] = useState({
        completedPercentage: '',
        inProgressPercentage: '',
        unAssignedPercentage: ''
    })
    const [evidenceDashboardDataPercentage, setEvidenceDashboardDataPercentage] = useState({
        completedPercentage: '',
        inProgressPercentage: '',
        unAssignedPercentage: ''
    }
    )

    // PC_COM_GRC_42 -> set state a variable isLoading
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // PC_COM_GRC_20 to PC_COM_GRC_21 -> useEffect declaration
    useEffect(() => {
        grcDetailsWidget()
    }, [data])

    // PC_COM_GRC_22 -> Function declaration
    const grcDetailsWidget = async () => {
        try {

            // PC_COM_GRC_23 -> set state a vaiable isLoading and API function call
            setIsLoading(true);
            let result = await grcDashboardDetailsApiResponse(data)

            // PC_COM_GRC_34 to PC_COM_GRC_40 -> update state variable whether the statuscode is 200
            if (result.statusCode === 200) {
                setControlDashboardData(result.responseData.control)
                setEvidenceDashboardData(result.responseData.evidenceDetails)
                setPolicyDashboardData(result.responseData.policyDetails)
                
                if(result.responseData.control.controlCount ===0 || result.responseData.control.controlCount===null ){
                    setControlDashboardDataPercentage({
                        completedPercentage: '0%',
                        inProgressPercentage: '0%',
                        unAssignedPercentage: '0%',
                        frameWorkWidgetRatio : result.responseData.control.ratio?.toString() + "%"
                    })
                }
                else{
                    const controlCompletedPercentage = (result.responseData.control.completedControlCount / result.responseData.control.controlCount) * 100
                    const controlInprogressPercentage = (result.responseData.control.inProgressControlCount / result.responseData.control.controlCount) * 100
                    const controlUnAssignedPercentage = (result.responseData.control.unAssignedControls / result.responseData.control.controlCount) * 100
                    setControlDashboardDataPercentage({
                        completedPercentage: Math.ceil(controlCompletedPercentage)?.toString() + '%',
                        inProgressPercentage: Math.ceil(controlInprogressPercentage)?.toString() + '%',
                        unAssignedPercentage: Math.ceil(controlUnAssignedPercentage)?.toString() + '%',
                        frameWorkWidgetRatio : result.responseData.control.ratio?.toString() + "%"
                    })
                }
                
                if(result.responseData.policyDetails.policyCount === 0 || result.responseData.policyDetails.policyCount === null){
                    setpolicyDashboardDataPercentage({
                        completedPercentage:'0%',
                        inProgressPercentage: '0%',
                        unAssignedPercentage: '0%'
                    })
    
                }
                else{
                    const policyCompletedPercentage = (result.responseData.policyDetails.acceptedPolicyCount / result.responseData.policyDetails.policyCount) * 100
                    const policyInprogressPercentage = (result.responseData.policyDetails.inProgressPolicyCount / result.responseData.policyDetails.policyCount) * 100
                    const policyUnAssignedPercentage = (result.responseData.policyDetails.unAssignedPolicyCount / result.responseData.policyDetails.policyCount) * 100
                    setpolicyDashboardDataPercentage({
                        completedPercentage: Math.floor(policyCompletedPercentage)?.toString() + '%',
                        inProgressPercentage: Math.floor(policyInprogressPercentage)?.toString() + '%',
                        unAssignedPercentage: Math.floor(policyUnAssignedPercentage)?.toString() + '%'
                    })
                }


               
                if(result.responseData.evidenceDetails.evidenceCount === 0 || result.responseData.evidenceDetails.evidenceCount === null){
                    setEvidenceDashboardDataPercentage({
                        completedPercentage: '0%',
                        inProgressPercentage: '0%',
                        unAssignedPercentage: '0%'
                    })
                }
                else{
                    const evidenceCompletedPercentage = (result.responseData.evidenceDetails.acceptedEvidenceCount / result.responseData.evidenceDetails.evidenceCount) * 100
                    const evidenceInprogressPercentage = (result.responseData.evidenceDetails.inProgressEvidenceCount / result.responseData.evidenceDetails.evidenceCount) * 100
                    const evidenceUnAssignedPercentage = (result.responseData.evidenceDetails.evidenceUnAssigned / result.responseData.evidenceDetails.evidenceCount) * 100

                    setEvidenceDashboardDataPercentage({
                        completedPercentage: Math.floor(evidenceCompletedPercentage)?.toString() + '%',
                        inProgressPercentage: Math.floor(evidenceInprogressPercentage)?.toString() + '%',
                        unAssignedPercentage: Math.floor(evidenceUnAssignedPercentage)?.toString() + '%'
                    })
                }  
            }

            // PC_COM_GRC_41 -> navigate to error page when statusCode in not equals to 200
            else {
                navigate('/error')
            }
             // PC_COM_GRC_42 -> set state a variable isLoading
             setIsLoading(false);

        }

        // PC_COM_GRC_43 -> navigate to error page
        catch (error) {
            navigate('/error');
        }
    }

    const handledateFormat = (data) =>{
        const formatedDate = data.slice(0, 10).split('-')
        const finalFormatedDate = formatedDate[1] + '/' + formatedDate[2] + '/' + formatedDate[0]
        return finalFormatedDate
    }

    // html part
    return (
       
            // <div className="tab-content" id="pills-tabContent">
                
                // <div className="tab-pane fade active show px-4 bg-light" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                            
                                
                                <div className="row">
                                    <Loader isLoading={isLoading} />
                                    <div className="col-md-12 col-sm-12 col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 col-lg-3 col-xl-4 mb-md-3">
                                                <div className="bg-white p-4 font-medium rm-dashboard-card custom-dashboard">
                                                    <div className="d-flex align-items-center">
                                                        <img src="images/soc-2.svg" />
                                                        <p className="ms-3 font-bold primary-textcolor font-20 active-textcolor">
                                                            SOC 2 • Type 2
                                                        </p>
                                                    </div>
                                                    <div className="position-relative mt-3">
                                                        <div className="lms-progress progress">
                                                            <div className="lms-progress-bar progress-bar" role="progressbar" style={{ width: controlDashboardDataPercentage?.frameWorkWidgetRatio }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}><span className="progress-label">{controlDashboardDataPercentage.frameWorkWidgetRatio}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-dashboard rm-dashboard-card p-4 d-flex card-dashboard pt-5 px-lg-2 px-xl-4 justify-content-between">
                                                    <div className="d-inline">
                                                        <p className="font-medium primary-textcolor mb-0 font-12">
                                                            Start Date</p>
                                                        <p className="font-regular primary-textcolor mb-0 font-12 mt-1">
                                                            {handledateFormat(data.startDate)}</p>
                                                    </div>
                                                    <div className="d-inline ms-3">
                                                        <p className="font-medium primary-textcolor mb-0 font-12">
                                                            End Date</p>
                                                        <p className="font-regular primary-textcolor mb-0 font-12 mt-1">
                                                            {handledateFormat(data.endDate)}</p>
                                                    </div>
                                                    
                                                    <div className="d-inline ms-3">
                                                    {data.auditorName != null ? 
                                                    <>
                                                        <p className="font-medium primary-textcolor mb-0 font-12">
                                                            {data.auditorName}</p>
                                                        <img src={data.profileUrl} alt="profile image"  className="table-prof" width={24} height={24} />
                                                        </>
                                                     : 
                                                     <>
                                                        <p className="font-medium primary-textcolor mb-0 font-12">
                                                            Auditor</p>
                                                            <p className="font-regular primary-textcolor mb-0 font-12 mt-1">
                                                            Nil</p>
                                                    </>
                                                     }
                                                     </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-lg-9 col-xl-8 mb-md-3 pe-0">
                                                <div className="row">
                                                    <div className="col-xl-4">
                                                        <div className="bg-white p-3 font-medium rm-dashboard-card comp-cards">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h3 className="font-14 font-medium primary-textcolor mb-0">
                                                                    Control</h3>
                                                            </div>
                                                            <div className="d-flex my-4">
                                                                <p className="font-bold font-24">{controlDashboardData.controlCount}<span className="font-medium font-14 ms-2 compliance-desc">Total
                                                                    Controls</span></p>
                                                            </div>
                                                            <div className="progress compliance-progress mb-3">
                                                                <div className="progress-bar bg-completed" role="progressbar" style={{ width: controlDashboardDataPercentage.completedPercentage}} aria-valuenow={15} aria-valuemin={0} aria-valuemax={100} />
                                                                <div className="progress-bar bg-inprogress" role="progressbar" style={{ width: controlDashboardDataPercentage.inProgressPercentage}} aria-valuenow={30} aria-valuemin={0} aria-valuemax={100} />
                                                                <div className="progress-bar bg-unassigned" role="progressbar" style={{ width: controlDashboardDataPercentage.unAssignedPercentage}} aria-valuenow={20} aria-valuemin={0} aria-valuemax={100} />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <span className="font-medium font-10 compliance-desc progress-text">Completed</span>
                                                                    <p className="d-flex align-items-center mt-2 font-14">
                                                                        <span className="pointer-completed me-2" />{controlDashboardData.completedControlCount}
                                                                    </p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <span className="font-medium font-10 compliance-desc progress-text text-nowrap">In
                                                                        Progress</span>
                                                                    <p className="d-flex align-items-center mt-2 font-14">
                                                                        <span className="pointer-inprogress me-2" />{controlDashboardData.inProgressControlCount}
                                                                    </p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <span className="font-medium font-10 compliance-desc progress-text text-nowrap">Un
                                                                        Assigned</span>
                                                                    <p className="d-flex align-items-center mt-2 font-14">
                                                                        <span className="pointer-unassigned me-2" />{controlDashboardData.unAssignedControls}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="bg-white p-3 font-medium rm-dashboard-card">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h3 className="font-14 font-medium primary-textcolor mb-0">
                                                                    Policy</h3>
                                                            </div>
                                                            <div className="d-flex my-4">
                                                                <p className="font-bold font-24">{policyDashboardData?.policyCount}<span className="font-medium font-14 ms-2 compliance-desc">Total
                                                                    Policies</span></p>
                                                            </div>
                                                            <div className="progress compliance-progress mb-3">
                                                                <div className="progress-bar bg-completed" role="progressbar" style={{ width: policyDashboardDataPercentage.completedPercentage}} aria-valuenow={15} aria-valuemin={0} aria-valuemax={100} />
                                                                <div className="progress-bar bg-inprogress" role="progressbar" style={{ width: policyDashboardDataPercentage.inProgressPercentage}} aria-valuenow={30} aria-valuemin={0} aria-valuemax={100} />
                                                                <div className="progress-bar bg-unassigned" role="progressbar" style={{ width: policyDashboardDataPercentage.unAssignedPercentage}} aria-valuenow={20} aria-valuemin={0} aria-valuemax={100} />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <span className="font-medium font-10 compliance-desc progress-text">Completed</span>
                                                                    <p className="d-flex align-items-center mt-2 font-14">
                                                                        <span className="pointer-completed me-2" />{policyDashboardData?.acceptedPolicyCount}
                                                                    </p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <span className="font-medium font-10 compliance-desc progress-text text-nowrap">In
                                                                        Progress</span>
                                                                    <p className="d-flex align-items-center mt-2 font-14">
                                                                        <span className="pointer-inprogress me-2" />{policyDashboardData?.inProgressPolicyCount}
                                                                    </p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <span className="font-medium font-10 compliance-desc progress-text text-nowrap">Un
                                                                        Assigned</span>
                                                                    <p className="d-flex align-items-center mt-2 font-14">
                                                                        <span className="pointer-unassigned me-2" />{policyDashboardData?.unAssignedPolicyCount}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="bg-white p-3 font-medium rm-dashboard-card">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h3 className="font-14 font-medium primary-textcolor mb-0">
                                                                    Evidence</h3>
                                                            </div>
                                                            <div className="d-flex my-4">
                                                                <p className="font-bold font-24">{evidenceDashboardData.evidenceCount !== 0 ? evidenceDashboardData.evidenceCount : 0}<span className="font-medium font-14 ms-2 compliance-desc">Total
                                                                    Evidences</span></p>
                                                            </div>
                                                            <div className="progress compliance-progress mb-3">
                                                                <div className="progress-bar bg-completed" role="progressbar" style={{ width: evidenceDashboardDataPercentage.completedPercentage}} aria-valuenow={15} aria-valuemin={0} aria-valuemax={100} />
                                                                <div className="progress-bar bg-inprogress" role="progressbar" style={{ width: evidenceDashboardDataPercentage.inProgressPercentage}} aria-valuenow={30} aria-valuemin={0} aria-valuemax={100} />
                                                                <div className="progress-bar bg-unassigned" role="progressbar" style={{ width: evidenceDashboardDataPercentage.unAssignedPercentage}} aria-valuenow={20} aria-valuemin={0} aria-valuemax={100} />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <span className="font-medium font-10 compliance-desc progress-text">Completed</span>
                                                                    <p className="d-flex align-items-center mt-2 font-14">
                                                                        <span className="pointer-completed me-2" />{evidenceDashboardData.acceptedEvidenceCount}
                                                                    </p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <span className="font-medium font-10 compliance-desc progress-text text-nowrap">In
                                                                        Progress</span>
                                                                    <p className="d-flex align-items-center mt-2 font-14">
                                                                        <span className="pointer-inprogress me-2" />{evidenceDashboardData.inProgressEvidenceCount}
                                                                    </p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <span className="font-medium font-10 compliance-desc progress-text text-nowrap">Un
                                                                        Assigned</span>
                                                                    <p className="d-flex align-items-center mt-2 font-14">
                                                                        <span className="pointer-unassigned me-2" />{evidenceDashboardData.evidenceUnAssigned}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    )
}

