import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { agovContext, encryptStorage, getFileIcon, validateFileType, validatePolicyFileType } from "../../Constant";
import { handleUpload } from '../../Configs/BlobContainer';
import { deleteParticularDocument, deletePolicyOrEvidence, generateSasToken, getEvidenceDetails, getRecurrenceData, insertLog, updateEvidenceActive, updateEvidencePause, updateEvidenceRecurrence, uploadEvidence } from "../../Services/GRC/GrcService";
import { Loader } from "../Loader/Loader";
import ToastMessageComponent from "../ToastMessageComponent/ToastMessageComponent";
import AssignPopupComponent from "./AssignPopupComponent";
import DeletePopup from "./DeletePopup";
import InactivePopup from './InactivePopup';
import RemovePopup from "./RemovePopup";
import { saveAs } from "file-saver";
import TaskForm from "../Task/TaskForm";

const EvidenceDeatils = () => {
    // PC_ED_02
    // create the respective state variables

    const navigate = useNavigate()
    const location = useLocation();
    const evidenceId = location?.state?.evidenceId

    const [evidenceDetails, setEvidenceDetails] = useState(null)
    const [recurrenceId, setRecurrenceId] = useState(null)
    const [fileSizes, setFileSizes] = useState({});
    const [fileType, setFileType] = useState({});
    const [showInActivePopup, setShowInActivePopup] = useState(false)
    const [identifier, setIdentifier] = useState('evidenceControl');
    const [popupName, setPopupName] = useState('Controls')
    const [reRender, setReRender] = useState(true);
    const [evidenceInformation, setEvidenceInformation] = useState({ evidenceId: '', evidenceName: '' });
    const [controlInformation, setControlInformation] = useState('');
    const [unmapId, setUnmapId] = useState('');
    const [unmapName, setUnmapName] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState('')
    const [deletePopupData, setDeletePopupData] = useState({ heading: null, subHeading: null, content: null, fileName: null })
    const [mappedControlNames, setMappedControlNames] = useState(null)
    const [isToastVisible, setIsToastVisible] = useState(false)
    const [toastMessage, setToastMessage] = useState({
        path: null,
        header: null,
        message: null,
        color: null
    })
    const [isLoading, setIsLoading] = useState(false);
    const [isViewTask, setIsViewTask] = useState('');
    const [viewTaskId, setViewTaskId] = useState('');
    const [isCreateTask, setIsCreateTask] = useState(false);
    const [isUpdateTask, setIsUpdateTask] = useState(false);  
    const [isSuccessToastMessage, setIsSuccessToastMessage] = useState(false)

    const endDate = encryptStorage.getItem('fin')
    const { roles, userId } = useContext(agovContext);

    const isAdmin = roles.includes('Super Admin') || roles.includes('GRC Admin')
    const viewAsAuditor = false

    let response;

    /**
 * PC_ED_05
 * The useEffect calls the API to get the data for page if evidenceId value is there when ever the reRender changes
 */
    useEffect(() => {
        if (evidenceId) {
            fetchEvidenceDetails()
            // fethchRecurrenceData()
        }
        else {
            navigate('/compliance/evidenceGrid')
        }
    }, [reRender]);

    /**
 * PC_PD_65
 * Get the file size based on the file url
 */
    const fetchFileSizes = async (fileUrls) => {
        const sizes = {};

        fileUrls?.map(async (data, index) => {
            setIsLoading(true)
            try {
                const response = await fetch(data.evidenceBlobLink, { method: 'HEAD' });
                if (response.ok) {
                    const size = (response.headers.get('content-length') / (1024 * 1024)).toFixed(2);
                    sizes[index] = size;
                } else {
                    throw new Error('Unable to fetch file size. Status code: ' + response.status);
                }
            } catch (error) {
                console.error('Error fetching file size:', error);
                sizes[data] = 'Error';
            }
            finally {
                setIsLoading(false)
                if (fileUrls.length - 1 <= index) {
                    setFileSizes(sizes);
                }
            }
        })
    };

    /**
 * PC_PD_68
 * Get the file extention based on the file url
 */
    const fetchFileType = async (fileUrls) => {
        const types = [];
        fileUrls?.map((data, index) => {
            types[index] = getFileIcon(data.evidenceName)
        })
        setFileType(types);
    };

    /**
 * PC_ED_09
 * Get the evidence details form the DB
 */
    const fetchEvidenceDetails = async () => {
        setIsLoading(true)
        try {
            response = await getEvidenceDetails({ evidenceId })
            setRecurrenceId(response.responseData.evidenceDetails.recurrenceId)
            setEvidenceDetails(response.responseData)
            fetchFileSizes(response.responseData.evidences)
            fetchFileType(response.responseData.evidences)

            setEvidenceInformation({ evidenceId: response?.responseData?.evidenceDetails?.evidenceId, evidenceName: response?.responseData?.evidenceDetails?.evidenceName });
            const allPrincipleId = response.responseData.mappedControls.flatMap(obj => obj.PrincipleId);
            const uniquePrins = [...new Set(allPrincipleId)];
            const result = uniquePrins.join(', ');
            setMappedControlNames(result)
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
 * PC_PD_74
 * formate the date to display
 */
    const formatTimeline = (date) => {
        return `${format(date, "MM/dd/yyyy")}`;
    };

    /**
     * PC_ED_125
     * Call the Upload API and pass the uploaded file
     */
    const handleFileUpload = async (event) => {
        const validate = evidenceDetails?.evidenceDetails?.evidenceName === 'System Description' ? validatePolicyFileType(event) : validateFileType(event)
        const fileSizeCheck = (event.target.files[0].size/(1024 * 1024)).toFixed(2)
        if (validate || fileSizeCheck <= 50) {
            try {
                setIsLoading(true)
                const selectedFile = await handleUpload(event.target.files[0])
                await uploadEvidence({ blobLink: selectedFile, blobName: event.target.files[0].name, evidenceId: evidenceId, userId: userId })
                await fetchEvidenceDetails()
            }
            catch (error) {
                console.log(error, "error");
            }
            finally {
                setIsLoading(false)
            }
        }
        else {
            setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Invalid File', message: 'Please select a valid file', color: 'error-toast' })
            setIsToastVisible(true)
        }
    }

    /**
 * PC_ED_36
 * Update the change recurrence time period in the DB
 */
    const handleRecurrenceChange = async (e) => {
        setIsLoading(true)
        console.log(e, "e");
        const evidenceName = evidenceDetails?.evidenceDetails?.evidenceName
        try {
            await updateEvidenceRecurrence({ recurrenceId: e.target.value, evidenceId: evidenceId, userId: userId, evidenceName: evidenceName, endDate: endDate })
            await fetchEvidenceDetails()
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
 * PC_ED_52
 * Update the status of the evidence as in-active in the DB
 */
    const handleInActive = async (data) => {
        setIsLoading(true)
        const date = format(new Date(data.pausedTillDate), "yyyy-MM-dd HH:mm:ss.SSS")
        try {
            setShowInActivePopup(false)
            await updateEvidencePause({ evidenceId: evidenceId, pauseStatus: 'InActive', pauseReason: data.reason, pauseTillDuration: date, evidenceName: evidenceDetails?.evidenceDetails?.evidenceName, userId: userId })
            await fetchEvidenceDetails()
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
 * PC_ED_225
 * Update the status of the evidence as active in the DB
 */
    const handleActive = async () => {
        setIsLoading(true)
        try {
            await updateEvidenceActive({ evidenceId: evidenceId, pauseStatus: 'Active', evidenceName: evidenceDetails?.evidenceDetails?.evidenceName, userId: userId })
            await fetchEvidenceDetails();
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
 * PC_ED_261
 * Delete the hole evidence
 */
    const deleteEvidence = async () => {
        setIsLoading(true)
        try {
            await deletePolicyOrEvidence({ identifier: 'evidence', deletionId: evidenceId })
            // await fetchEvidenceDetails();
            navigate('/compliance/evidenceGrid')
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
   * PC_ED_280
   * Open the selected file in new tab
   */
    const openFileInNewWindow = async (file, fileName) => {
        setIsLoading(true)
        try {
            response = await generateSasToken({ resourceTypes: "o", permission: "r" })
            const sasToken = response.responseData;
            const fileUrl = file + sasToken
            saveAs(fileUrl, fileName);
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    };

    //   const handleEvidenceDownload = (selectedFile) => {
    //     console.log(selectedFile,"selectedFile");
    //   }

    /**
 * PC_ED_156
 * Download the selected file
 */
    const handleEvidenceDownload = async (fileUrl, fileName, evidenceName) => {
        setIsLoading(true)
        saveAs(fileUrl, fileName);
        try {
            await insertLog({ event: 'evidenceDownload', eventDescription: evidenceName, userId: userId, category: 'grcManagement', riskType: 'download', result: 'Sucess' })
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    };


    const handleUnmap = (data) => {
        setControlInformation({
            controlDetails: {
                controlIdentity: data?.controlIdentity,
                controlName: data?.ControlName,
                controlId: data?.ControlId
            }
        });
        setUnmapId(evidenceDetails?.evidenceDetails?.evidenceId);
        setUnmapName(evidenceDetails?.evidenceDetails?.evidenceName);
        setIdentifier("controlEvidence");
    }

    /**
* PC_ED_72
* Delete the selected document
*/
    const deleteDocument = async (data) => {
        setIsLoading(true)
        const blobLink = data.split('?')
        try {
            setShowDeletePopup(false)
            await deleteParticularDocument({ userId: userId, blobLink: blobLink[0], deletionId: evidenceId, identifier: "evidence", fileName:  deletePopupData.fileName})
            await fetchEvidenceDetails()
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
 * PC_ED_255
 * Call the delete function according to the user click
 */
    const handleDeleteClick = () => {
        if (showDeletePopup === 'evidence') {
            deleteEvidence()
        }
        else {
            deleteDocument(showDeletePopup)
        }
    }

    /**
 * PC_ED_250
 * Call the delete popup component by sending the required inputs
 */
    const handleDeleteDocument = (data, fileName) => {
        setDeletePopupData({ ...deletePopupData, heading: 'Delete evidence Document', content: 'Please unmap any controls associated with this evidence document before deleting to avoid data inconsistencies.', subHeading: mappedControlNames, fileName: fileName })
        setShowDeletePopup(data)
    }

    /**
 * PC_ED_250
 * Call the delete popup component by sending the required inputs
 */
    const handleDeleteEvidence = () => {
        setDeletePopupData({ ...deletePopupData, heading: 'Delete evidence', content: 'Are you sure you want to delete this evidence? This action will permanently remove the selected evidence contact information.', subHeading: null })
        setShowDeletePopup('evidence')
    }

    /**
 * PC_ED_270
 * Close the delete popup
 */
    const handleCloseDeletePopup = () => {
        setShowDeletePopup('')
    }

    const handleCloseInActive = () => {
        setShowInActivePopup(false)
    }

    const navigateTaskAsAuditor = () => {
        setViewTaskId(evidenceDetails?.evidenceDetails?.evidenceTaskId)
        setIsViewTask(true)
    }


    return (
        <div className="container-fluid mt-4 mt-custom">
            <Loader isLoading={isLoading} />
            <div className="col-md-12 px-5">
                <div className="d-lg-flex justify-content-between align-items-center mt-4 pb-3 pb-lg-0">
                    <div className="d-flex align-items-center">
                        <img src="/images/left-arrow.svg" alt="back" className="me-3 pointer" style={{cursor : 'pointer'}}onClick={() => navigate(-1)} />
                        <h1 className="font-bold font-24 primary-textcolor mb-0">
                            {evidenceDetails?.evidenceDetails?.evidenceName}
                        </h1>
                    </div>
                    {isAdmin ? <div className="d-flex justify-content-end align-items-center mt-3 mt-lg-0">
                        <p className="font-regular font-13 mb-0 me-3"><span className={evidenceDetails?.evidenceDetails?.evidenceStatus === "Accepted" ? "green-dot big-dot me-2" : evidenceDetails?.evidenceDetails?.evidenceStatus === "Upload Pending" ? "orange-dot big-dot me-2" : "grey-dot big-dot me-2"}
                        />{evidenceDetails?.evidenceDetails?.evidenceStatus}
                        </p>
                        {evidenceDetails?.evidenceDetails?.pauseStatus != 'Active' ? <button type="button" className="cust-filter-btn font-14  font-regular primary-textcolor download-btn me-2" disabled={viewAsAuditor} onClick={() => handleActive()}>
                            <img src="/images/play-icon.svg" alt="browse" className="me-2" />Active
                        </button> :
                            <button type="button" className="cust-filter-btn font-14  font-regular primary-textcolor download-btn me-2" disabled={viewAsAuditor} onClick={() => setShowInActivePopup(!showInActivePopup)}>
                                <img src="/images/play-icon.svg" alt="browse" className="me-2" />In-Active
                            </button>}
                        <button type="button" className="cust-filter-btn font-14  font-regular primary-textcolor download-btn" disabled={viewAsAuditor} onClick={() => handleDeleteEvidence()}>
                            <img src="/images/delete-icon-grey.svg" alt="browse" className="me-2" />Delete Evidence
                        </button>
                    </div> : null}
                </div>
                {isAdmin && !viewAsAuditor && !(evidenceDetails?.evidenceDetails?.evidenceName === 'System Description' && evidenceDetails?.evidences?.length) ? <div className="col-md-12">
                    <div className="upload-files mt-4 p-4">
                        <div className="col-md-12 col-sm-12 p-0">
                            <span className="upload-btn-wrapper upload-wrapper d-block text-center">
                                <img src="/images/upload-btn-icon.svg" alt="dropfiles" className="drop-file text-center mb-2" />
                                <span className="font-regular font-13 d-block text-center primary-textcolor mb-3">
                                    Select a file
                                </span>
                                <span className="text-center upload-btn-wrapper mb-3">
                                    <button type="button" className="cust-filter-btn font-14  font-medium primary-color upload-btn ">
                                        <img src="/images/upload.svg" alt="upload" className="me-2" />Upload
                                        <input type="file" accept=".docx, .jpg, .pdf, .png, .webp, .xlsx, .csv, .txt" onChange={(e) => handleFileUpload(e)} />
                                    </button>
                                </span>
                                <span className="font-regular font-10 d-block text-center position-relative grey-light">
                                    Upload files up to 50 MB of the following types: .docx, .pdf, {evidenceDetails?.evidenceDetails?.evidenceName != 'System Description' ? '.jpg, .png, .webp, .xlsx, .csv, .txt' : null}
                                </span>
                                <input type="file" name="myfile" className="cursor-pointer cust-file" id="OrganizationLogo" />
                            </span>
                        </div>

                    </div>
                </div> : null}
                <div className="col-md-12 mt-4">
                    <div>
                        <p className="font-16 font-medium">Files ({evidenceDetails?.evidences?.length})</p>
                    </div>
                    <div className="col-md-12 mb-4">
                        {evidenceDetails?.evidences?.map((data, index) =>
                            <div className="d-inline-flex justify-content-between align-items-center border border-radius-8 p-3 mr-2">
                                <div className="d-flex align-items-center">
                                    <span className="course-thumbnail d-flex">
                                        <img src={fileType[index]} className="w-100" alt="Thumbnail" />
                                    </span>
                                    <div className="ps-3 align-items-center vertical-align-middle">
                                        <span className="font-medium font-12 primary-textcolor d-block">{data?.evidenceName}</span>
                                        <span className="grey-light font-regular font-12">{fileSizes[index] || 0}MB</span>
                                    </div>
                                </div>
                                <div className="download-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    {/* <button className="btn border-0 p-0 align-top" type="button" id="dropdownMenuButton3" onClick={() => setShowDownloadOptions(!showDownloadOptions)}> */}
                                    <img src="/images/kebab-right.svg" alt="kebab-icon" className=" align-top pt-1" />
                                    {/* </button> */}
                                    <ul className="dropdown-menu custom-dropdown py-3">
                                        <li>
                                            <span className="dropdown-item  font-14 d-flex align-items-center font-regular primary-textcolor custom-dropdown-li py-2 px-2 cursor-pointer" onClick={() => handleEvidenceDownload(data?.evidenceBlobLink, data?.evidenceName, evidenceDetails.evidenceDetails.evidenceName)}>
                                                <img src="/images/download-light-blue-icon.svg" alt="download-icon" className="me-2" />Download
                                            </span>
                                        </li>
                                        {isAdmin ? <li>
                                            <span className="dropdown-item py-2 font-14 d-flex align-items-center font-regular primary-textcolor px-2 custom-dropdown-li cursor-pointer" onClick={() => !viewAsAuditor ? handleDeleteDocument(data?.evidenceBlobLink, data?.evidenceName) : null}>
                                                <img src="/images/delete-icon.svg" alt="eye-icon" className="me-2" />Delete
                                            </span>
                                        </li> : null}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isAdmin && evidenceDetails?.evidenceDetails?.evidenceName === 'System Description' ? <div className="col-md-12">
                    <div className="template-container p-4 mt-4">
                        <div className=" d-flex justify-content-between align-items-center">
                            <div>
                                <div className="d-flex align-items-center mb-3"><img src="/images/info-icon-unfilled.svg" className="me-2" />
                                    <p className="font-16 font-medium primary-textcolor mb-0">Generate a System Description</p>
                                </div>
                                <p className="font-13 font-regular primary-textcolor ps-4 mb-0">Use our template guide to generate a system description document.</p>
                            </div>
                            <div>
                                <button type="button" className="cust-filter-btn font-14 font-regular primary-color upload-btn" disabled={viewAsAuditor} onClick={() => navigate('/compliance/SystemDescription')}>
                                    Open Template
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {showInActivePopup ? <InactivePopup onOpen={"Evidence"} onSave={handleInActive} onClose={handleCloseInActive} /> : null}
                {/* Accordion structure starts here */}
                <ul className="list-unstyled ps-0">
                    <li className=" py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <button className="p-0 d-flex align-items-center border-0 shadow-none orgtodo-accbutton-custom bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#details" aria-expanded="true" aria-controls="details">
                                <span className="font-16 font-medium mb-0 primary-textcolor ms-3">Details</span>
                            </button>
                        </div>
                        <div id="details" className="accordion-collapse table-parent-accordion collapse show pt-3">
                            <div className="rounded-4 cust-acc-box-shadow py-2">
                                <div className="cus-tab-border violet-tab-bg py-3 px-4">
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="CompanyName" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Description
                                        </label>
                                        <p className="font-12 font-regular mb-2 data-color mb-3">
                                            {evidenceDetails?.evidenceDetails?.evidenceDescription}
                                        </p>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="Companybackground" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Recurrence
                                        </label>
                                        <select className="form-select custom-form-control w-25" value={recurrenceId} onChange={(e) => handleRecurrenceChange(e)}>
                                            {evidenceDetails?.recurrenceOptions?.map((data) =>
                                                <option value={data.recurrenceId}>{data.recurrenceName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="Companybackground" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Task ID
                                        </label>
                                        <p className={evidenceDetails?.evidenceDetails?.evidenceTaskId ? "font-12 font-medium secondary-textcolor mb-2 data-color pointer" : "font-12 font-medium secondary-textcolor mb-2 data-color"} style={evidenceDetails?.evidenceDetails?.evidenceTaskId ? { color: "blue" } : {}} onClick={() => evidenceDetails?.evidenceDetails?.evidenceTaskId ? (!isAdmin ? navigateTaskAsAuditor() : navigate('/taskmanagement', { state: { viewTaskId: evidenceDetails?.evidenceDetails?.evidenceTaskId } })) : null}>
                                            {evidenceDetails?.evidenceDetails?.evidenceTaskId || 'Nil'}
                                        </p>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="Companybackground" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Assigned To
                                        </label>
                                        <div className="d-flex align-items-center">
                                            {evidenceDetails?.evidenceDetails?.evidenceAssignedTo ? <img src={evidenceDetails?.evidenceDetails?.profileUrl} className="table-prof" /> : null}
                                            <p className="font-12 primary-textcolor mb-0 ms-2"> {evidenceDetails?.evidenceDetails?.evidenceAssignedTo || 'Unassigned'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className=" py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <button className="p-0 d-flex align-items-center border-0 shadow-none orgtodo-accbutton-custom bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#controls" aria-expanded="false" aria-controls="controls">
                                <span className="font-16 font-medium mb-0 primary-textcolor ms-3">Controls</span>
                            </button>
                            {isAdmin ? <div className="d-flex align-items-center">
                                <button className="create-task-button medium-text customflip-btn mb-2" type="button" disabled={viewAsAuditor} onClick={() => { setIdentifier('evidenceControl') }} data-bs-toggle="modal" data-bs-target="#add-policy" >
                                    <img src="/images/Plus-icon.svg" alt="Plus-icon" className="plus-icon me-2" />Map Control
                                </button>
                            </div> : null}
                        </div>
                        <div id="controls">
                            <div className="rounded-4 cust-acc-box-shadow py-2">
                                <div className="cus-tab-border violet-tab-bg py-1 px-4">
                                    <div className="col-md-12 mb-3">
                                        <div className="table-responsive">
                                            <table className="table text-nowrap mb-0 table-borderless mt-3">
                                                <thead>
                                                    <tr>
                                                        <th className="font-14 font-medium position-relative">Section Code</th>
                                                        <th className="font-14 font-medium position-relative">Control</th>
                                                        {isAdmin ? <th className="font-14 font-medium position-relative text-center">Action</th> : null}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {evidenceDetails?.mappedControls?.map((data) =>
                                                        <tr>
                                                            <td className="font-12 font-regular">{data?.PrincipleId?.join(', ')}</td>
                                                            <td className="font-12 font-regular">{data?.ControlName}</td>
                                                            {isAdmin ? <td data-bs-toggle="modal" data-bs-target="#unmap-control" className="text-center" onClick={() => viewAsAuditor ? null : handleUnmap(data)}><img src="/images/link-icon.svg" /></td> : null}
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className=" py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <button className="p-0 d-flex align-items-center border-0 shadow-none orgtodo-accbutton-custom bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#versionHistory" aria-expanded="false" aria-controls="versionHistory">
                                <span className="font-16 font-medium mb-0 primary-textcolor ms-3">Version History</span>
                                <div className="tooltip-container">

                                    <img src="/images/info-icon.svg" className="ms-1"/>
                                    <div className="tooltip">Download Version History</div>
                                </div>
                            </button>
                        </div>
                        <div id="versionHistory">
                            <div className="rounded-4 cust-acc-box-shadow py-2">
                                <div className="cus-tab-border violet-tab-bg py-1 px-4">
                                    <div className="col-md-12 mb-3">
                                        <div className="table-responsive">
                                            <table className="table text-nowrap mb-0 table-borderless mt-3">
                                                <thead>
                                                    <tr>
                                                        <th className="font-14 font-medium position-relative">Document Name</th>
                                                        <th className="font-14 font-medium position-relative">Uploader Name</th>
                                                        <th className="font-14 font-medium position-relative">Audit Timeline</th>
                                                        <th className="font-14 font-medium position-relative">Uploaded Date</th>
                                                        <th className="font-14 font-medium position-relative">Approver Name</th>
                                                        <th className="font-14 font-medium position-relative">Approved Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {evidenceDetails?.evidenceHistory?.map((data) =>
                                                        <tr>
                                                            <td className="font-12 font-regular secondary-textcolor cursor-pointer" style={{ color: "blue" }} onClick={() => openFileInNewWindow(data?.uploadedEvidenceBlobLink, data?.uploadedEvidenceName)}>{data?.uploadedEvidenceName}</td>
                                                            <td className="font-12 font-regular">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={data.uploaderProfileUrl} className="table-prof" />
                                                                    <p className="font-12 primary-textcolor mb-0 ms-2">{data?.evidenceUploadedBy}</p>
                                                                </div>
                                                            </td>
                                                            <td className="font-12 font-regular">{data.evidenceAuditTimeline}</td>
                                                            <td className="font-12 font-regular">{formatTimeline(data.evidenceUploadedOn)}</td>
                                                            <td className="font-12 font-regular">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={data?.approverProfileUrl} className="table-prof" />
                                                                    <p className="font-12 primary-textcolor mb-0 ms-2">{data.evidenceApproverId}</p>
                                                                </div></td>
                                                            <td className="font-12 font-regular">{formatTimeline(data.evidenceApprovedOn)}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                {/* Accordion structure ends here */}
            </div>
            {evidenceDetails ? <AssignPopupComponent identifier={identifier} popupName={popupName} mappingInformation={evidenceInformation} reRender={reRender} setReRender={setReRender} mappingFrom={'evidence'} /> : null}
            <RemovePopup identifier={identifier} controlInformation={controlInformation} mappingId={unmapId} mappingName={unmapName} render={reRender} setReRender={setReRender} />
            {showDeletePopup ? <DeletePopup onClose={handleCloseDeletePopup} onDelete={handleDeleteClick} heading={deletePopupData?.heading} subHeading={deletePopupData?.subHeading} content={deletePopupData?.content} /> : null}
            {isToastVisible && <ToastMessageComponent
                toastResponse={toastMessage}
                setIsShowToastMessage={setIsToastVisible}
            />}
             {isViewTask ? <TaskForm props={
                {
                    taskId: viewTaskId,
                    isViewTask: isViewTask,
                    setIsViewTask: setIsViewTask,
                    setIsCreateTask: setIsCreateTask,
                    setIsUpdateTask: setIsUpdateTask,
                    setIsSuccessToastMessage: setIsSuccessToastMessage
                }} /> : <></>
            }
        </div>
    )
}

export default EvidenceDeatils;