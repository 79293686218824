import React, { useState, useEffect } from 'react';

const ControlsGridSidebar = ({ controls, handleCategoryClick, setHideControlsGrid, setHideControlInfo, setHideAddControlPage }) => {


  const [openIndex, setOpenIndex] = useState(null);


  return (
    <div className="col-lg-3 d-lg-block d-none pe-0 control-lefttab-scroll">
      <div className="accordion" id="accordionExample">
        <h6 className="font-medium sticky-top bg-white font-16 ps-4 mb-3" style={{zIndex: 1}}>
          Requirement Categories
        </h6>
        {controls.map((trustService, trustServiceIndex) => (
          <div className="accordion-item border-0 border-bottom" key={trustServiceIndex}>
            <h2 className="accordion-header" id={`heading${trustServiceIndex}`}>
              <button
                className="btn accordion-button Compliance-accbutton-custom font-medium font-14 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${trustServiceIndex}`}
                aria-expanded="true"
                aria-controls={`collapse${trustServiceIndex}`}
                onClick={() => setOpenIndex(openIndex === trustServiceIndex ? null : trustServiceIndex)}
              >
                {trustService.trust_service_name}
              </button>
            </h2>
            <div
              id={`collapse${trustServiceIndex}`}
              className={`accordion-collapse collapse `}
              aria-labelledby={`heading${trustServiceIndex}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="d-flex align-items-start">
                  <div
                    className="nav flex-column nav-pills w-100"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {trustService.categories.map((category, categoryIndex) => (
                      <button
                        className="nav-link Compliance-sidenav-btn font-14 cus-tab-border py-4 d-flex align-items-center mb-2 active"
                        id={`Controlenv-tab${categoryIndex}`}
                        data-bs-toggle="pill"
                        data-bs-target={`#Controlenv${categoryIndex}`}
                        type="button"
                        role="tab"
                        aria-controls={`Controlenv${categoryIndex}`}
                        aria-selected="true"
                        onClick={() => { handleCategoryClick(trustServiceIndex, categoryIndex); setHideControlsGrid(false); setHideControlInfo(true); setHideAddControlPage(true) }}
                        key={categoryIndex}
                      >
                        {category.categoryId} - {category.categoryName}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ControlsGridSidebar;