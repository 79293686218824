import { useEffect, useState } from "react";
import { getUsersNonCompliantDetails } from "../../../../Services/Agov/governanceService";
import PopupNonCompliantUsersGrid from "./PopupNonCompliantUsersGrid";
import PopupNonCompliantUsersHeader from "./PopupNonCompliantUsersHeader";
import exportDataToExcel from "./exportDataToExcel";

const PopupNonCompliantUsersOverview = ({
  isPopupClicked,
  managerId,
  roles,
  isSuperAdminAndManager,
  gridActiveTab,
  onClose,
  gridFilter,
}) => {
  const [popupNonCompliantUsers, setPopupNonCompliantUsers] = useState([]);
  const [popupTotalUsers, setPopupTotalUsers] = useState(0);
  const [popupActiveTab, setPopupActiveTab] = useState(gridActiveTab);
  const [popupFilter, setPopupFilter] = useState(gridFilter);
  const [popupSort, setPopupSort] = useState({
    column: "",
    order: "",
  });
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [popupIsLoading, setPopupIsLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  // NCU_FE_PC_57 - NCU_FE_PC_63 -> Fetch the Data Based on the Infinite Scroll in the Grid.
  useEffect(() => {
    fetchPopupUsersNonCompliantDetails();
  }, [popupActiveTab, popupFilter, popupSort, isPopupClicked, page]);

  const fetchPopupUsersNonCompliantDetails = async () => {
    setPopupIsLoading(true);

    try {
      const payload = {
        roles,
        isSuperAdminAndManager,
        managerId,
        activeTab: popupActiveTab,
        filter: popupFilter,
        sort: popupSort,
        isPopupClicked,
        page,
      };
      const response = await getUsersNonCompliantDetails(payload);
      if (response?.statusCode === 200) {
        setPopupNonCompliantUsers((prevUsers) =>
          page === 1
            ? response?.result?.users
            : [...prevUsers, ...response.result.users]
        );
        setPopupTotalUsers(response?.result?.totalCount);
        setHasMore(response?.result?.hasMore);
      }
    } catch(error) {
      console.error("Error Fetching Non-Compliant Users API", error);
    } finally {
      setPopupIsLoading(false);
    }
  };

  const handleExportToExcel = async () => {
    const payload = {
      roles,
      isSuperAdminAndManager,
      managerId,
      activeTab: popupActiveTab,
      filter: popupFilter,
      sort: popupSort,
      isPopupClicked,
      page: 1,
      isExcelClicked: true,
    };

    const response = await getUsersNonCompliantDetails(payload);
    if (response?.statusCode === 200) {
      const excelData = response.result.excelData;
      exportDataToExcel(excelData, popupActiveTab);
    }
  };

  const handlePopupTabChange = (tab) => {
    setPopupActiveTab(tab);
    setPopupSort({
      column: "",
      order: "",
    });
    setPage(1);
    setPopupNonCompliantUsers([]);
    setScrollPosition(0);
  };

  const handlePopupSort = (column, order) => {
    if (popupSort.column === column && popupSort.order === order) {
      return;
    }
    
    setPopupSort({ column, order });
    setPage(1);
    setPopupNonCompliantUsers([]);
  };

  const handlePopupFilterChange = (value) => {
    setPopupFilter(value);
    setPage(1);
    setPopupNonCompliantUsers([]);
  };

  const loadMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <PopupNonCompliantUsersHeader
        onPopupTabChange={handlePopupTabChange}
        onPopupFilterChange={handlePopupFilterChange}
        popupFilter={popupFilter}
        roles={roles}
        gridActiveTab={gridActiveTab}
        popupActiveTab={popupActiveTab}
        onClose={onClose}
        onExportToExcel={handleExportToExcel}
        isSuperAdminAndManager={isSuperAdminAndManager}
        popupIsLoading={popupIsLoading}
        popupTotalUsers={popupTotalUsers}
      />
      <PopupNonCompliantUsersGrid
        popupNonCompliantUsers={popupNonCompliantUsers}
        popupTotalUsers={popupTotalUsers}
        onPopupSort={handlePopupSort}
        isSuperAdminAndManager={isSuperAdminAndManager}
        popupFilter={popupFilter}
        loadMoreData={loadMoreData}
        hasMore={hasMore}
        gridActiveTab={gridActiveTab}
        roles={roles}
        popupIsLoading={popupIsLoading}
        popupActiveTab={popupActiveTab}
        scrollPosition={scrollPosition}
        onScrollPosition={setScrollPosition}
      />
    </>
  );
};

export default PopupNonCompliantUsersOverview;