import React, { useEffect, useState } from "react";
import ToastMessageComponent from "../ToastMessageComponent/ToastMessageComponent";

const AddEvidencePopup = ({ recurrenceList, onClose, onSave }) => {
  const [newEvidenceData, setNewEvidencedata] = useState({
    evidenceName: null,
    description: null,
    recurrenceId: null,
  });
  const [isToastVisible, setIsToastVisible] = useState(false)
  const [toastMessage, setToastMessage] = useState({
    path: null,
    header: null,
    message: null,
    color: null
  })

  const addEvidence = () => {
    if (newEvidenceData.evidenceName && newEvidenceData.description && newEvidenceData.recurrenceId != null) {
      onSave(newEvidenceData)
    }
    else {
      setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Missing Fields', message: 'Please fill all the required fields', color: 'error-toast' })
      setIsToastVisible(true)
    }
  }

  return (
    <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
      <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
        <div className="modal-content">
          {/*modal content starts here*/}
          <div className="modal-header border-0 pb-2 px-4">
            <h5
              className="modal-title font-bold font-20"
              id="exampleModalLabel"
            >
              Add Evidence
            </h5>
            <button
              type="button"
              className="btn-close ms-auto"
              aria-label="Close"
              onClick={() => onClose()}
            />
          </div>
          <div className="modal-body border-0 font-16 px-4">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="evidence-name"
                  className="form-label font-medium font-14 primary-textcolor w-100"
                >
                  Evidence Name<span className="required-text">*</span>
                </label>
                <input
                  type="text"
                  className="form-control font-regular font-14 custom-form-control py-2"
                  id="evidence-name"
                  placeholder="Enter Evidence Name"
                  onChange={(e) => setNewEvidencedata({ ...newEvidenceData, evidenceName: e.target.value })}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="description"
                  className="form-label font-medium font-14 primary-textcolor w-100"
                >
                  Description<span className="required-text">*</span>
                </label>
                <textarea
                  type="text"
                  className="form-control font-regular font-14 custom-form-control py-2 resize-none"
                  rows={4}
                  id="description"
                  placeholder="Enter Description"
                  defaultValue={""}
                  onChange={(e) => setNewEvidencedata({ ...newEvidenceData, description: e.target.value })}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="recurrence"
                  className="form-label font-medium font-14 primary-textcolor w-100"
                >
                  Recurrence<span className="required-text">*</span>
                </label>
                <label className="form-label font-regular grey-secondary font-12 w-100">
                  Since how often the document needs to be reviewed since the
                  most recent upload.
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  id="country"
                  onChange={(e) => setNewEvidencedata({ ...newEvidenceData, recurrenceId: e.target.value })}
                >
                  <option selected>Select</option>
                  {recurrenceList.map((data) => (
                    <option value={data.recurrenceId}>
                      {data.recurrenceName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="modal-footer border-0 mb-3 px-4">
            <button
              type="button"
              className="primary-btn font-14 font-medium py-2 me-2"
              onClick={() => onClose()}
            >
              Cancel
            </button>
            <button type="button"
              className={!newEvidenceData.evidenceName || !newEvidenceData.description || !newEvidenceData.recurrenceId ? "disabled-btn font-14 font-medium py-2" : "dark-btn font-14 font-medium py-2"}
              disabled={!newEvidenceData.evidenceName || !newEvidenceData.description || !newEvidenceData.recurrenceId} onClick={() => addEvidence()}>
              Add
            </button>
          </div>
          {/*modal content ends here*/}
        </div>
      </div>
      {isToastVisible && <ToastMessageComponent
        toastResponse={toastMessage}
        setIsShowToastMessage={setIsToastVisible}
      />}
    </div>
  );
};

export default AddEvidencePopup;
