import React, { useEffect, useState } from 'react'


const SystemDescriptionPopup = ({ notAnsweredQuestions, generateDocument, setShowPopup }) => {
    const [showModal, setShowModal] = useState(true);
    const [count, setCount] = useState(0);


    /**
     * GRC_SD_25 - GRC_SD_33
     * The unanswered questions are binded here
     */
    useEffect(() => {
        console.log(notAnsweredQuestions, "lalalalal");
        setCount(notAnsweredQuestions.length)
        console.log(notAnsweredQuestions.length);

        if (1 != 1) {
            setShowModal(false);
        }
    }, []);

    if (!showModal) {
        return null;
    }

    return (
        <div style={{
            position: 'fixed',
            width: '100%',
            height: '100',
            overflow: 'auto',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }} className="modal fade show" id="missing-form" tabIndex={-1} aria-labelledby="exampleModalLabel" data-bs-backdrop="static" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                <div className="modal-content sysdespopup" >
                    {/*modal content starts here*/}
                    <div className="modal-header border-0 pb-2 px-4">
                        <h5 className="modal-title font-bold font-20" id="exampleModalLabel">Missing Form Input</h5>
                        <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setShowPopup(false); }} />
                    </div>
                    <div className="modal-body border-0 font-16 px-4">
                        <div className="row">
                            <div className="col-md-12 mb-3 mt-2">
                                <p>There are <b>{count}</b>  input fields that are currently blank. We will mark these areas in the output document and you can fill them in.</p>
                            </div>
                            <div className="col-md-12 sysdeslistitems">
                                <ul>
                                    {notAnsweredQuestions.map((question, qIndex) => (
                                        <li key={qIndex}>{question.question}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 mb-3 px-4">
                        {/* GRC_SD_34 - GRC_SD_56
                        The generate document or the click of cancel happens hare
                        */}
                        <button type="button" className="primary-btn font-14 font-medium py-2 me-2" data-bs-dismiss="modal" onClick={() => { setShowPopup(false); setShowModal(false); }}>Go Back</button>
                        <button type="button" className="dark-btn font-14 font-medium py-2" onClick={() => { generateDocument(); setShowPopup(false); }}>Generate</button>
                    </div>
                    {/*modal content ends here*/}
                </div>
            </div>
        </div>

    )
}

export default SystemDescriptionPopup
