
import React, { useState, useEffect,useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyAccount,regenerateOTP } from '../../Services/Registration/RegistrationService';
import { Loader } from '../Loader/Loader';


//PS_OV_FE_1.4 to 1.29
const OTPVerificationPopUp = (props) => {

    const { setIsPopShow, registerDetails } = props;
    console.log("props in otp :",props)
    // PS_OV_FE_1.6: Initialize state
    const [errorMessage, setErrorMessage] = useState(false); 
    const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false); 
    const navigate = useNavigate();
    const [otpRequestData, setOtpRequestData] = useState(Array(6).fill(''));
    const inputRefs = useRef([]);
    const [checkEmailMessage, setCheckEmailMessage] = useState(true); 
    const [isLoading, setIsloading] = useState(false);

    // PS_OV_FE_1.8, Function to handle input change and move to next input  

    const handleOTPChange = (index) => (e) => {
        const newInputValues = [...otpRequestData];
        const otpDigit = e.target.value.slice(0, 1).replace(/[^0-9]/g, '');
        const { key } = e;
    
        // Update the OTP value
        newInputValues[index] = otpDigit;
        setOtpRequestData(newInputValues);
    
        // Move focus to the next input if the digit is added
        if (otpDigit && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    
        // Handle backspace: clear current input and move focus to the previous input
        if (key === 'Backspace' && !otpDigit && index > 0) {
            newInputValues[index - 1] = '';
            setOtpRequestData(newInputValues);
            inputRefs.current[index - 1].focus();
        }
    
        // Clear error message if present
        
        setErrorMessage(false);
        setCheckEmailMessage(false);
        setIsSubmitButtonEnabled(newInputValues.every(value => value !== ''));
    };

    // PS_OV_FE_1.10, PS_OV_FE_1.11, PS_OV_FE_2.6, PS_OV_FE_2.7: When the user clicks on the submit button and HandleOTPSubmit
    const handleOTPSubmit = async () => {
        console.log(parseInt(otpRequestData.join('')), "otpRequestData.toString(' ')");
        setIsloading(true);
        const accountVerifyResult = await verifyAccount({
            otp:parseInt(otpRequestData.join('')),
            registerId: registerDetails.registerId
        }); 
        setIsloading(false);

        if (accountVerifyResult.statusCode == 200) {
            if(accountVerifyResult.statusCode == 200 && accountVerifyResult.responseData =="Failed"){
                setErrorMessage(true);
            }
            else{
                // PS_OV_FE_1.28: NavigateToSubscription
            navigate('/subscription', { state: {value:{
                registerId : registerDetails.registerId,
                firstName : registerDetails.firstName,
                headCount : registerDetails.headCount,
                email:registerDetails.email
            }}});
            }
        } else {
            navigate('/error'); 
        }
    };

    // PS_OV_FE_1.27: DisplayError
    const renderErrorMessage = errorMessage ? <p>Enter a valid OTP.</p> : null;

   

    useEffect(() => {
        // Enable or disable submit button based on OTP data
        setIsSubmitButtonEnabled(otpRequestData.every(value => value !== ''));
    }, [otpRequestData]);


    const handleCancelIconClick = () => {
        setIsPopShow(false);
    };

    const resendCode = async (e) => {
        
        e.preventDefault();
        // Your resend logic here. This should call the API to resend code.
        //setIsloading(true);
        const resendResult = await regenerateOTP({registerId:registerDetails.registerId,
                                                  firstName: registerDetails.firstName});
         //setIsloading(false);                                       
        // Handle resend response accordingly
        if (resendResult.statusCode === 200 ) {
            setCheckEmailMessage(true)
        } else {
            navigate('/error')
         }
    };

    return (
        <> 
           <Loader isLoading={isLoading} />
            <div className="modal" id="" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true" data-bs-backdrop="static" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between align-items-center p-4 border-0">
                            <h5 className="modal-title font-14 font-medium primary-textcolor" id="staticBackdropLabel">
                                Verify OTP</h5>
                            <div className>
                                <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCancelIconClick()} />
                            </div>
                        </div>
                        <div className="modal-body px-4 pb-3 pt-0">
                            <div className="pt-0 mb-4">
                                <p className="font-regular font-14">Enter verification code sent to your registered
                                    email address to complete your
                                    account registration and confirm your email address.</p>
                            </div>
                            <div className="d-flex justify-content-start gap-2 mb-2">
                                {otpRequestData.map((value, index) => (
                                    <div key={index} className="d-block me-2">
                                        <input
                                            className="form-control custom-form"
                                            type="text"
                                            maxLength={1}
                                            value={otpRequestData[index] || ''}
                                            onChange={handleOTPChange(index)}
                                            onKeyDown={handleOTPChange(index)}
                                            name={`input-${index}`}
                                            ref={(el) => (inputRefs.current[index] = el)}
                                        />
                                    </div>
                                ))}
                            </div>
                            {/* {checkEmailMessage && (
            <span className="font-regular font-14">Check your email for the OTP.</span>
        )} */}
                            {errorMessage?<span className="cls-error-message font-regular font-14">In Valid OTP</span>:null}
                            <p className="font-14 mt-3">Didn’t received the code? <a href="" className="active-textcolor" onClick = {(e)=>resendCode(e) }>Resend code</a></p>
                            <div className="d-flex justify-content-center mt-4 mb-2">
                                <button type="button"
                                onKeyDownCapture={(e) => {
                                    if (e.key === "Enter") {
                                      handleOTPChange(e);
                                    }
                                  }}
                                 className={ isSubmitButtonEnabled ? "dark-btn w-100" :"disabled-btn w-100 mb-md-0 font-18 font-medium px-4"}
                                 disabled={!isSubmitButtonEnabled} onClick={() => handleOTPSubmit()}>
                                    Verify
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>

        </>

    );
};

export default OTPVerificationPopUp;