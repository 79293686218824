import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  getAuditorDetails,
  getExistingtimeline,
} from "../../Services/GRC/GrcService";
import { Loader } from "../Loader/Loader";
import ToastMessageComponent from "../ToastMessageComponent/ToastMessageComponent";

const AddEditPopup = ({
  onClose,
  onSave,
  heading,
  subHeading,
  content,
  onOpen,
  data,
  count,
  auditorData,
}) => {
  // PC_AE_02
  // create the respective state variables
  const [dateRange, setDateRange] = useState({
    showDatePickers: false,
    datePickerOpen: false,
  });
  const [auditorList, setAuditorList] = useState([]);
  const [existingTimelineLst, setExistingTimelineLst] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    profileUrl: null,
    employeeName: null,
  });
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);
  const [auditData, setAuditData] = useState({
    startDate: null,
    endDate: null,
    auditorId: null,
    auditorFirm: null,
    previousControlOwner: null,
  });
  const [isToastVisible, setIsToastVisible] = useState(false)
  const [toastMessage, setToastMessage] = useState({
    path: null,
    header: null,
    message: null,
    color: null
  })
  const [isLoading, setIsLoading] = useState(false);
  const [tomorrowDate, setTomorrowDate] = useState('')

  let response;

  /**
  * PC_AE_05
  * The useEffect calls the API to get the dropdown data according to the user click
  */
  useEffect(() => {
    fetchAuditorDetails();
    if (onOpen === "Existing") {
      fetchTimelineDetails();
    } else if (onOpen === "Edit") {
      setAuditData({
        ...auditData,
        startDate: data.startDate,
        endDate: data.endDate,
        auditorFirm: data.auditorFirm,
        auditorId: data.auditorId ? data.auditorId : null,
        previousControlOwner: data.previousControlOwner,
      });
      if (data.auditorId) {
        setSelectedItem({
          ...selectedItem,
          profileUrl: auditorData.profileUrl,
          employeeName: auditorData.employeeName,
        });
      }
    }
    else if (onOpen === 'Add' && count == 0) {
      setAuditData({ ...auditData, previousControlOwner: 'No' })
    }
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    setTomorrowDate(tomorrow);
  }, []);

  /**
 * PC_AE_07
 * Get all the auditors present
 */
  const fetchAuditorDetails = async () => {
    setIsLoading(true)
    try {
      response = await getAuditorDetails();
      setAuditorList(response.responseData[0]);
    } catch (error) {
      console.log(error, "error");
    }
    finally {
      setIsLoading(false)
    }
  };

  /**
 * PC_AE_17
 * Get all the audit cycle time lines
 */
  const fetchTimelineDetails = async () => {
    setIsLoading(true)
    try {
      response = await getExistingtimeline();
      setExistingTimelineLst(response.responseData[0]);
    } catch (error) {
      console.log(error, "error")
    }
    finally {
      setIsLoading(false)
    }
  };

  /**
 * PC_AE_42
 * store the selected start date in the state variable dateRange
 */
  const handleStartDateChange = (date) => {
    setAuditData((auditData) => ({ ...auditData, startDate: format(new Date(date), "MM/dd/yyyy HH:mm:ss.SSS") }));
  };

  /**
 * PC_AE_43
 * store the selected end date in the state variable dateRange
 */
  const handleEndDateChange = (date) => {
    setAuditData((auditData) => ({ ...auditData, endDate: format(new Date(date), "MM/dd/yyyy HH:mm:ss.SSS") }));
  };

  /**
 * PC_AE_40
 * Open the date pickers for the user
 */
  const toggleDatePickers = () => {
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      showDatePickers: !prevDateRange.showDatePickers,
      datePickerOpen: !prevDateRange.showDatePickers,
    }));
  };

  /**
 * PC_AE_44
 * Close the date pickers
 */
  const handleInputBlur = () => {
    // setTimeout(() => {
    if (!dateRange.datePickerOpen) {
      setDateRange((prevDateRange) => ({
        ...prevDateRange,
        showDatePickers: false,
      }));
    }
    // }, 200);
  };

  /**
 * PC_AE_45
 * Store the selected date in the state varibale auditData and close the date picker
 */
  const handleSelect = () => {
    if (auditData.startDate && auditData.endDate) {
      setDateRange((prevDateRange) => ({
        ...prevDateRange,
        showDatePickers: false,
      }));
      // setAuditData({
      //   ...auditData,
      //   startDate: format(new Date(dateRange.startDate),"yyyy-MM-dd HH:mm:ss.SSS"),
      //   endDate: format(new Date(dateRange.endDate), "yyyy-MM-dd HH:mm:ss.SSS"),
      // });
    }
  };

  /**
 * PC_AE_50
 * Store the selected auditor in the state varibale auditData
 */
  const changeDropDown = (userId = '', profileUrl = '', employeeName = '') => {
    if (userId) {
      setSelectedItem({
        ...selectedItem,
        profileUrl: profileUrl,
        employeeName: employeeName,
      });
    }
    setAuditData({ ...auditData, auditorId: userId || null });
    setIsDropdownSelected(false);
  };

  /**
 * PC_AE_55
 * Store the selected auditor in the state varibale auditData
 */
  const onAddClick = () => {
    if (auditData.startDate && auditData.endDate && (auditData.previousControlOwner || onOpen != "Add") && (auditData.startDate < auditData.endDate)) {
      onSave(auditData, onOpen);
    }
    else {
      setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Missing Fields', message: 'Please fill all the required fields', color: 'error-toast' })
      setIsToastVisible(true)
    }
  };

  /**
 * PC_AE_53
 * Store the selected timeline in the state varibale auditData
 */
  const handleTimelineSelect = (event) => {
    if (event.target.value) {
      const startDate = existingTimelineLst.filter(
        (data) => data.auditCycleId == event.target.value
      )[0].startDate;
      const endDate = existingTimelineLst.filter(
        (data) => data.auditCycleId == event.target.value
      )[0].endDate;
      setAuditData({ ...auditData, startDate: startDate, endDate: endDate });
    }
  };


  /**
   * PC_AE_26
   * Format the date to display
   */
  const formatTimeline = (startDate, endDate) => {
    return `${format(startDate, "MM/dd/yyyy")} - ${format(
      endDate,
      "MM/dd/yyyy"
    )}`;
  };

  return (
    <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
      <Loader isLoading={isLoading} />
      <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
        <div className="modal-content">
          {/*modal content starts here*/}
          <div className="modal-header border-0 pb-3 px-4">
            <h5 className="modal-title font-bold" id="exampleModalLabel">
              {heading}
            </h5>
            <button
              type="button"
              className="btn-close ms-auto"
              aria-label="Close"
              onClick={onClose}
            />
          </div>
          <div className="modal-body border-0 font-16 px-4">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label className="font-18 font-medium primary-textcolor mb-2">
                  {subHeading}
                </label>
                <p className="font-14 grey-secondary mb-2">{content}</p>
              </div>
              <div className="col-md-12 mb-4">
                <label
                  htmlFor="timeline"
                  className="form-label font-medium font-14 primary-textcolor"
                >
                  Timeline<span className="required-text">*</span>
                </label>
                {onOpen === "Add" ? (
                  <input
                    type="text"
                    className="form-control font-regular font-14 custom-form-control py-2"
                    id="timeline"
                    value={
                      auditData.startDate && auditData.endDate
                        ? `${auditData.startDate.split(' ')[0]} - ${auditData.endDate.split(' ')[0]}`
                        : ""
                    }
                    placeholder="Select Timeline"
                    onClick={toggleDatePickers}
                    onBlur={handleInputBlur}
                    readOnly
                  />
                ) : null}
                {onOpen === "Existing" ? (
                  <select
                    className="form-control font-regular font-14 custom-form-control py-2"
                    onChange={(e) => handleTimelineSelect(e)}
                  >
                    <option value="">Select</option>
                    {existingTimelineLst.map((data) => (
                      <option value={data.auditCycleId}>
                        {formatTimeline(data.startDate, data.endDate)}
                      </option>
                    ))}
                  </select>
                ) : null}
                {onOpen === "Edit" ? (
                  <span className="form-control font-regular font-14 custom-form-control py-2">
                    {formatTimeline(data.startDate, data.endDate)}
                  </span>
                ) : null}

                {dateRange.showDatePickers && (
                  <div className="date-pickers-container">
                    <DatePicker
                      selected={auditData.startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={auditData.startDate}
                      endDate={auditData.endDate}
                      minDate={tomorrowDate}
                      maxDate={auditData.endDate}
                      inline
                      onSelect={handleSelect}
                    />
                    <DatePicker
                      selected={auditData.endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={auditData.startDate}
                      endDate={auditData.endDate}
                      minDate={auditData.startDate || tomorrowDate}
                      inline
                      onSelect={handleSelect}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-4">
                <div className="dropdown">
                  <label
                    htmlFor="auditor"
                    className="form-label font-medium font-14 primary-textcolor mb-1"
                  >
                    Select Auditor
                  </label>
                  <div className="dropdown">
                    <button
                      id="auditor"
                      className="btn font-regular font-14 bg-white custom-form-control w-100 d-flex justify-content-between"
                      onClick={() => setIsDropdownSelected(!isDropdownSelected)}
                      type="button"
                    >
                      {!auditData.auditorId ? (
                        <span className="primary-textcolor font-14">
                          Select
                        </span>
                      ) : (
                        <span className="primary-textcolor font-14">
                          <li className="font-12 font-medium primary-textcolor profile-dropdown-style">
                            <a
                              className="dropdown-item custom-dropdown-li"
                            >
                              <img
                                className="custom-img-style me-2"
                                src={selectedItem.profileUrl}
                                alt="profile-img"
                              />
                              {selectedItem.employeeName}
                            </a>
                          </li>
                        </span>
                      )}
                      <span className="ps-1">
                        <img
                          src="/images/select-dropdown.svg"
                          alt="dropdown-icon"
                        />
                      </span>
                    </button>
                    {isDropdownSelected ? (
                      <ul
                        className="border-0 shadow-sm p-2 w-100 custom-dropdown-ul"
                        data-popper-placement="bottom-start"
                      >
                        <li className="font-12 font-medium primary-textcolor profile-dropdown-style">
                          <a
                            className="dropdown-item custom-dropdown-li"
                            onClick={() =>
                              changeDropDown()
                            }
                          >
                            Select
                          </a>
                        </li>
                        {auditorList.map((data) => (
                          <li className="font-12 font-medium primary-textcolor profile-dropdown-style">
                            <a
                              className="dropdown-item custom-dropdown-li"
                              onClick={() =>
                                changeDropDown(
                                  data.userId,
                                  data.profileUrl,
                                  data.employeename
                                )
                              }
                            >
                              <img
                                className="custom-img-style me-2"
                                src={data.profileUrl}
                                alt="profile-img"
                              />
                              {data.employeename}
                            </a>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  htmlFor="firm"
                  className="form-label font-medium font-14 primary-textcolor mb-1"
                >
                  Auditing Firm
                </label>
                <input
                  type="text"
                  className="form-control font-regular font-14 custom-form-control py-2"
                  id="firm"
                  maxLength={250}
                  value={auditData.auditorFirm}
                  placeholder="Enter Auditing Firm"
                  onChange={(e) =>
                    setAuditData({ ...auditData, auditorFirm: e.target.value })
                  }
                />
              </div>
              {onOpen === "Add" && count ? (
                <div className="col-md-12 mb-3">
                  <label className="form-label font-medium font-14 primary-textcolor w-100">
                    Retain previous control owner
                    <span className="required-text">*</span>
                  </label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-radio-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="yes"
                      // defaultValue="option1"
                      value="yes"
                      onClick={() =>
                        setAuditData({
                          ...auditData,
                          previousControlOwner: "Yes",
                        })
                      }
                    />
                    <label
                      className="form-check-label font-12 font-regular"
                      htmlFor="yes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-radio-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="no"
                      // defaultValue="option2"
                      value="No"
                      onClick={() =>
                        setAuditData({
                          ...auditData,
                          previousControlOwner: "No",
                        })
                      }
                    />
                    <label
                      className="form-check-label font-12 font-regular"
                      htmlFor="no"
                    >
                      No
                    </label>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="modal-footer border-0 mt-2 mb-3 px-4">
            <button
              type="button"
              className="primary-btn font-14 font-medium py-2"
              onClick={() => onClose()}
            >
              Cancel
            </button>
            <button
              type="button"
              className={!auditData.startDate || !auditData.endDate || !(auditData.previousControlOwner || onOpen != "Add") || (auditData.startDate > auditData.endDate) ? "disabled-btn font-14 font-medium py-2" : "dark-btn font-14 font-medium py-2"}
              disabled={!auditData.startDate || !auditData.endDate || !(auditData.previousControlOwner || onOpen != "Add") || (auditData.startDate > auditData.endDate)}
              onClick={() => onAddClick()}
            >
              Add
            </button>
          </div>
          {/*modal content ends here*/}
        </div>
      </div>
      {isToastVisible && <ToastMessageComponent
        toastResponse={toastMessage}
        setIsShowToastMessage={setIsToastVisible}
      />}
    </div>
  );
};

export default AddEditPopup;