// PC_COM_VI_4 to PC_COM_VI_6 -> Import statements
import { useEffect } from "react"
import { useState } from "react"
import { vendorAssessmentApiResponse } from "../../Services/GRC/GrcService"
import { ChartComponent } from "./chartComponent"
import { useNavigate } from "react-router-dom"
import NoRecordFound from "./dashboardNoRecordFound"
import { Loader } from "../Loader/Loader"

// PC_COM_VI_16 -> Component declaration
export function VendorAssessmentInfo() {

    // PC_COM_VI_17 to PC_COM_VI_19 -> state variable creation
    const [vendorInformation, setVendorInformation] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // PC_COM_VI_19 -> useNavigate Declaration
    const vendorNavigation = useNavigate()


    // PC_COM_VI_20 to PC_COM_VI_21 -> UseEffect declaration
    useEffect(() => {
        vendorInfo()
    }, [])

    // PC_COM_VI_22 -> function declaration
    const vendorInfo = async () => {
        try {

            // PC_COM_VI_23 -> set state a variable isLoading and API function call
            setIsLoading(true);
            let result = await vendorAssessmentApiResponse()

            // PC_COM_VI_34 -> update a state variables if statusCode is equals to 200
            if (result.statusCode === 200) {
                setVendorInformation([{
                    name: "completedCount",
                    value: result?.responseData?.vendorCompletedCount
                },
                {
                    name: "notCompletedCount",
                    value: result?.responseData?.vendorNotCompletedCount
                }
                ])
                setTotalCount(result?.responseData?.vendorCount)
            }

            // PC_COM_VI_34 -> Navigates to error page if statusCode is not equeals to 200
            else {
                navigate('/error');
            }

            // PC_COM_VI_35 -> set state a variable isLoading
            setIsLoading(false);
        }

        // PC_COM_VI_36 -> navigate to error page
        catch (error) {
            navigate('/error');
        }
    }

    // PC_COM_VI_41 to PC_COM_VI_43 -> Vendor page navigation
    const vendorPageNavigation = (status) => {
        console.log(status, "dashboard status")
        vendorNavigation('/compliance/vendor', { state: status })
    }

    // html part
    return (
        <div className="col-xl-4 col-lg-4 col-sm-12 col-md-12 mb-3">
            <div className="bg-white py-2 px-3 border-radius-8 three-col-widget rm-dashboard-card">
                <Loader isLoading={isLoading} />
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="font-14 font-medium primary-textcolor mb-0 mt-2" >
                        Vendor Assessment Information
                    </h3>
                </div>
                <div className="d-flex py-4 px-2 justify-content-center mt-4">
                    {totalCount != 0 ? <ChartComponent data1={vendorInformation} data2={totalCount}></ChartComponent> : <NoRecordFound data={"No vendors are created"}></NoRecordFound>}
                </div>
                {totalCount != 0 && <div className="align-items-center justify-content-center d-flex gap-2 mt-1">
                    <div className="align-items-baseline d-flex justify-content-between me-3">
                        <span className="legends legend-completed me-1" />
                        <label className="me-2 font-12 grey-light">Completed</label>
                        <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => vendorInformation[0].value === 0 ? null : vendorPageNavigation('Completed')}>{vendorInformation[0].value}</label>
                    </div>
                    <div className="align-items-baseline d-flex justify-content-between">
                        <span className="legends legend-medium me-1" />
                        <label className="me-2 font-12 grey-light">In progress</label>
                        <label className="font-12 secondary-textcolor font-medium cursor-pointer" onClick={() => vendorInformation[1].value === 0 ? null : vendorPageNavigation('In Progress')}>{vendorInformation[1].value}</label>
                    </div>
                </div>}
            </div>
        </div>
    )
}