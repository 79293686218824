import React from 'react';

const UploadedMediaSection = ({ mediaData, onDelete, isMedia }) => {
    return (
        <div className="d-inline-flex justify-content-between align-items-center border rounded-2 p-3" style={{ width: "450px" }}>
            <div className="d-flex align-items-center">
                <span className="vid-thumnail d-flex">
                    <img src={mediaData.src} alt="sample-image" className="w-100 image-cover" />
                </span>
                <div className="ps-3 align-items-center align-middle uploaded-media-text">
                    <span className="font-medium font-12 primary-textcolor d-block">{mediaData.name.length > 30 ? `${mediaData.name.substring(0, 20)}...` : mediaData.name}</span>
                    <span className="grey-light font-regular font-10">{mediaData.size} Mb</span>
                </div>
            </div>
            <button type="button" className="border-0 p-0 shadow-none bg-transparent pointer" onClick={() => onDelete(isMedia)}>
                <img src="/images/delete-icon.svg" alt="delete-icon" className="ms-5 pointer" />
            </button>
        </div>
    );
};

export default UploadedMediaSection;