/**PS_US_001 Importing of required Constants */
import React, { useState, useEffect, useContext } from 'react';
import { getAllUsersInfo } from '../../Services/Risk/RiskService';
import { getUserDepartment } from '../../Services/Risk/RiskService';
import { getAllUsersExcel } from '../../Services/Risk/RiskService';
import AddZero from './AddZeroComponent';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import { useLocation } from 'react-router-dom';
import NoDataFound from '../LMS/NoDataFound';
import RiskNoDataFound from './RiskNoDataFound';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import { agovContext } from '../../Constant';




const AllUsersInfo = () => {
    const location = useLocation();
    const { state } = location;
    const riskUserCountsInfo = (state && state.riskUserCountsInfo) || false;
    const { roles, userId } = useContext(agovContext);
    const navigate = useNavigate();
    /**PS_US_04 Declaration of State Variables */
    const [allUsersInfo, setAllUsersInfo] = useState([]);
    const [allUsersExcel, setAllUsersExcel] = useState([]);
    const [showUserFilterPopup, setShowUserFilterPopup] = useState(true);
    const [userFilters, setUserFilters] = useState({
        userDomain: '',
        acctStatus: '',
        fromDate: '',
        toDate: '',
    });
    const [applyButtonDisabled, setApplyButtonDisabled] = useState(true);
    const [limit, setLimit] = useState(10);
    const [domainArr, setDomainArr] = useState([]);
    const [sortKey, setSortKey] = useState('employee_name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [userToDateDisabled, setUserToDateDisabled] = useState(false);
    const [userTotalCount, setUserTotalCount] = useState(10);
    const [userSearch, setUserSearch] = useState('');
    const AccountStatus = ['Active', 'Inactive']
    const [isLoading, setIsLoading] = useState(false)



    /**PS_US_08 - invoke the getAllUsersData async function */
    const getAllUsersData = async (cancelFlag) => {
        try {

            let body;
            /**PS_US_44 checking for riskUser Navigation */
            if (cancelFlag === 'cancel') {
                body = {
                    "userFromDate": "",
                    "userToDate": "",
                    "userDepartment": "",
                    "userAccountStatus": '',
                    "riskUserInfo": riskUserCountsInfo,
                    "searchKey": userSearch.trim(),
                    "sortKey": sortKey,
                    "sortOrder": sortOrder,
                    "limit": limit,
                    "userRoles": roles

                }
            }
            else {
                body = {

                    "userFromDate": userFilters.fromDate,
                    "userToDate": userFilters.toDate,
                    "userDepartment": userFilters.userDomain,
                    "userAccountStatus": userFilters.acctStatus === 'Active' ? 1 : userFilters.acctStatus === 'Inactive' ? 0 : '',
                    "riskUserInfo": riskUserCountsInfo,
                    "searchKey": userSearch.trim(),
                    "sortKey": sortKey,
                    "sortOrder": sortOrder,
                    "limit": limit,
                    "userRoles": roles

                }
            }

            setIsLoading(true)
            const response = await getAllUsersInfo(body)
            /** PS_US_21 Set the totalCount and the responseData in respective state Variables */
            /**PS_US_25 Check if response value exists and data.statusCode = 200. */
            if (response && response.statusCode === 200) {
                setAllUsersInfo(response.responseData.userData);
                setUserTotalCount(response.responseData.totalCount);
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false)

        }

    }


    const getUserDomains = async () => {
        let body = {
            userRoles: roles
        }
        setIsLoading(true)
        try {
            /**Check if response value exists and data.statusCode = 200. */
            const response = await getUserDepartment(body)
            if (response && response.statusCode === 200) {
                const domains = response.responseData
                setDomainArr(domains);
                setIsLoading(false)

                /**PS_US_26 Using `setDomainArr`, set the values and iterate it in the advanced filter.*/
            }


        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false)

        }


    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                await getAllUsersData();
                await getUserDomains();
                /**PS_US_001 && PS_US_07 Fetch API calls during pageLoad */
                setIsLoading(false)

            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoading(false)

            }
        };

        fetchData();

    }, []);

    /**PS_US_33 Handling the sort PS_US_35  */
    const handleSortClicked = (columnName, order) => {
        setSortKey(columnName);
        setSortOrder(order);
    }

    /**PS_US_34 Handling the Search */
    const handleSearchClick = async (emptySearch) => {

        if (emptySearch) {
            setUserSearch('');
            await getAllUsersData();
            return
        }
        getAllUsersData();
    }


    /**PS_US_37 Making API call for the dependencies */
    useEffect(() => {
        getAllUsersData();
    }, [limit, sortKey, sortOrder]);




    useEffect(() => {
        //     let tempUserFilters = userFilters;
        //     /**PS_US_29 */
        //     // Check if any filter field except toDate has a value
        //     const isFilterSelected = Object.values(tempUserFilters).some((value, key) => key !== 'toDate' && value !== '');
        //     /**PS_US_30
        //   */
        //     // Check if filters.fromDate has a value or filters.toDate is empty
        //     const isFromDateValid = tempUserFilters.fromDate !== '' || tempUserFilters.toDate === '';
        //     /**PS_US_31
        //    */
        //     // Determine if Apply button should be disabled
        //     const applyButtonDisabled = !isFromDateValid || !isFilterSelected;

        //     // Proceed with enabling/disabling the Apply button
        //     setApplyButtonDisabled(applyButtonDisabled);

        let tempUserFilters = userFilters;
        // Check if any filter field has a value
        const isFilterSelected = Object.values(tempUserFilters).some(value => value !== '');
        // Determine if Apply button should be disabled
        const applyButtonDisabled = !isFilterSelected;

        // Proceed with enabling/disabling the Apply button
        setApplyButtonDisabled(applyButtonDisabled);

    }, [userFilters]);


    const handleFilterChange = (field, value) => {
        /**PS_US_28 */  // Update the filters state
        setUserFilters(prevFilters => ({
            ...prevFilters,
            [field]: value
        }));
        // Clear toDate value and disable it if fromDate is unselected
        if (field === 'fromDate' && !value) {
            setUserFilters(prevFilters => ({
                ...prevFilters,
                toDate: ''
            }));
        }
    };



    const allUsersMockData = [{
        "userName": "John Doe",
        "department": "Human Resources",
        "latestSignIn": "2023-01-15",
        "acctStatus": true,
        "riskSeverity": "Low",
        "profile_url": "https://example.com/johndoe"
    },
    {
        "userName": "Alice Smith",
        "department": "Finance",
        "latestSignIn": "2023-02-20",
        "acctStatus": false,
        "riskSeverity": "Medium",
        "profile_url": "https://example.com/alicesmith"
    }
    ]


    /**PS_US_40 Handling the clear filters */
    const handleCancelFilter = () => {
        setUserFilters({ userDomain: '', acctStatus: '', fromDate: '', toDate: '' });
        setShowUserFilterPopup(false);
        getAllUsersData("cancel")
    };

    const handleApplyFilter = () => {
        getAllUsersData();
    };

    /**PS_US_37 Handling the load More Data */
    const handleLoadMore = () => {
        // Increment the limit to fetch more data
        if (userTotalCount > allUsersInfo.length) {
            setLimit(prevLimit => prevLimit + 10);
        }
    };
    /**PS_UE_08 Handling the export excel  */
    const handleExportExcel = async () => {
        let body = {
            "userFromDate": userFilters.fromDate,
            "userToDate": userFilters.toDate,
            "userDepartment": userFilters.userDomain,
            "userAccountStatus": userFilters.acctStatus === 'Active' ? 1 : userFilters.acctStatus === 'Inactive' ? 0 : '',
            "riskUserInfo": riskUserCountsInfo,
            "searchKey": userSearch.trim(),
            "sortKey": sortKey,
            "sortOrder": sortOrder,
            "userRoles": roles,
            "userId": userId
        };

        setIsLoading(true);

        try {
            const response = await getAllUsersExcel(body);

            if (response && response.statusCode === 200) {
                setAllUsersExcel(response.responseData);
                setIsLoading(false);

                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('User Details');

                // Define headers
                worksheet.columns = [
                    { header: 'Employee Name', key: 'userName' },
                    { header: 'Employee Domain', key: 'department' },
                    { header: 'Manager Name', key: 'managerName' },
                    { header: 'Last Sign', key: 'latestSignIn' },
                    { header: 'Status', key: 'acctStatus' },
                    { header: 'Score Category', key: 'riskSeverity' }
                ];

                // Add data rows
                response.responseData.forEach(item => {
                    worksheet.addRow({
                        userName: item.userName || "-",
                        department: item.department || "-",
                        managerName: item.managerName || "-",
                        latestSignIn: item.latestSignIn || "Nil",
                        acctStatus: item.acctStatus ? "Active" : "Inactive",
                        riskSeverity: item.riskSeverity || "N/A"
                    });
                });

                // Generate Excel file
                const excelBuffer = await workbook.xlsx.writeBuffer();
                const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

                // Save Excel file
                FileSaver.saveAs(data, 'AGOV_user_logs.xlsx');
            }
        } catch (error) {
            console.error("Error exporting Excel:", error);
            setIsLoading(false);
        }
    };

    const renderAllUserRows = (allUsersMockData) => {
        return (
            <tbody>
                {allUsersMockData.map((row, index) => (
                    <tr key={index}>
                        <td className="font-12">
                            <a className="text-decoration-none d-flex align-items-center cursor-pointer" onClick={() => { navigate(`/userProfile`, { state: { value: { id: row.userId, employeeid: row.employeeId } } }) }}>
                                <img src={row.profile_url} className="table-prof" />
                                <span className="font-12 font-medium secondary-textcolor mb-0 ms-2" >{row.userName} </span>
                            </a>
                        </td>
                        <td className="font-12">{row.department}</td>
                        <td className="font-12">{row.latestSignIn}</td>
                        <td className="font-12">{row.riskSeverity}</td>
                        <td className={`font-12 font-medium ${row.acctStatus ? 'status-green-text' : 'grey-secondary'}`}>
                            <span className={row.acctStatus ? 'green-dot me-2' : 'grey-dot me-2'}></span>
                            {row.acctStatus ? 'Active' : 'Inactive'}
                        </td>

                    </tr>
                ))}
            </tbody>
        );
    };


    return (

        <>
            <Loader isLoading={isLoading} />
            <div className="container-fluid mt-4 mt-custom">
                <div className="col-md-12">
                    <div className="d-flex justify-content-between mt-4 px-5">
                        <h1 className="font-bold font-24 primary-textcolor">
                            Risk Management
                        </h1>
                    </div>
                    {/*tabs starts here*/}
                    <div className="d-flex justify-content-between align-items-center my-3 px-5 custom-tabs">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link px-0  font-14 bold-text tab-style rounded-0" id="overview-tab" data-bs-toggle="pill" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true" onClick={() => { navigate('/riskManagement') }}>
                                    <span className="tab-img"> <img src="/images/dashboard-icon-filled.svg" alt="filled-icon" className="me-2" />
                                        <img src="/images/dashboard-icon.svg" alt="overview-icon" className="me-2" /></span>Overview</button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 active font-14 tab-style  rounded-0" id="all-users-tab" data-bs-toggle="pill" data-bs-target="#all-users" type="button" role="tab" aria-controls="all-users" aria-selected="false" tabIndex={-1} >
                                    <span className="tab-img"> <img src="/images/people-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/people-icon.svg" alt="all-users-icon" className="me-2" />
                                    </span>All Users</button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-apps-tab" data-bs-toggle="pill" data-bs-target="#all-apps" type="button" role="tab" aria-controls="all-apps" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/allapplications') }}>
                                    <span className="tab-img"> <img src="/images/app-icon-filled.svg" alt="filled-icon" className="me-2" />
                                        <img src="/images/app-icon.svg" alt="all-apps-icon" className="me-2" />All
                                        Applications</span></button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="all-devices-tab" data-bs-toggle="pill" data-bs-target="#all-devices" type="button" role="tab" aria-controls="all-devices" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/alldevices') }}>
                                    <span className="tab-img"> <img src="/images/device-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/device-icon.svg" alt="all-devices-icon" className="me-2" />All Devices</span></button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="event-logs-tab" data-bs-toggle="pill" data-bs-target="#event-logs" type="button" role="tab" aria-controls="event-logs" aria-selected="false" tabIndex={-1} onClick={() => { navigate('/riskManagement/eventLogs') }}>
                                    <span className="tab-img"> <img src="/images/document-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="/images/document-icon.svg" alt="event-logs-icon" className="me-2" />Event Logs</span></button>
                            </li>
                        </ul>
                    </div>
                    {/*tabs ends here*/}
                    <div className="row position-relative">
                        <div className="tab-content px-0" id="pills-tabContent">

                            {/*All users tab*/}
                            <div className="tab-pane active show px-5 ms-2 mt-3" id="all-users" role="tabpanel" aria-labelledby="all-users-tab">
                                <ul className="nav position-absolute end-0 custom-filter-tab me-5">
                                    <li>
                                        <div className="input-group flex-nowrap search-group me-2">
                                            <input type="text" value={userSearch} className="form-control search-align" onChange={(e) => setUserSearch(e.target.value)}
                                                onKeyDownCapture={(e) => {
                                                    if (e.key == "Enter") {
                                                        handleSearchClick(false)
                                                    }
                                                }} placeholder="Search" aria-describedby="addon-wrapping" />
                                            <button className="input-group-text btn btn-search-custom" id="addon-wrapping" onClick={() => handleSearchClick(false)}><img src="/images/search-icon.svg" alt="search-icon" /></button>
                                        </div>
                                    </li>
                                    <div>



                                        <button className="cust-filter-btn d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => { setShowUserFilterPopup(true) }}>
                                            <img src="/images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
                                        </button>
                                        {true && (<div className=" dropdown-menu custom-filter border-0"  >

                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                                                    <button type="button" className="btn-close font-12" aria-label="Close" />
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-black font-12 font-medium">Department
                                                            </label>
                                                            <select className="form-select cust-input-sty font-12 font-regular" onChange={e => { handleFilterChange('userDomain', e.target.value) }} value={userFilters.userDomain} aria-label="Default select example">
                                                                <option value=''>Select</option>

                                                                {domainArr.map((domain, index) => (
                                                                    <option key={index} value={domain.domains} >{domain.domains}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-black font-12 font-medium">Account
                                                                Status
                                                            </label>
                                                            <select className="form-select cust-input-sty font-12 font-regular" onChange={e => { handleFilterChange('acctStatus', e.target.value) }} value={userFilters.acctStatus} aria-label="Default select example">
                                                                <option value=''>Select</option>
                                                                {AccountStatus.map((domain, index) => (
                                                                    <option key={index} value={domain}>{domain}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <h5 className="font-14 font-bold text-black mt-3">Date Range</h5>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="StartDate" className="form-label text-black font-12 font-medium">From</label>
                                                            <input type="date" value={userFilters.fromDate} onChange={e => { handleFilterChange('fromDate', e.target.value) }}
                                                                className="form-control custom-date cust-input-sty font-12 font-regular" id="StartDate" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="endDate" className="form-label text-black font-12 font-medium">To</label>
                                                            <input type="date" value={userFilters.toDate}
                                                                onChange={e => handleFilterChange('toDate', e.target.value)}
                                                                min={userFilters.fromDate}
                                                                className="form-control custom-date cust-input-sty font-12 font-regular" id="endDate" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-end mt-4 mb-2">
                                                    <button type="button" className="primary-btn font-14 font-medium me-2" onClick={handleCancelFilter}>
                                                        Clear
                                                    </button>
                                                    <button type="button" onClick={handleApplyFilter} disabled={applyButtonDisabled} className="dark-btn font-14 font-medium px-4">
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>)
                                        }
                                    </div>


                                    <button className="advanced-filter-button ms-2 medium-text customflip-btn" type="button" id="dropdownMenuButton1" onClick={handleExportExcel}>
                                        <img src="/images/excel-icon.svg" alt="Filter-icon" />
                                    </button>
                                </ul>
                                {/*All users table*/}
                                <h5 className="font-bold font-20 primary-textcolor">
                                    All Users
                                </h5>
                                <div className={allUsersInfo.length > 0 ? 'table-responsive' : ''}>
                                    {allUsersInfo.length > 0 ? (<table className="table text-nowrap mb-0 custom-table-grid table-borderless mt-3">
                                        <thead>
                                            <tr>
                                                <th className="font-14 px-4 position-relative">
                                                    Username
                                                    <span className="position-absolute">
                                                        <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("employee_name", "asc")}>
                                                            <img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" />
                                                        </a>
                                                        <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("employee_name", "desc")}>
                                                            <img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" />
                                                        </a>
                                                    </span>
                                                </th>

                                                <th className="font-14 position-relative">Department
                                                    <span className="position-absolute">
                                                        <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("employee_domain", "asc")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("employee_domain", "desc")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                    </span>
                                                </th>
                                                <th className="font-14 position-relative">Last Sign in
                                                    <span className="position-absolute">
                                                        <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("last_sign", "asc")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("last_sign", "desc")} ><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                    </span>
                                                </th>
                                                <th className="font-14 position-relative">Risk Severity
                                                    <span className="position-absolute">
                                                        <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("score", "asc")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("score", "desc")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                    </span>
                                                </th>
                                                <th className="font-14 position-relative">Account Status
                                                    <span className="position-absolute">
                                                        <a className="sort-up position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("is_active", "asc")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 cursor-pointer" onClick={() => handleSortClicked("is_active", "desc")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        {renderAllUserRows(allUsersInfo)}
                                    </table>) : (<RiskNoDataFound data={"No User's Found"}/>)}

                                </div>
                                <div className="mt-3">
                                    <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor"> <AddZero number={allUsersInfo.length}></AddZero> </span>
                                        out of <span className="font-medium primary-textcolor"><AddZero number={userTotalCount}></AddZero></span> items</p>
                                </div>
                                <div className="text-center mt-3">
                                    {/**PS_US_39 Handling the load More Visibility condition */}
                                    {userTotalCount > allUsersInfo.length && (
                                        <button className="primary-btn font-medium cursor-pointer" onClick={() => handleLoadMore()}>Load More</button>
                                    )}

                                </div>
                            </div>
                            {/*All users table*/}

                        </div>
                    </div>
                    {/*Event documents table*/}
                </div>
            </div>




        </>
    );
}



export default AllUsersInfo;