import React, { useState, useEffect, } from 'react';

const NoDataFound = () => {
    return (
        <div className="row py-3 position-relative">
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="MyLearning" role="tabpanel" aria-labelledby="MyLearning-tab">
                    <div className="row justify-content-center py-5">
                        <div className="col-md-10 col-lg-5 col-sm-12 text-center py-4">
                            <span>
                                <img src="/images/no-rec-icon.svg" alt="no-rec-icon" />
                            </span>
                            <h5 className="font-24 font-medium primary-textcolor mt-4">No Records Found</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default NoDataFound;