const PopupNonCompliantUsersHeader = ({
  onPopupTabChange,
  onPopupFilterChange,
  popupFilter,
  roles,
  gridActiveTab,
  onClose,
  popupActiveTab,
  onExportToExcel,
  isSuperAdminAndManager,
  popupIsLoading,
  popupTotalUsers,
}) => {
  return (
    <div className="modal-header d-flex justify-content-between align-items-center pt-2 pb-3 pe-2 border-0">
      <div className="d-flex align-items-center justify-content-center gap-3">
        <h5
          className="modal-title font-14 font-medium primary-textcolor"
          id="staticBackdropLabel"
        >
          Non Compliant
        </h5>
        <div className="d-flex justify-content-between align-items-center custom-tabs">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={
                  popupActiveTab === "overdue"
                    ? "nav-link px-0 active font-14 tab-style rounded-0 d-flex align-items-center"
                    : "nav-link px-0 font-1 tab-style rounded-0 d-flex align-items-center"
                }
                id="Overdue-tab"
                data-bs-toggle="pill"
                data-bs-target="#Overdue"
                type="button"
                role="tab"
                aria-controls="Overdue"
                aria-selected="true"
                onClick={() => onPopupTabChange("overdue")}
              >
                <span className="tab-img d-flex align-items-center">
                  <img
                    src="images/media-lib-active.svg"
                    alt="filled-icon"
                    className="me-2"
                  />{" "}
                  <img
                    src="images/media-lib-inactive.svg"
                    alt="information-icon"
                    className="me-2"
                  />
                </span>
                Overdue
              </button>
            </li>
            <li className="nav-item ms-4" role="presentation">
              <button
                className={
                  popupActiveTab === "risk"
                    ? "nav-link px-0 active font-12 tab-style rounded-0 d-flex align-items-center"
                    : "nav-link px-0 font-12 tab-style rounded-0 d-flex align-items-center"
                }
                id="Risk-tab"
                data-bs-toggle="pill"
                data-bs-target="#Risk"
                type="button"
                role="tab"
                aria-controls="Risk"
                aria-selected={gridActiveTab === "risk" ? "true" : "false"}
                onClick={() => onPopupTabChange("risk")}
              >
                <span className="tab-img d-flex align-items-center">
                  <img src="images/risk-active-filled.svg" className="me-2" />{" "}
                  <img src="images/risk-icon.svg" className="me-2" />
                </span>
                Risk
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        {roles === "superAdmin" && popupActiveTab === "overdue" && (
          <select
            value={popupFilter}
            onChange={(e) => onPopupFilterChange(e.target.value)}
            className="form-select custom-form-control py-2 font-12 font-regular primary-textcolor h-33 me-1"
            aria-label="Default select example"
            disabled={popupIsLoading}
            style={{
              cursor: popupIsLoading ? "not-allowed" : "pointer",
              filter: popupIsLoading ? "grayscale(80%)" : "none",
              opacity: popupIsLoading ? 0.5 : 1,
            }}
          >
            <option value={"team"} selected>
              Team
            </option>
            <option value={"organization"}>Organization</option>
          </select>
        )}
        <button
          className="advanced-filter-button medium-text customflip-btn me-3"
          type="button"
          id="dropdownMenuButton1"
          onClick={onExportToExcel}
          disabled={
            popupIsLoading ||
            (popupActiveTab === "risk" && popupTotalUsers?.riskUsers == 0) ||
            (popupActiveTab === "overdue" &&
              popupTotalUsers?.overdueUsers == 0) ||
            (gridActiveTab === "overdue" &&
              roles === "superAdmin" &&
              popupFilter === "team" &&
              !isSuperAdminAndManager)
          }
          style={{
            cursor:
              popupIsLoading ||
              (popupActiveTab === "risk" && popupTotalUsers?.riskUsers == 0) ||
              (popupActiveTab === "overdue" &&
                popupTotalUsers?.overdueUsers == 0) ||
              (gridActiveTab === "overdue" &&
                roles === "superAdmin" &&
                popupFilter === "team" &&
                !isSuperAdminAndManager)
                ? "not-allowed"
                : "pointer",
            filter:
              popupIsLoading ||
              (popupActiveTab === "risk" && popupTotalUsers?.riskUsers == 0) ||
              (popupActiveTab === "overdue" &&
                popupTotalUsers?.overdueUsers == 0) ||
              (gridActiveTab === "overdue" &&
                roles === "superAdmin" &&
                popupFilter === "team" &&
                !isSuperAdminAndManager)
                ? "grayscale(80%)"
                : "none",
            opacity:
              popupIsLoading ||
              (popupActiveTab === "risk" && popupTotalUsers?.riskUsers == 0) ||
              (popupActiveTab === "overdue" &&
                popupTotalUsers?.overdueUsers == 0) ||
              (gridActiveTab === "overdue" &&
                roles === "superAdmin" &&
                popupFilter === "team" &&
                !isSuperAdminAndManager)
                ? 0.5
                : 1,
          }}
        >
          <img src="images/excel-icon.svg" alt="Filter-icon" />
        </button>
        <button
          type="button"
          className="btn btn-close me-3"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default PopupNonCompliantUsersHeader;
