const NoNotificationFound = ({data}) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-center flex-column no-rec-found" style={{height: "450px"}}>
                <img src="/images/no-rec-found.png" width="150px" height="166px" />
                <p className="font-14 font-medium no-rec-description mt-3">{data}</p>
            </div>

        </>
    )
}

export default NoNotificationFound;