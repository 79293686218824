import React, { useEffect, useState } from 'react'
import { getControlHistory } from '../../Services/GRC/GrcService';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../Loader/Loader';

const ControlHistory = ({ controlInformation }) => {

  const [page, setPage] = useState(15);
  const [controlHistory, setControlHistory] = useState([]);
  const [hasMorePage, setHasMorePage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * PC_CC_181 - PC_CC_197,
   * PC_CC_198 - PC_CC_200
   * This function fetches all the data that are related to a particular control
   */
  useEffect(() => {
    loadControlHistory();
  }, [page])

  const loadControlHistory = async () => {
    setIsLoading(true);
    let controlHistoryValues = await getControlHistory({
      "controlIdentity": controlInformation.controlDetails.controlIdentity,
      "page": page
    });

    console.log(controlHistoryValues, "loggg");

    let result = controlHistoryValues?.responseData?.historyCount / page;
    setHasMorePage(result <= 1 ? false : true)

    console.log(result, "<><><>");
    setControlHistory(controlHistoryValues?.responseData?.historyDetails);
    setIsLoading(false);

  }

  const getMessage = (history) => {
    switch (history.controlAction) {
     
      case 'controlMap':
          return `${history.doneBy}  mapped  this control to principle ${history.actionMadeOn}`;
      case 'controlUnMap':
        return `${history.doneBy} unmapped this control from the principle ${history.actionMadeOn}`;
      case 'policyUnmapping':
        return `${history.doneBy} unmapped this control from policy ${history.actionMadeOn}`;
      // case 'policyMapping':
      //   return `${history.doneBy} mapped this control to policy ${history.actionMadeOn}`;
      case 'policyMap':
        return `${history.doneBy} mapped this control to policy ${history.actionMadeOn}`;
      case 'evidenceUnmapping':
        return `${history.doneBy} unmapped this control from policy ${history.actionMadeOn}`;
      // case 'evidenceMapping':
      //   return `${history.doneBy} mapped this control to policy ${history.actionMadeOn}`;
      case 'evidenceMap':
        return `${history.doneBy} mapped this control to policy ${history.actionMadeOn}`;
      case 'taskAssigned':
        return `${history.doneBy} has created a subtask  ${history.reason} for ${history.actionMadeOn}`;
      default:
        return `${history.controlAction}`;
    }
  }

  return (
    <>
      <div id="scrollIDGRC01" className="scrollable-content" style={{
        maxHeight: "300px",
        overflowY: "auto"
      }}>
        <InfiniteScroll
          dataLength={controlHistory.length}
          next={() => {
            setPage((previousLimit) => (previousLimit + 15))
          }}
          hasMore={hasMorePage}
          scrollThreshold={0.9}
          scrollableTarget="scrollIDGRC01"
        >

          <ul className="list-unstyled ps-0 my-4">
            { controlHistory ?.length != 0 ?  controlHistory?.map((history, index) => (
              <li key={index} className="mb-4">
                <div className="d-flex align-items-center">
                  <img src={history.profileUrl} alt="sample-icon" className="compliance-li-logo" />
                  <div className="ms-3">
                    <p className="font-12 primary-textcolor font-regular mb-1">{getMessage(history)}</p>
                    { history.Reason  ? 
                    <p className="font-12 primary-textcolor font-regular mb-1"><b>Reason:</b> {history.Reason}</p> : ''}
                    <p className="font-10 font-regular grey-light mb-0">{new Date(history.doneOn).toLocaleString()}</p>
                  </div>
                </div>
              </li>
            )): <p>No history found.</p>}

          </ul>
        </InfiniteScroll>
      </div>
      <Loader isLoading={isLoading} />
    </>

  )
}

export default ControlHistory
