import React, { useRef, useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import NoDataFound from '../LMS/NoDataFound';
import RiskNoDataFound from "../Risk/RiskNoDataFound";
import ControlsGridNoRecordFound from './ControlsGridNoRecordFound';

/**
 * PC_CC_21
 * Destructure the values of the props that are coming inside the component
 */
const ControlsGrid = ({ controls, activeCategory, setActiveCategory, setSelectedControlId, setHideControlsGrid, setHideControlInfo, setSelectedPrinciple, setHideAddControlPage, isAuditor, viewAsAuditor, setFilteredData, handleFilter, status, handleClear}) => {

    /*
    *PC_CC_22 - PC_CC_24
    *The useEffect and the handleCatefory function are being called here to make the screen scroll to particular seciton 
    */
    const categoryRefs = useRef([]);
    useEffect(() => {
        if (activeCategory && categoryRefs.current[activeCategory]) {
            categoryRefs.current[activeCategory].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setActiveCategory('')
    }, [activeCategory, viewAsAuditor]);

    return (
        <div className="col-lg-9 px-3 col-md-12 col-sm-12 border-start admin-rightcontainer-hig control-lefttab-scroll text-start">
            <div className="tab-content" id="v-pills-tabContent">
            <div className="d-flex justify-content-end">
            <button 
            className="cust-filter-btn d-flex align-items-center" 
            type="button" 
            data-bs-toggle="dropdown" 
            aria-expanded="false"
            >
            <img src="/images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
            </button>
            <div className="dropdown-menu custom-filter border-0">
            <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-center">
                <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                <button type="button" className="btn-close font-12" aria-label="Close" />
                </div>
                <div className="row mt-3">
                <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                    <label className="form-label text-black font-12 font-medium">Verification Status
                    </label>
                    <select className="form-select cust-input-sty font-14 py-2 font-regular" aria-label="Default select example" onClick={(event) =>{
                        setFilteredData(event.target.value)
                    }}>
                        {!status ? <option selected value = "">Select</option> : <option value = "">Select</option>}
                        {status==='verified' ? <option selected value={"verified"} >Verified</option> : <option value={"verified"}>Verified</option>}
                        {status === 'not-verified' ? <option selected value={"not-verified"} >Not Verified</option> : <option value={"not-verified"}>Not Verified</option>}
                    </select>
                    </div>
                </div>
                </div>
                <div className="text-end mt-4 mb-2">
                <button type="button" className="primary-btn font-14 font-medium me-2 py-2 me-3" onClick={handleClear}>
                    Clear
                </button>
                <button type="button" className="dark-btn font-14 font-medium py-2" onClick={
                    handleFilter
                }>
                    Apply
                </button>
                </div>
            </div>
            </div>
        </div>   
        {controls?.length != 0 ? (controls.map((trustService, trustServiceIndex) => (
                    trustService.categories.map((category, categoryIndex) => (
                        <div className='mb-4'
                            id={`Controlenv${trustServiceIndex}-${categoryIndex}`}
                            role="tabpanel"
                            aria-labelledby={`Controlenv-tab${trustServiceIndex}-${categoryIndex}`}
                            key={categoryIndex}
                            ref={el => categoryRefs.current[`${trustServiceIndex}-${categoryIndex}`] = el}
                        >
                            {category.principleAndControl.map((item, itemIndex) => (
                                <div key={itemIndex}>
                                    <div className={`d-flex justify-content-between align-items-center mb-3 ${trustServiceIndex == 1 && categoryIndex == 1 && itemIndex == 1 ? '' : 'mt-5'}`}>
                                        <div>
                                            <h5 className={`font-medium font-20 primary-textcolor`}>
                                                {item.principle.principleId} - {item.principle.principleName}
                                            </h5>
                                            <p className="font-regular font-13 grey-light-color mb-0">
                                                {item.principle.principleDescription}
                                            </p>
                                            {/* PC_CC_61 -PC_CC_63
                                            opens the addControl page  */}  
                                            </div>{trustServiceIndex === 0 && categoryIndex === 0 && itemIndex === 0 && !isAuditor && 
                                           !viewAsAuditor && (<button 
                                            style={{ display: 'block' }}
                                            type="button"
                                            className="dark-btn font-14 font-regular py-2 d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                                setHideAddControlPage(false);
                                                setHideControlInfo(true);
                                                setHideControlsGrid(true);
                                            }}
                                        >    

                                                <img
                                                    src="/images/Plus-icon.svg"
                                                    alt="Plus-icon"
                                                    className="me-2"
                                                />
                                                Add Control
                                            </button>
                                        )}

                                    </div>
                                    <div className="table-responsive control-landing-table-scroll">
                                        <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                                            <thead className="sticky-top-1">
                                                <tr>
                                                    <th className="font-14 px-4 position-relative ">Control</th>
                                                    <th className="font-14 position-relative">Owner</th>
                                                    {!isAuditor ? <th className="font-14 position-relative text-center">Progress</th> : null}
                                                    <th className="font-14 position-relative">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.controls.map((control, controlIndex) => (
                                                    <tr key={controlIndex}>
                                                        <td className="control-landing-td-width">
                                                            {/* PC_CC_155  - PC_CC_158
                                                            This redirects to  the particular control detail page */}
                                                            <div className='d-flex align-items-center mb-2'>
                                                            <p onClick={() => { setSelectedControlId(control.controlId); setHideControlsGrid(true); setHideControlInfo(false); setSelectedPrinciple(item.principle.principleId) }} className="font-14 font-medium" style={{ cursor: 'pointer' }}>
                                                                {control.controlName}</p>
                                                                {control?.control_type == 'custom' ? <span className="policy-custom-btn d-flex align-items-center ms-3"> Custom</span> : ''}
                                                            
                                                            </div>
                                                            <p className="font-regular font-12 grey-light-color mb-0">{control.controlDescription}</p>
                                                        </td>
                                                        <td className="font-13">

                                                            {
                                                                control.profileUrl[0] != '?' ?
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={control.profileUrl} className="table-prof" />
                                                                        <p className="font-13 font-regular mb-0 ms-2">{control.controlOwner}</p>
                                                                    </div>
                                                                    :
                                                                    <span>Unassigned</span>
                                                            }

                                                            {/* </div> */}
                                                        </td>
                                                        {!isAuditor ? <td className="font-13 text-center">
                                                            <div className="position-relative">
                                                                <div className="wrapper m-auto" style={{ width: '45px', height: '45px' }}>
                                                                    <CircularProgressbar
                                                                        value={(control.uploadedNumberOfEvidences / control.numberOfEvidences) * 100}
                                                                        text={`${control.uploadedNumberOfEvidences}/${control.numberOfEvidences}`}
                                                                        styles={buildStyles({
                                                                            textColor: "#191970",
                                                                            pathColor: "#191970",
                                                                            trailColor: "#ddd"
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td> : null}
                                                        <td className="font-13 font-medium">
                                                            {control.controlActiveStatus === 'active' ?
                                                                <div>
                                                                    <span className="green-dot big-got me-2" />
                                                                    <span className="green-textcolor">Active</span>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <span className="red-dot big-got me-2" />
                                                                    <span className="red-textcolor">In-Active</span>
                                                                </div>
                                                            }

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))
                ))): 
                (<div>
                    <ControlsGridNoRecordFound data={"No Records Found"}/>
                    </div>)}
            </div>
        </div>
    );
};

export default ControlsGrid;
