export function PopUpComponent({ data, onClose }) {
  return (
    <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
      {/* <div className="modal fade" id="staticBackdrop7" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true"> */}
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-between align-items-center p-4 pb-3 border-0">
            <h5 className="modal-title font-20 font-bold primary-textcolor" id="staticBackdropLabel">
              Compliance Summary</h5>
            <div className="d-flex flex-row align-items-center">
              <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
            </div>
          </div>
          <div className="modal-body px-3 pb-4 pt-0">
            <div className="table-scroll ps-2 pe-3 pt-2">
              <div className="summary-container py-2 ps-3 pe-4 d-flex flex-row align-items-center justify-content-between mb-3">
                <p className="font-12 font-regular primary-textcolor m-0"><img src="images/policy-error.svg" alt="policy-error" className="me-3" /> Controls
                  without Policies/Evidence mapped</p>
                <p className="font-14 font-medium primary-textcolor m-0">{data.controlNotMapped}</p>
              </div>
              <div className="summary-container py-2 ps-3 pe-4 d-flex flex-row align-items-center justify-content-between mb-3">
                <p className="font-12 font-regular primary-textcolor m-0"><img src="images/control-not-assigned.svg" alt="control-not-assigned-img" className="me-3" />
                  Controls not assigned</p>
                <p className="font-14 font-medium primary-textcolor m-0">{data.controlNotAssigned}</p>
              </div>
              <div className="summary-container py-2 ps-3 pe-4 d-flex flex-row align-items-center justify-content-between mb-3">
                <p className="font-12 font-regular primary-textcolor m-0"><img src="images/auditor-comments.svg" alt="auditor-comments-img" className="me-3" />
                  Comments mentioned by Auditor</p>
                <p className="font-14 font-medium primary-textcolor m-0">{data.commentsCount}</p>
              </div>
              <div className="summary-container py-2 ps-3 pe-4 d-flex flex-row align-items-center justify-content-between mb-3">
                <p className="font-12 font-regular primary-textcolor m-0"><img src="images/policy-evidence.svg" alt="policy-evidence-img" className="me-3" />
                  Policy/ Evidence pending post duedate</p>
                <p className="font-14 font-medium primary-textcolor m-0">{data.policyEvidenceOverDue}</p>
              </div>
              <div className="summary-container py-2 ps-3 pe-4 d-flex flex-row align-items-center justify-content-between mb-3">
                <p className="font-12 font-regular primary-textcolor m-0"><img src="images/policy-revision.svg" alt="policy-revision-img" className="me-3" />
                  Policies without revision</p>
                <p className="font-14 font-medium primary-textcolor m-0">{data.policyWithoutRevision}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
