import { useNavigate } from "react-router-dom";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";

const DonutChartWithLegendNavigation = ({ data, totalRequest, activeTab, roles }) => {
  const navigate = useNavigate();

  // UAA_FE_PC_32_ UAA_FE_PC_36 - Navigating to Access Goverance Page to Display the Users Based on Status on the Grid.
  const handleLegendClick = (item) => {
    if (item.value === 0) {
      return;
    }

    const filterValueMap = {
      "Approved": "Approved",
      "No Action Taken": "Not Approved",
      "Awaiting Approval": "Awaiting Confirmation"
    };

    const filterValue = filterValueMap[item.name];

    let tab;
    if (activeTab === "myTask") {
      tab = "myteamtab";
    } else if (activeTab === "allTask") {
      tab = "myorgtab";
    }

    navigate("/accessGovernance", {
      state: { filterValue, tab },
    });
  };

  const COLORS = ["#78DC8E", "#FFBF84"];

  const formatValue = (value) => {
    if(value === 0) {
      return "0";
    } else if (value > 0 && value < 10) {
      return `0${value}`;
    } else {
      return value;
    }
  }

  return (
    <div className={`row align-items-center ${roles === "manager" ? 'mt-5' : 'mt-4'} px-4`}>
      <div className="col-md-6 col-12 col-sm-6">
        <div className="user-risk-image align-items-center"
        >
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={100}
                fill="#8884d8"
                paddingAngle={2}
                strokeWidth={2}
                cornerRadius={4}
                dataKey="value"
              >
                {data?.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
                <Label
                  value="Total Requests"
                  position="center"
                  fill="#696969"
                  fontSize={14}
                  fontWeight={580}
                  dy={-10}
                />
                <Label
                  value={totalRequest?.toString().padStart(2, "0")}
                  position="center"
                  fill="#00000"
                  fontSize={14}
                  fontWeight="bold"
                  dy={15}
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="col-md-6 col-12 col-sm-6">
        {data?.map((item, index) => (
          <div
            key={index}
            className="d-flex align-items-center mt-1 justify-content-between cursor-pointer"
          >
            <div className="align-items-center d-flex align-items-baseline">
              <span
                className={`legends me-2 cursor-pointer status-${item.name}`}
              />
              <span
                style={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: COLORS[index % COLORS.length],
                  display: "inline-block",
                  marginRight: "8px",
                }}
              />
              <span className="font-12 grey-light text-decoration-none cursor-pointer">
                {item.name}
              </span>
            </div>
            <div>
              <label
                onClick={() => handleLegendClick(item)}
                className={`font-12 secondary-textcolor font-medium cursor-pointer`}
                style={{
                  cursor: item.value === 0 ? "not-allowed" : "pointer",
                  filter: item.value === 0 ? "grayscale(80%)" : "none",
                  opacity: item.value === 0 ? 0.5 : 1,
                }}
              >
                {formatValue(item.value)}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DonutChartWithLegendNavigation;