import { useNavigate } from "react-router-dom";

export  default function  InitialGuide (){
    const navigate = useNavigate();

    return (
        <>
         {/* Application Config Intro Popup Ends Here */}
         <div className="col-md-12 my-2">
          {/* Button trigger modal */}
          <div className="modal show fade " id="exampleModal1" data-bs-keyboard="false" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.6)" }}tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body p-3 modal-container">
                  <div className="row flex-xl-row flex-md-column-reverse flex-lg-column-reverse">
                    <div className="px-4 py-3 col-lg-12 col-xl-6">
                      <div className="d-flex justify-content-center flex-column h-100">
                        <h5 className="font-28 font-regular color-black mb-3">Welcome to <span className="font-32 font-bold secondary-gradient">AGov</span></h5>
                        <p className="font-16 font-medium color-black mb-4">To experience the power of
                          AGov, In the Next steps, you will</p>
                        <ul className="list-group mb-5">
                          <li className="list-group-item border-0 d-flex px-0 font-14 font-regular color-black bg-transparent">
                            <span className="list-bullets mt-1 pe-2 me-md-3 me-lg-0" />Set-up
                            your IDP (Identity
                            Provider) to connect for seamless integration with your
                            environment.
                          </li>
                          <li className="list-group-item border-0 d-flex px-0 font-14 font-regular color-black bg-transparent">
                            <span className="list-bullets mt-1 pe-2 me-md-3 me-lg-0" />Provide
                            permissions to your
                            peers to help you with Agov Implementation.
                          </li>
                          <li className="list-group-item border-0 d-flex px-0 font-14 font-regular color-black bg-transparent">
                            <span className="list-bullets mt-1 pe-2 me-md-3 me-lg-0" />Customize
                            configurations to
                            effectively prioritize and mitigate organizational risks with
                            enhanced efficiency.
                          </li>
                        </ul>
                        <div className="text-start">
                          <button type="button" className="btn font-14 font-medium color-white intro-btn" 
                          onClick={() => navigate(`/InitialConnector`)}>Get
                            Started</button>
                        </div>
                      </div>
                    </div>
                    <div className="px-2 py-0 col-lg-12 col-xl-6">
                      <div className="welcome-bg w-100 h-100 p-4">
                        <img src="images/open-quote-img.svg" alt="open-quote-img" />
                        <p className="font-16 font-regular color-white mt-3 mb-5"><span className="font-medium">Governance Unleashed:</span> Where Strategy
                          Meets Security.</p>
                        <div className="inner-bg-container">
                          <img src="images/welcome-preview-image.png" alt="welcome-preview-image" className="w-100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          {/* Modal */}
          
      
        {/* Application Config Intro Popup Ends Here */}

        </>
    )
}