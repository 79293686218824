import InfiniteScroll from 'react-infinite-scroll-component';
import NoRecordFound from '../../dashboardNoRecordFound';
import PopupLoader from './PopupLoader';
import { useEffect, useRef, useState } from 'react';

const PopupNonCompliantUsersGrid = ({
  roles,
  onPopupSort,
  popupTotalUsers,
  popupNonCompliantUsers,
  isSuperAdminAndManager,
  popupFilter,
  hasMore,
  loadMoreData,
  gridActiveTab,
  popupIsLoading,
  popupActiveTab,
  scrollPosition,
  onScrollPosition
}) => {
  const scrollRef = useRef(null);

  const formatValue = value => {
    if (value === 0) {
      return '0';
    } else if (value > 0 && value < 10) {
      return `0${value}`;
    } else {
      return value;
    }
  };

  const handleScroll = () => {
    if(scrollRef.current) {
      onScrollPosition(scrollRef.current.scrollTop)
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition;
    }
  }, [popupNonCompliantUsers]);

  return (
    <>
      {popupIsLoading ? (
        <PopupLoader isLoading={popupIsLoading} />
      ) : (
        <div className="modal-body px-3 pb-4 pt-0">
          <div className="tab-content" id="pills-tabContent">
            {popupActiveTab === 'overdue' ? (
              <div
                className={gridActiveTab === 'overdue' || popupActiveTab === 'overdue' ? 'tab-pane fade show active' : 'tab-pane fade'}
                id="Overdue"
                role="tabpanel"
                aria-labelledby="Overdue-tab"
              >
                <div className="table-responsive table-scroll" id="overdueTab" ref={scrollRef} onScroll={handleScroll}>
                  <InfiniteScroll dataLength={popupNonCompliantUsers?.length || 0} next={loadMoreData} hasMore={hasMore} scrollThreshold={0.9} scrollableTarget="overdueTab">
                    <table className="table text-nowrap mb-0 custom-table-grid table-borderless" style={{ tableLayout: 'fixed' }}>
                      {popupNonCompliantUsers?.length === 0 || (roles === 'superAdmin' && popupFilter === 'team' && !isSuperAdminAndManager) ? (
                        <NoRecordFound data={'No Overdue Users'} />
                      ) : (
                        <>
                          <thead>
                            <tr className="sticky-top">
                              <th className="font-12 font-medium px-4 position-relative text-start">
                                Username
                                <span className="position-absolute cursor-pointer">
                                  <a className="sort-up position-absolute ms-2">
                                    <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => onPopupSort('username', 'asc')} />
                                  </a>
                                  <a className="sort-down position-absolute ms-2">
                                    <img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => onPopupSort('username', 'desc')} />
                                  </a>
                                </span>
                              </th>
                              <th className="font-12 font-medium position-relative dashboard-fixed-width-popup">
                                Campaigns
                                <span className="position-absolute cursor-pointer">
                                  <a className="sort-up position-absolute ms-2">
                                    <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => onPopupSort('campaigns', 'asc')} />
                                  </a>
                                  <a className="sort-down position-absolute ms-2">
                                    <img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => onPopupSort('campaigns', 'desc')} />
                                  </a>
                                </span>
                              </th>
                              <th className="font-12 font-medium position-relative text-end dashboard-fixed-width-popup text-end">
                                Days
                                <span className="position-absolute cursor-pointer">
                                  <a className="sort-up position-absolute ms-2">
                                    <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => onPopupSort('days', 'asc')} />
                                  </a>
                                  <a className="sort-down position-absolute ms-2">
                                    <img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => onPopupSort('days', 'desc')} />
                                  </a>
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {popupNonCompliantUsers?.map((user, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center text-center">
                                    <img src={user.profileImages} className="table-prof" />
                                    <p className="font-12 font-regular primary-textcolor mb-0 ms-2">{user?.overdueUsers}</p>
                                  </div>
                                </td>
                                <td className="font-12 font-regular primary-textcolor">{user?.campaigns}</td>
                                <td className="font-12 font-regular primary-textcolor text-end">{user?.days}</td>
                              </tr>
                            ))}
                          </tbody>
                        </>
                      )}
                    </table>
                  </InfiniteScroll>
                </div>
                <div className="mt-3">
                  <p className="font-12 grey-primary m-0">
                    {popupNonCompliantUsers?.length === 0  || (roles === 'superAdmin' && popupFilter === 'team' && !isSuperAdminAndManager) ? null : (
                      <>
                        Showing <span className="font-medium primary-textcolor">{formatValue(popupTotalUsers?.overdueUsers)}</span> items
                      </>
                    )}
                  </p>
                </div>
              </div>
            ) : popupActiveTab === 'risk' ? (
              <div
                className={gridActiveTab === 'risk' || popupActiveTab === 'risk' ? 'tab-pane fade show active' : 'tab-pane fade'}
                id="Risk"
                role="tabpanel"
                aria-labelledby="Risk-tab"
              >
                <div className="table-responsive table-scroll" id="riskTab" ref={scrollRef} onScroll={handleScroll}>
                  <InfiniteScroll dataLength={popupNonCompliantUsers?.length || 0} next={loadMoreData} hasMore={hasMore} scrollThreshold={0.9} scrollableTarget="riskTab">
                    <table className="table text-nowrap mb-0 custom-table-grid table-borderless" style={{ tableLayout: 'fixed' }}>
                      <thead>
                        <tr className="sticky-top">
                          <th className="font-12 font-medium px-4 position-relative text-start">
                            Username
                            <span className="position-absolute cursor-pointer">
                              <a className="sort-up position-absolute ms-2">
                                <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => onPopupSort('username', 'asc')} />
                              </a>
                              <a className="sort-down position-absolute ms-2">
                                <img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => onPopupSort('username', 'desc')} />
                              </a>
                            </span>
                          </th>
                          <th className="font-12 font-medium position-relative text-end">
                            Risk Severity
                            <span className="position-absolute cursor-pointer">
                              <a className="sort-up position-absolute ms-2">
                                <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => onPopupSort('severity', 'asc')} />
                              </a>
                              <a className="sort-down position-absolute ms-2">
                                <img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => onPopupSort('severity', 'desc')} />
                              </a>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {popupNonCompliantUsers?.map((user, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center text-start">
                                <img src={user?.profileImages} className="table-prof" alt="profile" />
                                <p className="font-12 font-regular primary-textcolor mb-0 ms-2">{user?.riskUsers}</p>
                              </div>
                            </td>
                            <td className="font-12 font-medium text-end">
                              <span className={`batch-${user?.severity?.toLowerCase()}`}>{user.severity}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
                <div className="mt-3">
                  <p className="font-12 grey-primary m-0">
                    Showing <span className="font-medium primary-textcolor">{formatValue(popupTotalUsers?.riskUsers)}</span> items
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default PopupNonCompliantUsersGrid;
