import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getAuditorDetails } from "../../Services/GRC/GrcService";
import { validatePolicyFileType } from "../../Constant";
import { handleUpload } from '../../Configs/BlobContainer';
import ToastMessageComponent from "../ToastMessageComponent/ToastMessageComponent";
import { Loader } from "../Loader/Loader";

const UploadPastAuditReportPopup = ({ onClose, onSave }) => {
  // PC_UP_02
  // create the respective state variables
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    showDatePickers: false,
    datePickerOpen: false,
  });
  const [auditorList, setAuditorList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    profileUrl: null,
    employeeName: null,
  });
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [auditData, setAuditData] = useState({
    startDate: null,
    endDate: null,
    auditorId: null,
    auditorFirm: null,
    auditCycleName: null,
    fileUrl: null,
  });
  const [isToastVisible, setIsToastVisible] = useState(false)
  const [toastMessage, setToastMessage] = useState({
    path: null,
    header: null,
    message: null,
    color: null
  })
  const [isLoading, setIsLoading] = useState(false);

  let response;

  /**
   * PC_UP_04
   * The useEffect triggers the function to get the auditors list
   */
  useEffect(() => {
    fetchAuditorDetails();
  }, []);

  /**
   * PC_UP_06
   * Makes the API call to get the auditors list
   */
  const fetchAuditorDetails = async () => {
    setIsLoading(true)
    try {
      response = await getAuditorDetails();
      console.log(response, "response");
      setAuditorList(response.responseData[0]);
      console.log(response.responseData[0], "response.responseData[0]");
    } catch (error) {
      console.log(error, "error");
    }
    finally {
      setIsLoading(false)
    }
  };

  /**
 * PC_UP_29
 * store the selected start date in the state variable dateRange
 */
  const handleStartDateChange = (date) => {
    setDateRange((prevDateRange) => ({ ...prevDateRange, startDate: date }));
  };

  /**
 * PC_UP_28
 * store the selected end date in the state variable dateRange
 */
  const handleEndDateChange = (date) => {
    setDateRange((prevDateRange) => ({ ...prevDateRange, endDate: date }));
  };

  /**
 * PC_UP_26
 * Open the date pickers for the user
 */
  const toggleDatePickers = () => {
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      showDatePickers: !prevDateRange.showDatePickers,
      datePickerOpen: !prevDateRange.showDatePickers,
    }));
  };

  /**
 * PC_UP_27
 * Close the date pickers
 */
  const handleInputBlur = () => {
    // setTimeout(() => {
    if (!dateRange.datePickerOpen) {
      setDateRange((prevDateRange) => ({
        ...prevDateRange,
        showDatePickers: false,
      }));
    }
    // }, 200);
  };

  /**
 * PC_UP_36
 * Store the selected date in the state varibale auditData and close the date picker
 */
  const handleSelect = () => {
    if (dateRange.startDate && dateRange.endDate) {
      setDateRange((prevDateRange) => ({
        ...prevDateRange,
        showDatePickers: false,
      }));
      setAuditData({
        ...auditData,
        startDate: format(
          new Date(dateRange.startDate),
          "yyyy-MM-dd HH:mm:ss.SSS"
        ),
        endDate: format(new Date(dateRange.endDate), "yyyy-MM-dd HH:mm:ss.SSS"),
      });
    }
  };

  /**
 * PC_UP_50
 * Store the selected auditor in the state varibale auditData
 */
  const changeDropDown = (userId, profileUrl, employeeName) => {
    setSelectedItem({
      ...selectedItem,
      profileUrl: profileUrl,
      employeeName: employeeName,
    });
    setAuditData({ ...auditData, auditorId: userId });
    setIsDropdownSelected(false);
  };

  /**
 * PC_UP_53
 * Convert the bytes value to MB
 */
  const bytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2);
  };

  /**
 * PC_UP_45
 * Emit the user entered data to the parent component after the validation
 */
  const handleAdd = async () => {
    if (!auditData.startDate || !auditData.endDate || !auditData.auditCycleName || !selectedFile) {
      setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Missing Fields', message: 'Please fill all the required fields', color: 'error-toast' })
      setIsToastVisible(true)
      console.log("File is Invalid");
      return;
    }
    setIsLoading(true)
    try {
      const blobUrl = await handleUpload(selectedFile);
      onSave(auditData, blobUrl);
    }
    catch (error) {
      console.log(error, "error");
    }
    finally {
      setIsLoading(false)
    }
  };

  /**
 * PC_UP_48
 * Uploads the file in blob after the validation
 */
  const handleFileUpload = (event) => {
    const fileSizeCheck = (event.target.files[0].size/(1024 * 1024)).toFixed(2)

    if (validatePolicyFileType(event) && fileSizeCheck <= 50) {
      setSelectedFile(event.target.files[0])
    }
    else {
      setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Invalid File', message: 'Please select a valid file', color: 'error-toast' })
      setIsToastVisible(true)
      console.log("File is Invalid");
    }
  }

  return (
    <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block",backgroundColor: "rgba(0,0,0,0.6)" }}>
      <Loader isLoading={isLoading} />
      <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
        <div className="modal-content">
          {/*modal content starts here*/}
          <div className="modal-header border-0 pb-2 px-4">
            <h5 className="modal-title font-bold" id="exampleModalLabel">
              Past Audit &amp; Reports
            </h5>
            <button
              type="button"
              className="btn-close ms-auto"
              aria-label="Close"
              onClick={() => onClose()}
            />
          </div>
          <div className="modal-body border-0 font-16 px-4 customPopup">
            <div className="row">
              <div className="col-md-12 mb-2">
                <p className="font-14 grey-secondary">
                  Upload your SOC 2 Type 2 compliance report
                </p>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  htmlFor="report-name"
                  className="form-label font-medium font-14 primary-textcolor mb-1"
                >
                  Report Name<span className="required-text">*</span>
                </label>
                <input
                  type="text"
                  className="form-control font-regular font-14 custom-form-control py-2"
                  id="report-name"
                  maxLength={250}
                  placeholder="Enter Report Name"
                  onChange={(event) =>
                    setAuditData({
                      ...auditData,
                      auditCycleName: event.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-12">
                <label className="font-18 font-medium primary-textcolor mb-3">
                  Select Audit Timeline{" "}
                </label>
              </div>
              <div className="col-md-12 mb-4">
                <label
                  htmlFor="timeline"
                  className="form-label font-medium font-14 primary-textcolor mb-1"
                >
                  Timeline<span className="required-text">*</span>
                </label>
                {/* <input
                  type="date"
                  className="form-control font-regular font-14 custom-form-control py-2"
                  id="timeline"
                  placeholder="Select Time Line"
                /> */}
                <input
                  type="text"
                  className="form-control font-regular font-14 custom-form-control py-2"
                  id="timeline"
                  value={
                    dateRange.startDate && dateRange.endDate
                      ? `${dateRange.startDate.toLocaleDateString()} - ${dateRange.endDate.toLocaleDateString()}`
                      : ""
                  }
                  placeholder="Select Time Line"
                  onClick={toggleDatePickers}
                  onBlur={handleInputBlur}
                  readOnly
                />
                {dateRange.showDatePickers && (
                  <div className="date-pickers-container">
                    <DatePicker
                      selected={dateRange.startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={dateRange.startDate}
                      endDate={dateRange.endDate}
                      inline
                      onSelect={handleSelect}
                    />
                    <DatePicker
                      selected={dateRange.endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={dateRange.startDate}
                      endDate={dateRange.endDate}
                      minDate={dateRange.startDate}
                      inline
                      onSelect={handleSelect}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-4">
                <div className="dropdown">
                  <label
                    htmlFor="auditor"
                    className="form-label font-medium font-14 primary-textcolor mb-1"
                  >
                    Select Auditor
                  </label>
                  <div className="dropdown">
                    <button
                      id="auditor"
                      className="btn font-regular font-14 bg-white custom-form-control w-100 d-flex justify-content-between py-2"
                      onClick={() => setIsDropdownSelected(!isDropdownSelected)}
                      type="button"
                    >
                      {!auditData.auditorId ? (
                        <span className="primary-textcolor font-14">
                          Select
                        </span>
                      ) : (
                        <span className="primary-textcolor font-14">
                          <li className="font-12 font-medium primary-textcolor profile-dropdown-style">
                            <a
                              className="dropdown-item custom-dropdown-li pointer"
                            >
                              <img
                                className="custom-img-style me-2"
                                src={selectedItem.profileUrl}
                                alt="profile-img"
                              />
                              {selectedItem.employeeName}
                            </a>
                          </li>
                        </span>
                      )}
                      <span className="ps-1">
                        <img
                          src="/images/select-dropdown.svg"
                          alt="dropdown-icon"
                        />
                      </span>
                    </button>
                    {isDropdownSelected ? (
                      <ul
                        className="border-0 shadow-sm p-2 w-100"
                        data-popper-placement="bottom-start"
                      >
                        {auditorList.map((data) => (
                          <li className="font-12 font-medium primary-textcolor profile-dropdown-style">
                            <a
                              className="dropdown-item custom-dropdown-li pointer"
                              onClick={() =>
                                changeDropDown(
                                  data.userId,
                                  data.profileUrl,
                                  data.employeename
                                )
                              }
                            >
                              <img
                                className="custom-img-style me-2"
                                src={data.profileUrl}
                                alt="profile-img"
                              />
                              {data.employeename}
                            </a>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  htmlFor="firm"
                  className="form-label font-medium font-14 primary-textcolor mb-1"
                >
                  Auditing Firm
                </label>
                <input
                  type="text"
                  className="form-control font-regular font-14 custom-form-control py-2"
                  id="firm"
                  maxLength={250}
                  value={auditData.auditorFirm}
                  placeholder="Enter Auditing Firm"
                  onChange={(e) =>
                    setAuditData({ ...auditData, auditorFirm: e.target.value })
                  }
                />
              </div>
              {!selectedFile ? (
                <div className="col-md-12">
                  <label className="form-label font-medium font-14 primary-textcolor w-100">
                    Upload Report<span className="required-text">*</span>
                  </label>
                  <div className="upload-btn-wrapper-popup w-100">
                    <div className="upload-btn-popup font-12 font-medium primary-textcolor w-100">
                      <img
                        src="/images/upload-file-icon.svg"
                        alt="upload"
                        className="me-2 mb-3"
                      />
                      Upload .pdf or .docx
                      <label
                        htmlFor="upload-btn"
                        className="btn-upload-outline bg-white px-3 py-2 font-12 font-regular d-flex align-items-center mt-3"
                      >
                        <img
                          className="me-2 custom-upload-btnimg-style"
                          src="/images/upload-btn-icon.svg"
                          alt="upload-btn-icon"
                        />
                        Upload
                      </label>
                    </div>
                    <input
                      className="w-100"
                      id="upload-btn"
                      type="file"
                      name="myfile"
                      accept=".docx, .pdf"
                      onChange={(e) => handleFileUpload(e)}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-md-12">
                  <label className="form-label font-medium font-14 primary-textcolor w-100">
                    Report
                  </label>
                  <div className="card custom-pdf-card px-3 py-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <span className="d-flex align-items-center">
                          <span className="d-block">
                            <img src="/images/pdf-icon.svg" alt="pdf-icon" />
                          </span>
                          <span className="d-block ms-2">
                            <label className="w-100 font-12 font-medium">
                              {selectedFile.name}
                            </label>
                            <label className="font-12 font-medium">
                              {bytesToMB(selectedFile.size)} MB
                            </label>
                          </span>
                        </span>
                      </div>
                      <div>
                        <span onClick={() => setSelectedFile(null)}>
                          <img
                            src="/images/delete-icon.svg"
                            alt="delete-icon"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer border-0 mt-2 mb-3 px-4">
            <button
              type="button"
              className="primary-btn font-14 font-medium py-2"
              onClick={() => onClose()}
            >
              Cancel
            </button>
            <button
              type="button"
              className={!auditData.startDate || !auditData.endDate || !auditData.auditCycleName || !selectedFile ? "disabled-btn font-14 font-medium py-2" : "dark-btn font-14 font-medium py-2"}
              disabled={!auditData.startDate || !auditData.endDate || !auditData.auditCycleName || !selectedFile}
              onClick={() => handleAdd()}
            >
              Upload
            </button>
          </div>
          {/*modal content ends here*/}
        </div>
      </div>
      {isToastVisible && <ToastMessageComponent
        toastResponse={toastMessage}
        setIsShowToastMessage={setIsToastVisible}
      />}
    </div>
  );
};

export default UploadPastAuditReportPopup;