import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';


const LineEchartGraph = ({ data, onDataClick }) => {
  // Sort data by month
  /**PS_RT_35 - get the passed data as EventData, now sort the data array by month in ascending order */
  const sortedData = data.sort((a, b) => (a.month > b.month ? 1 : -1));

  // Format the month data
  /**PS_RT_36 */

  /**PS_RT_37 - Short the month January as 'Jan'  */
  const formattedData = sortedData.map(entry => {
    const [year, month] = entry.month.split('-');
    const monthName = new Date(`${year}-${month}-01`).toLocaleString('en-US', { month: 'short' });
    return { ...entry, month: `${monthName}, ${year}` };
  });


  /**PS_RT_42 - the handleLineEventClick gets Invoked when user clicks on point  */ 

  const handleLineEventClick = (e) => {
    if (onDataClick) {
      const activeLabel = e.activeLabel
      if (activeLabel) {
        onDataClick(activeLabel);
      }
    }
  }



  return (
    <LineChart width={600} height={300} data={formattedData} onMouseUp={(e) => handleLineEventClick(e)}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      {/* <Legend layout="horizontal" verticalAlign="top" align="right" iconType="rect"  /> */}
      {/**PS_RT_39 - Initializing of three Data Lines */}
      <Line
        dataKey="High"
        name="High"
        stroke="rgba(255,143,143)"
        dot={{ r: 5 }}
        strokeWidth={2}
      />
      <Line
        dataKey="Medium"
        name="Medium"
        stroke="rgba(255,178,145)"
        dot={{ r: 5 }}
        strokeWidth={2}
      />
      <Line
        dataKey="Low"
        name="Low"
        stroke="rgba(255,209,141)"
        dot={{ r: 5 }}
        strokeWidth={2}
      />
    </LineChart>
  );
};

export default LineEchartGraph;
