import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { encryptStorage } from '../../Constant';
import {Loader} from '../Loader/Loader'
// import { publicStripeKey, agovcontext } from './constants';
import { getSubscriptionDetails, getSessionIdForStripe, generatePaymentId, createDynamicDb } from '../../Services/Registration/RegistrationService';
import { EncryptStorage } from 'encrypt-storage';
 
//PS_SB_FE_1.4 to 1.77 To handle subscription and stripe payment
const SubscriptionPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
 
    const companyCountDetails = location?.state?.value;
    // Initialize initialRequest object with keys and default values
    const planDetails = {
        sessionId: '',
        amount: 0,
        registerId: companyCountDetails?.registerId,
        firstName: companyCountDetails?.firstName
    };
 
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);
    const [selectedPlanDetails, setSelectedPlanDetails] = useState(planDetails);
    const [morePlanShow, setMorePlanShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
  
    const publicStripeKey = `pk_test_51PTMtx07fMgcnxHjcWQ87dc8uwbnfbrMOdACU77LnEx2LYnnsTHdWeEiLKalSDj7fiVoloCMkMuFFsBqkopsoDSP00glDMl137`

    //console.log("company count details :", companyCountDetails);
 
    //PS_SB_FE_1.15  Fetch the subscription details when component mounts
    useEffect(() => {
        console.log("company count details :", companyCountDetails);
        encryptStorage.setItem('registerId', companyCountDetails?.registerId || encryptStorage.getItem('registerId'));
        encryptStorage.setItem('firstName', companyCountDetails?.firstName || encryptStorage.getItem('firstName'));
        encryptStorage.setItem('emailId', companyCountDetails?.email || encryptStorage.getItem('emailId'))
        //PS_SB_FE_1.55 To know whether the stripe paymnet is happen
        const urlParams = new URLSearchParams(window.location.search);
        const sessionIdFromURl = urlParams.get('session_id');
        //PS_SB_FE_1.57
        if (sessionIdFromURl) {
            const paymentDetails = {
                sessionId: sessionIdFromURl,
                registerId: encryptStorage.getItem('registerId'),
                firstName: encryptStorage.getItem('firstName'),
                planId: Number(encryptStorage.getItem('planId')),
                amount: Number(encryptStorage.getItem('amount')),
                validPeriod: Number(encryptStorage.getItem('validPeriod')),
                email: encryptStorage.getItem('email')
            }
            checkPaymentSuccess(paymentDetails);
        }
        else {
            fetchSubscriptionDetails();
        }
 
    }, []);
 
 
    //PS_SB_FE_1.16 to 1.26 Funation to fetch the subscription details based on head count
    const fetchSubscriptionDetails = async () => {
 
        const request = {
            registerId: companyCountDetails?.registerId,
            firstName: companyCountDetails?.firstName,
            headCount: companyCountDetails?.headCount
        };
        console.log("req in fetch subscription:", request);
        setIsLoading(true);
        const response = await getSubscriptionDetails(request);
        setIsLoading(false);
        if (response?.statusCode !== 200) {
            // Handle error: navigate to error page and show API failed message
            navigate('/error');
        } else {
            setSubscriptionDetails(response?.responseData);
        }
 
    };
 
    //PS_SB_FE 1.36 to 1.48 Fnstion to create the session id for stripe page
    const handleBuySubscription = async (planId, amount, validPeriod) => {
        setSelectedPlanDetails(prevState => ({
            ...prevState,
            planId,
            amount // This adds or updates the amount property
        }));
 
        
        encryptStorage.setItem('planId', planId);
        encryptStorage.setItem('amount', amount);
        encryptStorage.setItem('validPeriod', validPeriod);
        // Load stripe with publicStripeKey
        const stripe = await loadStripe(publicStripeKey);
 
        // // PS_SB_FE_.38 Call getSessionIdForStripe with apiRequest and planId
        setIsLoading(true);
        const sessionIdResult = await getSessionIdForStripe({
            amount: amount
        });
        setIsLoading(false);
        // Check sessionIdResult statusCode
        if (sessionIdResult.statusCode !== 200) {
            // Handle failure: navigate to error page and show pop-up message
            navigate('/error');
        } else {
            setSelectedPlanDetails(prevState => ({
                ...prevState,
                sessionId: sessionIdResult.responseData.sessionId
            }));
 
            // Redirect user to Stripe checkout page
            await stripe.redirectToCheckout({ sessionId: sessionIdResult.responseData.sessionId });
 
        }
 
    }
 
    //PS_SB_FE_1.58 to 1.79 function to get the stripe payment Id
    const checkPaymentSuccess = async (paymentDetails) => {
        try {   
           setIsLoading(true)
            const paymentSuccessResult = await generatePaymentId(paymentDetails);
            console.log(paymentDetails,"paymentdetails")

            if (paymentSuccessResult.statusCode == 200 && paymentSuccessResult.responseData.userState == 'Already exist user') {
               setIsLoading(false)
               navigate('/adminManagement/billing');
            }
            else {
                console.log("In the else condition");
                const createDynamic = await createDynamicDb({
                    registerId: encryptStorage.getItem('registerId'),
                    skewType: paymentSuccessResult.responseData.skewType
                });
                if(createDynamic.responseData.message === "DB Created Sucessfully"){
                    setIsLoading(false)
                    navigate('/InitialGuide');
                   
                }               
            }
            // else {
            //     navigate('/error');
            // }
        }
 
        catch (error) {
            // Handle error: navigate to error page and show API failed message
            navigate('/error');
        }
    }
 
 
 
    // Bind subscription details to HTML elements and render subscription plan details
    return (
        <>
        <Loader isLoading={isLoading} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <h2 className="font-bold font-48 pt-5 payment">Pay only for <span className="primary-gradient">what you need</span>
                            </h2>
                            <p className="font-24 font-regular text-secondary-color pt-2">Join our team with the plans below</p>
                            {/* first-section-start */}
                            <div className="col-xl-10 col-12 my-5 ">
                                <div className="row">
                                    {subscriptionDetails?.map((item, index) => (
                                        <div className="col-4">
                                            <div className="payment-container">
                                                <h4 className="font-18 font-medium ">{item.planName}</h4>
                                                <p className="font-25 font-medium mt-2">${item.amount}<span className="font-18 font-regular gray-v1">
                                                    /Month</span></p>
                                                <p className="font-14 font-regular black-v2 mt-2">{item.Description}</p>
                                                <div className="text-center"><button className="font-14 font-regular buy-btn mt-4 " disabled={index == 0 || index == 2} onClick={() => handleBuySubscription(item.planId, item.amount, item.validPeriod)}>Buy Now</button></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* first-section-end */}
                            {/* 2nd section starts here */}
                            <div className="col-xl-10 col-12 d-flex my-5 ">
                                <div className="row">
                                    <div className="col-md-4  my-3 d-flex  align-items-start">
                                        <img src="images/check.svg" alt="check-icon" className="pt-1" />
                                        <div className="ms-3 w-75 ">
                                            <p className="font-medium font-14 black-v2 mb-0"> Free connections</p>
                                            <p className="font-12 font-regular black-v2">Lorem Ipsum&nbsp;is simply dummy text of the
                                                printing and typesetting industry. </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4  my-3 d-flex  align-items-start">
                                        <img src="images/check.svg" alt="check-icon" className="pt-1" />
                                        <div className="ms-3 w-75 ">
                                            <p className="font-medium font-14 black-v2 mb-0"> Free connections</p>
                                            <p className="font-12 font-regular black-v2">Lorem Ipsum&nbsp;is simply dummy text of the
                                                printing and typesetting industry. </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4  my-3 d-flex  align-items-start">
                                        <img src="images/check.svg" alt="check-icon" className="pt-1" />
                                        <div className="ms-3 w-75 ">
                                            <p className="font-medium font-14 black-v2 mb-0"> Free connections</p>
                                            <p className="font-12 font-regular black-v2">Lorem Ipsum&nbsp;is simply dummy text of the
                                                printing and typesetting industry. </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4  my-3 d-flex  align-items-start">
                                        <img src="images/check.svg" alt="check-icon" className="pt-1" />
                                        <div className="ms-3 w-75 ">
                                            <p className="font-medium font-14 black-v2 mb-0"> Free connections</p>
                                            <p className="font-12 font-regular black-v2">Lorem Ipsum&nbsp;is simply dummy text of the
                                                printing and typesetting industry. </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4  my-3 d-flex  align-items-start">
                                        <img src="images/check.svg" alt="check-icon" className="pt-1" />
                                        <div className="ms-3 w-75 ">
                                            <p className="font-medium font-14 black-v2 mb-0"> Free connections</p>
                                            <p className="font-12 font-regular black-v2">Lorem Ipsum&nbsp;is simply dummy text of the
                                                printing and typesetting industry. </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4  my-3 d-flex  align-items-start">
                                        <img src="images/check.svg" alt="check-icon" className="pt-1" />
                                        <div className="ms-3 w-75 ">
                                            <p className="font-medium font-14 black-v2 mb-0"> Free connections</p>
                                            <p className="font-12 font-regular black-v2">Lorem Ipsum&nbsp;is simply dummy text of the
                                                printing and typesetting industry. </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4  my-3 d-flex  align-items-start">
                                        <img src="images/check.svg" alt="check-icon" className="pt-1" />
                                        <div className="ms-3 w-75 ">
                                            <p className="font-medium font-14 black-v2 mb-0"> Free connections</p>
                                            <p className="font-12 font-regular black-v2">Lorem Ipsum&nbsp;is simply dummy text of the
                                                printing and typesetting industry. </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4  my-3 d-flex  align-items-start">
                                        <img src="images/check.svg" alt="check-icon" className="pt-1" />
                                        <div className="ms-3 w-75 ">
                                            <p className="font-medium font-14 black-v2 mb-0"> Free connections</p>
                                            <p className="font-12 font-regular black-v2">Lorem Ipsum&nbsp;is simply dummy text of the
                                                printing and typesetting industry. </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4  my-3 d-flex  align-items-start">
                                        <img src="images/check.svg" alt="check-icon" className="pt-1" />
                                        <div className="ms-3 w-75 ">
                                            <p className="font-medium font-14 black-v2 mb-0"> Free connections</p>
                                            <p className="font-12 font-regular black-v2">Lorem Ipsum&nbsp;is simply dummy text of the
                                                printing and typesetting industry. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 2nd section ends here */}
                            <div  onClick={() => setMorePlanShow(!morePlanShow)}>
                                <button className="font-16 font-medium plan-btn mb-5 mt-3">Know about more plan <img src="images/down-arrow.svg" alt="down-arrow" className="ms-3 curser-pointer" />
                                </button>
                            </div>
                            {/* 3nd section starts here */}
                            {morePlanShow && <div className="col-xl-10 col-12 bg-white d-flex flex-column shadow-box rounded-2 align-items-center  mb-5">
                                <h4 className="font-24 font-medium black-v2 mt-5">Find the Insight about our plan</h4>
                                <p className="font-16 font-regular black-v2 mt-3">Lorem Ipsum&nbsp;is simply dummy text of the printing
                                    and typesetting industry. </p>
                                {/* table-start-here */}
                                <div className="table-responsive my-5 table-width">
                                    <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                                        <thead>
                                            <tr>
                                                <th className="font-14 font-medium">Features</th>
                                                <th className="font-14 font-medium text-center"> Plan A</th>
                                                <th className="font-14 font-medium text-center"> Plan B</th>
                                                <th className="font-14 font-medium text-center">Plan C</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="font-14 font-regular ">One compliance framework </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">300+ integrations </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">Employee management </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">Access management </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular ">Risk management </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">Vendor management </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">Policy templates </td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">Trust Center</td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">Role-based access controls </td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">Vulnerability management </td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">SSO </td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">Event logs</td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-14 font-regular">Vanta API </td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/red-cancel.svg" alt="red-check" />
                                                </td>
                                                <td className="text-center">
                                                    <img src="images/green-check.svg" alt="green-check" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* table-ends-here */}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
 
        </>
    );
};
 
export default SubscriptionPage;