import React from 'react';

const ReadyQuizPopUp = ({ setIsReadyQuizVisisble, handleStartQuizQuestions }) => {

    return (
        <>
            <div
                className="modal"
                id="delete"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                style={{ display: 'block' }}
            >

                <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-bottom pb-3 px-4">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">
                                Quiz
                            </h5>
                            <button
                                type="button"
                                className="btn-close ms-auto"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setIsReadyQuizVisisble(false)}
                            />
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="row mt-5 mb-5">
                                <div className="col-md-12 text-center">
                                    <span className="mb-5">
                                        <img src="/images/Quiz-ready.svg" alt="Quiz" className="mb-5" />
                                    </span>
                                    <h3 className="font-bold font-24 mb-3">Ready for Quiz?</h3>
                                    <p className="font-16 font-regular primary-textcolor text-center mb-5 px-5">
                                        Test yourself on the skills in this course and earn mastery
                                        points for what you already know!
                                    </p>
                                    <button
                                        type="button"
                                        className="dark-btn font-14 font-medium py-2"
                                        onClick={() => handleStartQuizQuestions()}
                                    >
                                        Start Quiz
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div >
            <div class="modal-backdrop fade show"></div>
        </>

    );
};

export default ReadyQuizPopUp;