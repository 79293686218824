import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { agovContext, encryptStorage } from '../../Constant';
import {
  approveAccess,
  closeRiskTag,
  getAccessNotification,
  getAccordianData,
  getApprovalStatusData,
  getExportToExcelData,
  getGovernanceUser,
  notificationClick,
  revokeApplicationAccess,
  showRiskTag,
} from '../../Services/Agov/governanceService';
import profilepicture from '../HeaderComponent/user-icon.png';
import { Loader } from '../Loader/Loader';
import NoRecordFoundForAG from './NoRecordFoundForAG';

function AccessGovernance() {
  const { employeeId, roles, userId } = useContext(agovContext);

  const navigate = useNavigate();
  const { state } = useLocation();

  const [managerId, setManagerId] = useState(employeeId);
  let [gridData, setGridData] = useState([]);
  let [nextPage, setNextPage] = useState(10);
  let [searchValue, setSearchValue] = useState('');
  let [showToolTip, setShowToolTip] = useState(true);
  let [showPopup, setShowPopup] = useState(true);
  let [approvePopup, setApprovePopup] = useState(true);
  let [revokePopup, setRevokePopup] = useState(true);
  let [reasonError, setReasonError] = useState('');
  let [categoryError, setCategoryError] = useState('');
  let [userAccordian, setuserAccordian] = useState([]);
  const [gridCount, setGridCount] = useState(0);
  let [hireCount, setHireCount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appTab, setAppTab] = useState(true);
  const [flag, setFlag] = useState(true);
  const [searchValueConstant, setSearchValueConstant] = useState('');
  // const [sortColumnName, setSortColumnName] = useState('e.employee_name asc');
  const [sortColumnName, setSortColumnName] = useState(null);
  let [revokeAccessData, setrevokeAccessData] = useState([]);
  let [hierarchyId, setHierarchyId] = useState([managerId]); // REPORTS TO ID ALONG WITH LOGGED IN USER'S EMP ID
  const [initialManagerId, setInitialManagerId] = useState(managerId);
  let [revokeDataLength, setRevokeDataLength] = useState(0);
  let [excelButton, setExcelButton] = useState(true);
  let [removedAppLength, setRemovedAppLength] = useState(0);
  let [allSelectCheckbox, setAllSelectCheckbox] = useState([]);
  let [approveCheckArray, setApproveCheckArray] = useState([]);
  let [riskUsersCheckArray, setRiskUsersCheckArray] = useState([]);
  const [isRevoke, setisRevoke] = useState(true);
  const [isApprove, setisApprove] = useState(true);
  let [newData, setNewData] = useState([]);
  let [changeData, setChangeData] = useState([]);
  const [checkArray, setCheckArray] = useState([]);
  const [approveButtonHideOrShow, setApproveButtonHideOrShow] = useState(null);
  const [riskTagHideOrShow, setRiskTagHideOrShow] = useState(true); // DECIDIES WHETHER PAR RIBBON NEED TO SHOW OR NOT BASED ON RES
  const [riskTagDetails, setRiskTagDetails] = useState(''); // PAR RIBBON MESSAGE
  const [dynamicHeader, setdynamicHeader] = useState([]); // RETRIEVES OF HEADER OF THE GRID
  const [breadcrumbVisible, setBreadcrumbVisible] = useState(true);
  let [showNotification, setShowNotification] = useState(true);
  let [currentUserID, setCurrentUserId] = useState('');
  const [revokeReason, setrevokeReason] = useState('');
  const [revokeCategory, setRevokeCategory] = useState('');
  const [isApproveOrRevoke, setIsApproveOrRevoke] = useState('');
  const [isAccessOff, setIsAccessOff] = useState(false);
  let [reporteeId, setReporteeId] = useState('');
  let [showAccordian, setshowAccordian] = useState(true);
  const [tableIndex, setTableIndex] = useState(null);
  const [noAction, setNoAction] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('Awaiting Confirmation');
  const [userSelectedCount, setUserSelectedCount] = useState([]);
  const [uniqueUsersSet, setUniqueUsersSet] = useState(new Set());
  const [accessPeriod, setAccessPeriod] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const [reportsToCount, setReportsToCount] = useState([]);
  const [activeId, setActiveId] = useState(null); // ID RESPONSIBLE FOR CLOSING THE ACCORDION
  const [isAccordianClicked, setIsAccordianClicked] = useState(false);
  const [filterValue, setFilterValue] = useState(state?.filterValue || '');
  const [teamTab, setTeamTab] = useState(state?.tab === 'myteamtab' ? true : true);
  const [orgTab, setOrgTab] = useState(state?.tab === 'myorgtab' ? true : true);
  const [governanceTab, setGovernanceTab] = useState(state?.tab || '');
  const [currentTab, setCurrentTab] = useState('');
  const [myAppButton, setMyAppButton] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(new Set());

  let appData = [];
  let appValue = [];
  let tabValue = state?.tab;
  let isGovernanceTabSet = true;

  useEffect(() => {
    setIsLoading(true);
    accessGovernanceIntialLoad()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [managerId, reporteeId, nextPage, sortColumnName, pageLoading, myAppButton]);

  useEffect(() => {
    let shouldDisable = false;
    for (const user of selectedUsers) {
      if (user.is_threat === 1 || user.lms_status === 'overdue' || user.is_active === false) {
        shouldDisable = true;
        break;
      }
    }
    setApproveButtonHideOrShow(shouldDisable);
  }, [selectedUsers]);

  const isDisabled = gridData.length === 0;

  const accessGovernanceIntialLoad = async () => {
    if (riskTagDetails === '') {
      await Promise.all([loadRiskTag(), loadGrid(), loadApprovalStatus()]);
    } else if (currentTab === 'myapptab') {
        await loadGrid();
    } else {
      await Promise.all([loadGrid(), loadApprovalStatus()]);
    }
  };

  const handleFilterClear = (e) => {
    e.stopPropagation();
    setFilterValue('');
  };

  const handleApplyFilter = async () => {
    setIsLoading(true);
    try {
      await loadGrid();
      setSelectedUsers(new Set());
      setActiveId(null);
      setNextPage(10);
      // setSortColumnName('e.employee_name asc');
      setSortColumnName(null);
    } catch (error) {
      console.log('ERROR: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadApprovalStatus = async () => {
    try {
      let lastindex = hierarchyId.length - 1;
      const loadAccessData = {
        id: userId,
        date: moment().format('YYYY-MM-DD'),
      };

      let data = await getApprovalStatusData(loadAccessData);
      setAccessPeriod(data.message);
      if (data.message == 'AccessOn') {
        setShowPopup(false);
      } else if (data.message == 'AccessOff') {
        setShowPopup(false);
        setNoAction(true);
      }
    } catch (error) {
      console.log('ERROR: ', error);
    } finally {
      setSelectedUsers(new Set());
      setActiveId(null);
    }
  };

  // RES OF PAR RIBBON MESSAGE
  const loadRiskTag = async () => {
    try {
      const riskDetails = {
        id: userId,
        date: moment().format('YYYY-MM-DD'),
      };

      const details = await showRiskTag(riskDetails);

      if (details.statusCode == 200) {
        const splitResponse = details.response.split(' ');
        const access = splitResponse.includes('off');
        setIsAccessOff(access);
        setRiskTagDetails(details.response);
        setRiskTagHideOrShow(false);
      } else {
        setRiskTagHideOrShow(true);
      }
    } catch (error) {
      console.log('ERROR MESSAGE: ', error);
    }
  };

  // HIDING RISK TAG WHEN CLOSE BUTTON IS CLICKED
  const hideRiskTag = async () => {
    setIsLoading(true);
    const riskDetails = {
      id: userId,
      date: moment().format('YYYY-MM-DD'),
    };

    const tagResponse = await closeRiskTag(riskDetails);
    setIsLoading(false);

    if (tagResponse.statusCode == 400) {
      throw tagResponse;
    } else {
      setRiskTagHideOrShow(true);
      setRiskTagDetails('');
    }
  };

  const formatDate = (dateTimeString) => {
    if (!dateTimeString || dateTimeString == undefined) return '-';
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 === 0 ? '12' : (hour % 12).toString().padStart(2, '0');
    return day + '/' + month + '/' + year + ' ' + formattedHour + ':' + minute + ' ' + ampm;
  };

  const handleExportExcel = async () => {
    try {
      setIsLoading(true);
      let requestObject = {
        id: userId,
        managerId: managerId,
        search: searchValue,
        tab: currentTab,
        filter: filterValue,
      };

      let exportData = await getExportToExcelData(requestObject);

      const users = exportData.response;

      const logDate = formatDate(exportData.responseDate);

      let applications = exportData.response;

      applications.map((data) => {
        data.splice(5, 0, logDate);
      });

      const filename = 'Access-Governance-Reports_' + new Date().toISOString().slice(0, 10).replace(/-/g, '') + '.xlsx';
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

      let workbook = new ExcelJS.Workbook();
      let worksheet = workbook.addWorksheet('Application Assignment');

      worksheet.columns = [
        { header: 'Employee Name', key: 'employeeName', width: 20 },
        { header: 'Employee Email', key: 'employeeEmail', width: 30 },
        { header: 'Department', key: 'department', width: 15 },
        { header: 'Manager Name', key: 'managerName', width: 20 },
        { header: 'Application Name', key: 'applicationName', width: 25 },
        { header: 'Last Login Date & Time', key: 'lastLogin', width: 25 },
        { header: 'Location', key: 'location', width: 15 },
        { header: 'IP Address', key: 'ipAddress', width: 15 },
        { header: 'Review By', key: 'reviewBy', width: 15 },
        { header: 'Status', key: 'status', width: 15 },
      ];

      applications.forEach((row) => {
        const modifiedRow = row.map((cell, index) => {
          if (index === 3 && (!cell || cell.trim() === '')) {
            return '-';
          }
          return cell;
        });
        worksheet.addRow(modifiedRow);
      });

      let buffer = await workbook.xlsx.writeBuffer();
      let dataBlob = new Blob([buffer], { type: fileType });

      FileSaver.saveAs(dataBlob, filename);
    } catch (error) {
      console.error('ERROR: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadGrid = async (fetchNotifications = true) => {
    try {
      let lastindex = hierarchyId.length - 1;

      if (currentTab == '') {
        if (roles.includes('Manager')) {
          setTeamTab(false);
          if (isGovernanceTabSet && !governanceTab) {
            setGovernanceTab(encryptStorage.getItem('agTab') || 'myteamtab');
            tabValue = encryptStorage.getItem('agTab') || 'myteamtab';
            isGovernanceTabSet = false;
          }
        }
        if (roles.includes('Super Admin') || roles.includes('SOC Admin')) {
          setOrgTab(false);

          if (isGovernanceTabSet && !governanceTab) {
            setGovernanceTab(encryptStorage.getItem('agTab') || 'myorgtab');
            tabValue = encryptStorage.getItem('agTab') || 'myorgtab';
            isGovernanceTabSet = false;
          }
        }
        if (roles.includes('App Admin')) {
          setAppTab(false);
          if (isGovernanceTabSet && !governanceTab) {
            setGovernanceTab(encryptStorage.getItem('agTab') || 'myapptab');
            setExcelButton('false');
            tabValue = encryptStorage.getItem('agTab') || 'myapptab';
            isGovernanceTabSet = false;
          }
        }
      } else {
        tabValue = currentTab;
      }

      let agConfig = {
        id: userId,
        hierarchyId: hierarchyId[lastindex][0],
        reporteeId: reporteeId,
        managerId: managerId,
        search: searchValue,
        page: nextPage,
        tab: tabValue,
        sort: sortColumnName,
        approval: approvalStatus,
        filterValue,
      };

      const gridPromise = getGovernanceUser(agConfig);

      let grid, notify;

      if (fetchNotifications && currentTab !== 'myapptab') {
        [grid, notify] = await Promise.all([gridPromise, getAccessNotification(agConfig)]);
      } else {
        grid = await gridPromise;
      }

      setReportsToCount(grid?.response[0]);
      setCurrentTab(tabValue);

      if (tabValue == 'myapptab') {
        setGridData(grid.response);
      } else {
        setGridData(grid.response[0]);
      }

      appValue = grid.response;
      setdynamicHeader(grid.responseData);

      hireCount.push(reportsToCount);
      if (!breadcrumbVisible) {
        setBreadcrumbVisible(true);
      }

      if (tabValue != 'myapptab') {
        if (grid.response[1] && grid.response[1].totalCount) {
          setGridCount(grid.response[1].totalCount);
        } else {
          setGridCount(0);
        }
      } else {
        setGridCount(grid.counts[0].totalCount);
      }

      let checkboxArray = new Array(gridData?.length).fill(false);
      setCheckArray(checkboxArray);

      setNewData({ ...newData, ...notify?.response[0] });
      setChangeData({ ...changeData, ...notify.response[1] });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // SWITCHTING BETWEEN TABS
  const handleAgTab = async (e) => {
    encryptStorage.setItem('agTab', e || encryptStorage.getItem('agTab'));
    let tabValue = e || encryptStorage.getItem('agTab');
    setFilterValue('');
    setCurrentTab(tabValue);
    setSelectedUsers(new Set());
    setrevokeAccessData([]);
    setReporteeId('');
    setManagerId(initialManagerId);
    setHierarchyId([initialManagerId]);
    setBreadcrumbVisible(true);
    setAllSelectCheckbox([]);
    setCheckArray([]);
    setuserAccordian([]);
    setIsLoading(true);
    setGovernanceTab(tabValue);
    setBreadcrumbVisible(false);
    setIsLoading(false);
    setSearchValue('');
    setSearchValueConstant('');
    setshowAccordian(true);
    setSearchValue('');
    setMyAppButton(!myAppButton);
    setIsAccordianClicked(false);
    setActiveId(null);
    setNextPage(10);
    // setSortColumnName('e.employee_name asc');
    setSortColumnName(null);
  };

  // BINDING GRID HEADER
  const tableHeader = (dynamicHeader) => {
    return isDisabled ? (
      <tr>
        {dynamicHeader?.map((value, index) => (
          <th key={index} className="font-14">
            {value}
            {renderSortIcons(value)}
          </th>
        ))}
      </tr>
    ) : (
      <tr>
        <th></th>
        <th></th>
        {dynamicHeader?.map((value, index) => (
          <th key={index} className="font-14">
            {value}
            {renderSortIcons(value)}
          </th>
        ))}
      </tr>
    );
  };

  const renderSortIcons = (value) => {
    if (value.includes('User Name') || value.includes('Reporting User Count') || value.includes('Department')) {
      return (
        <span className="position-absolute">
          {value.includes('User Name') && (
            <>
              <a onClick={() => setSortColumnName('e.employee_name asc')} className="sort-up position-absolute ms-2">
                <img className="sort-img-size cursor-pointer" src="/images/sort-up.svg" alt="sort-up-icon" />
              </a>
              <a onClick={() => setSortColumnName('e.employee_name desc')} className="sort-down position-absolute ms-2">
                <img className="sort-img-size cursor-pointer" src="/images/sort-down.svg" alt="sort-down-icon" />
              </a>
            </>
          )}
          {value.includes('Reporting User Count') && (
            <>
              <a onClick={() => setSortColumnName('report_Count asc')} className="sort-up position-absolute ms-2">
                <img className="sort-img-size cursor-pointer" src="/images/sort-up.svg" alt="sort-up-icon" />
              </a>
              <a onClick={() => setSortColumnName('report_Count desc')} className="sort-down position-absolute ms-2">
                <img className="sort-img-size cursor-pointer" src="/images/sort-down.svg" alt="sort-down-icon" />
              </a>
            </>
          )}
          {value.includes('Department') && (
            <>
              <a onClick={() => setSortColumnName('e.employee_domain asc')} className="sort-up position-absolute ms-2">
                <img className="sort-img-size cursor-pointer" src="/images/sort-up.svg" alt="sort-up-icon" />
              </a>
              <a onClick={() => setSortColumnName('e.employee_domain desc')} className="sort-down position-absolute ms-2">
                <img className="sort-img-size cursor-pointer" src="/images/sort-down.svg" alt="sort-down-icon" />
              </a>
            </>
          )}
        </span>
      );
    } else {
      return null;
    }
  };

  // HANDLE CLICK OF THE NEW & CHANGE BUTTON
  const handleNotificationClick = (user_id) => {
    setCurrentUserId(user_id);
    setShowToolTip(!showToolTip);
  };

  // HANDLE CLOSING OF THE NEW & CHANGE BUTTON
  const handleCloseClick = () => {
    setShowNotification(false);
  };

  let bindNewNotifications = (userID) => {
    let userEvents = Object.values(newData[userID] || []).flat();

    if (userEvents.length > 0) {
      return {
        notifications: userEvents.slice(0, 5).map((event, index) => {
          return (
            <li className="font-10 my-3" key={index}>
              <img src={event.application_icon_url !== null ? event.application_icon_url : 'images/Adobe_Photoshop.svg'} className="new-hover-img" alt="Application Icon" />
              <span className="font-medium ms-2">{event.application_name}</span> had been added
            </li>
          );
        }),
        count: userEvents.length,
      };
    } else {
      return {
        notifications: null,
        count: 0,
      };
    }
  };

  let bindChangeNotification = (userID) => {
    let userEvents = Object.values(changeData[userID] || []).flat();

    if (userEvents.length > 0) {
      return {
        notifications: userEvents.slice(0, 5).map((event, index) => {
          return (
            <li className="font-10 my-3" key={index}>
              <img src={event.application_icon_url != null ? event.application_icon_url : ''} className="new-hover-img" alt="Application Icon" />
              <span className="font-medium ms-2">{event.application_name}</span> had been revoked
            </li>
          );
        }),
        count: userEvents.length,
      };
    } else {
      return {
        notifications: null,
        count: 0,
      };
    }
  };

  let clearNotification = async (userId, value, redirect) => {
    setCurrentUserId('');
    let data = changeData[userId];
    let data1 = newData[userId];
    switch (value) {
      case 'change':
        data.splice(0, data.length);
        changeData = { ...changeData, ...data };
        setChangeData(changeData);
        break;
      case 'new':
        data1.splice(0, data1.length);
        newData = { ...newData, ...data1 };
        setNewData(newData);
        break;
      default:
        break;
    }
    let response = await notificationClick({
      userId: userId,
      value: value,
      manager_id: managerId,
    });
    if (redirect === 'assign') {
      navigate(`/activityLogs`, {
        state: {
          value: { activity: 'assign', userId: userId },
        },
      });
    } else if (redirect === 'unassign') {
      navigate(`/activityLogs`, {
        state: {
          value: { activity: 'unassign', userId: userId },
        },
      });
    }
    if (response.statusCode === 200) {
      await loadGrid();
    }
  };

  let accordianClick = async (e, type, value, userdata, index) => {
    try {
      setActiveId((prevActiveId) => (prevActiveId === index ? null : index));
      setTableIndex(index);
      setIsLoading(true);
      if (showAccordian && tableIndex === index) {
        setshowAccordian(false);
      } else {
        setshowAccordian(true);
      }
      let userid = value;
      if (!userAccordian[userid]) {
        let data = await getAccordianData({
          id: userid,
          date: moment().format('YYYY-MM-DD HH:mm:ss'),
        });
        if (data.statusCode == 400) {
          throw data;
        }
        userAccordian = { ...userAccordian, [userid]: data.response[0] };
        setuserAccordian(userAccordian);

        if (tableIndex !== index || !showAccordian) {
          setshowAccordian(true);
        }
      }
      if (type == 'checkbox') {
        if (e.target.checked) {
          setSelectedUsers((prevSet) => new Set(prevSet.add(userdata)));
        } else {
          setSelectedUsers((prevSet) => {
            const newSet = new Set(prevSet);
            newSet.delete(userdata);
            return newSet;
          });
        }

        let test = userSelectedCount;
        if (test.includes(value)) {
          test.pop(value);
        } else {
          if (!uniqueUsersSet.has(value)) {
            test.push(value);
          }
        }

        showAccordian === true ? setshowAccordian(false) : setshowAccordian(true);
        selectAllCheckbox(e, index, userdata);
        setRevokeCategory('');
      }
    } catch (error) {
      console.log('ERROR: ', error);
    } finally {
      setIsLoading(false);
      setIsAccordianClicked(activeId === index);
    }
  };

  const selectAllCheckbox = (e, index, empValue) => {
    let userId = e.target.id.split('@')[0];
    let array = [...checkArray];

    if (e.target.checked) {
      setSelectedUsers((prevSet) => new Set(prevSet.add(empValue)));
      if (revokeAccessData.length != 0) {
        revokeAccessData = RemoveUser(revokeAccessData, userId + '@');
        setrevokeAccessData(revokeAccessData);
      }
      if (allSelectCheckbox.length != 0) {
        allSelectCheckbox = RemoveAllCheckBox(allSelectCheckbox, userId);
        setAllSelectCheckbox(allSelectCheckbox);
      }
      array[index] = true;

      if ((empValue.threat_status == 'Threat' || empValue.lms_status == 'overdue') && userAccordian[userId]?.length) riskUsersCheckArray.push(`risk@#$${userId}`);

      userAccordian[userId]?.forEach((value) => {
        allSelectCheckbox.push(value.resource_id + '@#' + userId);
        revokeAccessData.push([
          value.resource_id,
          e.target.id.split('@')[0],
          value.revoke_id,
          empValue.employee_name,
          value.application_name,
          empValue.manager_email,
          empValue.primary_email,
          empValue.manager_id,
        ]);

        if (value.approval_status == 'Approved') approveCheckArray.push(`approved@#$${userId}@#$${value.revoke_id}`);
      });
    } else {
      setSelectedUsers((prevSet) => {
        const newSet = new Set(prevSet);
        newSet.delete(empValue);
        return newSet;
      });

      array[index] = false;

      approveCheckArray = approveCheckArray?.filter((value) => !(value.split('@#$')[1] == userId));
      riskUsersCheckArray = riskUsersCheckArray?.filter((value) => !(value.split('@#$')[1] == userId));

      if (allSelectCheckbox.length != 0) {
        allSelectCheckbox = RemoveAllCheckBox(allSelectCheckbox, userId);
        setAllSelectCheckbox(allSelectCheckbox);
      }

      revokeAccessData = RemoveUser(revokeAccessData, userId + '@');
    }

    setCheckArray(array);

    updateUniqueUsersSet();

    setRevokeDataLength(revokeAccessData.length);

    if (revokeAccessData.length) {
      setisRevoke(false);
      setisApprove(!approveCheckArray.length ? false : true);
    } else {
      setisRevoke(true);
      setisApprove(true);
    }

    adminRevoke(revokeAccessData);
    setShowPopup(false);
  };

  const adminRevoke = (user_id) => {
    let id = user_id.filter((ele) => ele[1] == userId);
    if (id.length > 0) setisRevoke(true);
    else if (user_id.length > 0) setisRevoke(false);
  };

  const updateUniqueUsersSet = () => {
    const newSet = new Set();
    allSelectCheckbox.forEach((item) => {
      const userId = item.split('@#')[1];

      if (!userSelectedCount.includes(userId)) {
        newSet.add(userId);
      }
    });
    setUniqueUsersSet(newSet);
  };

  const selectIndividualCheckbox = (e, appData, employeeData, gridIndex) => {
    let array = [...checkArray];
    let userId = e.target.name;

    if (e.target.checked) {
      setSelectedUsers((prevSet) => new Set(prevSet.add(employeeData)));
      allSelectCheckbox.push(appData.resource_id + '@#' + employeeData.user_id);
      revokeAccessData.push([
        appData.resource_id,
        e.target.id.split('@')[0],
        appData.revoke_id,
        employeeData.employee_name,
        appData.application_name,
        employeeData.manager_email,
        employeeData.primary_email,
        employeeData.manager_id,
      ]);
      setrevokeAccessData(revokeAccessData);

      let applications = checkSameArrayLength(userId);
      if (applications.length == userAccordian[userId].length) {
        array[gridIndex] = true;
        setCheckArray(array);
      }

      if (appData.approval_status?.toLowerCase() == 'approved') approveCheckArray.push(`approved@#$${userId}@#$${appData.revoke_id}`);

      if (employeeData.threat_status == 'Threat' || employeeData.lms_status == 'overdue') riskUsersCheckArray.push(`risk@#$${userId}`);
    } else {
      let applications = checkSameArrayLength(userId);

      if (applications.length === 1) {
        setSelectedUsers((prevSet) => {
          const newSet = new Set(prevSet);
          newSet.delete(employeeData);
          return newSet;
        });
      }
      array[gridIndex] = false;
      setCheckArray(array);
      let deleteIndex = allSelectCheckbox.indexOf(appData.resource_id + '@#' + employeeData.user_id);
      allSelectCheckbox.splice(deleteIndex, 1);
      var userApp = userId + '@' + appData.resource_id;
      revokeAccessData = RemoveApp(revokeAccessData, userApp);
      setrevokeAccessData(revokeAccessData);

      approveCheckArray = approveCheckArray?.filter((value) => {
        return !(value.split('@#$')[1] + '@#$' + value.split('@#$')[2] == userId + '@#$' + appData.revoke_id);
      });

      setApproveCheckArray(approveCheckArray);
      setRiskUsersCheckArray(riskUsersCheckArray);
    }
    setAllSelectCheckbox(allSelectCheckbox);
    setFlag(!flag);

    setRevokeDataLength(revokeAccessData.length);
    if (revokeAccessData.length) {
      setisRevoke(false);
    } else {
      setisRevoke(true);
      setisApprove(true);
    }
    if (!approveCheckArray.length && revokeAccessData.length) setisApprove(false);
    else setisApprove(true);
    adminRevoke(revokeAccessData);

    updateUniqueUsersSet();
    setShowPopup(false);
  };

  let RemoveAllCheckBox = (arr, value) => {
    return arr.filter((val) => {
      return !(val.split('@#')[1].trim() == value.trim());
    });
  };

  // REMOVE ANY EXISTING USER TO AVOID USER CONFLICT
  let RemoveUser = (arr, value) => {
    return arr.filter((param) => {
      return !(param[1] == value.split('@')[0]);
    });
  };

  // REMOVE ANY EXISTING APPLICATION TO AVOID APP CONFLICT
  let RemoveApp = (arr, value) => {
    return arr.filter((param) => {
      return !(param[0] == value.split('@')[1] && param[1] == value.split('@')[0]);
    });
  };

  const handleToastClose = () => {
    setActiveId(null);
    setshowAccordian(false);
    setSelectedUsers(new Set());
    setCheckArray(new Array(checkArray.length).fill(false));
    setAllSelectCheckbox([]);
    setrevokeAccessData([]);
    setApproveCheckArray([]);
    setRiskUsersCheckArray([]);
    setRevokeDataLength(0);
    setisRevoke(true);
    setisApprove(true);
    setShowPopup(false);
  };

  const checkSameArrayLength = (userId) => {
    let checkApplicationLength = [];
    allSelectCheckbox.map((obj) => {
      if (obj.split('@#')[1] == userId) {
        checkApplicationLength.push(userId);
      }
    });
    return checkApplicationLength;
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString || dateTimeString == undefined) return '-';

    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 === 0 ? '12' : (hour % 12).toString().padStart(2, '0');

    return (
      <span>
        {day}/{month}/{year} {formattedHour}:{minute} {ampm}
      </span>
    );
  };

  const bindAccessGovernanceUser = () => {
    try {
      return gridData?.map((obj, index) => {
        let Hier = obj.employee_id + '@' + obj.employee_name;

        if (gridData.length != 0) {
          let hierarchyBindCount;
          reportsToCount?.map((hierObj) => {
            if (hierObj.employee_id == obj.employee_id) {
              hierarchyBindCount = hierObj.report_Count;
            }
          });

          hierarchyBindCount = hierarchyBindCount === undefined ? 0 : hierarchyBindCount;

          return (
            <>
              <tr style={obj.is_active === false ? { opacity: 0.5 } : {}}>
                <td>
                  <button
                    key={index}
                    className={`p-0 border-0 shadow-none orgtodo-accbutton-custom bg-transparent cursor-pointer ${activeId === index ? '' : 'collapsed'}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#row-${index}`}
                    id={`accordian-${index}`}
                    aria-expanded={activeId === index}
                    onClick={(e) => {
                        accordianClick(e, '', obj.user_id, obj, index);
                      }
                    }
                  />
                </td>
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input custom-checkbox"
                      type="checkbox"
                      id={obj.user_id + '@all'}
                      data-bs-toggle={userAccordian[obj.user_id] == undefined || userAccordian[obj.user_id] == [] ? `collapse` : ''}
                      data-bs-target={'#row-' + index}
                      aria-expanded="true"
                      name={obj.user_id}
                      disabled={obj.validAppCount === 0}
                      style={{ cursor: obj.validAppCount > 0 ? 'pointer' : 'default' }}
                      onChange={(e) => selectAllCheckbox(e, index, obj)}
                      onClick={(e) => {
                        if (obj.validAppCount > 0 || obj.is_active) {
                          accordianClick(e, 'checkbox', obj.user_id, obj, index);
                        }
                        const approveButtonCondition = obj.is_threat === 1 || obj.lms_status === 'overdue' || obj.is_active === false;
                        setApproveButtonHideOrShow(approveButtonCondition);
                      }}
                      checked={(obj.validAppCount > 0 || obj.is_active) && checkArray[index]}
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <img
                      src={obj.profile_url}
                      className="table-prof"
                      style={{
                        cursor: 'pointer',
                        zIndex: '100',
                      }}
                    />
                    <p
                      className={`
                        font-12 font-medium mb-0 ms-2
                        ${obj.is_threat == 0 && obj.lms_status != 'overdue' ? 'secondary-textcolor' : 'red-textcolor'}
                      `}
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // if (obj.is_active === true) {
                          navigate(`/userprofile`, {
                            state: {
                              value: {
                                id: obj.user_id,
                                employeeid: obj.employee_id,
                                navigatedFromAG: true
                              },
                            },
                          });
                        // }
                      }}
                    >
                      {obj.employee_name}
                    </p>

                    <span>
                      {obj.is_active === false && obj.validAppCount > 0 && (
                        <div className="tooltip-container">
                          <img src="images/info-icon.svg" className="ms-2" />
                          <div className="tooltip3">The user is inactive and still has application assigned.</div>
                        </div>
                      )}
                      {obj.lms_status === 'overdue' && (
                        <div className="tooltip-container">
                          <img
                            src="/images/Graduation-cap.svg"
                            className="ms-2"
                            onClick={() =>
                              navigate(`/activitylogs`, {
                                state: {
                                  value: {
                                    id: obj.user_id,
                                    category: ['lmsTaskManagement'],
                                    event: ['campaignOverdue'],
                                  },
                                },
                              })
                            }
                          />
                          <div className="tooltip3">This user has his courses in pending/Overdue</div>
                        </div>
                      )}
                      {obj.is_threat === 1 && (
                        <div className="tooltip-container">
                          <img
                            src="/images/warning.svg"
                            className="ms-2"
                            onClick={() =>
                              navigate(`/activitylogs`, {
                                state: {
                                  value: {
                                    id: obj.user_id,
                                    category: ['riskTaskManagement'],
                                  },
                                },
                              })
                            }
                          />
                          <div className="tooltip3">This user activity is flagged as suspicious</div>
                        </div>
                      )}
                      {obj.app_count !== 0 &&
                        obj.is_active ===
                          false(
                            <img
                              src="/images/info-icon.svg"
                              className="ms-2 info-icon"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="The user is inactive and still has applications assigned"
                              aria-label="The user is inactive and still has applications assigned"
                              style={{ opacity: 1 }}
                            />
                          )}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <img
                      src={profilepicture}
                      className="table-prof"
                      value={Hier}
                      style={{
                        cursor: hierarchyBindCount > 0 ? 'pointer' : 'default',
                      }}
                      onClick={() => {
                        if (hierarchyBindCount > 0) {
                          handleHierarchy(Hier);
                        }
                      }}
                    />
                    <p
                      className={`font-12 font-medium secondary-textcolor mb-0 ms-2`}
                      style={{
                        cursor: hierarchyBindCount > 0 ? 'pointer' : 'default',
                      }}
                      onClick={() => {
                        if (hierarchyBindCount > 0) {
                          handleHierarchy(Hier);
                        }
                      }}
                    >
                      {hierarchyBindCount}
                    </p>
                  </div>
                </td>
                {dynamicHeader.includes('Email ID') && (
                  <td className="font-12" style={{ cursor: 'default' }}>
                    {obj.primary_email === 'null' ? '-' : obj.primary_email}
                  </td>
                )}
                {dynamicHeader.includes('Department') && (
                  <td className="font-12" style={{ cursor: 'default' }}>
                    {obj.employee_domain === 'null' ? '-' : obj.employee_domain}
                  </td>
                )}
                {dynamicHeader.includes('Employee ID') && (
                  <td className="font-12" style={{ cursor: 'default' }}>
                    {obj.employee_id === 'null' ? '-' : obj.employee_id}
                  </td>
                )}
                <td>
                  <div className="d-flex">
                    <div className="d-flex align-items-center ms-4 ">
                      {bindNewNotifications(obj.user_id).count ? <img src="  /images/green-grid.png" className="table-grid-image" /> : <></>}
                      <span className="custom-tooltip fade">
                        {bindNewNotifications(obj.user_id).count ? (
                          <p className="font-12 font-medium green-textcolor mb-0 ms-2" onClick={() => handleNotificationClick(obj.user_id)}>
                            New ({bindNewNotifications(obj.user_id).count})
                          </p>
                        ) : (
                          <></>
                        )}
                        {currentUserID === obj.user_id && showToolTip && (
                          <span className="custom-tooltip-contentNew">
                            <span className="d-block">
                              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button type="button" value="new" className="btn-close text-end font-10" aria-label="Close" onClick={() => clearNotification(obj.user_id, 'new')} />
                              </div>
                              <ul className="list-unstyled text-start">{bindNewNotifications(obj.user_id).notifications}</ul>
                              {bindNewNotifications(obj.user_id).count > 5 && (
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                  <button
                                    type="button"
                                    className="border-0 bg-transparent font-medium secondary-textcolor font-10 viewall-btn"
                                    onClick={() => {
                                      navigate(`/activitylogs`, {
                                        state: {
                                          value: {
                                            id: obj.user_id,
                                            category: 'riskManagement',
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    View All
                                  </button>
                                </div>
                              )}
                            </span>
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="d-flex align-items-center ms-4">
                      {bindChangeNotification(obj.user_id).count ? <img src="  /images/orange-grid.png" className="table-grid-image" /> : <></>}
                      <span className="custom-tooltip fade">
                        {bindChangeNotification(obj.user_id).count ? (
                          <p className="font-12 font-medium orange-textcolor mb-0 ms-2" onClick={() => handleNotificationClick(obj.user_id)}>
                            Changes ({bindChangeNotification(obj.user_id).count})
                          </p>
                        ) : (
                          <></>
                        )}
                        {currentUserID === obj.user_id && (
                          <span className="custom-tooltip-contentChange">
                            <span className="d-block">
                              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button type="button" className="btn-close text-end font-10" aria-label="Close" onClick={() => clearNotification(obj.user_id, 'change')} />
                              </div>
                              <ul className="list-unstyled text-start">{bindChangeNotification(obj.user_id).notifications}</ul>
                              {bindChangeNotification(obj.user_id).count > 5 && (
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                  <button
                                    type="button"
                                    className="border-0 bg-transparent font-medium secondary-textcolor font-10 viewall-btn"
                                    onClick={() =>
                                      navigate(`/activitylogs`, {
                                        state: {
                                          value: {
                                            id: obj.user_id,
                                            category: 'riskManagement',
                                          },
                                        },
                                      })
                                    }
                                  >
                                    View All
                                  </button>
                                </div>
                              )}
                            </span>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
              {activeId === index && (
                <tr id={'row-' + index} className={'accordion-collapse table-parent-accordion collapse show'}>
                  <td colSpan="7">
                    <table className="table text-nowrap mb-0 table-borderless custom-inner-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th className="font-14">Application</th>
                          <th className="font-14">Last Login</th>
                          <th className="font-14">Location</th>
                          <th className="font-14">Device Type</th>
                          <th className="font-14">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userAccordian[obj.user_id] == undefined || userAccordian[obj.user_id].length == 0 ? (
                          <tr>
                            <td></td>
                            <td></td>
                            <td colSpan="2" className="accord-content1" style={{ textAlign: 'center' }}>
                              No Record Found
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        ) : (
                          userAccordian[obj.user_id].map((app, appIndex) => {
                            return (
                              <tr>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input custom-checkbox"
                                      type="checkbox"
                                      style={{ cursor: 'pointer' }}
                                      id={obj.user_id + '@' + appIndex}
                                      name={obj.user_id}
                                      checked={allSelectCheckbox.includes(app.resource_id + '@#' + obj.user_id)}
                                      onChange={(e) => {
                                        selectIndividualCheckbox(e, app, obj, index);
                                        const approveButtonCondition = obj.is_threat === 1 || obj.lms_status === 'overdue' || obj.is_active === false;
                                        setApproveButtonHideOrShow(approveButtonCondition);
                                      }}
                                    />
                                  </div>
                                </td>
                                <td className="font-12" style={{ cursor: 'default' }}>
                                  <img src={app.application_icon != '' ? '/images/my-app-active.svg' : '/images/Adobe_Photoshop.svg'} className="me-2" />
                                  {app.application_name}
                                </td>
                                <td className="font-12" style={{ cursor: 'default' }}>
                                  {formatDateTime(app.application_login_date)}
                                </td>
                                <td className="font-12">
                                  <div className="tooltip-container">
                                    {app.location ? (
                                      <img
                                        src={app.network == 'External' ? 'images/home.svg' : 'images/business-and-trade.svg'}
                                        className="me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                      />
                                    ) : (
                                      <span>-</span>
                                    )}
                                    {app.location}
                                    <div className="tooltip">{app.network || '-'}</div>
                                  </div>
                                </td>
                                <td className="font-12" style={{ cursor: 'default' }}>
                                  {app.is_managed == '1' ? 'Managed' : 'Unmanaged'}
                                </td>
                                <td className="font-12">
                                  <span
                                    className={
                                      app.approval_status == 'Application Assigned' || app.approval_status == 'Approved'
                                        ? 'green-dot me-2'
                                        : app.approval_status == 'Awaiting Confirmation'
                                        ? 'orange-dot me-2'
                                        : app.approval_status == 'Not Approved'
                                        ? 'red-dot me-2'
                                        : '-'
                                    }
                                  ></span>
                                  {app.approval_status == 'Not Approved' ? 'No Action' : app.approval_status}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </>
          );
        }
      });
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };

  let handleHierarchy = async (id) => {
    let countFlag = id.split('@')[0];
    if (countFlag != null && countFlag != undefined && countFlag != 0) {
      hierarchyId.push([id.split('@')[0], id.split('@')[1]]);
      searchValue = '';
      setReporteeId(hierarchyId.at(-1)[0]);
      setSearchValue(searchValue);
      setHierarchyId(hierarchyId);
      setisRevoke(true);
      setisApprove(true);
      gridData = [];
      setGridData(gridData);
      hireCount = [];
      setHireCount(hireCount);
      setNextPage(10);
      setManagerId(hierarchyId.at(-1)[0]);
    }
  };

  // MOVES BACK TO PREVIOUS USER IN BREADCRUMBS
  const handleHeaderHier = (e) => {
    for (var i = hierarchyId.length - 1; i >= 0; i--) {
      if (hierarchyId[i][0] == e.target.id) {
        break;
      } else {
        hierarchyId.pop();
      }
    }
    gridData = [];
    setGridData(gridData);
    hireCount = [];
    setHireCount(hireCount);
    setisRevoke(true);
    setisApprove(true);
    setManagerId(hierarchyId.at(-1)[0]);
    setReporteeId(hierarchyId.at(-1)[0]);
  };

  // BINDING EACH USERS FOR BREADCRUMBS
  const bindHierarchy = () => {
    if (hierarchyId.length != 1) {
      return hierarchyId.map((obj, index) => {
        if (index === 0) {
          return (
            <ol className="breadcrumb">
              <li key={index} className="breadcrumb-item custom-breadcrumb font-medium font-12 grey-secondary" style={{ cursor: 'pointer' }}>
                <a className="text-decoration-none grey-secondary" onClick={() => navigate(0)}>
                  Access Governance
                </a>
              </li>
              <li className="custom-breadcrumb-divider" />
            </ol>
          );
        } else if (index === hierarchyId.length - 1) {
          return (
            <ol className="breadcrumb">
              <li key={index} className="breadcrumb-item custom-breadcrumb active font-medium font-12 grey-secondary" style={{ cursor: 'pointer' }}>
                <a className="text-decoration-none grey-secondary" id={obj[0]} onClick={(e) => handleHeaderHier(e)}>
                  {obj[1]}
                </a>
              </li>
              <li className="custom-breadcrumb-divider" />
              <span className="custom-breadcrumb-pointer" />
            </ol>
          );
        } else {
          return (
            <ol className="breadcrumb">
              <li
                key={index}
                className={
                  index < hierarchyId.length
                    ? 'breadcrumb-item custom-breadcrumb font-medium font-12 grey-secondary'
                    : 'breadcrumb-item custom-breadcrumb active font-medium font-12 grey-secondary'
                }
                style={{ cursor: 'pointer' }}
              >
                <a className="text-decoration-none grey-secondary" id={obj[0]} onClick={(e) => handleHeaderHier(e)}>
                  {obj[1]}
                </a>
              </li>
              <li className="custom-breadcrumb-divider" />
              <span className="custom-breadcrumb-pointer" />
            </ol>
          );
        }
      });
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      setSearchValueConstant(searchValue);
      setuserAccordian('');
      setSelectedUsers(new Set());
      setIsAccordianClicked(false);
      setActiveId(null);
      await loadGrid();
    } catch (error) {
      console.log('ERROR: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (applicationName, application_Url) => {
    navigate('/accessGovernance/appGrid', {
      state: { value: { id: applicationName, appUrl: application_Url } },
    });
  };

  const bindApplicationTab = () => {
    if (gridData?.length > 0) {
      return gridData.map((obj, index) => {
        return (
          <div
            key={index}
            className="custom-card"
            onClick={() => {
              if (obj.total_users > 0) {
                handleClick(obj.application_name, obj.application_Url);
              }
            }}
            style={{
              width: '23%',
              marginBottom: '16px',
              marginRight: (index + 1) % 4 !== 0 ? "2%" : "0"
            }}
          >
            <div className="d-lg-flex justify-content-between pb-3 custom-card-top">
              <div className="font-16 font-medium" style={{ cursor: obj.total_users === 0 ? 'default' : 'pointer' }}>
                <img src={obj.application_Url ? '/images/my-app-active.svg' : obj.application_Url} className="me-2" />
                {obj.application_name}
              </div>
              <div className="font-12 font-medium d-flex align-items-center justify-content-end flex-wrap" style={{ cursor: obj.total_users === 0 ? 'default' : 'pointer' }}>
                <img src="/images/count.svg" alt="count" className="me-2" />
                <span style={{ opacity: obj.total_users === 0 ? 0.5 : 1 }}>{obj.total_users}</span>
              </div>
            </div>
            <div className="d-block d-lg-flex justify-content-between mt-1">
              <div className="font-12 my-2 mt-3 m-lg-0">
                <p className="mb-0 grey-secondary" style={{ cursor: obj.total_users === 0 ? 'default' : 'pointer' }}>
                  <span className="green-dot me-2" />
                  Approved
                  <span
                    className="font-medium font-14 primary-textcolor ms-2"
                    style={{
                      cursor: obj.total_users === 0 ? 'default' : 'pointer',
                      opacity: obj.total_users === 0 ? 0.5 : 1,
                    }}
                  >
                    {obj.approved_users}
                  </span>
                </p>
              </div>
              <div className="font-12">
                <p className="mb-0 grey-secondary" style={{ cursor: obj.total_users === 0 ? 'default' : 'pointer' }}>
                  <span className={noAction === true ? 'red-dot me-2' : 'orange-dot me-2'} />
                  {noAction === true ? 'No Action' : 'Awaiting Approval'}
                  <span
                    className="font-medium font-14 primary-textcolor ms-2"
                    style={{
                      cursor: obj.total_users === 0 ? 'default' : 'pointer',
                      opacity: obj.total_users === 0 ? 0.5 : 1,
                    }}
                  >
                    {obj.unapproved_users}
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      });
    } else if (gridData.length === 0) {
      return <NoRecordFoundForAG />;
    }
  };

  // APPROVE & REVOKE TOAST POPUP
  const bindToastPopup = () => {
    if (selectedUsers?.size > 0) {
      return (
        <>
          {showPopup == false ? (
            <div className="toast align-items-center show custom-approve-toast mx-auto text-decoration-none" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="d-flex">
                <div className="toast-body d-flex p-2">
                  <p className="font-16 font-medium border-end m-0 d-flex align-items-center pe-3">
                    <span className="font-32 font-bold secondary-textcolor mx-3">{selectedUsers.size}</span>
                    Users Selected
                  </p>
                  <button
                    type="button"
                    className={'border-0 p-0 bg-transparent mx-3 font-medium green-textcolor font-14 ' + (!approveButtonHideOrShow ? '' : 'opacity-50 cursor-not-allowed')}
                    onClick={() => setApprovePopup(false)}
                    disabled={approveButtonHideOrShow}
                  >
                    <img src="images/correct.svg" className="me-2" />
                    Approve Access
                  </button>
                  <button
                    type="button"
                    className="border-0 p-0 bg-transparent font-medium red-textcolor font-14"
                    onClick={() => {
                      setRevokePopup(false);
                    }}
                  >
                    <img src="images/remove.svg" className="me-2" />
                    Revoke Access
                  </button>
                </div>
                <button type="button" className="btn-close h-auto px-4 grey-bg" data-bs-dismiss="toast" aria-label="Close" onClick={handleToastClose} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      );
    }
  };

  const handleRevokeSubmit = async () => {
    if (!revokeReason.trim()) {
      setReasonError('Please select the required Reason category');
    }
    if (!revokeCategory) {
      setCategoryError('Please enter the required Reason ');
    } else {
      setReasonError('');
      setCategoryError('');
      revokeAccess();
    }
  };

  const handleRadioChange = (event) => {
    setRevokeCategory(event);
  };

  const handleTextArea = (value) => {
    setrevokeReason(value.target.value);
  };

  const revokeAccess = async () => {
    setIsLoading(true);
    try {
      setRevokePopup(true);

      let revokeObj = {
        userId: userId,
        employeeId: employeeId,
        reason: revokeReason,
        revokeCategory: revokeCategory,
        managerId: managerId,
        revokeData: revokeAccessData,
      };

      let confirmClick = await revokeApplicationAccess(revokeObj);
      if (confirmClick.statusCode === 400) {
        setRemovedAppLength(confirmClick.unRevoked[1]);
        setIsApproveOrRevoke('revoked');
        setAllSelectCheckbox([]);
        let checkboxArray = new Array(gridData?.length).fill(false);
        setCheckArray(checkboxArray);
        setApproveCheckArray([]);
        setrevokeReason('');
        setuserAccordian([]);
        setrevokeAccessData([]);
        setGridData([]);
        setGridData(gridData);
        setisRevoke(true);
      }

      if (confirmClick.statusCode == 200) {
        setIsApproveOrRevoke('revoked');
        setAllSelectCheckbox([]);
        let checkboxArray = new Array(gridData?.length).fill(false);
        setCheckArray(checkboxArray);
        setApproveCheckArray([]);
        setrevokeReason('');
        setuserAccordian([]);
        setrevokeAccessData([]);
        setGridData([]);
        setGridData(gridData);
        setisRevoke(true);
        setRevokePopup(true);
      }

      await loadGrid();
    } catch (error) {
      console.log('ERROR: ', error);
    } finally {
      setActiveId(null);
      setSelectedUsers(new Set());
      setIsLoading(false);
    }
  };

  const handleApprove = async () => {
    setIsLoading(true);
    setApprovePopup(true);
    try {
      let approvedata = {
        ApprovedData: revokeAccessData,
        managerid: managerId,
      };

      let confirmClick = await approveAccess(approvedata);
      if (confirmClick.statusCode == 200) {
        setIsLoading(false);
        setIsApproveOrRevoke('approved');
        setshowAccordian(false);
        setTableIndex(null);
        setAllSelectCheckbox([]);

        let checkboxArray = new Array(gridData?.length).fill(false);
        setCheckArray(checkboxArray);
        setApproveCheckArray([]);
        setisApprove(true);
        setuserAccordian([]);
        setrevokeAccessData([]);
        setisRevoke(true);
        setGridData([]);
        setGridData(gridData);
        setApprovePopup(true);
      }

      if (confirmClick.statusCode == 400) {
        setIsLoading(false);
        setIsApproveOrRevoke('approve');
        setAllSelectCheckbox([]);
        let checkboxArray = new Array(gridData?.length).fill(false);
        setCheckArray(checkboxArray);
        setApproveCheckArray([]);
        setisApprove(true);
        setRemovedAppLength(confirmClick.ResponseData);
        setrevokeAccessData([]);
        setisRevoke(true);
      }
    } catch (error) {
      console.log('ERROR: ', error);
    } finally {
      setActiveId(null);
      setSelectedUsers(new Set());
      setIsLoading(false);
    }
  };

  const hasAppAdmin = roles.includes('App Admin');
  const hasSuperAdminOrManager = roles.includes('Super Admin') || roles.includes('Manager');
  const myAppClassName = !hasSuperAdminOrManager && hasAppAdmin ? 'nav-item' : 'nav-item ms-4';

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={!isLoading ? 'mt-custom' : ''}>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" type="text/Css" href="css/agov.css" />
        <link rel="stylesheet" type="text/css" href="/bocssotstrap.css" />
        <title>Access Governance</title>
        <div className={!isLoading ? 'container-fluid mt-4' : 'container-fluid'}>
          <div className="col-md-12 px-5">
            {/* PERIODCAL ACCESS REVIEW RIBBON - STARTS */}
            {!riskTagHideOrShow ? (
              roles.includes('Super Admin') || roles.includes('Manager') ? (
                <div className="toast align-items-center show w-100 recurrance-toast h-auto" role="alert" aria-live="assertive" aria-atomic="true">
                  <div className="d-flex">
                    <div className="toast-body font-14">
                      <img src="/images/orange-info.svg" className="mx-2" />
                      <span className="font-14">{riskTagDetails}</span>
                    </div>
                    <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => hideRiskTag()} />
                  </div>
                </div>
              ) : (
                ''
              )
            ) : (
              <></>
            )}
            {/* PERIODICAL ACCESS REVIEW RIBBON - ENDS */}

            {/* BREADCRUMBS - STARTS */}
            <nav style={{ bsBreadcrumbDivider: '""' }} aria-label="breadcrumb" className={breadcrumbVisible ? `mb-3 mt-2` : 'd-none'}>
              <ol className="breadcrumb">{bindHierarchy()}</ol>
            </nav>
            {/* BREADCRUMBS - ENDS */}

            {/* TITLE, SEARCH, FILTER & EXPORT EXCEL - STARTS */}
            <div
              className={`d-flex justify-content-between align-items-center mt-4`}
            >
              <h1 className="font-bold font-24 primary-textcolor">Access Governance</h1>
              <ul className="nav">
                <li>
                  <div className="input-group flex-nowrap search-group ms-2">
                    <input
                      type="search"
                      onKeyDownCapture={(e) => {
                        if (e.key === 'Enter') handleSearch(e);
                      }}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className="form-control search-align"
                      placeholder="Search"
                      aria-label="Username"
                      maxLength={250}
                      aria-describedby="addon-wrapping"
                    />
                    <button className="input-group-text btn btn-search-custom" style={{ border: 'none' }} id="addon-wrapping" onClick={() => handleSearch()}>
                      <img src="/images/search-icon.svg" alt="search-icon" />
                    </button>
                  </div>
                </li>
                {(governanceTab === 'myteamtab' || governanceTab === 'myorgtab') && (
                  <li>
                    <button className="cust-filter-btn d-flex align-items-center me-2 ms-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />
                      Filter
                    </button>
                    <div className="dropdown-menu custom-filter border-0" style={{ zIndex: 100 }}>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                          <button type="button" className="btn-close font-12" aria-label="Close" />
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12 col-sm-12">
                            <div className="mb-3">
                              <label className="form-label text-black font-12 font-medium">Status</label>
                              <select
                                className="form-select cust-input-sty font-14 py-2 font-regular cursor-pointer"
                                aria-label="Default select example"
                                value={filterValue}
                                onChange={(e) => setFilterValue(e.target.value)}
                              >
                                <option value="">Select</option>
                                <option value="Approved">Approved</option>
                                {accessPeriod === 'AccessOn' ? (
                                  <option value="Awaiting Confirmation">Awaiting Approval</option>
                                ) : accessPeriod === 'AccessOff' ? (
                                  <option value="Not Approved">Not Action Taken</option>
                                ) : null}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="text-end mt-4 mb-2">
                          <button type="button" className="primary-btn font-14 font-medium me-2 py-2 me-3" onClick={handleFilterClear}>
                            Clear
                          </button>
                          <button type="button" className="dark-btn font-14 font-medium py-2" onClick={handleApplyFilter}>
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {currentTab !== 'myapptab' && (
                  <button
                    className="advanced-filter-button medium-text customflip-btn ms-2"
                    type="button"
                    onClick={() => {
                      !isDisabled && handleExportExcel();
                    }}
                  >
                    <img src="/images/excel-icon.svg" alt="Filter-icon" />
                  </button>
                )}
              </ul>
            </div>
            {/* TITLE,SEARCH, FILTER & EXPORT EXCEL - ENDS */}

            {/* TABS - STARTS */}
            <div className="d-flex justify-content-between align-items-center my-3 custom-tabs">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                {roles.includes('Manager') ? (
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        governanceTab === 'myteamtab' ? 'nav-link px-0 active font-14 tab-style bold-text rounded-0' : 'nav-link px-0 font-14 tab-style bold-text rounded-0'
                      }
                      id="Daily-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Daily"
                      type="button"
                      role="tab"
                      aria-selected={governanceTab === 'myteamtab' ? 'true' : 'false'}
                      tabIndex={governanceTab === 'myteamtab' ? '' : '-1'}
                      hidden={teamTab}
                      name="myteamtab"
                      onClick={(e) => handleAgTab(e.target.name)}
                    >
                      <span className="tab-img">
                        <img src="/images/my-team-active.svg" className="me-2" />
                        <img src="/images/my-team.svg" className="me-2" name="myteamtab" onClick={(e) => handleAgTab(e.target.name)} />
                      </span>
                      My Team
                    </button>
                  </li>
                ) : (
                  ''
                )}
                {roles.includes('Super Admin') || roles.includes('SOC Admin') ? (
                  <li className="nav-item ms-4" role="presentation">
                    <button
                      className={governanceTab == 'myorgtab' ? 'nav-link px-0 active font-14 tab-style bold-text rounded-0' : 'nav-link px-0 font-14 tab-style bold-text rounded-0'}
                      id="Weekly-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Weekly"
                      type="button"
                      role="tab"
                      aria-selected={governanceTab === 'myorgtab' ? 'true' : 'false'}
                      tabIndex={governanceTab === 'myorgtab' ? '' : '-1'}
                      hidden={orgTab}
                      name="myorgtab"
                      onClick={(e) => handleAgTab(e.target.name)}
                    >
                      <span className="tab-img">
                        <img src="/images/my-org-active.svg" className="me-2" />
                        <img src="/images/my-org.svg" className="me-2" name="myorgtab" onClick={(e) => handleAgTab(e.target.name)} />
                      </span>
                      My Organization
                    </button>
                  </li>
                ) : (
                  ''
                )}
                <li className={myAppClassName} role="presentation">
                  <button
                    className={governanceTab === 'myapptab' ? 'nav-link px-0 active font-14 tab-style bold-text rounded-0' : 'nav-link px-0 font-14 tab-style bold-text rounded-0'}
                    id="Monthly-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Monthly"
                    type="button"
                    role="tab"
                    aria-selected={governanceTab === 'myapptab' ? 'true' : 'false'}
                    tabIndex={governanceTab === 'myapptab' ? '' : '-1'}
                    hidden={appTab}
                    name="myapptab"
                    onClick={(e) => {
                      handleAgTab(e.target.name);
                      setActiveId(null);
                    }}
                  >
                    <span className="tab-img">
                      <img src="/images/my-app-active.svg" className="me-2" />
                      <img
                        src="/images/my-app.svg"
                        className="me-2"
                        name="myapptab"
                        onClick={(e) => {
                          handleAgTab(e.target.name);
                          setActiveId(null);
                        }}
                      />
                    </span>
                    My Application
                  </button>
                </li>
              </ul>
            </div>
            {/* TAB - ENDS */}
            <div className="row mb-5">
              <div className="tab-content" id="pills-tabContent">
                {/* MY TEAM GRID, TOAST, COUNT, LOAD MORE - STARTS */}
                <div className={governanceTab === 'myteamtab' ? 'tab-pane show active' : 'tab-pane fade'} id="Daily" role="tabpanel" aria-labelledby="Daily-tab">
                  <div className="table-responsive">
                    <table className="table text-nowrap mb-0 custom-table table-borderless">
                      <thead>{tableHeader(dynamicHeader)}</thead>
                      <tbody>
                        {gridData?.length === 0 ? (
                          <tr>
                            <td colSpan={dynamicHeader?.length} className="text-center">
                              <NoRecordFoundForAG />
                            </td>
                          </tr>
                        ) : (
                          bindAccessGovernanceUser()
                        )}
                      </tbody>
                    </table>
                  </div>
                  {bindToastPopup()}
                  <div>
                    {!isDisabled && (
                      <p className="font-12 grey-primary mt-4">
                        Showing
                        <span className="font-medium primary-textcolor"> {gridData?.length} </span>out of
                        <span className="font-medium primary-textcolor"> {gridCount} </span>items
                      </p>
                    )}
                  </div>
                  <div className="text-center">
                    {gridCount > 10 && gridCount !== gridData.length && (
                      <button onClick={() => setNextPage(nextPage + 10)} className="primary-btn font-medium">
                        Load More
                      </button>
                    )}
                  </div>
                </div>
                {/* MY TEAM GRID, TOAST, COUNT, LOAD MORE - ENDS */}
                {/* MY ORGANIZATION GRID, TOAST, COUNT, LOAD MORE - STARTS */}
                <div className={governanceTab == 'myorgtab' ? 'tab-pane fade  show active' : 'tab-pane fade '} id="Weekly" role="tabpanel" aria-labelledby="Weekly-tab">
                  <div className="table-responsive">
                    <table className="table text-nowrap mb-0 custom-table table-borderless">
                      <thead>{tableHeader(dynamicHeader)}</thead>
                      <tbody>
                        {gridData?.length === 0 ? (
                          <tr>
                            <td colSpan={dynamicHeader?.length} className="text-center">
                              <NoRecordFoundForAG />
                            </td>
                          </tr>
                        ) : (
                          bindAccessGovernanceUser()
                        )}
                      </tbody>
                    </table>
                  </div>
                  {bindToastPopup()}
                  <div>
                    {!isDisabled && (
                      <p className="font-12 grey-primary mt-4">
                        Showing
                        <span className="font-medium primary-textcolor"> {gridData?.length} </span>out of
                        <span className="font-medium primary-textcolor"> {gridCount} </span>items
                      </p>
                    )}
                  </div>
                  <div className="text-center">
                    {gridCount > 10 && gridCount !== gridData.length && (
                      <button onClick={() => setNextPage(nextPage + 10)} className="primary-btn font-medium">
                        Load More
                      </button>
                    )}
                  </div>
                </div>
                {/* MY ORGANIZATION GRID, TOAST, COUNT, LOAD MORE - ENDS */}
                {/* MY APPLICATION GRID, TOAST, COUNT, LOAD MORE - STARTS */}
                <div className={governanceTab == 'myapptab' ? 'tab-pane fade active show' : 'tab-pane fade'} id="Monthly" role="tabpanel" aria-labelledby="Monthly-tab">
                  <div className="col-md-12">
                    <div className="row" style={{ marginLeft: '0.10px', marginRight: '0.10px', display: "flex", flexWrap: "wrap", justifyContent: gridData.length % 4 === 0 ? 'space-between' : 'flex-start' }}>
                      {bindApplicationTab()}
                    </div>
                  </div>
                  <div>
                    {!isDisabled && (
                      <p className="font-12 grey-primary mt-2">
                        Showing
                        <span className="font-medium primary-textcolor"> {gridData?.length} </span>out of
                        <span className="font-medium primary-textcolor"> {gridCount} </span>items
                      </p>
                    )}
                  </div>
                  <div className="text-center">
                    {gridCount > 10 && gridCount !== gridData.length && (
                      <button onClick={() => setNextPage(nextPage + 10)} className="primary-btn font-medium" hidden={gridCount > gridData?.length ? false : true}>
                        Load More
                      </button>
                    )}
                  </div>
                </div>
                {/* MY APPLICATION GRID, TOAST, COUNT, LOAD MORE - STARTS */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* REVOKE POPUP - STARTS  */}
      {!revokePopup && (
        <div className="modal modal-lg fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" style={{ display: 'block' }} role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title font-bold mt-3" id="exampleModalLabel">
                  <img src="images/file-revoke.png" className="file-img me-3" />
                  Revoke Access
                </h5>
                <button type="button" className="btn-close ms-auto font-12 mb-3" onClick={() => setRevokePopup(true)} />
              </div>
              <div className="modal-body border-0 font-16 primary-textcolor">
                <p className="font-16 primary-textcolor">Are you sure you want to revoke access?</p>
                <p htmlFor="exampleFormControlInput1" className="form-label font-medium font-14 primary-textcolor">
                  Select Reason<span className="required-text">*</span>
                </p>

                <div className="btn-group" role="group" aria-label="Basic radio toggle button group" id="exampleFormControlInput1">
                  <button
                    className={`select-btn primary-textcolor font-13 me-3 ${revokeCategory == 'Suspious Behaviour' ? 'outline-btn-focus' : ''} `}
                    style={{
                      backgroundColor: revokeCategory == 'Suspious Behaviour' ? 'lightgray' : 'white',
                      outline: revokeCategory == 'Suspious Behaviour' ? '1px solid #fc5050' : 'none',
                    }}
                    htmlFor="btnradio1"
                    name="Suspious Behaviour"
                    onClick={(e) => {
                      handleRadioChange(e.target.name);
                    }}
                  >
                    Suspicious Behaviour
                  </button>
                  <button
                    className={`select-btn primary-textcolor font-13 me-3 ${revokeCategory == 'Inactive Usage' ? 'outline-btn-focus' : ''} `}
                    style={{
                      backgroundColor: revokeCategory == 'Inactive Usage' ? 'lightgray' : 'white',
                      outline: revokeCategory == 'Inactive Usage' ? '1px solid #fc5050' : 'none',
                    }}
                    htmlFor="btnradio2"
                    name="Inactive Usage"
                    onClick={(e) => {
                      handleRadioChange(e.target.name);
                    }}
                  >
                    Inactive Usage
                  </button>
                  <button
                    className={`select-btn primary-textcolor font-13 me-3 ${revokeCategory == 'No longer needed' ? 'outline-btn-focus' : ''} `}
                    style={{
                      backgroundColor: revokeCategory == 'No longer needed' ? 'lightgray' : 'white',
                      outline: revokeCategory == 'No longer needed' ? '1px solid #fc5050' : 'none',
                    }}
                    htmlFor="btnradio3"
                    name="No longer needed"
                    onClick={(e) => {
                      handleRadioChange(e.target.name);
                    }}
                  >
                    No Longer Needed
                  </button>
                  <button
                    className={`select-btn primary-textcolor font-13 me-3 ${revokeCategory == 'other Reasons' ? 'outline-btn-focus' : ''} `}
                    style={{
                      backgroundColor: revokeCategory == 'other Reasons' ? 'lightgray' : 'white',
                      outline: revokeCategory == 'other Reasons' ? '1px solid #fc5050' : 'none',
                    }}
                    htmlFor="btnradio4"
                    name="other Reasons"
                    onClick={(e) => {
                      handleRadioChange(e.target.name);
                    }}
                  >
                    Other Reasons
                  </button>
                </div>
                {categoryError && <div className="text-danger">{categoryError}</div>}
                <div className="mt-3">
                  <label className="form-label font-medium font-14 primary-textcolor">
                    Enter Reason<span className="required-text">*</span>
                  </label>
                  <textarea
                    className="form-control custom-text-area-ag"
                    rows={5}
                    placeholder="Enter your reason for revoke"
                    value={revokeReason}
                    onChange={(e) => {
                      handleTextArea(e);
                    }}
                  />
                </div>
                {reasonError && <div className="text-danger">{reasonError}</div>}
              </div>
              <div className="modal-footer border-0">
                <button type="button" className="primary-btn font-medium" data-bs-dismiss="modal" onClick={() => setRevokePopup(true)}>
                  Cancel
                </button>
                <button type="button" className="revoke-btn font-medium" disabled={allSelectCheckbox.length > 1 ? true : false} onClick={() => handleRevokeSubmit()}>
                  Revoke
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* REVOKE POPUP - ENDS */}
      {/* APPROVE POPUP - STARTS */}
      {!approvePopup ? (
        <div className="modal modal-lg fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" style={{ display: 'block' }} role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title font-bold mt-3" id="exampleModalLabel">
                  <img src="images/file-approve.png" className="file-img me-3" />
                  Approve Access
                </h5>
                <button type="button" className="btn-close ms-auto font-12 mb-3" onClick={() => setApprovePopup(true)} />
              </div>
              <div className="modal-body border-0 font-16 primary-textcolor">
                You have selected <span className="secondary-textcolor font-bold">{allSelectCheckbox.length} application</span>, Are you sure you want to approve access?
              </div>
              <div className="modal-footer border-0">
                <button type="button" className="primary-btn font-medium" data-bs-dismiss="modal" onClick={() => setApprovePopup(true)}>
                  Cancel
                </button>
                <button type="button" className="approve-btn font-medium" onClick={() => handleApprove()}>
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* APPROVE POPUP - ENDS */}
      {!revokePopup || !approvePopup ? <div class="modal-backdrop fade show"></div> : <></>}
    </>
  );
}

export default AccessGovernance;