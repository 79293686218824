import { useEffect } from "react"

// PC_COM_RES_38 to PC_COM_RES_40 
// PC_COM_RES_POP_36 to PC_COM_RES_POP_38
// ProgressBarComponent declaration
// and bind a completed and not completed percentage in a status bar
export function ProgressBarComponent({ data }) {
    let completedRatio = Math.floor((data.completedCount / data.totalCount) * 100).toString() + '%'
    let notCompletedRatio = Math.floor((data.notCompletedCount / data.totalCount) * 100).toString() + '%'

    return (
        <>
            <ul className="p-0 list-style-none mt-3">
                <li className="p-0 my-4">
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <img src={data?.profileUrl} className="table-prof" />
                            <p className="font-12 primary-textcolor mb-0 ms-2 maxWidth100">
                                {data?.Name}</p>
                        </div>
                        <div className="flex-grow-1 ms-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="progress compliance-progress" style={{ width: '90%' }}>
                                    <div className="progress-bar bg-completed" role="progressbar" style={{ width: completedRatio, "background-color": "green" }} aria-valuenow={15} aria-valuemin={0} aria-valuemax={100} data-bs-toggle="tooltip" data-bs-placement="bottom" title={data.completedCount} />
                                    <div className="progress-bar bg-inprogress" role="progressbar" style={{ width: notCompletedRatio, "background-color": "orange" }} aria-valuenow={30} aria-valuemin={0} aria-valuemax={100} data-bs-toggle="tooltip" data-bs-placement="bottom" title={data.notCompletedCount} />
                                </div>
                                <span className="font-12 primary-textcolor">{completedRatio}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

        </>
    )
}