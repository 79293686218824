import React, { useEffect, useState,useContext } from 'react'
import AssignPopupComponent from './AssignPopupComponent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RemovePopup from './RemovePopup';
import TaskForm from '../Task/TaskForm';
import { agovContext } from '../../Constant'

const ControlMappings = ({ controlInformation, mappedPrinciples, mappedPolicies, mappedEvidences, isAuditor, viewAsAuditor, render, setReRender }) => {

  const { employeeId, employeeName, roles, userId } = useContext(agovContext);
  const [identifier, setIdentifier] = useState('');
  const [unmapIdentifier, setUnmapIdentifier] = useState('');
  const [popupName, setPopupName] = useState('');
  const [policyEditClick, setPolicyEditClick] = useState(false);
  const [evidenceEditClick, setEvidenceEditClick] = useState(false);
  const [unmapId, setUnmapId] = useState('');
  const [unmapName, setUnmapName] = useState('');
  const [viewTaskId, setViewTaskId] = useState('');
  const [isViewTask, setIsViewTask] = useState('');
  const [isCreateTask, setIsCreateTask] = useState(false);
  const [isUpdateTask, setIsUpdateTask] = useState(false);
  const [isSuccessToastMessage, setIsSuccessToastMessage] = useState(false)
  const [ isAdmin , setIsAdmin]=useState(false)
  const Navigate = useNavigate();

  useEffect(() => {
    if (roles.includes("Auditor")) {
        setIsAdmin(true);
    } else {
        setIsAdmin(false);
    }
   
}, []);
  /**
   *  PC_CC_173 - PC_CC_177
   */
  // Binding the policies
  const PolicyMapping = () => {

    let totalPolicies = mappedPolicies.length;
    let acceptedPolicies = 0;

    mappedPolicies.forEach((policy) => {
      if (policy.policyStatus.toLowerCase() === 'accepted') {
        acceptedPolicies += 1;
      }
    });

    let dotClassName = '';
    if (acceptedPolicies === 0) {
      dotClassName = 'grey-dot';
    } else if (totalPolicies === acceptedPolicies) {
      dotClassName = 'green-dot';
    } else {
      dotClassName = 'orange-dot';
    }

    return (
      <div className="col-md-12">
        <div className="d-flex align-items-center justify-content-between">
          <span className="d-flex align-items-center">
            <img src="/images/policy-doc-icon.svg" alt="policy-doc-icon" />
            <label className="font-14 font-medium primary-textcolor ms-2">
              Policy Document
            </label>
            <span className={`${dotClassName} ms-4`} />
            <span className="font-10 font-regular grey-light ms-2">
              {acceptedPolicies}/{totalPolicies} Completed
            </span>
          </span>
          <div hidden={isAuditor}>
            <button disabled={viewAsAuditor} type="button" className="custom-gray-btn me-3" data-bs-toggle="modal" data-bs-target="#add-policy" onClick={() => { setIdentifier('policy'); setPopupName('Policies') }}>
              <img src="/images/black-add-icon.svg" alt="black-add-icon" />
            </button>
            {policyEditClick == false ?
              <button disabled={viewAsAuditor} type="button" className="custom-blue-outlined-btn" onClick={() => { setPolicyEditClick(true) }}>
                <img src="/images/edit-btn-icon.svg" alt="edit-btn-icon" />
              </button> :

              <button disabled={viewAsAuditor} type="button" classname="custom-gray-btn custom-darkgray-btn" onClick={() => { setPolicyEditClick(false) }} >
                <img src="/images/remove-btn-close-icon.svg" alt="close-icon" />
              </button>
            }
          </div>
        </div>


        <div className="table-responsive cust-complience-grid my-3 py-3">
          <table className="table table-borderless mb-0">
            <tbody>
              {mappedPolicies.map((policy) => (
                <tr>
                  <td className="font-medium font-13" style={{width: '40%', paddingRight: '1%'}}>
                    <a className="text-decoration-none pointer" onClick={() => { Navigate("/compliance/policyDetails", { state: { policyId: policy.policyId } }) }}>
                      {policy.policyName}
                    </a>
                  </td>


                  <td className="font-regular font-12 grey-light">
                    Task ID:
                    {policy.policyTaskId ?
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (isAuditor) {
                            setIsViewTask(true);
                            setViewTaskId(policy.policyTaskId)
                          }
                          else {
                            Navigate('/taskmanagement', {
                              state: {
                                viewTaskId: policy.policyTaskId
                              }
                            })
                          }
                        }}
                        className="text-decoration-none font-bold"
                        id="offcanvasFilledBtn"
                      >
                        {" "}{policy.policyTaskId}
                      </a>
                      : ' Nil'}
                  </td>
                  <td className="font-regular font-12 grey-light">
                    Due Date:
                    <span className="font-medium primary-textcolor">
                      {" "}{policy.policyEndDate === null ? 'Nil' : new Date(policy.policyEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}
                    </span>
                  </td>
                  <td className="font-regular font-12">
                    <div className="d-flex align-items-center">
                      {policy.profileUrl[0] != '?' ? (
                        <>
                          <img
                            src={policy.profileUrl}
                            alt="Profile-Icon"
                            className="compliance-grid-logo"
                          />
                          <p className="primary-textcolor mb-0 ms-2">{policy.assignedTo}</p>
                        </>
                      ) : (
                        <p className="primary-textcolor mb-0 ms-2">Unassigned</p>
                      )}
                    </div>
                  </td>
                  <td className="font-regular pointer">
                    {policyEditClick == true ? <span>
                      <img src="/images/grid-unknown-icon.svg" alt="grid-unknown-icon" data-bs-toggle="modal" data-bs-target="#unmap-control" onClick={() => { setUnmapIdentifier('controlPolicy'); setUnmapId(policy.policyId); setUnmapName(policy.policyName); }} style={{ cursor: 'pointer' }} />
                    </span> :
                      <span>
                        {policy.policyStatus.toLowerCase() === 'accepted' ? (
                          <img src="/images/table-tick-icon.svg" alt="table-tick-icon" />
                        ) : (
                          <span className="unassigned-yellow-batch"></span>
                        )}
                      </span>
                    }
                  </td>
                </tr>))}
            </tbody>
          </table>
        </div>
      </div>

    )
  }

  /**
   *  PC_CC_173 - PC_CC_177
   */
  // Binding the evidences 
  const EvidenceMapping = () => {
    let totalEvidences = mappedEvidences.length;
    let acceptedEvidences = 0;


    mappedEvidences.forEach((evidence) => {
      if (evidence.evidenceStatus === 'accepted') {
        acceptedEvidences += 1;
      }
    });

    let dotClassName = '';
    if (acceptedEvidences === 0) {
      dotClassName = 'grey-dot';
    } else if (totalEvidences === acceptedEvidences) {
      dotClassName = 'green-dot';
    } else {
      dotClassName = 'orange-dot';
    }


    return (<div className="col-md-12 mt-3">
      <div className="d-flex align-items-center justify-content-between">
        <span className="d-flex align-items-center">
          <img src="/images/policy-doc-icon.svg" alt="policy-doc-icon" />
          <label className="font-14 font-medium primary-textcolor ms-2">
            Evidence
          </label>
          <span className={`${dotClassName} ms-4`} />
          <span className="font-10 font-regular grey-light ms-2">
            {acceptedEvidences}/{totalEvidences} Completed
          </span>
        </span>
        <div hidden={isAuditor}>
          <button disabled={viewAsAuditor} type="button" className="custom-gray-btn me-3" data-bs-toggle="modal" data-bs-target="#add-policy" onClick={() => { setIdentifier('evidence'); setPopupName('Evidences') }}>
            <img src="/images/black-add-icon.svg" alt="black-add-icon" />
          </button>
          {evidenceEditClick == false ?
            <button disabled={viewAsAuditor} type="button" className="custom-blue-outlined-btn" onClick={() => { setEvidenceEditClick(true) }}>
              <img src="/images/edit-btn-icon.svg" alt="edit-btn-icon" />
            </button> :

            <button disabled={viewAsAuditor} type="button" classname="custom-gray-btn custom-darkgray-btn" onClick={() => { setEvidenceEditClick(false) }} >
              <img src="/images/remove-btn-close-icon.svg" alt="close-icon" />
            </button>
          }
        </div>
      </div>
      <div className="table-responsive cust-complience-grid my-3 py-3">
        <table className="table table-borderless mb-0">
          <tbody>
            {mappedEvidences.map((evidence) => (
              <tr>
                <td className="font-medium font-13" style={{width: '40%', paddingRight: '1%'}}>
                  <a className="text-decoration-none pointer" onClick={() => { Navigate("/compliance/evidenceDetails", { state: { evidenceId: evidence.evidenceId } }) }}>
                    {evidence.evidenceName}
                  </a>
                </td>

                <td className="font-regular font-12 grey-light ">
                  Task ID:
                  {evidence.evidenceTaskId ?
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (isAuditor) {
                          setIsViewTask(true);
                          setViewTaskId(evidence.evidenceTaskId)
                        }
                        else {
                          Navigate('/taskmanagement', {
                            state: {
                              viewTaskId: evidence.evidenceTaskId
                            }
                          })
                        }
                      }}
                      className="text-decoration-none font-bold"
                      id="offcanvasFilledBtn"
                    >
                      {" "}{evidence.evidenceTaskId}
                    </a>
                    : ' Nil'}
                </td>
                <td className="font-regular font-12 grey-light ">
                  Due Date:
                  <span className="font-medium primary-textcolor">
                    {" "}{evidence.evidenceEndDate === null ? 'Nil' : new Date(evidence.evidenceEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}
                  </span>
                </td>
                <td className="font-regular font-12">
                  <div className="d-flex align-items-center">
                    {evidence.profileUrl[0] != '?' ? (
                      <>
                        <img
                          src={evidence.profileUrl}
                          alt="Profile-Icon"
                          className="compliance-grid-logo"
                        />
                        <p className="primary-textcolor mb-0 ms-2">{evidence.assignedTo}</p>
                      </>
                    ) : (
                      <p className="primary-textcolor mb-0 ms-2">Unassigned</p>
                    )}
                  </div>
                </td>
                <td className="font-regular pointer">
                  {evidenceEditClick == true ?
                    <span>
                      <img src="/images/grid-unknown-icon.svg" alt="grid-unknown-icon" data-bs-toggle="modal" data-bs-target="#unmap-control" onClick={() => { setUnmapIdentifier('controlEvidence'); setUnmapId(evidence.evidenceId); setUnmapName(evidence.evidenceName) }} />
                    </span>
                    :
                    <span>
                      {evidence.evidenceStatus.toLowerCase() === 'accepted' ? (
                        <img src="/images/table-tick-icon.svg" alt="table-tick-icon" />
                      ) : (
                        <span className="unassigned-yellow-batch"></span>
                      )}
                    </span>
                  }
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>)
  }


  /**
   *  PC_CC_173 - PC_CC_177
   */
  //Binding the principles
  const PrincipleMapping = () => {
    return (
      <div class="col-md-12 mt-3">
        <div
          class="d-flex align-items-center justify-content-between"
        >
          <span class="d-flex align-items-center">
            <img
              src="/images/policy-doc-icon.svg"
              alt="policy-doc-icon"
            />
            <label
              class="font-14 font-medium primary-textcolor ms-2"
            >Mapped Controls</label>
          </span>
          {console.log(isAdmin, "*************************")}
          {!isAuditor &&
          <div>
            <button
              type="button" disabled={isAdmin}
              class="custom-gray-btn"
              data-bs-toggle="modal" data-bs-target="#add-policy"
              onClick={() => { setIdentifier('framework_mapping'); setPopupName('Controls') }}
            >
              <img
                src="/images/black-add-icon.svg"
                alt="black-add-icon"
                hidden={isAuditor}
              />
            </button>
          </div>}
        </div>

        <div
          class="form-control font-regular font-14 custom-form-control mt-3 py-2"
        >
          {mappedPrinciples.map((principle) => (
            <span
              class="d-inline-block align-items-center blue-batch-bg brd-radius-3 px-2 py-0 gap"
            >
              <span class="font-12 font-regular"
              >{principle.principleId}</span>
              <span >
                <img
                  src="/images/violet-close-icon.svg"
                  alt="violet-close-icon"
                  class="ms-2 cursor-pointer"
                  hidden={isAuditor || viewAsAuditor}
                  data-bs-toggle="modal" data-bs-target="#unmap-control"
                  onClick={() => { setUnmapIdentifier('controlPrinciple'); setUnmapId(principle.principleId); setUnmapName(principle.principleId) }}
                /></span>
              <span>{"   "}</span>
            </span>
          ))}
        </div>
        {mappedPrinciples.length == 1 ?
          <p
            class="font-13 font-regular   mt-2 mb-2"
          >
            Note: At least one control should be mapped to
            this.
          </p> : ''}
      </div>
    )
  }

  return (
    <div
      className="tab-pane fade show active"
      id="MappedElements"
      role="tabpanel"
      aria-labelledby="MappedElements-tab"
    >
      <div className="row">
        {PolicyMapping()}
        {EvidenceMapping()}
        {PrincipleMapping()}
        <AssignPopupComponent identifier={identifier} popupName={popupName} mappingInformation={controlInformation} render={render} setReRender={setReRender} mappingFrom={"controlMapping"} />
        <RemovePopup identifier={unmapIdentifier} controlInformation={controlInformation} mappingId={unmapId} mappingName={unmapName} render={render} setReRender={setReRender} />
      </div>
      {/* PC_CC_424 - PC_CC_430
      The onClick of the task id, it will direct the user to task portal or will be shw=own in the side screen
      */}
      {isViewTask ? <TaskForm props={
        {
          taskId: viewTaskId,
          isViewTask: isViewTask,
          setIsViewTask: setIsViewTask,
          setIsCreateTask: setIsCreateTask,
          setIsUpdateTask: setIsUpdateTask,
          setIsSuccessToastMessage: setIsSuccessToastMessage
        }} /> : <></>
      }
      {isViewTask ? <div class="modal-backdrop fade show"></div> : <></>}
    </div>

  )
}

export default ControlMappings