import React, { useEffect, useState, useContext } from 'react'
import { unmappingGRC } from '../../Services/GRC/GrcService';
import { agovContext } from '../../Constant'
import { encryptStorage } from '../../Constant';
import { Loader } from '../Loader/Loader';

const RemovePopup = ({ controlInformation, mappingId, mappingName, identifier, reRender, setReRender }) => {


  const [reason, setReason] = useState('');
  const { employeeId, employeeName, roles, userId } = useContext(agovContext);
  const [isLoading, setIsLoading] = useState(false);


  /**
   * PC_CC_264 - PC_CC_276
   * This function unmaps control or policy or control and hides the popup
   */
  const unMapFunction = async () => {
    setIsLoading(true);
    try {
      await unmappingGRC({
        "identifier": identifier,
        "mappingId": mappingId,
        "mappingName": mappingName,
        "startDate": encryptStorage.getItem('commencer'),
        "endDate": encryptStorage.getItem('fin'),
        "selectedId": controlInformation?.controlDetails?.controlId,
        "selectedName": controlInformation?.controlDetails?.controlName,
        "controlIdentity": controlInformation?.controlDetails?.controlIdentity,
        "reason": reason,
        "userId": userId
      });
      setReRender(new Date());
    }
    catch (error) {
      console.log(error, "error");
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="modal fade" id="unmap-control" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
          <div className="modal-content">
            {/*modal content starts here*/}
            <div className="modal-header border-0 px-4">
              <h5 className="modal-title font-bold font-20" id="exampleModalLabel">Unmap {mappingName} from  the Control {controlInformation?.controlDetails?.controlName}</h5>
              <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body border-0 font-16 px-4">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="custom-bg px-3 py-3">
                    <div className="d-flex">
                      <div className="d-block me-2">
                        <img src="/images/alter-icon.svg" alt="alert=icon" />
                      </div>
                      <div className="d-block">
                        <label className="font-medium font-14 w-100 primary-textcolor mb-2">This action can affect your compliance posture.</label>
                        <label className="font-regular font-14 w-100 dark-grey">Please ensure that the framework requirement continues to have sufficient control coverage.</label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* PC_CC_261 - PC_CC_263
                onChange function changes the state of the reason 
                */}
                <div className="col-md-12 mb-3">
                  <label htmlFor="reason" className="form-label font-medium font-14 primary-textcolor w-100">Reason for the change</label>
                  <textarea type="text" value={reason} onChange={(e) => { setReason(e.target.value) }} className="form-control font-regular font-14 custom-form-control py-2 resize-none" rows={4} id="reason" placeholder="Enter your reason" defaultValue={""} />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 mb-3 px-4">
              {/*PC_CC_277 - PC_CC_279
              The cancel button hides the remove popup
                */}
              <button type="button" className="primary-btn font-14 font-medium py-2 me-2" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="dark-btn font-14 font-medium py-2" data-bs-dismiss="modal" async onClick={() => {
                unMapFunction()
              }}>Remove</button>
            </div>
            {/*modal content ends here*/}
          </div>
        </div>
      </div>
    </>
  )
}

export default RemovePopup