import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRiskCount } from '../../../../Services/Agov/governanceService';

// VIY_FE_PC_11 - VIY_FE_PC_22 -> TO FETCH THE COUNT OF RISK ACTIVITIES PERFORMED BY THE USER
const WelcomeAndVerification = ({ userName, userId }) => {
  const RISK_CATEGORY_ID = '3';
  const [riskTaskCount, setRiskTaskCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRiskTaskCount();
  }, [userId]);

  const formatValue = value => {
    if (value === 0) {
      return '0';
    } else if (value > 0 && value < 10) {
      return `0${value}`;
    } else {
      return value;
    }
  };

  const fetchRiskTaskCount = async () => {
    const response = await getRiskCount({ userId });
    if (response?.statusCode === 200) {
      const riskCount = response.result.riskTaskCount;
      setRiskTaskCount(formatValue(riskCount));
    }
  };

  // ON CLICKING THE COUNT, PAGE WILL BE NAVIGATED TO TASK PAGE DISPLAYING THE CLICKED TASK IN THE GRID (TaskGrid)
  const handleTaskNavigation = () => {
    navigate('/taskManagement', { state: { categoryId: RISK_CATEGORY_ID } });
  };

  return (
    <div className="bg-white px-1">
      <div className="custom-container px-5">
        <div className="col-md-12 col-sm-12 col-lg-12 py-4">
          <div className="d-flex justify-content-between">
            <p className="font-24 font-regular grey-primary p-0 m-0">
              Welcome Back,
              <span className="font-bold primary-textcolor"> {userName} </span>
              <img src="images/Waving Hand Emoji.svg" alt="Waving Hand Icon" />
            </p>
            <div className="d-flex align-items-center justify-content-center gap-2">
              <div className="d-flex align-items-center">
                <img src="images/warning.svg" alt="Warning Icon" className="me-2" />
                <a
                  onClick={riskTaskCount > 0 ? handleTaskNavigation : undefined}
                  className={`font-13 font-medium text-decoration-none`}
                  style={{
                    cursor: riskTaskCount === '0' ? 'not-allowed' : 'pointer',
                    filter: riskTaskCount === '0' ? 'grayscale(80%)' : 'none',
                    opacity: riskTaskCount === '0' ? 0.5 : 1,
                  }}
                  aria-disabled={riskTaskCount === 0 ? 'true' : 'false'}
                >
                  Verify It's You ({riskTaskCount})
                </a>
              </div>
              <div className="tooltip-container d-flex align-items-center" style={{ cursor: 'pointer' }}>
                <img src="images/info-icon-unfilled.svg" alt="Info Icon" />
                <div className="tooltip2">Verify your identity for secure access</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeAndVerification;
