//PC_SC_1 to PC_SC_4
import React, { useState, useEffect } from "react";
import {
  countDetails,
  deleteConnectorData,
  fetchUserDetails,
  getConnectorData,
  insertConnectorData,
  updateConnectorData,
} from "../../Services/Admin/AdminService";
import { ConnectorPopUp } from "./SecurityConnectorPopUp";
import { Loader } from "../Loader/Loader";
import axios from "axios";
import { encryptStorage } from "../../Constant";

//PC_SC_5 to PC_SC_9
const SecurityConnector = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [nextButton, setNextButton] = useState(true);
  let azureConnectorObj = {
    orgId: null,
    connectorTypeId: null,
    connectorId: null,
    tenantId: "",
    clientId: "",
    clientSecret: "",
    microsoftDomain: "",
    cloudAppstoken: "",
    connectorTypeId: 1,
  };
  //PC_SC_35 to PC_SC_36
  const [formData, setFormData] = useState(azureConnectorObj);
  const [isLoading, setIsLoading] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [connectType, setConnectType] = useState("Connect");
  const [percentage, setPercentage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showProgress, setShowProgress] = useState(true);
  const [validate, setValidate] = useState(true);
  const [infovalidate, setInfoValidate] = useState(true);
  const [isTenantIdDisabled, setIsTenantIdDisabled] = useState(true);
  let interval;

  useEffect(() => {
    fetchConnector();
  }, []);

  const increasePercentage = () => {
    if (Number(percentage) < 98) {
      setPercentage((prevPercentage) => Number(prevPercentage) + 1);
      console.log(percentage, "percentage");
    } else {
      clearInterval(interval);
    }
  };

  //PC_SC_10 to PC_SC_21
  const fetchConnector = async () => {
    try {
      setIsLoading(true);
      let result = await getConnectorData();
      if(result.responseData == "newLogin"){
        setNextButton(false)
        setConnectType("Connect")
        setIsTenantIdDisabled(false)
      }
      if (result.statusCode === 200 && result.responseData != "newLogin") {
        let azureConnectorObj = {
          orgId: result.responseData.organization_id,
          connectorTypeId: result.responseData.connector_type_id,
          connectorId: result.responseData.connector_id,
          tenantId: result.responseData.tenant_id,
          clientId: result.responseData.client_id,
          clientSecret: result.responseData.client_secret,
          microsoftDomain: result.responseData.microsoft_domain,
          cloudAppstoken: result.responseData.cloudapps_token,
          connectorTypeId: result.responseData.connector_type_id,
        };
        console.log(result.expiryDate, "value in connector");
        setIsTenantIdDisabled(true)
        setFormData(azureConnectorObj);
        setExpiryDate(result.expiryDate);
        setConnectType("Disconnect");
      } else {
        console.log("Something went wrong!");
      }
    } catch (error) {
      console.log("Error updating connector information:", error);
    }
    setIsLoading(false);
  };

  //PC_SC_22 to PC_SC_33
  const updateConnector = async (formData) => {
    try {
      setIsLoading(true);
      console.log("updateConnectorfunction invoke", formData);

      let connectorObj = formData;
      if (nextButton == true) {
        const result = await updateConnectorData(connectorObj);
        if (result.statusCode === 200) {
          console.log("Connector Information Updated  successfully");
          setIsLoading(false);
          setShowPopUp(false);
          setShowProgress(false);
          interval = setInterval(increasePercentage, 2000);

          let fetchUser = {
            orgId: connectorObj.orgId,
            connectorTypeId: connectorObj.connectorTypeId,
            connectorId: connectorObj.connectorId,
            tenantId: connectorObj.tenantId,
            clientId: connectorObj.clientId,
            clientSecret: connectorObj.clientSecret,
            microsoftDomain: connectorObj.microsoftDomain,
            cloudAppstoken: connectorObj.cloudAppstoken,
            connectorTypeId: 1,
            emailValue: encryptStorage.getItem("emailId"),
          };

          let userResponse = await fetchUserDetails(fetchUser);
          let countResponse = await countDetails(fetchUser);

          clearInterval(interval);
          let newPercentage = 0;
          if (
            userResponse.statusCode === 200 &&
            countResponse.statusCode === 200
          ) {
            newPercentage = 100;
            setShowProgress(true)
           
            setIsLoading(false); // Set to 100% when both responses are successful
          } else if (
            userResponse.statusCode === 200 ||
            countResponse.statusCode === 200
          ) {
            newPercentage = 50; 
            // Set to 50% when one response is successful
          }
          setPercentage(newPercentage); // Update the percentage state with the new value
        } else if ((result.responseData = "Permission denied")) {
          setValidate(false);
          setIsLoading(false);
        } else if (result.statusCode === 400) {
          setInfoValidate(false);
          setIsLoading(false);
        }
      } else {
        const result = await insertConnectorData(connectorObj);
        if (result.statusCode === 200) {
          console.log("Connector Information insert successfully");
          setIsLoading(false);
      
          setShowProgress(false);
          interval = setInterval(increasePercentage, 2000);

          let fetchUser = {
            orgId: connectorObj.orgId,
            connectorTypeId: connectorObj.connectorTypeId,
            connectorId: connectorObj.connectorId,
            tenantId: connectorObj.tenantId,
            clientId: connectorObj.clientId,
            clientSecret: connectorObj.clientSecret,
            microsoftDomain: connectorObj.microsoftDomain,
            cloudAppstoken: connectorObj.cloudAppstoken,
            connectorTypeId: 1,
            emailValue: encryptStorage.getItem("emailId"),
          };

          let userResponse = await fetchUserDetails(fetchUser);
          let countResponse = await countDetails(fetchUser);

          clearInterval(interval);

          let newPercentage = 0;
          if (
            userResponse.statusCode === 200 &&
            countResponse.statusCode === 200
          ) {
            newPercentage = 100;
            
            setShowProgress(true)
              
            setIsLoading(false);
          } else if (
            userResponse.statusCode === 200 ||
            countResponse.statusCode === 200
          ) {
            newPercentage = 50; 
                 
          }
          setPercentage(newPercentage);
          fetchConnector()
         // Update the percentage state with the new value
        } else if ((result.responseData = "Permission denied")) {
          setValidate(false);
          setIsLoading(false);
        } else if (result.statusCode === 400) {
          setInfoValidate(false);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("Error updating or Inserting connector information:", error);
    }
    setIsLoading(false);
  };

  const showConnectorPopUp = () => {
    setShowPopUp(true);
    fetchConnector();
  };

  const Disconnect = async()=>{
    try {
      setIsLoading(true);
      let result = await deleteConnectorData();
      if(result.statusCode == 200){
        setConnectType("Connect");
        setIsTenantIdDisabled(false)
      }
      fetchConnector()
  }catch(error){
    console.log("Connectors Disconnect Issue",error);
  }
  setIsLoading(false);
 
}
  return (
    <>
      {/* connector starts here */}

      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-between mt-4 pt-2 align-items-center">
            <div className="col-md-8">
              <h2 className="font-bold font-20 primary-textcolor">
                Connectors
              </h2>
              <p className="font-14 grey-light mt-3">
                Maximize connectivity with a variety of application connectors,
                including Microsoft cloud apps and Azure, on AGov. Stay tuned
                for more connectors in future phases
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-7 col-xl-5 security-azure-connect d-flex justify-content-center flex-column">
            <div className="d-flex align-items-center mt-3">
              <div className="me-auto">
                <img
                  src="/images/Azure logo.png"
                  className="rounded azure-img float-start"
                  alt="Azure Logo"
                />
              </div>
              <div>
                <button
                  className="cust-filter-btn d-flex align-items-center py-2"
                  type="button"
                  aria-expanded="false"
                  onClick={() => {
                    if(connectType== "Disconnect"){
                      Disconnect();
                    }else{
                      showConnectorPopUp()
                    }
                    
                  }}
                >
                  <img
                    src="/images/plugin-icon.svg"
                    alt="filter-icon"
                    className="filt-icon me-2"
                  />{" "}
                  {connectType == "Disconnect" ? "Disconnect" : "Connect"}
                </button>
              </div>
              <div>
                <button
                  className={nextButton == true ?"advanced-filter-button medium-text customflip-btn ms-2 py-2":"advanced-filter-button medium-text customflip-btn ms-2 py-2 fade"}
                  type="button"
                  aria-expanded="false"
                  
                  onClick={() => {
                    showConnectorPopUp();
                    
                  }}
                > 
                  <img src="/images/edit-text.svg" alt="Filter-icon"/>
                </button>
              </div>
            </div>
            {nextButton == true ? <p className="text-end font-10 font-regular mt-2 mb-0 red-textcolor">
             Valid till {expiryDate}
            </p>:null}
          </div>

          {/* PC_SC_37 to PC_SC_38 */}
          {showPopUp && (
            <ConnectorPopUp
              disableTenantiD={ isTenantIdDisabled}
              setShowPopUp={setShowPopUp}
              insertConnector={updateConnector}
              formData={formData}
              setFormData={setFormData}
            ></ConnectorPopUp>
          )}
          {!validate ? (
            <div>
              <div className="container">
                <p>
                  <p
                    style={{
                      color: "red",
                      padding: "0 480px",
                      textalign: "left",
                    }}
                  >
                    Please enter the following permissions in AD *
                    "DeviceManagementManagedDevices.Read.All",
                    "User.ReadWrite.All", "Directory.ReadWrite.All",
                    "Directory.Read.All", "User.Read.All",
                    "DeviceManagementConfiguration.Read.All",
                    "IdentityProvider.Read.All", "IdentityRiskyUser.Read.All",
                    "IdentityRiskEvent.Read.All", "AuditLog.Read.All",
                    "ThreatHunting.Read.All"
                  </p>
                </p>
              </div>
            </div>
          ) : null}

          {!infovalidate ? (
            <div>
              <p
                style={{
                  color: "red",
                  padding: "0 480px",
                  textalign: "left",
                }}
              >
                "Given Connector Information is Invaild"
              </p>
            </div>
          ) : null}

          {showProgress != true && (
            <div className="col-md-12 my-2">
              <div
                className="modal fade show"
                id="staticBackdrop10"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                style={{
                  display: "block",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header d-flex justify-content-between align-items-center p-4 border-0">
                      <h5
                        className="modal-title font-14 font-medium primary-textcolor"
                        id="staticBackdropLabel"
                      >
                        Please wait, while we are fetching the data
                      </h5>
                    </div>
                    <div className="modal-body px-4 pb-5 pt-0">
                      <div className="progress payment-pg">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${percentage}%` }}
                          aria-valuenow={percentage}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        >
                          <span
                            style={{
                              color: "#000",
                              position: "absolute",
                              right: "15px",
                              textalign: "right",
                              top: "12px",
                              fontsize: "12px",
                              fontweight: "500",
                            }}
                          >
                            {percentage}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Loader isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};
export { SecurityConnector };
