import React, { useState, useEffect } from 'react';
import { useNavigate,} from 'react-router-dom';
import { agovContext, encryptStorage } from '../../Constant';
import { getBillingDetails } from '../../Services/Admin/AdminService';
import { Loader } from "../Loader/Loader";

export function Billing() {
  const navigate = useNavigate();
  // const { userId, employeeName, employeeEmail } = useContext(agovContext);
  const [billingDetails, setBillingDetails]= useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
     fetchBillingDetails()
  },[]);

  const employeeEmail = encryptStorage.getItem('emailId');
   
  //PS_BD_FE_1.10 to 1.24 Function to fetch the billing details 
  const fetchBillingDetails =async () => {
    setIsLoading(true)
    try{
      const response = await getBillingDetails({
        email: employeeEmail
      });
  
     if(response.statusCode){
       setBillingDetails(response.response[0]);
       console.log(billingDetails)
     }
     else {
      navigate('/adminmanagement/billing')
     }

    }catch{

    }
    finally{
      setIsLoading(false)
    }
   
  }

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString || dateTimeString == undefined) return "-"; // Return an empty string if dateTimeString is null or undefined

    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour =
        hour % 12 === 0 ? "12" : (hour % 12).toString().padStart(2, "0");

    return (
        <span>
           {month}/{day}/{year} {formattedHour}:{minute} {ampm}
        </span>
    );
};

  //PS_BD_FE_1.25 to 1.27 function to navigate to payment 
  

  return (
    <>
    <Loader isLoading={isLoading} />
    <div className="container-fluid mt-4 mt-custom">
      <div className="col-md-12">
        <div className="px-3 px-xl-5">
          <div className="d-flex justify-content-between mt-4">
            <h1 className="font-bold font-24 primary-textcolor">
              Admin Configurations
            </h1>
          </div>
          {/*tabs starts here*/}
          <div className="d-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link px-0  font-14 tab-style rounded-0"
                  id="information-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#information"
                  type="button"
                  role="tab"
                  aria-controls="information"
                  aria-selected="true"
                  onClick={() => navigate('/adminmanagement')}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="/images/information-icon-filled.svg"
                      alt="filled-icon"
                      className="me-2"
                    />
                    <img
                      src="/images/information-icon.svg"
                      alt="information-icon"
                      className="me-2"
                    />
                  </span>
                  Information
                </button>
              </li>
              <li className="nav-item ms-4" role="presentation">
                <button
                  className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                  id="permissions-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#permissions"
                  type="button"
                  role="tab"
                  aria-controls="permissions"
                  aria-selected="false"
                  tabIndex={-1}
                  onClick={() => navigate('/adminmanagement/adminpermission')}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="/images/permission-icon-filled.svg"
                      alt="filled-icon"
                      className="me-2"
                    />{" "}
                    <img
                      src="/images/permission-icon.svg"
                      alt="permissions-icon"
                      className="me-2"
                    />
                  </span>
                  Permissions
                </button>
              </li>
              <li className="nav-item ms-4" role="presentation">
                <button
                  className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                  id="configurations-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#configurations"
                  type="button"
                  role="tab"
                  aria-controls="configurations"
                  aria-selected="false"
                  tabIndex={-1}
                  onClick={() => navigate('/adminmanagement/accessReview')}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="/images/key-icon-filled.svg"
                      alt="filled-icon"
                      className="me-2"
                    />
                    <img
                      src="/images/key-icon.svg"
                      alt="configurations-icon"
                      className="me-2"
                    />
                    Configurations
                  </span>
                </button>
              </li>
              <li className="nav-item ms-4" role="presentation">
                <button
                  className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                  id="app-owner-config-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#app-owner-config"
                  type="button"
                  role="tab"
                  aria-controls="app-owner-config"
                  aria-selected="false"
                  tabIndex={-1}
                  onClick={() => navigate('/adminmanagement/appOwnerConfig')}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="/images/app-icon-filled.svg"
                      alt="filled-icon"
                      className="me-2"
                    />
                    <img
                      src="/images/app-icon.svg"
                      alt="app-owner-config-icon"
                      className="me-2"
                    />
                    App Owner Config
                  </span>
                </button>
              </li>
              <li className="nav-item ms-4" role="presentation">
                <button
                  className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                  id="security-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#security"
                  type="button"
                  role="tab"
                  aria-controls="security"
                  aria-selected="false"
                  tabIndex={-1}
                  onClick={() => navigate('/adminmanagement/securityConfig')}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="/images/lock-icon-filled.svg"
                      alt="filled-icon"
                      className="me-2"
                    />{" "}
                    <img
                      src="/images/lock-icon.svg"
                      alt="security-icon"
                      className="me-2"
                    />
                    Security
                  </span>
                </button>
              </li>
              <li className="nav-item ms-4" role="presentation">
                <button
                  className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                  id="policy-config-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#policy-config"
                  type="button"
                  role="tab"
                  aria-controls="policy-config"
                  aria-selected="false"
                  tabIndex={-1}
                  onClick={() => navigate('/adminmanagement/policyConfig')}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="/images/privacy-icon-filled.svg"
                      alt="filled-icon"
                      className="me-2"
                    />{" "}
                    <img
                      src="/images/privacy-icon.svg"
                      alt="policy-config-icon"
                      className="me-2"
                    />
                    Policy Configuration
                  </span>
                </button>
              </li>
              <li className="nav-item ms-4" role="presentation">
                <button
                  className="nav-link px-0 active font-14 tab-style bold-text rounded-0"
                  id="billing-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#billing"
                  type="button"
                  role="tab"
                  aria-controls="billing"
                  aria-selected="false"
                  tabIndex={-1}
                >
                  <span className="tab-img">
                    {" "}
                    <img
                      src="/images/bill-icon-filled.svg"
                      alt="filled-icon"
                      className="me-2"
                    />{" "}
                    <img
                      src="/images/bill-icon.svg"
                      alt="billing-icon"
                      className="me-2"
                    />
                    Billing
                  </span>
                </button>
              </li>
            </ul>
          </div>
          {/*tabs ends here*/}
          {/* Billing tab starts here */}
          <div className="tab-pane mt-3" id="billing" role="tabpanel" aria-labelledby="billing-tab">
            <div className="tab-pane show active justify-content-center" id="Admininfo" role="tabpanel" aria-labelledby="Admininfo-tab">
              <h1 className="font-medium font-24 mb-3">Billing Details</h1>
              <p className="font-regular font-14 grey-text-color mb-4">You can find the billing information
                and plan details here</p>
              <h5 className="font-medium font-18 mb-3">Purchased Plan</h5>
              <div className="row">
                <div className="col-4">
                  <div className="border border-radius-8 p-3">
                    <p className="font-regular font-14 grey-text-color mb-2 cust-letter-spacing">{billingDetails.planName}</p>
                    <p className="font-bold font-28 primary-textcolor">${billingDetails.amount}</p>
                    <p className="font-regular font-14 grey-text-color mb-4">The plan Purchased.
                    </p>
                    <div className="d-flex justify-content-between">
                      <span className="font-13 font-regular grey-primary">Expiry Date: <span className="font-medium">{formatDateTime(billingDetails?.validTill)}</span></span>
                      <span className="d-flex align-items-center justify-content-center pointer"><a
                       className="font-14 font-medium text-decoration-none active-textcolor d-flex justify-content-center align-items-center cursor-pointer" onClick={ ()=>navigate(`/subscription`,{ state:{value:{
                        registerId : billingDetails?.registerId,
                        firstName : billingDetails?.firstName,
                        headCount : billingDetails?.headCount,
                        email : billingDetails?.email
                    }}})}>
                        Update plans<img src="/images/anchor-next-arrow.svg" alt="anchor-next-arrow" 
                        className="ms-2"/></a></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Billing tab ends here */}
        </div>
      </div>
    </div>
    </>
  )
}