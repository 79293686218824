import { Loader } from '../../../Loader/Loader';
import NoRecordFound from '../../dashboardNoRecordFound';

const NonCompliantUsersGrid = ({ onSort, nonCompliantUsers, isLoading, roles, gridActiveTab }) => {
  const validObject = nonCompliantUsers || {};

  const isDataEmpty = nonCompliantUsers?.users?.length === 0 || Object.keys(validObject).length === 0;

  return (
    <div className="py-2 px-2">
      <Loader isLoading={isLoading} />
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="overdue" role="tabpanel" aria-labelledby="overdue-tab">
          {isDataEmpty ? (
            <NoRecordFound data={'No Overdue Users Found'} roles={roles} activeTab={gridActiveTab} />
          ) : (
            <table className="table text-nowrap mb-0 custom-table-grid table-borderless dashboard-table pt-3 mt-4" style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th className="font-14 px-4 position-relative text-start">
                    Username
                    <span className="position-absolute cursor-pointer">
                      <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => onSort('username', 'asc')} />
                      <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => onSort('username', 'desc')} />
                    </span>
                  </th>
                  <th className="font-14 position-relative">
                    Campaigns
                    <span className="position-absolute cursor-pointer">
                      <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => onSort('campaigns', 'asc')} />
                      <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => onSort('campaigns', 'desc')} />
                    </span>
                  </th>
                  <th className="font-14 position-relative text-end">
                    Days
                    <span className="position-absolute cursor-pointer">
                      <img className="sort-img-size sort-up position-absolute ms-2" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => onSort('days', 'asc')} />
                      <img className="sort-img-size sort-down position-absolute ms-2" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => onSort('days', 'desc')} />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {nonCompliantUsers?.users?.map((user, index) => (
                  <tr key={index}>
                    <td className="font-12">
                      <div className="d-flex align-items-center text-start">
                        <img src={user.profileImages} alt="Profile" className="table-prof" />
                        <p className="font-12 primary-textcolor mb-0 ms-2">{user.overdueUsers}</p>
                      </div>
                    </td>
                    <td className="font-12">{user.campaigns}</td>
                    <td className="font-12 text-end">{user.days}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="tab-pane fade" id="risk" role="tabpanel" aria-labelledby="risk-tab">
          {isDataEmpty ? (
            <NoRecordFound data={'No Risk Users Found'} roles={roles} activeTab={gridActiveTab} />
          ) : (
            <table className="table text-nowrap mb-0 custom-table-grid table-borderless dashboard-table mt-4" style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th className="font-14 font-medium px-4 position-relative text-start">
                    Username
                    <span className="position-absolute cursor-pointer">
                      <a className="sort-up position-absolute ms-2">
                        <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => onSort('username', 'asc')} />
                      </a>
                      <a className="sort-down position-absolute ms-2">
                        <img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => onSort('username', 'desc')} />
                      </a>
                    </span>
                  </th>
                  <th className="font-14 font-medium position-relative text-end">
                    Risk Severity
                    <span className="position-absolute cursor-pointer">
                      <a className="sort-up position-absolute ms-2">
                        <img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" onClick={() => onSort('severity', 'asc')} />
                      </a>
                      <a className="sort-down position-absolute ms-2">
                        <img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" onClick={() => onSort('severity', 'desc')} />
                      </a>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {nonCompliantUsers?.users?.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-start">
                        <img src={user.profileImages} className="table-prof" />
                        <p className="font-12 primary-textcolor mb-0 ms-2">{user.riskUsers}</p>
                      </div>
                    </td>
                    <td className="font-12 font-medium text-end">
                      <span className={`batch-${user?.severity?.toLowerCase()}`}>{user.severity}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default NonCompliantUsersGrid;
