import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getIpConfig, insertIpConfig, updateIpConfig, deleteIpConfig } from '../../Services/Admin/AdminService';
import { DeleteAdminPopUp } from "../Admin/DeleteAdminPopUp";
import { agovContext } from '../../Constant';
import NoDataFound from '../LMS/NoDataFound';
import { Loader } from '../Loader/Loader';
import { SecuritySlaConfig } from "../Admin/SecuritySlaConfig";
import { SecurityRiskTaskConfig } from "../Admin/SecurityRiskTaskConfig";
import { SecurityRetentionConfig } from "../Admin/SecurityRetentionConfig";
import { PolicyConfigForm } from "../Admin/PolicyConifguration";
import { PolicyConfigPopUp } from "./policyconfigPopup";

export default function InitialSecurity() {
  const navigate = useNavigate();
  const { userId, employeeName, reportsToId } = useContext(agovContext);

  let updateLoadMore = 15;
  let ipConfigDataObj = {
    ipId: null,
    ipName: "",
    ipAddressRange: "",
    category: "",
    createdBy: employeeName,
    modifiedBy: employeeName,
    userId: userId ? userId : 'test123',
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName
  }
  let clearObj = {
    ipName: "",
    ipAddressRange: "",
    category: ""
  }

  const [ipConfig, setIpConfig] = useState(ipConfigDataObj)
  const [ipConfigData, setIpConfigData] = useState([]);
  const [count, setCount] = useState(0);
  const [orderColn, setOrderColn] = useState('created_on DESC');
  const [loadMore, setLoadMore] = useState(updateLoadMore);
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState([]);

  const [hideIpConfig, setHideIpConfig] = useState(true)
  const [hideSlaConfig, setHideSlaConfig] = useState(false)
  const [hideConnetorConfig, setHideConnectorConfig] = useState(false)
  const [hideRiskConfig, setHideRiskConfig] = useState(false)
  const [hideRetentionConfig, setHideRetentionConfig] = useState(false)
  const [delPopUp, setDelPopUp] = useState(false)
  const [totalRec, setTotalRec] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [isApplication1,setIsApplication1] = useState(false)
  const [isApplication2,setIsApplication2] = useState(false)
  const [isApplication3,setIsApplication3] = useState(false)
  const [isEmail1,setEmail1] = useState(false)
  const [isEmail2,setEmail2] = useState(false)
  let ipConfigErrorObj = {
    ipNameError: false,
    ipRangeError: false,
    ipRangeRegexError: false,
    categoryError: false
  }
  let ipGridErrorObj = {
    ipIdError: false,
    ipNameError: false,
    ipRangeError: false,
    ipRangeRegexError: false,
    categoryError: false
  }

  let delObj = {
    ipId: null,
    ipName: "",
    userId: userId ? userId :'test123',
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName
  }

  const [errors, setErrors] = useState(ipConfigErrorObj);
  const [errorGrid, setErrorGrid] = useState(ipGridErrorObj);
  const [editIpId, setEditIpId] = useState(null)
  const [deleteData, setDeleteData] = useState(delObj)
  const [isEditing, setIsEditing] = useState(false);

  let ipConfigObj = {
    sortColn: orderColn,
    searchValue: searchText,
    updateLoadMore: loadMore
  }
  const [ipObj, setIpObj] = useState(ipConfigObj)
  let editConfigObj = {
    ipId: "",
    ipName: "",
    ipAddressRange: "",
    category: "",
    modifiedBy: employeeName
  }

  const [updateIpConfigData, setUpdateIpConfigData] = useState(editConfigObj)

  useEffect(() => {
    fetchIpConfig();
  }, [loadMore, orderColn]);

  useEffect(() => {
    console.log("In application")
  }, [isApplication1,isApplication2,isApplication3,isEmail1,isEmail2,hideIpConfig,hideRetentionConfig,hideRiskConfig,hideSlaConfig]);
    
  const handleLoadMore = () => {
    setLoadMore(prevLoadMore => prevLoadMore + 5); // Increment loadMore by 5
  };

  const handleSearch = () => {
    fetchIpConfig();
  };


  const handleEditClick = (value) => {
    setEditIpId(value.ip_id);
    setUpdateIpConfigData({
      ...updateIpConfigData,
      ipId: value.ip_id,
      ipName: value.ip_name,
      ipAddressRange: value.ip_address_range,
      category: value.category,
    })

    const updatedIpConfigDataError = ipConfigData.map(obj => {
      return { ...obj, ipIdError: false, ipNameError: false, ipRangeError: false, categoryError: false };
    });

    setIpConfigData(updatedIpConfigDataError);

    setIsEditing(true);
  };

  const handleTickClick = (index) => {
    // Perform actions for tick icon
    handleUpdate(index);
  };

  const handleCrossClick = (index) => {
    // Perform actions for cross icon
    setIsEditing(false);
    setErrorGrid(ipGridErrorObj)

    let updatedIpConfigData = [...ipConfigData];

    updatedIpConfigData[index].ipIdError = false;
    updatedIpConfigData[index].ipNameError = false;
    updatedIpConfigData[index].ipRangeError = false;
    updatedIpConfigData[index].categoryError = false;
    setIpConfig(updatedIpConfigData);
  };

  const fetchIpConfig = async () => {
    let ipval = {
      sortColn: orderColn,
      searchValue: searchText,
      updateLoadMore: loadMore
    }
    let ipConfigObj = ipval
    ipConfigObj.updateLoadMore = loadMore
    ipConfigObj.searchValue = searchText
    try {
      setIsLoading(true);
      let result = await getIpConfig(ipConfigObj);
      if (result.statusCode === 200) {
        setIpConfigData(result.responseData.recordsets[0]);
        setTotalRec(result.responseData.recordsets[0].length)
        setCategory(result.responseData.recordsets[1]);
        setCount(result.responseData.recordsets[2][0].count);
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  function bindCategory(selectedCategory) {
    console.log(selectedCategory, "selecetedCategory");
    return (
      category.map((value, index) => {
        return (
          <option value={value.category} selected={value.category == selectedCategory ? true : false}
            name="category">{value.category}</option>
        )

      })
    )
  }
  //PC_SIC_42 to PC_SIC_47
  const handleInputChange = (e) => {
    let ipConfigErrorObj = { ...errors }
    if (e.target.name === 'ipAddressRange') {
      const rangeRegex = /^((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))$/
      const rangeAllowRegex = /^[0-9.\/]+$/
      if(!rangeAllowRegex.test(e.target.value) && e.target.value) e.target.value = ipConfig.ipAddressRange
      if (rangeRegex.test(e.target.value)) {
        ipConfigErrorObj.ipRangeRegexError = false;
      } else {
        ipConfigErrorObj.ipRangeRegexError = true;
      }
    }
    const { name, value } = e.target;
    setIpConfig(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(ipConfigErrorObj);
  };

  const handleDeletePopUp = (ipId, ipName) => {
    let delObj = {
      ipId: ipId,
      ipName: ipName,
      userId: userId ? userId :'test123',
      managerId: reportsToId,
      appliedBy: employeeName,
      appliedOn: employeeName
    }
    setDeleteData(delObj)
    setDelPopUp(true);
  }

  const handleUpdateChange = (e) => {
    let ipGridErrorObj = { ...errorGrid }
    if (e.target.name === 'ipAddressRange') {
      let rangeRegex = /^((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))$/
      if (rangeRegex.test(e.target.value)) {
        ipGridErrorObj.ipRangeRegexError = false;
      } else {
        ipGridErrorObj.ipRangeRegexError = true;
      }
    }
    const { name, value } = e.target;
    setUpdateIpConfigData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrorGrid(ipGridErrorObj);
  };
  //PC_SIC_48 to PC_SIC_61
  const handleInsert = async () => {
    try {
      setIsLoading(true);
      if (validateIpConfigData()) {
        let result = await insertIpConfig(ipConfig);
        if (result.statusCode == 200) {
          fetchIpConfig();
          setIpConfig(clearObj)
          console.log(" Ip Config inserted successfully");
        }
        else {
          console.log("Something went wrong!");
        }
      }
    }
    catch (error) {
      console.error('Error inserting Ipconfig:', error);
    }
    setIsLoading(false);
  };
  //PC_SIC_95 to PC_SIC_103
  const validateIpConfigData = () => {

    let isValid = true;
    let ipConfigErrorObj = { ...errors };
    let rangeRegex = /^((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))$/

    if (ipConfig.ipName === "") {
      ipConfigErrorObj.ipNameError = true;
      isValid = false;
    }

    if (ipConfig.ipAddressRange === "") {
      ipConfigErrorObj.ipRangeError = true;
      isValid = false;
    }

    if (ipConfig.category === "") {
      ipConfigErrorObj.categoryError = true;
      isValid = false;
    }

    if (!rangeRegex.test(ipConfig.ipAddressRange)) {
      ipConfigErrorObj.ipRangeRegexError = true;
      isValid = false
    }

    setErrors(ipConfigErrorObj);
    return isValid;
  };
  //PC_SIC_104 to  PC_SIC_114
  const validateGridData = (index) => {

    let isValid = true;
    let ipGridErrorObj = [...ipConfigData];

    if (updateIpConfigData.ipId === "") {
      ipGridErrorObj[index].ipIdError = true;
      isValid = false;
    }
    if (updateIpConfigData.ipName === "") {
      ipGridErrorObj[index].ipNameError = true;
      isValid = false;
    }
    if (updateIpConfigData.ipAddressRange === "") {
      ipGridErrorObj[index].ipRangeError = true;
      isValid = false;
    }

    if (updateIpConfigData.category === "") {
      ipGridErrorObj[index].categoryError = true;
      isValid = false;
    }

    setIpConfigData(ipGridErrorObj);
    return isValid;
  };
  //PC_SIC_62 to PC_SIC_77
  const handleDelete = async (deleteData) => {
    try {
      setIsLoading(true);
      let result = await deleteIpConfig(deleteData);
      if (result.statusCode === 200) {
        console.log(result, "result of delete");
        fetchIpConfig();
        console.log('IP Configuration Information fetched successfully');
      } else {
        console.log('Something went wrong!');
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  //PC_SIC_79 to PC_SIC_94
  const handleUpdate = async (index) => {
    try {
      setIsLoading(true);
      if (validateGridData(index)) {
        let result = await updateIpConfig(updateIpConfigData);
        if (result.statusCode === 200) {
          setIsEditing(false);
          fetchIpConfig();
          console.log('IP Configuration Information fetched successfully');
        } else {
          console.log('Something went wrong!');
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  function gridDynamicBind() {
    return (
      ipConfigData.map((value, index) => {
        console.log(value, "value");
        return (
          <tr>
            <td className="font-14 font-regular">
              {value.ip_id === editIpId && isEditing ? (
                <input type="text"
                  className="form-control font-regular font-14 custom-form-control py-2"
                  name="ipName"
                  value={updateIpConfigData.ipName ?? value.ip_name}
                  onChange={(e) => {
                    handleUpdateChange(e);
                  }}
                  placeholder="Enter Range Name" />
              ) : value.ip_name}
              <h6>
                {value?.ipNameError ? (
                  <span id="alertMessage"style={{color:"red",font:"small-caption"}}>Enter IpName</span>
                ) : null}
              </h6>
            </td>
            <td className="font-14 font-regular">
              {value.ip_id === editIpId && isEditing ? (
                <input type="text"
                  className="form-control font-regular font-14 custom-form-control py-2"
                  name="ipAddressRange"
                  value={updateIpConfigData.ipAddressRange ?? value.ip_address_range}
                  onChange={(e) => {
                    handleUpdateChange(e);
                  }}
                  placeholder="Eg: 192.168.1.1/32" />
              ) : value.ip_address_range}
              <h6>
                {(value.ip_id === editIpId && isEditing && value?.ipRangeError) ? (
                  <span id="alertMessage"style={{color:"red",font:"small-caption"}}>Enter Ip Range</span>
                ) : value.ip_id === editIpId && isEditing && errorGrid.ipRangeRegexError ? (<span id="alertMessage">Enter Valid Ip Address</span>) : null}
              </h6>
            </td>
            <td className="font-14 font-regular">
              {value.ip_id === editIpId && isEditing ? (
                <select className="form-select form-control font-regular font-14 custom-form-control py-2" aria-label="Default select example" id="country"
                  name="category"
                  onChange={(e) => {
                    handleUpdateChange(e);
                  }}>
                  <option value="">Select</option>
                  {bindCategory(value.category)}
                </select>
              ) : (
                value.category
              )}
              <h6>
                {value?.categoryError ? (
                  <span id="alertMessage"style={{color:"red",font:"small-caption"}}>
                    Choose Category
                  </span>) : null}
              </h6>
            </td>
            <td className="text-center">
              {value.ip_id === editIpId && isEditing ? (
                <>
                  <a className="me-3" onClick={() => { handleTickClick(index) }}>
                    <img src="/images/tick-icon.svg" alt="tick-icon" />
                  </a>
                  <a className="me-3" onClick={() => handleCrossClick(index)}>
                    <img src="/images/cross-icon.svg" alt="cross-icon" />
                  </a>
                </>
              ) : (
                <a className="me-2">
                  <img src="/images/edit-icon.svg" alt="edit-icon" onClick={() => {
                    handleEditClick(value);
                  }} />
                </a>
              )}
              <a className="me-2"><img src="/images/delete-icon.svg" alt="edit-icon" onClick={() => {
                handleDeletePopUp(value.ip_id, value.ip_name)
              }} /></a>
            </td>
          </tr>
        )
      }
      )
    )
  }


  return (
    <>
    <div>
    <Loader isLoading={isLoading} />
      <div className="container-fluid">
        {/* Stepper for Security*/}
        <div className="row justify-content-center">
          <div className="col-md-10 col-xl-6">
            <div className="mx-5 mt-5">
              <div className="progress-container">
                <div className="progress-step completed">
                  <div className="step-icon completed" />
                  <div className="text-center m-0 step-label-completed font-regular font-16 grey-light">
                    Connectors
                  </div>
                </div>
                <div className="progress-step completed">
                  <div className="step-icon completed" />
                  <div className="text-center m-0 step-label-completed font-regular font-16 grey-light">
                    Permissions
                  </div>
                </div>
                <div className="progress-step active">
                  <div className="step-icon active" />
                  <div className="text-center m-0 step-label-active font-regular font-16 grey-light">
                    Security
                  </div>
                </div>
                <div className="progress-step">
                  <div className="step-icon" />
                  <div className="text-center m-0 font-regular font-16 grey-light">
                    AG Configs
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Stepper for Security */}

        {/* Landing Page for Security  statement */}
        <h5 className="font-medium font-24 primary-textcolor text-center mt-5">
          Boost security with AGov's settings to fortify your environment
          against risks.
        </h5>
        <p className="font-regular font-16 grey-light-color text-center mb-5 mt-3">
          Fortify your organization's defenses with tailored security
          configurations in AGov, ensuring robust protection{" "}
          <span className="d-block">
            against diverse organizational risks. Empower your environment to
            withstand threats confidently.
          </span>
        </p>
        {/* Landing Page for Security Statement */}

        <div className="row justify-content-center">
          <div className="col-md-11">
            <h2 className="font-bold font-24">Security</h2>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-start security-ip-tab">
                  <div className="col-md-3 tab-column vh-100">
                    {/* LEFT NAVIGATION FOR EACH TAB */}
                    <div className=" d-flex flex-column">
                      <ul
                        className="nav nav-pills d-block"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <li
                          className={hideIpConfig ? "nav-link text-nowrap pe-0 text-wrap active" : "nav-link text-nowrap pe-0 text-wrap"}
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-home"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected="true"
                          onClick={()=>{
                            setHideIpConfig(true)
                            setIsApplication2(false)
                            setIsApplication1(false)
                            setIsApplication3(false)
                            setEmail1(false)
                            setEmail2(false)
                            setHideSlaConfig(false)
                            setHideRiskConfig(false)
                            setHideRetentionConfig(false)
                          }}
                        >
                          IP Config
                        </li>
                        <li
                          className={hideSlaConfig ? "nav-link text-nowrap pe-0 text-wrap active" : "nav-link text-nowrap pe-0 text-wrap"}
                          id="v-pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                          onClick={()=>{
                            setHideSlaConfig(true)
                            setIsApplication2(false)
                            setIsApplication1(false)
                            setIsApplication3(false)
                            setEmail1(false)
                            setEmail2(false)
                            setHideIpConfig(false)
                            setHideRiskConfig(false)
                            setHideRetentionConfig(false)
                          }}
                        >
                          SLA Config
                        </li>
                        <li
                          className={hideRetentionConfig?"nav-link text-nowrap pe-0 text-wrap active" : "nav-link text-nowrap pe-0 text-wrap"}
                          id="v-pills-disabled-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-disabled"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-disabled"
                          aria-selected="false"
                          onClick={()=>{
                            setHideRetentionConfig(true)
                            setIsApplication2(false)
                            setIsApplication1(false)
                            setIsApplication3(false)
                            setEmail1(false)
                            setEmail2(false)
                            setHideSlaConfig(false)
                            setHideIpConfig(false)
                            setHideRiskConfig(false)
                          }}
                        >
                          Data Retention
                        </li>
                        <li
                          className={hideRiskConfig?"nav-link text-nowrap pe-0 text-wrap active":"nav-link text-nowrap pe-0 text-wrap"}
                          id="v-pills-settings-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-settings"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-settings"
                          aria-selected="false"
                          onClick={()=>{
                            setHideRiskConfig(true)
                            setIsApplication2(false)
                            setIsApplication1(false)
                            setIsApplication3(false)
                            setEmail1(false)
                            setEmail2(false)
                            setHideRetentionConfig(false)
                            setHideSlaConfig(false)
                            setHideIpConfig(false)
                          }}
                        >
                          Risk Task Config
                        </li>
                        <li
                          className="nav-link text-nowrap pe-4 text-wrap"
                          id="v-pills-application-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-application"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-application"
                          aria-selected="false"
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <button
                              className="d-flex justify-content-between w-100 align-items-center p-0 border-0 shadow-none custom-drop-btn bg-transparent collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accapplication"
                              aria-expanded="true"
                              aria-controls="accapplication"
                              
                            >
                              <span className="font-16 font-regular primary-textcolor">
                                Application
                              </span>
                              <img
                                src="images/down-arrow.svg"
                                alt="down-arrow"
                              />
                            </button>
                          </div>
                          <div
                            id="accapplication"
                            className="accordion-collapse table-parent-accordion collapse pt-3"
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-column">
                                <ul
                                  className="nav nav-pills d-block"
                                  id="v-pills-tab"
                                  role="tablist"
                                  aria-orientation="vertical"
                                >
                                  <li
                                    className={isApplication1?"nav-link Admin-nav-btn font-14 cus-tab-border green-tab-bg d-flex align-items-center mb-3 border-0 pe-0 text-wrap active" : "nav-link Admin-nav-btn font-14 cus-tab-border green-tab-bg d-flex align-items-center mb-3 border-0 pe-0 text-wrap"}
                                    id="MassDownload-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#MassDownload"
                                    type="button"
                                    role="tab"
                                    aria-controls="MassDownload"
                                    aria-selected="true"
                                    onClick={()=>{
                                      setIsApplication1(true)
                                      setIsApplication2(false)
                                      setIsApplication3(false)
                                      setEmail1(false)
                                      setEmail2(false)
                                      setHideIpConfig(false)
                                      setHideRetentionConfig(false)
                                      setHideRiskConfig(false)
                                      setHideSlaConfig(false)
                                    }}
                                  >
                                    Mass Download
                                  </li>
                                  <li
                                    className={isApplication2?"nav-link Admin-nav-btn font-14 cus-tab-border green-tab-bg d-flex align-items-center border-0 mb-3 pe-0 text-wrap active" : "nav-link Admin-nav-btn font-14 cus-tab-border green-tab-bg d-flex align-items-center border-0 mb-3 pe-0 text-wrap"}
                                    id="MassDelete-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#MassDelete"
                                    type="button"
                                    role="tab"
                                    aria-controls="MassDelete"
                                    aria-selected="false"onClick={()=>{
                                      setIsApplication2(true)
                                      setIsApplication1(false)
                                      setIsApplication3(false)
                                      setEmail1(false)
                                      setEmail2(false)
                                      setHideIpConfig(false)
                                      setHideRetentionConfig(false)
                                      setHideRiskConfig(false)
                                      setHideSlaConfig(false)
                                    }}                                  >
                                    Mass Delete
                                  </li>
                                  <li
                                    className={isApplication3?"nav-link Admin-nav-btn font-14 cus-tab-border green-tab-bg d-flex align-items-center border-0 pe-0 text-wrap active" : "nav-link Admin-nav-btn font-14 cus-tab-border green-tab-bg d-flex align-items-center border-0 pe-0 text-wrap"}
                                    id="HighVolumeapplication-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#HighVolumeapplication"
                                    type="button"
                                    role="tab"
                                    aria-controls="HighVolumeapplication"
                                    aria-selected="false"
                                    onClick={()=>{
                                      setIsApplication3(true)
                                      setIsApplication2(false)
                                      setIsApplication1(false)
                                      setEmail1(false)
                                      setEmail2(false)
                                      setHideIpConfig(false)
                                      setHideRetentionConfig(false)
                                      setHideRiskConfig(false)
                                      setHideSlaConfig(false)
                                    }}
                                  >
                                    High Volume application
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          className="nav-link text-nowrap pe-4"
                          id="v-pills-emails-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-emails"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-emails"
                          aria-selected="false"
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <button
                              className="d-flex justify-content-between align-items-center w-100 p-0 border-0 shadow-none custom-drop-btn bg-transparent collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#accEmail"
                              aria-expanded="false"
                              aria-controls="accEmail"
                              
                            >
                              <span className="font-16 font-regular primary-textcolor">
                                Email
                              </span>
                              <img
                                src="images/down-arrow.svg"
                                alt="down-arrow"
                              />
                            </button>
                          </div>
                          <div
                            id="accEmail"
                            className="accordion-collapse table-parent-accordion collapse pt-3"
                          >
                            <div className="d-flex align-items-start">
                              <div
                                className="flex-column"
                                id="v-pills2-tab"
                                role="tablist"
                                aria-orientation="vertical"
                              >
                                <ul className="nav nav-pills d-block">
                                  <li
                                    className={isEmail1?"nav-link Admin-nav-btn font-14 cus-tab-border orange-tab-bg d-flex align-items-center mb-3 border-0 pe-0 text-wrap active":"nav-link Admin-nav-btn font-14 cus-tab-border orange-tab-bg d-flex align-items-center mb-3 border-0 pe-0 text-wrap" }
                                    id="BulkEmaildelete-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#BulkEmaildelete"
                                    type="button"
                                    role="tab"
                                    aria-controls="BulkEmaildelete"
                                    aria-selected="false"
                                    onClick={()=>{
                                      setIsApplication1(false)
                                      setIsApplication2(false)
                                      setIsApplication3(false)
                                      setEmail1(true)
                                      setEmail2(false)
                                      setHideIpConfig(false)
                                      setHideRetentionConfig(false)
                                      setHideRiskConfig(false)
                                      setHideSlaConfig(false)
                                    }}
                                  >
                                    Bulk Email delete
                                  </li>
                                  <li
                                    className={isEmail2?"nav-link Admin-nav-btn font-14 cus-tab-border orange-tab-bg d-flex align-items-center border-0 pe-0 text-wrap active" : "nav-link Admin-nav-btn font-14 cus-tab-border orange-tab-bg d-flex align-items-center border-0 pe-0 text-wrap"}
                                    id="SensitiveSharedUser-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#SensitiveSharedUser"
                                    type="button"
                                    role="tab"
                                    aria-controls="SensitiveSharedUser"
                                    aria-selected="false"
                                    onClick={()=>{
                                      setIsApplication1(false)
                                      setIsApplication2(false)
                                      setIsApplication3(false)
                                      setEmail2(true)
                                      setEmail1(false)
                                      setHideIpConfig(false)
                                      setHideRetentionConfig(false)
                                      setHideRiskConfig(false)
                                      setHideSlaConfig(false)
                                    }}
                                  >
                                    Sensitive email shared user
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* LEFT NAVIGATION FOR EACH TAB */}
                  </div>

                  <div className="col-md-9 tab-content ps-5" id="v-pills-tabContent">
                    {/* IP CONFIG FUNCTIONALITIES STARTS HERE */}
                    <div className={hideIpConfig?"tab-pane fade show active" : "tab-pane fade show"} id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex={0} >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between mt-4 align-items-center">
                            <div className="col-md">
                              <h1 className="font-bold font-20 primary-textcolor">
                                IP Config
                              </h1>
                              <p className="font-14 grey-light mt-3">
                                Efficiently categorize and manage IP addresses
                                for optimized network organization and
                                administration
                              </p>
                            </div>
                            <div className="col-md d-flex justify-content-end">
                              <div className="input-group flex-nowrap search-group me-2">
                                <input
                                  type="search"
                                  className="form-control search-align"
                                  placeholder="Search"
                                  aria-label="Username"
                                  aria-describedby="addon-wrapping"
                                  onKeyDownCapture={(e) => {
                                    if (e.key === "Enter") {
                                      handleSearch(e)
                                    }
                                  }}
                                  onChange={(e) => { setSearchText(e.target.value) }} />

                                <button className="input-group-text btn btn-search-custom"id="addon-wrapping">
                                  <img src="/images/search-icon.svg"  alt="search-icon" onClick={() => { handleSearch() }}/>
                                </button>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="table-responsive" style={{ overflowX: 'hidden' }}>
                            <table className="table mb-0 custom-table-grid table-borderless mt-3">
                              <thead>
                                <tr>
                                  <th className="font-14 px-4 position-relative">Name
                                    <span className="position-absolute">
                                    <a className="sort-up position-absolute ms-2" onClick={() => { setOrderColn("ip_name ASC") }}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2" onClick={() => setOrderColn("ip_name DESC")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                    </span>
                                  </th>
                                  <th className="font-14 position-relative">
                                    IP Address Range
                                    <span className="position-absolute">
                                    <a className="sort-up position-absolute ms-2" onClick={() => { setOrderColn("ip_address_range ASC") }}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2" onClick={() => setOrderColn("ip_address_range DESC")}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                    </span>
                                  </th>

                                  <th className="font-14 position-relative">
                                    Category
                                    <span className="position-absolute">
                                    <a className="sort-up position-absolute ms-2" onClick={() => setOrderColn("category ASC")}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2" onClick={() => { setOrderColn("category DESC") }}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                    </span>
                                  </th>
                                  <th className="font-14 text-center">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                              <tr>
                                          <td><input type="text" className="form-control font-regular font-14 custom-form-control py-2"
                                            name="ipName"
                                            value={ipConfig.ipName}
                                            onChange={(e) => {
                                              handleInputChange(e);
                                            }}
                                            placeholder="Enter Range Name" />
                                            <h6>
                                              {errors.ipNameError ? (
                                                <span id="alertMessage" style={{color:"red" ,font:"small-caption"}}>
                                                  Enter Ip Name
                                                </span>) : null}
                                            </h6>
                                          </td>
                                          <td><input type="text" className="form-control font-regular font-14 custom-form-control py-2"
                                            name="ipAddressRange"
                                            value={ipConfig.ipAddressRange}
                                            onChange={(e) => {
                                              handleInputChange(e);
                                            }}
                                            placeholder="Eg: 192.168.1.1/32" />
                                            <h6>
                                              {errors.ipRangeError ? (
                                                <span id="alertMessage"  style={{color:"red" ,font:"small-caption"}}>Enter Ip Range</span>
                                              ) : errors.ipRangeRegexError ? (<span id="alertMessage"style={{color:"red",font:"small-caption"}}>Enter Valid Ip Address</span>) : null}
                                            </h6>
                                          </td>
                                          <td>
                                            <select className="form-select form-control font-regular font-14 custom-form-control py-2" aria-label="Default select example" id="country"
                                              name="category"
                                              value={ipConfig.category}
                                              onChange={(e) => {
                                                handleInputChange(e);
                                              }}
                                            >
                                              <option value="">Select</option>
                                              {bindCategory()}
                                            </select>
                                            <h6>
                                              {errors.categoryError ? (
                                                <span id="alertMessage"  style={{color:"red" ,font:"small-caption"}}>
                                                  Choose Category
                                                </span>) : null}
                                            </h6>
                                          </td>
                                          <td className="text-center"><a ><img src="/images/add-icon-line.svg" alt="add-icon" onClick={() => handleInsert()} /></a></td>
                                        </tr>

                                {gridDynamicBind()}
                              </tbody>
                            </table>
                               {  delPopUp && ( <DeleteAdminPopUp handleDelete={handleDelete} setDelPopUp={setDelPopUp} deleteData={deleteData}></DeleteAdminPopUp>)}
                        </div>
                          <div className="mt-2">
                                  <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor">{totalRec} </span>
                                    out of <span className="font-medium primary-textcolor">{count}</span> items</p>
                                </div>
                                <div className="text-center mt-5 mb-4">
                                  {totalRec < count && (
                                    <button className="primary-btn font-medium mb-5  cursor-pointer"
                                      onClick={handleLoadMore}>
                                      Load More</button>
                                  )}
                                </div>
                        </div>

                        <div className="text-end mt-2 mb-4">
              <button type="button" className="primary-btn font-14 font-medium py-2 me-3" 
              onClick={() => navigate("/InitialPermission")}>Previous</button>
                <button type="button"className="dark-btn font-14 font-medium py-2"
                  onClick={() => navigate(`/InitialAgconfig`)}>Next </button>
                        </div>

                      </div>
                    </div>
                    {/* IP CONFIG FUNCTIONALITIES ENDS  HERE */}

                    {/* SLA CONFIG FUNCTIONALITIES STARTS HERE */}
                    <div className={hideSlaConfig?"tab-pane fade show active" : "tab-pane fade show"} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex={0}>
                    <SecuritySlaConfig></SecuritySlaConfig>
                    </div>
                    {/* SLA CONFIG FUNCTIONALITIES ENDS HERE */}

                    {/* DATA RETENTION STARTS HERE */}
                    <div className={hideRetentionConfig?"tab-pane fade show active" : "tab-pane fade show"} id="v-pills-disabled" role="tabpanel" aria-labelledby="v-pills-disabled-tab" tabIndex={0}>
                    <SecurityRetentionConfig></SecurityRetentionConfig>
                    </div>
                    {/* DATA RETENTION ENDS HERE */}

                    {/* RISK TASK STARTS CONFIG */}
                    <div className={hideRiskConfig?"tab-pane fade show active" : "tab-pane fade show"} id="v-pills-settings"role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex={0}>
                    <SecurityRiskTaskConfig></SecurityRiskTaskConfig>
                    </div>
                    {/* RISK TASK  ENDS CONFIG */}

                    {/* MASS DOWNLOAD FORM SET-UP */}
                    <div className={isApplication1 ?"tab-pane fade show active" : "tab-pane fade show" } id="MassDownload" role="tabpanel" aria-labelledby="MassDownload-tab">
                      <PolicyConfigPopUp></PolicyConfigPopUp>
                    </div>
                  

                    {/* MASS DELETE START */}
                    <div className={isApplication2 ?"tab-pane fade show active" : "tab-pane fade show" } id="MassDelete" role="tabpanel" aria-labelledby="MassDelete-tab">
                    <PolicyConfigPopUp></PolicyConfigPopUp>
                    </div>
                   

                    {/* HIGH VOLUMN APPLICATION STARTS */}
                    <div className={isApplication3 ?"tab-pane fade show active" : "tab-pane fade show" } id="HighVolumeapplication" role="tabpanel" aria-labelledby="HighVolumeapplication-tab">
                    <PolicyConfigPopUp></PolicyConfigPopUp>
                    </div>
                   

                    {/* BULK EMAIL DELETION  */}
                    <div className={isEmail1 ?"tab-pane fade show active" : "tab-pane fade show" } id="BulkEmaildelete" role="tabpanel" aria-labelledby="BulkEmaildelete-tab">
                    <PolicyConfigPopUp></PolicyConfigPopUp>
                    </div>
                    
                    {/* SENSITIVE SHARED USER  */}
                    <div className={isEmail2 ?"tab-pane fade show active" : "tab-pane fade show" }  id="SensitiveSharedUser"  role="tabpanel"  aria-labelledby="SensitiveSharedUser-tab"  >
                    <PolicyConfigPopUp></PolicyConfigPopUp>
                    </div>

                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </>
  );
}
