import React from 'react';

const DeletePopup = (props) => {
    const { isCourseDelete, setIsDeleteCampaignPopupVisible, handleConfirm, assigneeCount } = props;

    return (
        <>
            <div className="modal" id="delete" tabIndex={-1} aria-labelledby="exampleModalLabel" style={{ display: "block" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-2 px-4">
                            <h5 className="modal-title font-bold" id="exampleModalLabel"><img className="me-3" src="/images/delete-popup-icon.svg" alt="delete-icon" /> Delete Campaign</h5>
                            <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsDeleteCampaignPopupVisible(false)} />
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <p className="font-16 font-medium primary-bluecolor">This {isCourseDelete ? "course" : "campaign"} is already assigned to <span style={{ color: "#000080" }}>{assigneeCount} employees</span>. Are you sure you want to delete ?</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 mb-3 px-4">
                            <button type="button" className="primary-btn font-14 font-medium py-2" data-bs-dismiss="modal" onClick={() => setIsDeleteCampaignPopupVisible(false)}>Cancel</button>
                            <button type="button" className="btn revoke-btn font-14 font-medium py-2" onClick={() => handleConfirm()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </>
    );
};

export default DeletePopup;
