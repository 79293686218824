import React, { useContext, useState } from 'react';
import { agovContext } from '../../Constant';
import { updateControlStatus } from '../../Services/GRC/GrcService';
import { Loader } from '../Loader/Loader';

const InactivePopup = ({ onOpen, controlInformation, reRender, setReRender, onClose, onSave }) => {

    const [reason, setReason] = useState('');
    const [pausedTillDate, setPausedTillDate] = useState('');
    const { employeeId, employeeName, roles, userId } = useContext(agovContext);
    const [isLoading, setIsLoading] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    /**
     * PC_CC_327 - PC_CC_329
     * This closes the popup
     */
    const handleClose = () => {
        setReason('')
        setPausedTillDate('')
        onClose();
    }

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
const minDate = tomorrow.toISOString().split('T')[0];

    /**
     * PC_CC_314 - PC_CC_326
     * The control is set to inactive
     */
    const handleSave = async () => {
        if (pausedTillDate && pausedTillDate >= minDate) {
            if (onOpen === 'Control') {
                try {
                    setIsLoading(true);
                    await updateControlStatus({
                        "reason": reason,
                        "activeStatus": "inactive",
                        "userId": userId,
                        "pausedTill": pausedTillDate,
                        "controlId": controlInformation?.controlDetails?.controlId,
                        "controlIdentity": controlInformation?.controlDetails?.controlIdentity,
                        "controlName": controlInformation?.controlDetails?.controlName
                    });
                    setValidationMessage('');
                    setIsLoading(false);
                    setReRender(!reRender);
                    onOpen === 'Control' ? handleClose() : onClose();
                }
                catch (error) {
                    console.log(error, "error");
                    setIsLoading(false);
                }
            }
            else {
                setValidationMessage('Please enter a valid date');
                onSave({ reason, pausedTillDate });
            }
        } else {
            setValidationMessage('Please enter a valid date');
        }
    }

    return (
        <>
        <Loader isLoading={isLoading} />
        <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block",backgroundColor: "rgba(0,0,0,0.6)" }}>
            <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                <div className="modal-content">
                    {/*modal content starts here*/}
                    <div className="modal-header border-0 pb-3 px-4">
                        <h5 className="modal-title font-bold font-20" id="exampleModalLabel">In Active</h5>
                        <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={() => { onOpen === 'Control' ? handleClose() : onClose() }} />
                    </div>
                    <div className="modal-body border-0 font-16 px-4">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label className="form-label font-regular grey-secondary font-14 w-100">Temporarily stops monitoring of this document. You will not be notified of any updates while paused. You can resume monitoring at any time.</label>
                            </div>
                            {/* PC_CC_310 - PC_CC_313
                            The values are setStated
                             */}
                            <div className="col-md-12 mb-3">
                                <label htmlFor="recurrence" className="form-label font-medium font-14 primary-textcolor w-100">Date to re-enable monitoring<span className="required-text">*</span></label>
                                <input type="date" className="form-control font-regular font-14 custom-form-control py-2" id="monitoring" placeholder="mm/dd/yyyy" value={pausedTillDate} min={minDate} onChange={(e) => { setPausedTillDate(e.target.value) }} />
                                <p className="font-13 font-regular required-text mt-2 mb-2">{validationMessage}</p>
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="description" className="form-label font-medium font-14 primary-textcolor w-100">Please provide a reason why you are pausing Company organization chart, so it may be documented for your audit.</label>
                                <textarea type="text" className="form-control font-regular font-14 custom-form-control py-2 resize-none" rows={4} id="description" placeholder="Enter your reason" defaultValue={""} value={reason} onChange={(e) => { setReason(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 mb-3 px-4">
                        <button type="button" className="primary-btn font-14 font-medium py-2 me-2" data-bs-dismiss="modal" onClick={() => { onOpen === 'Control' ? handleClose() : onClose() }}>Cancel</button>
                        <button type="button" className={pausedTillDate ? "dark-btn font-14 font-medium py-2" : "disabled-btn font-14 font-medium py-2"} disabled={!pausedTillDate} onClick={() => handleSave()}>Save</button>
                    </div>
                    {/*modal content ends here*/}
                </div>
            </div>
        </div>
        </>

    )
}

export default InactivePopup;
