/**
 * AG_PC_NA_01 - AG_PC_NA_07
 * Import statement for this component
 */
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { agovContext, encryptStorage } from "../../Constant";
import { checkUserLogin, getEmployeeDetails, mandatoryDataCheck } from "../../Services/Login/LoginService";
import { Loader } from "../Loader/Loader";
/**
 * AG_PC_NA_08 - AG_PC_NA_12
 * Declare the import functions and the required variables for this component.
 */
export default function NewLoginAuth() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const accounts = instance.getAllAccounts();
    const [isLoading, setIsLoading] = useState(false);


    let { setRoles, setUserId, setProfileUrl, setEmployeeName, setEmployeeId, setReportsToId, setPlanID } = useContext(agovContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            AcquireToken();
        }
    }, [isAuthenticated]);

    /**
     * AG_PC_NA_13 - AG_PC_NA_44
     * Fetch the token from the acquireTokenSilent method and get the user Role details 
     * Set state the values obtained from BE
     */
    const AcquireToken = async () => {
        if (accounts.length > 0) {
            const silentRequest = {
                account: accounts[0],
                scopes: ["user.read"],
            };
            console.log(silentRequest, "silentRequest");
            encryptStorage.setItem("emailId", silentRequest?.account?.username);
            /**
            * AG_PC_NA_16 - AG_PC_NA_25
            * The registered user will be checked
            */
            setIsLoading(true)
            const checkUserDetails = await checkUserLogin({ emailId: silentRequest?.account?.username })
            // AG_PC_NA_30 -> Check whether the statusCode is not equal to 200 or responseData.userId is empty , then navigate to initial page called \azure. 
            if (checkUserDetails.statusCode != 200 || checkUserDetails.responseData.userId === '') {
                setIsLoading(false)
                navigate('/azure')
                return;
            }

            // AG_PC_NA_31 to AG_PC_NA_44 -> If the statusCode is not equals to 200, api call for check getEmployeeDetails and mandatoryDataCheck
            else{
            console.log(checkUserDetails.responseData.userId, "checkUserDetails.responseData.userId", checkUserDetails.responseData.planId, "checkUserDetails.responseData.planId");
            const getEmployeeData = await getEmployeeDetails({ userId: checkUserDetails.responseData.userId, planId: checkUserDetails.responseData.planId });
            if(getEmployeeData.message =="In Valid User"){
                navigate('/azure')
            }
            let responseData = getEmployeeData.responseData.length > 0 ? getEmployeeData.responseData : [];

            localStorage.setItem("token", responseData[0]?.jwtToken);
            encryptStorage.setItem("username", responseData[0]?.userName);
            encryptStorage.setItem("employeeId", responseData[0]?.employeeId);
            encryptStorage.setItem("userValue", responseData[0]?.userId);
            encryptStorage.setItem("roleValue", responseData[0]?.roleDetails);
            encryptStorage.setItem("planId", responseData[0]?.planId);

            setRoles(responseData[0]?.roleDetails);
            setUserId(responseData[0]?.userId);
            setProfileUrl(responseData[0]?.profileUrl);
            setEmployeeId(responseData[0]?.employeeId);
            setEmployeeName(responseData[0]?.userName);
            setReportsToId(responseData[0]?.reportsToId);
            setPlanID(responseData[0]?.planId)

            let roleDetails = responseData[0].roleDetails;
            
                const roleCheck = await mandatoryDataCheck()
                setIsLoading(false)
                console.log(roleCheck, "roleCheck");
                if (roleCheck.responseData === "Data Not Filled" &&  roleDetails.includes('Super Admin')) {
                    navigate('/adminmanagement/securityConfig')
                }
                else {
                    roleDetails.includes('Super Admin') || roleDetails.includes('Manager') || roleDetails.includes('User') || roleDetails.includes('LMS Admin') || roleDetails.includes('GRC Admin') || roleDetails.includes('SOC Admin')? navigate('/dashboard') : roleDetails.includes('Auditor') ? navigate('/compliance') : navigate('/taskmanagement')
                }
        
            /**
            * PC_AL_06 - PC_AL_08
            * mandatory Data Check
            */
           
           
        }
        }
    }

    return (
        <>
            <Loader isLoading={isLoading} />
        </>
    );
};