import React, { useState, useEffect ,useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { agovContext } from '../../Constant';

import NoRecordsPage from './NoRecordsPage';
import { Loader } from '../Loader/Loader';
import { getUpcomingCampaignApiData } from '../../Services/LMS/LmsService';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NoRecordFound from '../Dashboard/dashboardNoRecordFound';

const UpComingCampaigns = () => {
  // PC_upComingCam_05 state variable of upComing Campaign

  const [upComingCampaign, setUpComingCampaign] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { userId, roles } = useContext(agovContext);

  useEffect(() => {
    getUpcomingCampaign();
  }, []);

  const getUpcomingCampaign = async () => {
    setIsLoading(true)
    const payload = {
      userId: userId, // set your employee id here
    };

    try {
      // PC_07
      const response = await getUpcomingCampaignApiData(payload);
      // PC_26, PC_27, PC_28
      if (response && response.statusCode === 200) {
        setUpComingCampaign(response.responseData); // PC_27
      } else {
        // Show error message to the user
        console.error('Error fetching upComingCampaign data: ', response.statusCode);
      }
    } catch (error) {
      // Error handling logic here
      console.error('Error fetching upComingCampaign data:', error);
    }finally {
      setIsLoading(false)}
  };

  // PC_upComingCam_30 a onclick function to navigate to campaign page
  const onNavigate = (status) => {
    navigate('/lmsmanagement/campaign', {
      state: {
        value: {
          status: status,
        },
      },
    });
  };

  // properties for the sliderPackage
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: upComingCampaign.length > 2 ? 1 : upComingCampaign.length,
    arrows: upComingCampaign.length > 2 ? true : false,
    draggable: false,
  };

  return (
    <>
    <Loader isLoading={isLoading} />
  
    <div className="d-flex justify-content-between align-items-center">
      <h3 className="font-14 font-medium primary-textcolor mb-0 mt-2">Upcoming Campaigns</h3>
      {upComingCampaign.length > 0 ? (
        <div className="d-flex align-items-center cursor-pointer" onClick={() => onNavigate('Upcoming')}>
          <a className="font-12 secondary-textcolor font-medium text-decoration-none">
            View All <img src="images/right-arw-blue.svg" alt="right-arrow" />
          </a>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          {/* <span className="font-12 secondary-textcolor font-medium">
            View All <img src="images/right-arw-blue.svg" alt="right-arrow" />
          </span> */}
        </div>
      )}
    </div>
    {upComingCampaign.length === 0 ? (
      <NoRecordFound data={"No UpComing Campaigns"} />
    ) : (
      <Slider {...settings} className="custom-slick mt-5">
        {upComingCampaign.map((campaign) => (
          <div key={campaign.campaignId} className="custom-slide h-100">
            <div className="card border-0 h-100">
              <span className="card-bg-image d-flex mb-5">
                <img
                  src={campaign.pictures}
                  alt="campaign"
                  className="card-image-cover w-100"
                />
              </span>
              <a className="card-body text-decoration-none learning-card-pos p-0 w-100">
                <div className="mx-2 learning-card p-4">
                  <h5 className="font-14 font-medium primary-textcolor">
                    {campaign.campaignName}
                  </h5>
                  <p className="font-12 font-regular mb-0 grey-light mb-2 linehig">
                    {campaign.campaign_description}
                  </p>
                  <div className="mt-auto d-flex">
                    <span className="d-flex align-items-center">
                      <span>
                        <img
                          className="d-inline"
                          src="images/clock-icon.svg"
                          alt="clock-icon"
                        />
                        <span className="ms-2 grey-primary font-12 font-regular">
                          {`${Math.floor(parseInt(campaign.duration.split(':')[1]))} mins`}
                        </span>
                      </span>
                      <span className="ms-3">
                        <img
                          className="d-inline"
                          src="images/calender-icon.svg"
                          alt="calender-icon"
                        />
                        <span className="ms-2 grey-primary font-12 font-regular">
                          {new Intl.DateTimeFormat('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                          }).format(new Date(campaign.startDate))}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        ))}
      </Slider>
    )}
  </>
  

  );
};

export default UpComingCampaigns;
