import React, { useEffect } from 'react';

const ToastMessageComponent = ({toastResponse, setIsShowToastMessage }) => {

    useEffect(() => {
        setTimeout(() => {
            setIsShowToastMessage(false);
        }, 3000);
    })

    return (
        <>
            <div className="toast-container">
                <div className={`${toastResponse.color} toast show`} id="taskToast" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="toast-body d-flex">
                        <img src={toastResponse.path} className="me-3" alt="error-toast-icon" />
                        <div>
                            <h6 className="font-medium font-14 primary-textcolor me-2">{toastResponse.header}</h6>
                            <p className="font-12 font-regular mb-0 primary-textcolor">{toastResponse.message}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ToastMessageComponent;
