import { client } from "../../Client/Client";
import { backendUrl as URL ,governanceService } from "../../Constant"


// Dashboard Add On 
// PC_08 - 13 configuring the api and hitting the api call by axios
export const getTileData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getTileData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
} 

// PS_TD_09 - 14 configuring the api and hitting the api call by axios
export const getTaskData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getTaskData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
// Closing task Summamry Dashbaord 

//opening My learnings

export const getCampaignData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getCampaignsData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
} 

// closing my learnings

// Opening Learning Summary

export const getLMSData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getLMSData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
} 



export const getUsersNonCompliantDetails = async (data) => {
  let config = {
    method: "POST",
    url: URL + governanceService.getUsersNonCompliantDetails,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

export const getUsersAccessDetails = async (data) => {
  let config = {
    method: "POST",
    url: URL + governanceService.getUsersAccessDetails,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

export const getRiskCount = async (data) => {
  let config = {
    method: "POST",
    url: URL + governanceService.getRiskCount,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

export const getGovernanceUser = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getGovernanceUser,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const showRiskTag = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.showRiskTag,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;

}
export const closeRiskTag = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.closeRiskTag,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;

}

export const getExportToExcelData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getExportToExcelData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAccessNotification = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getAccessNotification,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}

//application Grid 
export const getAppData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getAppData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}
//Accordian Data 
export const getAccordianData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getAccordionData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}
export const getFilterAppData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getFilterAppData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}
export const getExportToExcelApp = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getExportToExcelApp,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}
export const getApprovalStatusData = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getApprovalStatusData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}
export const revokeApplicationAccess = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.revokeAccess,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}
export const approveAccess = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.approveAccess,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}


export const getUserProfile = async (data) => {


    let config = {
        method: 'POST',
        url: URL + governanceService.getUserProfile,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}


export const getActivityLogs = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getActivityLogs,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}


export const getOrgDetails = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.getOrgDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}


export const notificationClick = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.notificationClick,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}


export const generateSasToken = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.generateSasToken,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}


export const uploadProfile = async (data) => {

    let config = {
        method: 'POST',
        url: URL + governanceService.uploadProfile,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    let response = await client(config);
    return response;
}

