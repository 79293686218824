const NoRecordFound = ({data, roles, activeTab}) => {
    const height =
    roles === 'superAdmin' && (activeTab === 'myTask' || activeTab === 'allTask')
      ? '250px'
      : roles === 'manager'
      ? '325px'
      : roles === 'superAdmin' && (activeTab === 'overdue' || activeTab === 'risk')
      ? '300px'
      : '300px';

    return (
        <>
            <div className="d-flex align-items-center justify-content-center flex-column no-rec-found" style={{height}}>
                <img src="/images/no-rec-found.png" width="150px" height={166} />
                <p className="font-14 font-medium no-rec-description mt-3">{data}</p>
            </div>

        </>
    )
}
export default NoRecordFound;