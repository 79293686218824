import { client } from "../../Client/Client";
import { backendUrl as URL, registrationService } from "../../Constant";

//PS_REG_FE_1.11 to 1.19 Function to fecth the data for head count drop down 
export const getHeadCountOptions = async () => {
    const config = {
      url: URL + registrationService.getHeadCountOptions,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {}
    };
    let response = await client(config);
    console.log("response in registration service",response);
    return response;
  }
  
  //PS_REG_FE_1.27 to 1.36 function to isert the register details
  export const postRegisterDetails = async (data) => {
    const config = {
      url: URL + registrationService.postRegisterDetails,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data:data
    };
    let response = await client(config)
    return response;
  }

  //PS_OV_FE_1.8 to 1.22 function to verify the OTP 
  export const verifyAccount =async (data) =>{
    const config = {
      url: URL + registrationService.verifyAccount,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data:data
    };
    let response = await client(config)
    return response;
  }

  //PS_SB_FE_1.18 to 1.26 Function to get the scription details based on head count.
  export const getSubscriptionDetails = async (data) => {
    const config = {
      url: URL + registrationService.getSubscriptionDetails,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data:data
    };
    let response = await client(config)
    return response;
  }

  //PS_SB_FE_1.40 to 1.48 Function to create the session id for stripe payment page 
  export const getSessionIdForStripe= async (data) => {
    const config = {
      url: URL + registrationService.getSessionIdForStripe,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data:data
    };
    let response = await client(config)
    return response;
  }

  //PS_SB_FE_1.58 to 1.66
  export const generatePaymentId = async (data) => {
    const config = {
      url: URL + registrationService.generatePaymentId,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data:data
    };
    let response = await client(config)
    return response;
  }
   
  //PS_SB_FE_1.58 to 1.66
  export const regenerateOTP = async (data) => {
    const config = {
      url: URL + registrationService.regenerateOTP,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data:data
    };
    let response = await client(config)
    return response;
  }
  

  export const createDynamicDb = async (data) =>{
    const config = {
      url: URL + registrationService.createDynamicDb,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data:data
    };
    let response = await client(config)
    return response;
 
  }