import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import './App.css';
import AgovDashboard from './Components/Dashboard/AgovDashboard'
// import from './Components/Login/login';
// import Newlogin from './Components/Login/newLogin';
// import NewLoginAuth from './Components/Login/newLoginAuth';

// import Dashboard from './Components/Dashboard/Dashboard';
import MyLearningsGrid from './Components/LMS/MyLearningsGridView';
import CourseGridView from './Components/LMS/CourseGridView';
import CreateCoursePopUp from './Components/LMS/CreateCoursePopUp';
import MediaLibraryGridView from './Components/LMS/MediaLibrary';
import NotFound from './Components/NotFound/404Page';
import { getUserRoles } from './Services/Admin/AdminService';
import { agovContext } from './Constant';
import TaskGrid from './Components/Task/TaskGrid';
import HeaderComponent from './Components/HeaderComponent/HeaderComponent';
import ControlsComponent from './Components/Compliance/ControlsComponent';
import AuditCycleGrid from './Components/Compliance/AuditCycleGrid';
import AccessGovernance from './Components/Agov/accessGovernance';
import { AdminInformation } from './Components/Admin/AdminInformation';
import AdminPermissionGrid from './Components/Admin/AdminPermissionGrid';
import { SecurityIpConfig } from './Components/Admin/SecurityIpConfig';
import { PolicyConfigForm } from './Components/Admin/PolicyConifguration';
import VendorGridComponent from './Components/Compliance/VendorGridComponent'
import RiskOverview from './Components/Risk/riskOverview';
import AllUsersInfo from './Components/Risk/riskAllUsers';
import CampaignGrid from './Components/LMS/CampaignGrid';
import AllDevicesInfo from './Components/Risk/riskAllDevices';
import AllApplicationInfo from './Components/Risk/riskAllApplications';
import EvidenceGrid from './Components/Compliance/EvidenceGrid'
import { VendorFormComponent } from './Components/Compliance/VendorFormComponent';
import CreateMediaForm from './Components/LMS/CreateMediaForm';
import Activitylogs from './Components/ActivityLogs/activityLogs';
import applicationGrid from './Components/Agov/applicationGrid';
import ApplicationGrid from './Components/Agov/applicationGrid';
import { QuestionariesForm } from './Components/Compliance/QuestionariesComponent';
import EvidenceDeatils from './Components/Compliance/EvidenceDetails'
import { VendorQuestionary } from './Components/Compliance/VendorQuestionaryComponent';
import PolicyGrid from './Components/Compliance/PolicyGrid';
import ViewMediaComponent from './Components/LMS/ViewMediaComponent';
import Newlogin from './Components/Login/newLogin';
import NewLoginAuth from './Components/Login/newLoginAuth';
import SystemDescription from './Components/Compliance/SystemDescription';
import PolicyDetails from './Components/Compliance/PolicyDetails';
import OtherDocumentsGrid from './Components/Compliance/OtherDocumetsGrid';
import OtherDocumentsDetails from './Components/Compliance/OtherDocumentsDetails';
import { QuestionaryHistory } from './Components/Compliance/QuestionaryHistory';
import EventLogs from './Components/Risk/eventLogs';
import { UserProfile } from './Components/userProfile/userProfile';
// import { SecurityConnector } from './Components/Admin/SecurityConnector';
import { AccessReview } from './Components/Admin/AccessReview';
import { AppOwnerConfig } from './Components/Admin/AppOwnerConfig';
import { Billing } from './Components/Admin/Billing';
import Config from './Components/Connectors/Config';
import { validateJWT } from './Services/Login/LoginService';
import ErrorPage from './Components/NotFound/ErrorPage';
import Dashboard from './Components/Dashboard/Dashboard';
import RegisterPage from './Components/Registration/Registration';
import SubscriptionPage from './Components/Registration/Subscription';
import DashboardOverview from './Components/LMS/DashboardOverview';
import InitialGuide from './Components/Registration/InitialGuide';
import InitialConnector from './Components/Registration/InitialConnector';
import IntitialPermission from './Components/Registration/InitialPermission';
import InitialSecurity from './Components/Registration/InitialSecurity';
import InitialAgconfig from './Components/Registration/InitialAgconfig';
import { ProfileProvider } from './ProfileContext';

function App() {

  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [reportsToId, setReportsToId] = useState("")
  const [profileUrl, setProfileUrl] = useState("");
  const [roles, setRoles] = useState([]);
  const [userId, setUserId] = useState();
  const [planID, setPlanID] = useState('')

  useEffect(() => {
    isLogin()
  }, []);

  let tokenJWT = localStorage.getItem('token');
  const isLogin = async () => {
    if (tokenJWT && window.location.pathname !== '/azure' && window.location.pathname !== '/loginAuth'
      && window.location.pathname !== '/InitialGuide' && window.location.pathname !== '/InitialConnector'
      && window.location.pathname !== '/InitialPermission' && window.location.pathname !== '/InitialSecurity'
      && window.location.pathname !== '/InitialAgconfig' && window.location.pathname !== '/subscription'
      && window.location.pathname !== '/register'
    ) {

      let isValidateResponse = await validateJWT({ token: tokenJWT });

      console.log(isValidateResponse, "isValidateResponse");

      if (isValidateResponse.status) {

        // console.log(!areArraysSame(roles, isValidateResponse.responseData[0].roleDetails), "arrayCheck>>>");

        // if (!areArraysSame(roles, isValidateResponse.responseData[0].roleDetails)) {
        setRoles(isValidateResponse.responseData[0].roleDetails)
        // }
        setProfileUrl(isValidateResponse.responseData[0].profileUrl)
        setUserId(isValidateResponse?.responseData[0]?.userId);
        setEmployeeId(isValidateResponse?.responseData[0]?.employeeId);
        setEmployeeName(isValidateResponse?.responseData[0]?.userName);
        setReportsToId(isValidateResponse?.responseData[0]?.reportsToId);
        setPlanID(isValidateResponse?.responseData[0]?.planId)
      }
      else {
        console.log('into the else ')
        localStorage.removeItem('token')
        window.location.href = '/azure'
      }
    }
    else if (window.location.pathname !== '/azure'
      && window.location.pathname !== '/loginAuth'
      && window.location.pathname !== '/' && window.location.pathname !== '/InitialGuide' && window.location.pathname !== '/InitialConnector'
      && window.location.pathname !== '/InitialPermission' && window.location.pathname !== '/InitialSecurity'
      && window.location.pathname !== '/InitialAgconfig' && window.location.pathname !== '/subscription'
      && window.location.pathname !== '/register') {
      localStorage.removeItem('token')
      window.location.href = '/azure'
    }
    else if (window.location.pathname !== '/accessGovernance') {
      localStorage.removeItem('agTabValue');
    }
  };

  useEffect(() => {

  }, [roles, employeeName])



  const hasRole = (rolesToCheck) => {
    for (const role of rolesToCheck) {
      if (roles.includes(role)) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <agovContext.Provider
        value={{
          employeeId,
          employeeName,
          roles,
          userId,
          reportsToId,
          profileUrl,
          planID,
          setRoles,
          setUserId,
          setPlanID,
          setEmployeeId,
          setEmployeeName,
          setReportsToId,
          setProfileUrl,
        }}
      >
        <ProfileProvider>
        <Router>
          <React.Fragment>

            {window.location.pathname !== "/" &&
              window.location.pathname !== "/azure" &&
              window.location.pathname !== "/config" &&
              window.location.pathname !== "/error" &&
              window.location.pathname !== "/InitialGuide" &&
              window.location.pathname !== "/InitialConnector" &&
              window.location.pathname !== "/InitialPermission" &&
              window.location.pathname !== "/InitialSecurity" &&
              window.location.pathname !== "/InitialAgconfig" ? (<HeaderComponent />) : null}
            <Routes>
              <Route path="/subscription" element={<SubscriptionPage />}></Route>
              <Route path="/" element={<RegisterPage />}></Route>
              <Route path="/register" element={<Config />}></Route>
              <Route path="/azure" element={<Newlogin />}></Route>
              <Route path="/loginAuth" element={<NewLoginAuth />}></Route>
              <Route path="/error" element={<ErrorPage />} />

              <Route path="/InitialGuide" element={<InitialGuide />} />
              <Route path="/InitialConnector" element={<InitialConnector />} />
              <Route path="/InitialPermission" element={<IntitialPermission />} />
              <Route path="/InitialSecurity" element={<InitialSecurity />} />
              <Route path="/InitialAgconfig" element={<InitialAgconfig />} />

            </Routes>
            {/* Header Component path starts here  */}
            <Routes>
              {hasRole(["Super Admin"]) && (
                <>
                  <Route path='adminmanagement'>
                    <Route path='' element={<AdminInformation />} />
                    <Route path='adminpermission' element={<AdminPermissionGrid />} />
                    <Route path='accessReview' element={<AccessReview />} />
                    <Route path='appOwnerConfig' element={<AppOwnerConfig />} />
                    <Route path='policyConfig' element={<PolicyConfigForm />} />
                    <Route path='securityConfig' element={<SecurityIpConfig />} />
                    <Route path='billing' element={<Billing />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </>
              )}
            </Routes>

            {/* LMS routes starts here */}

             <Routes>
              {
                hasRole(['Super Admin', 'SOC Admin', 'GRC Admin', 'LMS Admin', 'User','Manager']) && (
                <>
                  <Route path='lmsmanagement'>
                    {hasRole(['Super Admin', 'LMS Admin']) ? <Route path='' element={<DashboardOverview />} /> : null}
                    <Route path='myLearnings' element={<MyLearningsGrid />} />
                    <Route path='course' element={<CourseGridView />} />
                    <Route path='campaign' element={<CampaignGrid />} />
                    <Route path='createmedia' element={<CreateMediaForm />} />
                    <Route path='mediaLibrary' element={<MediaLibraryGridView />} />
                    <Route path='viewmedia' element={<ViewMediaComponent />} />
                  </Route>
                </>
              )}
            </Routes> 
            {/* LMS routes ends here */}
            <Routes>
              {
                hasRole(['Super Admin', 'GRC Admin']) ? (
                  <>
                    <Route path='compliance'>
                      <Route path='' element={<Dashboard />} />
                      <Route path='auditCycleGrid' element={<AuditCycleGrid />} />
                      <Route path='controls' element={<ControlsComponent />} />
                      <Route path='evidenceGrid' element={<EvidenceGrid />} />
                      <Route path='policyGrid' element={<PolicyGrid />} />
                      <Route path='policyDetails' element={<PolicyDetails />} />
                      <Route path='otherDocumentsGrid' element={<OtherDocumentsGrid />} />
                      <Route path='otherDocumentsDetails' element={<OtherDocumentsDetails />} />
                      <Route path='evidenceDetails' element={<EvidenceDeatils />} />
                      <Route path='SystemDescription' element={<SystemDescription />} />

                      <Route path='vendor' element={<VendorGridComponent />} />
                      <Route path='vendorForm' element={<VendorFormComponent />} />
                      <Route path='vendorQuestions' element={<VendorQuestionary />} />
                      <Route path='questionaries' element={<QuestionariesForm />} />
                      <Route path='questionaryhistory' element={<QuestionaryHistory />} />
                      <Route path="*" element={<NotFound />} />
                    </Route>
                  </>
                ) : 
                hasRole(['Auditor']) && (
                  <>
                      <Route path='compliance'>
                      <Route path='' element={<AuditCycleGrid />} />
                      <Route path='controls' element={<ControlsComponent />} />
                      <Route path='evidenceGrid' element={<EvidenceGrid />} />
                      <Route path='policyGrid' element={<PolicyGrid />} />
                      <Route path='policyDetails' element={<PolicyDetails />} />
                      <Route path='otherDocumentsGrid' element={<OtherDocumentsGrid />} />
                      <Route path='otherDocumentsDetails' element={<OtherDocumentsDetails />} />
                      <Route path='evidenceDetails' element={<EvidenceDeatils />} />
                      <Route path='SystemDescription' element={<SystemDescription />} />

                      <Route path='vendor' element={<VendorGridComponent />} />
                      <Route path='vendorForm' element={<VendorFormComponent />} />
                      <Route path='vendorQuestions' element={<VendorQuestionary />} />
                      <Route path='questionaries' element={<QuestionariesForm />} />
                      <Route path='questionaryhistory' element={<QuestionaryHistory />} />
                      <Route path="*" element={<NotFound />} />
                    </Route>
                  </>
                ) 
              }
            </Routes>
            {/* Access Governance Component Part starts  here */}
            <Routes>
              {hasRole([
                "Super Admin",
                "App Admin",
                "Manager",
                "SOC Admin",
              ]) && (
                  <Route path="accessGovernance">
                    <Route path="" element={<AccessGovernance />} />
                    <Route path="appGrid" element={<ApplicationGrid />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                )}
            </Routes>
            {/* Access Governance Component Part Ends  here */}

            {/* Activity Logs Component Part starts here  */}
            <Routes>
              {hasRole([
                "Super Admin",
                "Manager",
                "SOC Admin",
                "GRC Admin",
                "LMS Admin",
              ]) && (
                  <Route path="activitylogs">
                    <Route path="" element={<Activitylogs />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                )}
            </Routes>
            {/* Activity Logs Component Part Ends here  */}

            {/* {UserProfile component starts here} */}
            <Routes>
              <Route path="userprofile">
                <Route path="" element={<UserProfile />} />
              </Route>
            </Routes>
            {/* {UserProfile component ends here} */}

            <Routes>
              {hasRole(['Super Admin', 'SOC Admin']) && (
                <Route path="riskManagement">
                  <Route path='' element={<RiskOverview />} />
                  <Route path="allusers" element={<AllUsersInfo />} />
                  <Route path="alldevices" element={<AllDevicesInfo />} />
                  <Route path="allapplications" element={<AllApplicationInfo />} />
                  <Route path="eventLogs" element={<EventLogs />} />

                </Route>
              )
              }

            </Routes>
            {/* Risk Component Part Ends here  */}

            {/*  Auditor Component Part Starts here  */}
            <Routes>
              {hasRole(['Super Admin', 'SOC Admin', 'GRC Admin', 'LMS Admin', 'User','Manager']) && (
                <>
                  <Route path="taskmanagement">
                    <Route path="" element={<TaskGrid />} />
                    {/* <Route path='createtask' element={<TaskForm />} /> */}
                  </Route>
                </>
              )}
            </Routes>

            <Routes>
              {
                hasRole(['Super Admin', 'SOC Admin', 'GRC Admin', 'LMS Admin', 'User','Manager']) && (
                    <>
                    <Route path='Dashboard'>
                      <Route path='' element={<AgovDashboard />} />
                    </Route>
                  </>
                )}
            </Routes>
            {/* GRC Auditor Component Part Ends here  */}
          </React.Fragment>
        </Router>
        </ProfileProvider>
      </agovContext.Provider>
    </>
  );
}

export default App;