import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';
import { getConnectors } from '../../Services/Login/LoginService';

export default function Config() {

    const navigate = useNavigate()
    const [showToast, setShowToast] = useState(false);
    const [toastResponse, setToastResponse] = useState({});

    const initialFormData = {
        EmailId: '',
        TenantId: '',
        ClientID: '',
        ClientSecret: '',
        MicrosoftDomain: '',
        CloudApp: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const validateForm = () => {
        const newErrors = {};
        for (const key in formData) {
            if (!formData[key].trim()) {
                newErrors[key] = `${key} field is required`;

                console.log(key, "newErrors[key]");
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        // Proceed with API call
        const response = await getConnectors({ emailId: formData.EmailId, tenantId: formData.TenantId, clientId: formData.ClientID, clientSecret: formData.ClientSecret, cloudAppDomain: formData.MicrosoftDomain, cloudAppToken: formData.CloudApp });

        if (response.statusCode === 200) {
            console.log("Valid Credentials")
            setToastResponse({
                path: "/images/Success-icon.svg",
                header: "Login Success",
                message: "Successs",
                color: "success-toast"
            });
            setShowToast(true);
            navigate('/azure')
        } else {
            setToastResponse({
                path: "/images/error-toast-icon.svg",
                header: "Invalid Credentials",
                message: "Please give the Valid Crendentials",
                color: "error-toast"
            });
            setShowToast(true)
            // alert("Invalid Tenant Credentials")
        }
    };

    return (
        <>
            <div className="row">
                {
                    showToast ? <ToastMessageComponent isToastMessageVisible={showToast} toastResponse={toastResponse} setIsShowToastMessage={setShowToast} /> : <></>
                }

                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row justify-content-center">
                        <div className="col-md-12 px-4 pt-4">
                            <span>
                                <img src="/images/agov-logo.svg" alt="agov-logo-login" className="login-logo-sty mb-3" />
                            </span>
                        </div>
                        <div className="col-md-11 col-sm-12 pt-0 pb-0 login-scroll">
                            <div className="login-content-height w-100 d-flex">
                                <div className="form-signup my-auto w-100">
                                    <h5 className="mb-4 font-24 primary-textcolor font-medium d-flex align-items-center">
                                        Sign Up
                                    </h5>
                                    <p className="mt-4 mb-3  font-16 primary-textcolor font-regular">
                                        Securely access A-GOV to manage permissions, roles, and compliance effortlessly. Protect your data with A-GOV's trusted sign-in, ensuring robust security at every login.
                                    </p>
                                    <div className="col-md-12 my-3">
                                        <label htmlFor="" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Email ID
                                        </label>
                                        <input type="email" className="form-control font-regular font-14 custom-form-control py-2" id="EmailId" placeholder="Enter Email ID"
                                            onChange={(e) => {
                                                handleChange(e)
                                            }} value={formData.EmailId} />
                                        {errors.EmailId && <div className="text-danger">{errors.EmailId}</div>}
                                    </div>
                                    <div className="col-md-12 my-3">
                                        <label htmlFor="TenantId" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Tenant ID
                                        </label>
                                        <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="TenantId" placeholder="Enter Tenant ID"
                                            onChange={(e) => {
                                                handleChange(e)
                                            }} value={formData.TenantId} />
                                        {errors.TenantId && <div className="text-danger">{errors.TenantId}</div>}
                                    </div>
                                    <div className="col-md-12 my-3">
                                        <label htmlFor="ClientID" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Client ID
                                        </label>
                                        <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="ClientID" placeholder="Enter Client ID"
                                            onChange={(e) => {
                                                handleChange(e)
                                            }} value={formData.ClientID} />
                                        {errors.ClientID && <div className="text-danger">{errors.ClientID}</div>}
                                    </div>
                                    <div className="col-md-12 my-3">
                                        <label htmlFor="ClientSecret" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Client Secret
                                        </label>
                                        <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="ClientSecret" placeholder="Enter Client Secret"
                                            onChange={(e) => {
                                                handleChange(e)
                                            }} value={formData.ClientSecret} />
                                        {errors.ClientSecret && <div className="text-danger">{errors.ClientSecret}</div>}
                                    </div>
                                    <div className="mt-3">
                                        <h5 className="modal-title font-bold my-4" id="exampleModalLabel">
                                            Microsoft CloudApps Connection</h5>
                                    </div>
                                    <div className="col-md-12 my-3">
                                        <label htmlFor="MicrosoftDomain" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Microsoft Domain
                                        </label>
                                        <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="MicrosoftDomain" placeholder="Enter Microsoft Domain"
                                            onChange={(e) => {
                                                handleChange(e)
                                            }} value={formData.MicrosoftDomain} />
                                        {errors.MicrosoftDomain && <div className="text-danger">{errors.MicrosoftDomain}</div>}
                                    </div>
                                    <div className="col-md-12 my-3">
                                        <label htmlFor="CloudApp" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Microsoft CloudApps Token
                                        </label>
                                        <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="CloudApp" placeholder="Example: https://<domain name>us2.portal.cloudappsecurity.com"
                                            onChange={(e) => {
                                                handleChange(e)
                                            }} value={formData.CloudApp} />
                                        {errors.CloudApp && <div className="text-danger">{errors.CloudApp}</div>}
                                    </div>
                                    <div className="d-flex justify-content-end mt-5 mb-5">
                                        <button type="button" className="dark-btn w-50  mb-3 mb-md-0 font-14 font-medium px-4" onClick={() => handleSubmit()}>
                                            <span><img src="images/lg-microsoft-icon.svg" alt="lg-microsoft-icon" className="me-2" /></span> Sign up with Microsoft
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed-bottom p-0">
                        <div className="text-center w-50 footer-bg p-2">
                            <span className=" font-regular font-16 mb-0 ">
                                For issues or concerns kindly reach <a style={{ cursor: "pointer" }} href="mailto:Agov@support.com" className="text-decoration-none font-medium ">Agov@support.com</a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className=" col-xl-6 d-xl-block d-none py-2 pe-2">
                    <div className="login-bg-height position-relative my-0">
                        <div className="login-bg-height login-bg-img" />
                    </div>
                </div>
            </div>
        </>
    );
}
