import React, { useContext, useEffect, useState } from "react";
import { agovContext } from "../../../Constant";
import { getTileData } from "../../../Services/Agov/governanceService";
import { Loader } from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";
const UserTileData = (TileData) => {

let navigate = useNavigate()    
//   tileData state variables starts
// PS_Tile_0.5 set stateing all the state variables.
  const [totalLMSData, setTotalLMSData] = useState(0)
  const [dueCount, setDueCount] = useState(0)
  const [overDueCount, setOverDueCount] = useState(0)
  const [appAccess, setAppAccess] = useState(0)
  const [approvedAppCount, setApprovedAppCount] = useState(0)
  const [pendingAppCount, setPendingAppCount] = useState(0)
  const [totalOtherTask, setTotalOtherTask] = useState(0)
  const [completedTaskCount, setCompletedTaskCount] = useState(0)
  const [pendingTaskCount, setPendingTaskCount] = useState(0)
  const [isLoading,setIsLoading] = useState(false)

//   tileData state variables ends

//PS_Tile_07 
// TO get the required user information from localstorage using useContext
let { employeeName, userId, managerId, roles, employeeId, employeeValue} = useContext(agovContext);
  //PS_Tile_07-16
  //function onIntialPageLoad triggres and make api call to fetch the data\
//and set states the response in state variables and bind them in HTML.
useEffect(()=>{
    onInitialPageLoad()
},[])


async function onInitialPageLoad(){
        //   Tile Data fetching starts
        let payload = {
            userId: userId,
            userRole: roles,
            managerId: managerId,
            employeeId: employeeId
          }
          setIsLoading(true)
          const tileResponse = await getTileData(payload)
      
          if(tileResponse && tileResponse.statusCode === 200)
          {
              if(tileResponse.responseData === null || tileResponse.responseData === undefined)
                  {
                      setTotalLMSData(0)
                      setDueCount(0)
                      setOverDueCount(0)
                      setAppAccess(0)
                      setApprovedAppCount(0)
                      setPendingAppCount(0)
                      setTotalOtherTask(0)
                      setCompletedTaskCount(0)
                      setPendingTaskCount(0)
                  }
                  else{
                      setTotalLMSData(tileResponse.responseData.LmsTask.totalTaskCount)
                      setDueCount(tileResponse.responseData.LmsTask.due)
                      setOverDueCount(tileResponse.responseData.LmsTask.overDue)
                      setAppAccess(tileResponse.responseData.applicationAccessTask.TotalApplication)
                      setApprovedAppCount(tileResponse.responseData.applicationAccessTask.Approved)
                      setPendingAppCount(tileResponse.responseData.applicationAccessTask.Pending)
                      setTotalOtherTask(tileResponse.responseData.otherTileTask.totalTaskCount)
                      setCompletedTaskCount(tileResponse.responseData.otherTileTask.completed)
                      setPendingTaskCount(tileResponse.responseData.otherTileTask.pending)
                  }
          }
          else
          {
              setTotalLMSData(0)
              setDueCount(0)
              setOverDueCount(0)
              setAppAccess(0)
              setApprovedAppCount(0)
              setPendingAppCount(0)
              setTotalOtherTask(0)
              setCompletedTaskCount(0)
              setPendingTaskCount(0)
              navigate('/error');
          }
          setIsLoading(false)
}



    return (
        <div className="row">
        <div className="col-md-12 col-sm-12 col-lg-12 mt-4">
        <Loader isLoading={isLoading} />
            <div className="row">
            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4 mb-md-3">
                <div className="bg-white p-3 font-medium rm-dashboard-card px-4 py-3">
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                    <img
                        src="images/training-task.svg"
                        className="img-size"
                        alt="Users Icon"
                    />
                    <h3 className="d-inline primary-textcolor font-14 ms-2 mb-0">
                        Training Task
                    </h3>
                    </div>
                    <div className="d-inline">
                    <span className="primary-textcolor font-32">{totalLMSData}</span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-inline me-3">
                    <span className="grey-light font-11">Due</span>
                    <span className="primary-textcolor font-13 ms-1">{dueCount}</span>
                    </div>
                    <div className="d-inline">
                    <span className="grey-light font-11">OverDue</span>
                    <span className="primary-textcolor font-13 ms-1">{overDueCount}</span>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4 mb-md-3">
                <div className="bg-white p-3 font-medium rm-dashboard-card px-4 py-3">
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                    <img
                        src="images/file-dashboard.svg"
                        className="img-size"
                        alt="Users Icon"
                    />
                    <h3 className="d-inline primary-textcolor font-14 ms-2 mb-0">
                        Application Access
                    </h3>
                    </div>
                    <div className="d-inline">
                    <span className="primary-textcolor font-32">{appAccess}</span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-inline me-3">
                    <span className="grey-light font-11">Approved</span>
                    <span className="primary-textcolor font-13 ms-1">{approvedAppCount}</span>
                    </div>
                    <div className="d-inline">
                    <span className="grey-light font-11">Pending</span>
                    <span className="primary-textcolor font-13 ms-1">{pendingAppCount}</span>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-4 mb-md-3 ">
                <div className="bg-white p-3 font-medium rm-dashboard-card px-4 py-3 margin-tile1">
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                    <img
                        src="images/todo-list.svg"
                        className="img-size"
                        alt="Users Icon"
                    />
                    <h3 className="d-inline primary-textcolor font-14 ms-2 mb-0">
                        Other Task
                    </h3>
                    </div>
                    <div className="d-inline">
                    <span className="primary-textcolor font-32">{totalOtherTask}</span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-inline me-3">
                    <span className="grey-light font-11">Completed</span>
                    <span className="primary-textcolor font-13 ms-1">{completedTaskCount}</span>
                    </div>
                    <div className="d-inline">
                    <span className="grey-light font-11">Pending</span>
                    <span className="primary-textcolor font-13 ms-1">{pendingTaskCount}</span>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

        
    );
};


export default UserTileData;