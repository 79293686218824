import React, { useState } from 'react';
import Select from 'react-select';
const AddToCoursePopUp = (Props) => {
    const { courses, selectedVideoDuration, isVisible, onSave, onClose } = Props;

    const values = {
        selectedOptions: []
    };

    const [selectedValues, setSelectedValues] = useState(values);
    const [selectedCoursesDetails, setSelectedCoursesDetails] = useState([]);
    const [isSubmitButtonEnable, setIsSubmitButtonEnable] = useState(false);

    const handleSelectedCourse = async (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);

        setSelectedValues(prevState => ({
            ...prevState,
            ["selectedOptions"]: selectedValues
        })
        );

        setSelectedCoursesDetails(selectedOptions);

        setIsSubmitButtonEnable(selectedOptions.length > 0 ? true : false);
    }

    const getDurationById = (selectedOptionId) => {
        const selectedOption = courses.find(option => option.value === selectedOptionId);
        return selectedOption ? selectedOption.duration : null;
    }

    const addDurations = (duration1, duration2) => {
        // Split duration strings into hours, minutes, and seconds
        const [h1, m1, s1] = duration1.split(':').map(Number);
        const [h2, m2, s2] = duration2.split(':').map(Number);

        // Calculate total duration in seconds
        const totalSeconds = (h1 + h2) * 3600 + (m1 + m2) * 60 + (s1 + s2);

        // Convert total duration back to HH:MM:SS format
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        // Pad with zeros if necessary
        const pad = num => String(num).padStart(2, '0');

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    }
    const handleClickOnAddButton = async () => {
        const courseDurations = selectedValues.selectedOptions.map(courseId => {
            const courseDuration = getDurationById(courseId);

            return {
                courseId: courseId,
                duration: addDurations(courseDuration, selectedVideoDuration)
            };
        });

        onSave(courseDurations)
    }

    const handleClickCancelButton = async () => {
        setSelectedValues([]);
        setSelectedCoursesDetails([]);
        onClose();
    }

    return (
        <>
            <div className="modal" id="add-audit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" style={{ display: isVisible ? "block" : "none" }}>
                <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-bottom pb-3 px-4">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">Add to Course</h5>
                            <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClickCancelButton()}></button>
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="dropdown">
                                        <label className="form-label text-black font-14 font-medium">Select Course<span className="required">*</span>
                                        </label>
                                        <Select
                                            options={courses}
                                            isMulti
                                            onChange={(selectedOptions) => handleSelectedCourse(selectedOptions)}
                                            name="selectedOptions"
                                            value={selectedCoursesDetails}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 mb-3 px-4 pt-0 mt-0">
                            <button type="button" className="primary-btn font-14 font-medium py-2 me-2" data-bs-dismiss="modal" onClick={() => handleClickCancelButton()}>Cancel</button>
                            <button type="button" className={isSubmitButtonEnable ? "dark-btn" : "disabled-btn" + " font-14 font-medium py-2"}  onClick={isSubmitButtonEnable ? handleClickOnAddButton : null}
                            disabled={!isSubmitButtonEnable}>Add</button>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>
            {isVisible && <div class="modal-backdrop fade show"></div>}
        </>
    )
}
export default AddToCoursePopUp;
