import React, { useState, useEffect,useContext } from 'react';
import { getmultiInfoAPIdata, getExportExcelApidata } from '../../Services/LMS/LmsService';
import InfiniteScroll from 'react-infinite-scroll-component'; // Import InfiniteScroll if using react-infinite-scroll-component
import ExcelJS from 'exceljs'   ;
import * as FileSaver from 'file-saver';
import { agovContext } from '../../Constant';
import { Workbook } from 'exceljs';
import { Loader } from '../Loader/Loader';

const PopUpDashBoard = ({ Props, onClose })  => {
    const { userId, roles } = useContext(agovContext);
    const exportExcelPayload = {
        "userId": userId,
        "widget": ""
    }
    // PS_popUpDashboard_08 state variable  to PS_popUpDashboard_11


    const [widget, setWidget] = useState(exportExcelPayload)
    const [exportExcelData, setExportExcelData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    console.log('Props:', Props); // Check the entire Props object


    // const payload = {
    //     userId: 1,
    //     tab: Props,
    //     sortColumn: 'Username',
    //     sortOrder: 'ASC',
    //     limit: 5,
    // };

    const [userDetailsRequest, setUserDetailsRequest] = useState({
        userId: 1,
        tab: Props ,
        sortColumn: 'Username',
        sortOrder: 'ASC',
        riskSortColumn :'taskPriority',
        riskSortOrder:"DESC",
        limit: 6,
    });
    const [userDetails, setUserDetails] = useState([]);
    const [totalUserCount, setTotalUserCount] = useState(0);
    const [hasMoreLoad, setHasMoreLoad] = useState(false);


    //PS_popUpDashboard_12  useEffect to trigger 

    useEffect(() => {
        console.log("In the PopUpDashBoard component");
        fetchUserDetails();
    }, [userDetailsRequest, Props]);
    //PS_popUpDashboard_13 this function fetches the tab data 
    console.log(userDetailsRequest,"taa")    
    const fetchUserDetails = async () => {
        
        setIsLoading(true)
        try {
            const response = await getmultiInfoAPIdata(userDetailsRequest);
            if (response && response.statusCode === 200) {
                const data = response.responseData;
                // setUserDetails((prevDetails) => [...prevDetails, ...data]);
                setUserDetails(data)
                let totalCount; 
            switch (userDetailsRequest.tab) {
                case 'overDue':
                    totalCount = response.count.overdueCount;
                    break;
                case 'due':
                    totalCount = response.count.dueCount;
                    break;
                case 'risk':
                    totalCount = response.count.riskCount;
                    break;
                default:
                    totalCount = response.tabCount;
                    break;
            }

              setTotalUserCount(totalCount);
              
                setHasMoreLoad(totalCount > userDetailsRequest.limit ? true : false);

            } else {
                console.error('Error fetching data:', response.statusCode);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false)
        }
    };
    console.log(userDetailsRequest,"laa")    //PS_popUpDashboard_44 this function  gets the data for  risk excel 
    console.log(userDetailsRequest.limit,"limit")
    console.log(hasMoreLoad,"hasmoreload")    //PS_popUpDashboard_44 this function  gets the data for  risk excel 
    console.log(totalUserCount,"total")    //PS_popUpDashboard_44 this function  gets the data for  risk excel 
    
    //PS_popUpDashboard_44 this function  gets the data for  risk excel 
 
    const getExportExcelData = async () => {
        setIsLoading(true)

        
        try {

            const response = await getExportExcelApidata(widget);
            if (response && response.statusCode === 200) {
                console.log('Response:', response);
                const data = response.responseData;
                setExportExcelData(data);
                handleExportToExcel(data);
            } else {
                console.error('Error:', response ? response.statusCode : 400, 'Error message');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false)
        }
    };
    const onWidgetExcel = async (event) => {

        const newWidget = event;

        console.log(newWidget, "Value from event excel");

        // Update widget state while preserving existing properties
        setWidget((prevWidget) => ({
            ...prevWidget,
            widget: newWidget,
        }));

        widget.widget = newWidget;
        console.log(widget, "widgetForn excel"); // This might log the old state

        await getExportExcelData(); // Only await if necessary
    };

 // PS_popUpDashboard_63 this function  handles the exportexcel scenario
 
    const handleExportToExcel = async (data) => {
        try {

            let excelData = data; // Ensure exportExcelData is correctly populated
            console.log("Export Data:", excelData);

            let header = [];
            let tabName = widget.widget; // Define as let to allow reassignment
            console.log(widget, "widget", tabName);


            header = ["riskUsers", "riskSeverity"];
            tabName = "risk";

            // Create a new Excel workbook
            let workbook = new ExcelJS.Workbook();
            let worksheet = workbook.addWorksheet('LMSReport');

            // Define header row
            worksheet.addRow(header);

            // Populate data rows
            excelData.forEach((dataItem, index) => {
                let rowData = [];
                // Iterate through headers to match the order and populate rowData accordingly
                header.forEach((column) => {
                    rowData.push(dataItem[column]); // Use dataItem[column] if excelData is an array of objects
                });

                worksheet.addRow(rowData);
            });

            // Set filename
            let filename = tabName + new Date().toISOString().slice(0, 10).replace(/-/g, "") + ".xlsx";
            let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            // Write workbook to buffer
            let buffer = await workbook.xlsx.writeBuffer();

            // Create Blob
            let dataBlob = new Blob([buffer], { type: fileType });

            // Save file using FileSaver.js
            FileSaver.saveAs(dataBlob, filename);
        } catch (error) {
            console.error("Error exporting to Excel:", error);
        }

    }
    //  //PS_popUpDashboard_34  this  function handles the sort 
    const handleColumnSort = (columnName) => {
        const newSortOrder = columnName === userDetailsRequest.sortColumn && userDetailsRequest.sortOrder === 'DESC' ? 'ASC' : 'DESC';
        setUserDetailsRequest({ ...userDetailsRequest, sortColumn: columnName, sortOrder: newSortOrder });
    };
    const fetchMoreData = () => {

        setUserDetailsRequest((prevState) => ({
            ...prevState,
            limit: prevState.limit + 5,
        }));
    };
    console.log("userDetails request:::",userDetailsRequest)


    const riskhandleColumnSort = (columnName) => {
        // Toggle sortOrder if sorting the same column, otherwise set to 'Asc'
        const newSortOrder = columnName === userDetailsRequest.riskSortColumn && userDetailsRequest.riskSortOrder === 'DESC' ? 'ASC' : 'DESC';
        setUserDetailsRequest({ ...userDetailsRequest, riskSortColumn: columnName, riskSortOrder: newSortOrder });
      };

    const renderContent = () => {
        switch (Props) {
            case 'due':


            return (
                <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
                    <div className="modal-dialog custom-popup-width modal-dialog-centered">
                        <div className="modal-content custom-popup-width">
                            <div className="modal-header d-flex justify-content-between align-items-center p-3 border-0">
                                <div className="d-flex align-items-center justify-content-center gap-3">
                                    <h5 className="modal-title font-14 font-medium primary-textcolor" id="staticBackdropLabel">
                                        Users based on・Due
                                    </h5>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
                                </div>
                            </div>
                            <div className="modal-body px-3 pb-4 pt-0">
                                <div className="table-responsive table-scroll" id="scrollableDiv">
                                    <InfiniteScroll
                                        dataLength={userDetails.length}
                                        next={fetchMoreData} // Corrected: pass the function reference
                                        hasMore={hasMoreLoad}
                                        scrollThreshold={0.9}
                                        scrollableTarget="scrollableDiv"
                                    >
                                        <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                                            <thead>
                                                <tr className="sticky-top">
                                                    <th className="font-12 font-medium px-4 position-relative">
                                                        Username
                                                        <span className="position-absolute" onClick={() => handleColumnSort('Username')}>
                                                            <a  className="sort-up position-absolute ms-2  cursor-pointer"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" /></a>
                                                            <a className="sort-down position-absolute ms-2  cursor-pointer"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" /></a>
                                                        </span>
                                                    </th>
                                                    <th className="font-12 font-medium position-relative">
                                                        Campaigns
                                                        <span className="position-absolute" onClick={() => handleColumnSort('campaigns')}>
                                                            <a className="sort-up position-absolute ms-2  cursor-pointer"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" /></a>
                                                            <a className="sort-down position-absolute ms-2  cursor-pointer"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" /></a>
                                                        </span>
                                                    </th>
                                                    <th className="font-12 font-medium position-relative text-end">
                                                        Days
                                                        <span className="position-absolute" onClick={() => handleColumnSort('campaign_duration')}>
                                                            <a className="sort-up position-absolute ms-2  cursor-pointer"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" /></a>
                                                            <a className="sort-down position-absolute ms-2 cursor-pointer"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" /></a>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userDetails.map(task => (
                                                    <tr className="font-12" key={task.id}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img src={task?.profileImages} className="table-prof" alt="Profile" />
                                                                <p className="font-12 font-regular primary-textcolor mb-0 ms-2">
                                                                    {task?.Username}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {task?.campaigns}
                                                            </span>
                                                        </td>
                                                        <td className="font-12 font-medium text-end">
                                                            <span>
                                                                {task?.dueDays}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </InfiniteScroll>
                                </div>
                                <div className="mt-3">
                                    <p className="font-12 grey-primary m-0">
                                        Showing <span className="font-medium primary-textcolor">{totalUserCount}</span> items
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
            case 'overDue':
    return (
        <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
            <div className="modal-dialog custom-popup-width modal-dialog-centered">
                <div className="modal-content custom-popup-width">
                    <div className="modal-header d-flex justify-content-between align-items-center p-3 border-0">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <h5 className="modal-title font-14 font-medium primary-textcolor" id="staticBackdropLabel">
                                Users based on・OverDue
                            </h5>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                            <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
                        </div>
                    </div>
                    <div className="modal-body px-3 pb-4 pt-0">
                        <div className="table-responsive table-scroll" id="scrollableDiv">
                            <InfiniteScroll
                                dataLength={userDetails.length}
                                next={fetchMoreData} // Corrected: pass the function reference
                                hasMore={hasMoreLoad}
                                scrollThreshold={0.9}
                                scrollableTarget="scrollableDiv"
                            >
                                <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                                    <thead>
                                        <tr className="sticky-top">
                                            <th className="font-12 font-medium px-4 position-relative">
                                                Username
                                                <span className="position-absolute" onClick={() => handleColumnSort('Username')}>
                                                    <a  className="sort-up position-absolute ms-2 cursor-pointer"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" /></a>
                                                    <a className="sort-down position-absolute ms-2 cursor-pointer"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" /></a>
                                                </span>
                                            </th>
                                            <th className="font-12 font-medium position-relative">
                                                Campaigns
                                                <span className="position-absolute" onClick={() => handleColumnSort('campaigns')}>
                                                    <a   className="sort-up position-absolute ms-2 cursor-pointer"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" /></a>
                                                    <a   className="sort-down position-absolute ms-2 cursor-pointer"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" /></a>
                                                </span>
                                            </th>
                                            <th className="font-12 font-medium position-relative text-end">
                                                Days
                                                <span className="position-absolute" onClick={() => handleColumnSort('campaign_duration')}>
                                                    <a   className="sort-up position-absolute ms-2 cursor-pointer"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" /></a>
                                                    <a   className="sort-down position-absolute ms-2 cursor-pointer"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" /></a>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userDetails.map(task => (
                                            <tr className="font-12" key={task.id}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <img src={task?.profileImages}  className="table-prof" alt="Profile" />
                                                        <p className="font-12 font-regular primary-textcolor mb-0 ms-2">
                                                            {task?.Username}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span>
                                                        {task?.campaigns}
                                                    </span>
                                                </td>
                                                <td className="font-12 font-medium text-end">
                                                    <span>
                                                        {task?.overdueDays}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        </div>
                        <div className="mt-3">
                            <p className="font-12 grey-primary m-0">
                                Showing <span className="font-medium primary-textcolor">{totalUserCount}</span> items
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

           case 'risk':
                return (
                    <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between align-items-center p-3 border-0">
                                    <div className="d-flex align-items-center justify-content-center gap-3">
                                        <h5 className="modal-title font-14 font-medium primary-textcolor" id="staticBackdropLabel">
                                            User Based on・Risk</h5>
                                    </div>
                                    <div className="d-flex flex-row align-items-center" >
                                        <button className="advanced-filter-button medium-text customflip-btn me-3 p-2" type="button"
                                            onClick={() => onWidgetExcel('risk')}>
                                            <img src="images/excel-icon.svg" alt="Filter-icon" />
                                        </button>
                                        <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
                                    </div>
                                </div>
                                <div className="modal-body px-3 pb-4 pt-0">
                                    <div className="table-responsive table-scroll" id="scrollableDiv">
                                    <InfiniteScroll
                                        dataLength={userDetails.length}
                                        next={fetchMoreData} // Corrected: pass the function reference
                                        hasMore={hasMoreLoad}
                                        scrollThreshold={0.9}
                                        scrollableTarget="scrollableDiv"
                                    >
                                        <table className="table text-nowrap mb-0 custom-table-grid table-borderless">
                                            <thead>
                                                <tr className="sticky-top">
                                                    <th className="font-12 font-medium px-4 position-relative" onClick={() => riskhandleColumnSort('Username')}>Username
                                                        <span className="position-absolute">
                                                            <a className="sort-up position-absolute ms-2  cursor-pointer"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" /></a> <a   className="sort-down position-absolute ms-2"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" /></a>
                                                        </span>
                                                    </th>
                                                    <th className="font-12 font-medium position-relative text-center"  onClick={() => riskhandleColumnSort('taskPriority')}>
                                                        Risk Severity
                                                        <span className="position-absolute">
                                                            <a   className="sort-up position-absolute ms-2  cursor-pointer"><img className="sort-img-size" src="images/sort-up.svg" alt="sort-up-icon" /></a> <a   className="sort-down position-absolute ms-2"><img className="sort-img-size" src="images/sort-down.svg" alt="sort-down-icon" /></a>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userDetails.map(task => (
                                                    <tr key={task.taskDetailsId}>
                                                        <td className="font-12">
                                                            <div className="d-flex align-items-center">
                                                                <img src={task?.profileImages}  className="table-prof" alt="Profile" />
                                                                <p className="font-12 font-regular primary-textcolor mb-0 ms-2">
                                                                    {task?.Username}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td className="font-12 font-medium text-left">
                                                            <span className={`batch-${task?.riskSeverity === 'Low' ? 'low' : task?.riskSeverity === 'Medium' ? 'medium' : 'high'}`}>
                                                                {task?.riskSeverity}
                                                            </span>



                                                        </td>
                                                    </tr>

                                                ))}
                                            </tbody>
                                        </table>
                                        </InfiniteScroll>
                                    </div>
                                    <div className="mt-3">
                                        <p className="font-12 grey-primary m-0">Showing <span className="font-medium primary-textcolor">{totalUserCount}</span> items</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                )
            default:
                return null;
        }
    };

    return (
        <>
            <Loader isLoading={isLoading} />

            {/* <div className="mt-3">
                <p className="font-12 grey-primary m-0">
                    Showing <span className="font-medium primary-textcolor">{userDetails.length}</span> items
                </p>




            </div> */}
            {renderContent()}

        </>
    );
};

export default PopUpDashBoard;

