const ControlsGridNoRecordFound = ({data}) => {
    return (
        <div className="row py-3" style={{zIndex: 5, position: "relative"}}>
            <div className="row justify-content-center py-5" style={{marginTop: "-20px"}}>
                <div className="col-md-10 col-lg-5 col-sm-12 text-center py-4">
                    <span>
                        <img src="/images/No Data Found - Risk.png" alt="no-rec-icon" />
                    </span>
                    <h5 className="font-24 font-medium primary-textcolor mt-4">{data}</h5>
                </div>
            </div>
        </div>
    );

}
export default ControlsGridNoRecordFound;
