// PC_AL_01 - PC_AL_16  Import the required Hooks,Pcakages, services from LogServices 
import { logConst } from '../../Configs/logValues';
import { Loader } from '../Loader/Loader';
import { useContext, useEffect, useState } from "react";
import * as FileSaver from 'file-saver';
import NoDataFound from "./noDataFound";
import { agovContext } from "../../Constant";
import { useLocation } from "react-router";
import ExcelJS from 'exceljs';

const { getLogsData } = require("../../Services/ActivityLogs/logServices")


//PC_AL_04 - PC_AL_14 initiating the Activity Logs with Required State, Constants
function Activitylogs() {
    let [quickObj, setQuickObj] = useState({
        category: [],
        riskType: "",
        event: []
    })
    let [quickOption, setQuickOption] = useState({
        category: [],
        riskType: "",
        event: []
    })
    let [filterObj, setFilterObj] = useState({
        category: [],
        event: [],
        riskType: "",
        performedBy: "",
        assignedTo: "",
        fromDate: "",
        toDate: ""
    })

    
    let logValue = []
    let [isActive, setIsactive] = useState("")
    let [excelData, setExcelData] = useState([])
    let [logsCount, setLogsCount] = useState(0)
    let [logData, setLogData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    let [nextPage, setNextPage] = useState(10);
    let [subCategory, setSubcategory] = useState([])
    let [selectedSubCategory, setSelectedSubCategory] = useState("")
    let [searchValue, setSearchValue] = useState("")
    let [category, setCategory] = useState("");
    let [showfilter, setShowFilter] = useState(true);
    let [filter, setFilter] = useState(filterObj)
    let [performedBy, setPerformedBy] = useState([])
    let [assignedTo, setAssignedTo] = useState([])
    let [closeFilter,setCloseFilter] = useState(false)
    let [showQuickAction, setShowQuickAction] = useState(true)
    const { roles, userId, reportsToId } = useContext(agovContext);

    //UseEffect Function Triggers
    useEffect(() => {
        console.log('Effect triggered');
        loadlogDetails();
    }, [nextPage]);

    useEffect(() => {
        console.log(quickObj.event[0], "event")
        console.log(quickObj, "quickObj")
        if(closeFilter==true)
            {
                loadlogDetails();
            }
    }, [closeFilter]);





    let location = useLocation()
    const { state } = location

console.log(state?.value?.category ,"categoryrece")
    let navigatedCategory = state?.value?.category || []
    let navigatedEvent = state?.value?.event || []
    let userid = state?.value?.id ? state?.value?.id : userId

    console.log(navigatedCategory, "receieved state")
    console.log(userid, "receieved state")

    let navigatedFilterObj = {
        category: navigatedCategory,
        event:navigatedEvent,
        riskType: "",
        performedBy: "",
        assignedTo: "",
        fromDate: "",
        toDate: ""
    }

    //PC_EXL_13 - PC_EXP_53  handle Export Excel Function defined with the Respective Payload and Api Call

    const handleExportLogs = async () => {
        try {
            let logparams = {
                userId: userid,
                managerId: reportsToId,
                search: searchValue,
                page: 0,
                filter: Object.values(filter).some(value => value !== "" && value.length !== 0) ? filter : navigatedFilterObj,
                QuickAction: quickObj,
                isActive: isActive,
                userRole: roles
            }

            let exportData = await getLogsData(logparams)

            setExcelData(exportData.response)

            // Assuming excelData is an array of objects
            let exportlogsArray = [];

            for (var i = 0; i < exportData.response.length; i++) {
                let exportDataItem = exportData.response[i]
                let exportlogs = {
                    category: "",
                    subCategory: "",
                    activity: "",
                    performedBy: "",
                    assignedTo: "",
                    status: "",
                    Email: "",
                    DateTime: ""
                };



                if (exportDataItem.event == 'approved' || exportDataItem.event == 'notapproved') {
                    exportlogs.category = 'Access Governance';
                } else if (exportDataItem.event == 'taskAssigned' || exportDataItem.event == 'taskUpdated' || exportDataItem.event == 'taskCompleted') {
                    exportlogs.category = 'Risk';
                } else if (exportDataItem.risk_type == 'policy' || exportDataItem.risk_type == 'evidence') {
                    exportlogs.category = 'Compliance';
                } else if (exportDataItem.category == 'adminManagement' || exportDataItem.category == 'riskManagement' || exportDataItem.category == 'lmsManagement' || exportData.category == 'grcManagement' || exportData.category == 'appManagement') {
                    exportlogs.category = 'Task';
                } else if (exportDataItem.event == 'adminManagement') {
                    exportlogs.category = 'General'
                } else {
                    exportlogs.category = '-';
                }

                if (exportDataItem.event == 'approved') {
                    exportlogs.subCategory = 'approved';
                } else if (exportDataItem.event == 'unassign') {
                    exportlogs.subCategory = 'revoke';
                } else if (exportDataItem.event == 'taskAssigned' || exportDataItem.event == 'campaignAssigned') {
                    exportlogs.subCategory = 'Assigned';
                } else if (exportDataItem.event == 'taskUpdated') {
                    exportlogs.subCategory = 'updated';
                } else if (exportDataItem.event == 'taskCompleted' || exportDataItem.event == 'campaignCompleted') {
                    exportlogs.subCategory = 'Completed';
                } else if (exportDataItem.risk_type == 'policy' || exportDataItem.risk_type == 'evidence') {
                    exportlogs.subCategory = 'evidence';
                } else if (exportDataItem.category == 'adminManagement') {
                    exportlogs.subCategory = 'adminConfig';
                } else if (exportDataItem.category == 'lmsManagement') {
                    exportlogs.subCategory = 'Lms';
                } else if (exportDataItem.category == 'appManagement') {
                    exportlogs.subCategory = 'app';
                } else if (exportDataItem.category == 'grcManagement') {
                    exportlogs.subCategory = 'Compliance';
                } else if (exportDataItem.category == 'riskManagement') {
                    exportlogs.subCategory = 'Risk';
                } else {
                    exportlogs.subCategory = '-';
                }

                exportlogs.activity = exportDataItem.event;
                exportlogs.performedBy = exportDataItem.applied_by;
                exportlogs.assignedTo = exportDataItem.applied_on;
                exportlogs.DateTime = exportDataItem.date_time;
                exportlogs.status = exportDataItem.result;
                exportlogs.Email = exportDataItem.primary_email;

                exportlogsArray.push(exportlogs);
            }

            let workbook = new ExcelJS.Workbook();
            let worksheet = workbook.addWorksheet('Activity Log report');

            // Define header row
            let headerRow = worksheet.getRow(1);
            headerRow.values = ["Category", "Sub-Category", "Activity", "Performed by", "Assigned to", "Email", "Status", "Date and Time"];

            // Populate data rows
            exportlogsArray.forEach((value) => {
                worksheet.addRow([
                    value.category,
                    value.subCategory,
                    value.activity,
                    value.performedBy,
                    value.assignedTo,
                    value.Email,
                    value.status,
                    value.DateTime
                ]);
            });

            // Set filename
            let filename = "Activity_logs_Reports_" + new Date().getFullYear() + '' + ((parseInt(new Date().getMonth()) + 1) < 10 ? '0' + (parseInt(new Date().getMonth()) + 1) : (parseInt(new Date().getMonth()) + 1)) + '' + (new Date().getDate() < 10 ? ('0' + new Date().getDate()) : new Date().getDate()) + ".xlsx";

            // Set content type and extension
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            // Write workbook to buffer
            let buffer = await workbook.xlsx.writeBuffer();

            // Create Blob
            let data1 = new Blob([buffer], { type: fileType });

            // Save file
            FileSaver.saveAs(data1, filename);
        }
        catch (error) {
            console.log("error message", error)
        }
    }

    /**
     * PC_AL_23 -PC_AL_40 Load Log Details function performs the inital get of the activity logs 
     */
    const loadlogDetails = async () => {
        // // cancelFlag = cancelFlag ? cancelFlag : false
        // console.log(Object.values(filter).some(value => value !== "" && value.length !== 0) ? filter : navigatedFilterObj
        //     , "filter condition  ");
        console.log(filter, "folte")
        try {
            setIsLoading(true)
            // setQuickObj(quickOpiton)
            const logparams = {
                userId: userid,
                managerId: reportsToId,
                search: searchValue,
                page: nextPage,
                filter: Object.values(filter).some(value => value != [] && value.length !== 0) ? filter : navigatedFilterObj,
                QuickAction: quickObj,
                isActive: isActive,
                userRole: roles
            }
            console.log(logparams, "logValue sent")

            let logReport = await getLogsData(logparams)
            logValue = logReport.response
            setLogData(logReport.response)
            setLogsCount(logReport.count[0].totalCount)
            setIsLoading(false)
            setPerformedBy(logReport.performedBy)
            setAssignedTo(logReport.appliedOn)


            console.log(logValue, "logData value");
            setShowQuickAction(true);
            setShowFilter(true) 
            setCloseFilter(false)
        }

        catch {

        }
    }

    //Const object Subcategory
    const subCategories = {
        'Access Governance': ['Approved', 'Revoke'],
        'Risk': ['Assigned', 'Updated', 'Closed'],
        'Compliance': ['Policy', 'Evidence'],
        'Task': ['LMS', 'Access Request', 'Compliance', 'Risk'],
        'General': ['Admin Config']
    };
    /**
     * PC_AL_46 invokes when the handlecategory function 
     */
    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setCategory(selectedCategory);
        setSubcategory([]); // Reset sub-category when category changes
    };
    /**
     * PC_AL_47 invokes when the handleSubCategoryChange function 
     */
    const handleSubCategoryChange = (event) => {
        const selectedSubCategory = event.target.value;
        setSubcategory([selectedSubCategory]);
    };

    //Binding function for the performed by option invokes 
    const bindPerformedBy = () => {
        console.log(performedBy, "performedby valueeeee")
        return performedBy.map((obj, index) => {
            return (
                <option key={index} value={obj.applied_by}>{obj.applied_by}</option>
            )
        })
    }
    //Binding the Function for the Applied On Option invokes 
    const bindAppliedOn = () => {
        console.log(assignedTo, "assignetdtooovalue")
        return assignedTo.map((obj, index) => {
            return (
                <option key={index} value={obj.applied_on}>{obj.applied_on}</option>
            )
        })
    }
    /***
     * PC_AL_155 handleLableClick method gets invoked  during the label click
     */
    let handleLabelClick = (category, riskType, event) => {
        console.log(category, riskType, event, "came inside")
        setQuickObj({
            category: category || [],
            riskType: riskType || "",
            event: event || []
        })
        if ((isActive == "" || isActive == 1) && category.length === 0 && event.length === 0 && riskType.length === 0) {
            setIsactive(0)
        } else {
            setIsactive(1)
        }

    }

    /**
     * PC_AL_41 handleFilterData function invokes to show Filter popUp
     */

    let handleFilterData = () => {
        setShowFilter(false);
        setShowQuickAction(true);
        console.log()

    }
    /**
     * PC_AL_42 handleFilter Function gets Invoked 
     */
    const handleFilter = (event) => {

        let selectedValue = event.target.value;
        let selectedName = event.target.name;
        let filterValue = { ...filter };
        if (selectedValue == "Access Governance") {
            filterValue.category = ["appManagement","adminManagement"];
        }
        else if (selectedValue == "Compliance") {
            filterValue.category = ["grcManagement"];
        }
        else if (selectedValue == "LMS") {
            filterValue.category = ["lmsTaskManagement"];
        }
        else if (selectedValue == "Access Request") {
            filterValue.category = ["adminTaskManagement"];
        }
        else if (selectedValue == "Compliance") {
            filterValue.category = ["grcTaskManagement"];
        }
        else if (selectedValue == "Risk") {
            filterValue.category = ["riskTaskManagement"];
        }
        else if (selectedValue == "Task") {
            filterValue.category = ["lmsTaskManagement", "grcTaskManagement", "riskTaskManagement", "adminTaskManagement"];
        }
        else if (selectedValue == "General") {
            filterValue.category = ["adminManagement"]
        }
        else if(selectedValue == "" && selectedName== "category"){
            filterValue.category = []
        }
        else if (selectedValue == "Approved") {
            filterValue.event = ["approve"]
            filterValue.riskType = ""
        }
        else if (selectedValue == "Revoke") {
            filterValue.event = ["Unassign"]
            filterValue.riskType = ""
        }
        else if (selectedValue == "Assigned") {
            filterValue.event = ["Application Assigned"]
            filterValue.riskType = ""
        }
        else if (selectedValue == "Update") {
            filterValue.event = ["taskUpdated"]
            filterValue.riskType = ""
        }
        else if (selectedValue == "Closed") {
            filterValue.event = ["taskCompleted"]
            filterValue.riskType = ""
        }
        else if (selectedValue == "Policy") {
            filterValue.riskType = "policy"
            filterValue.event = []
        }
        else if (selectedValue == "Evidence") {
            filterValue.riskType = "evidence"
            filterValue.event = []
        }
        else if(selectedValue == "" && selectedName== "subCategory"){
            filterValue.riskType = ""
            filterValue.event = []
        }
        else if (selectedValue == "General") {
            filterValue.category = ["adminManagement"]
        }
        else if (selectedValue == "Admin Config") {
            filterValue.category = ["adminManagement"]
        }

        else if (selectedName == "performedBy") {
            filterValue.performedBy = selectedValue
        }
        else if (selectedName == "assignedTo") {
            filterValue.assignedTo = selectedValue
        }
        else if (selectedName == "fromDate") {
            filterValue.fromDate = selectedValue
        }
        else if (selectedName == "toDate") {
            filterValue.toDate = selectedValue
        }
        setFilter({ ...filterValue });
    }
    //Binding the Filter Data  in the Filter Pop Up
    const bindFilterData = () => {
        return (
            <div className="row mt-3">
                <div className="col-md-6 col-sm-12">
                    <div className="mb-3">
                        <label className="form-label text-black font-12 font-medium">Category</label>
                        <select value={category}
                            onChange={(e) => {
                                handleCategoryChange(e);
                                handleFilter(e)
                            }} className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example"
                            name="category">
                            <option selected value="">Select</option>
                            {Object.keys(subCategories).map((cat) => (
                                <option key={cat} value={cat}>{cat}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="mb-3">
                        <label className="form-label text-black font-12 font-medium">Sub Category</label>
                        <select value={subCategory}
                            onChange={(e) => {
                                handleSubCategoryChange(e);
                                handleFilter(e)
                            }} className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example"
                            name="subCategory" >
                            <option selected value="">Select</option>
                            {subCategories[category] && subCategories[category].map((subCat) => (
                                <option key={subCat} value={subCat}>{subCat}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="mb-3">
                        <label className="form-label text-black font-12 font-medium">Performed By</label>
                        <select className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example"
                            name="performedBy" value={filter.performedBy} onChange={(e) => { handleFilter(e); }}>
                            <option selected value="">Select</option>
                            {bindPerformedBy()}
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="mb-3">
                        <label className="form-label text-black font-12 font-medium">Assigned To</label>
                        <select className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example"
                            name="assignedTo" value={filter.assignedTo} onChange={(e) => handleFilter(e)}>
                            <option selected value="">Select</option>
                            {bindAppliedOn()}
                        </select>
                    </div>
                </div>
                <h5 className="font-14 font-bold text-black mt-3">Date Range</h5>
                <div className="col-md-6 col-sm-12">
                    <div className="mb-3">
                        <label htmlFor="StartDate" className="form-label text-black font-12 font-medium">From</label>
                        <input type="date" className="form-control custom-date cust-input-sty font-12 font-regular" id="StartDate"
                            value={filter.fromDate} name='fromDate' onChange={(e) => { handleFilter(e) }} />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="mb-3">
                        <label htmlFor="endDate" className="form-label text-black font-12 font-medium">To</label>
                        <input type="date" className="form-control custom-date cust-input-sty font-12 font-regular" id="endDate"
                            value={filter.toDate} name='toDate' onChange={(e) => { handleFilter(e) }} />
                    </div>
                </div>
            </div>
        )
    }

    // format Date and Time 
    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString || dateTimeString == undefined) return "-"; // Return an empty string if dateTimeString is null or undefined

        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
        const hour = date.getHours().toString().padStart(2, "0");
        const minute = date.getMinutes().toString().padStart(2, "0");
        const ampm = hour >= 12 ? "PM" : "AM";
        const formattedHour =
            hour % 12 === 0 ? "12" : (hour % 12).toString().padStart(2, "0");

        return (
            <span>
               {month}/{day}/{year} {formattedHour}:{minute} {ampm}
            </span>
        );
    };


    /**
     * 
     *PC_AL_155 - 159 bindLogReports part  
     */
    const bindlogReport = () => {
        console.log(logsCount, "Count")

        if (logsCount == 0) {
            return (
                <NoDataFound />
            )
        }
        else {
            if (logsCount != 0) {
                return logData?.map((obj, index) => {
                    console.log(obj[logConst[obj?.event]?.dateTime], "date reecieved")
                    return (
                        <div className={
                            obj[logConst[obj?.event]?.Category] == "grcTaskManagement" || obj[logConst[obj?.event]?.Category] == "grcManagement" ? "row log-card compliance-log" :
                                obj[logConst[obj?.event]?.Category] == "lmsTaskManagement" || obj[logConst[obj?.event]?.Category] == "lmsManagement" ? "row log-card lms-log" :
                                    obj[logConst[obj?.event]?.Category] == "appManagement" || obj[logConst[obj?.event]?.Category] == "adminManagement" || obj[logConst[obj?.event]?.Category] == "appTaskManagement" ? "row log-card access-governance-log" :
                                        obj[logConst[obj?.event]?.Category] == "adminTaskManagement" ? "row log-card admin-config-log" :
                                        obj[logConst[obj?.event]?.Category] == "riskTaskManagement" || obj[logConst[obj?.event]?.Category] == "riskTaskCategory" ? "row log-card risk-log" :
                                            obj[logConst[obj?.event]?.Category] == "taskManagement" ? "row log-card task-log":  logConst[obj?.event]?.divColor
                        }>
                            <div className="col-md-12 mt-4 pt-3">
                                <p className="font-14 text-black mb-2 mb-lg-0">
                                    <span className="font-bold">{obj[logConst[obj?.event]?.firstname]} </span>{logConst[obj?.event]?.divStatement1} <span className="font-bold wrap-text">{obj[logConst[obj?.event]?.subEvent]}</span>{logConst[obj.event]?.divStatement2} <span className="font-bold"> {obj[logConst[obj?.event]?.subEventName]} {obj[logConst[obj?.event]?.lastname]}</span>
                                </p>
                            </div>
                            <div className="col-lg mt-lg-3"><span className="font-12 text-black font-bold mb-1 m-lg-0">{logConst[obj?.event]?.description}</span></div>
                            <div className="col-lg mt-lg-3"><span className="font-12 text-color-grey mb-1 m-lg-0">{logConst[obj?.event]?.logtype}</span></div>
                            <div className="col-lg mt-lg-3"><span className="font-12 text-color-grey mb-1 m-lg-0">{logConst[obj?.event]?.assignedOrPerform} <span className="font-bold text-black">{obj[logConst[obj?.event]?.performedBy]} </span></span></div>
                            <div className="col-lg mt-lg-3"><span className="font-12 text-color-grey font-medium">{formatDateTime(obj[logConst[obj?.event]?.dateTime])}</span></div>
                        </div>

                    )
                }
                )
            }
        }
    }

    /**
     * PC_AL_138-PC_AL_141 handleSearch Function gets invoked
     */
    let handleSearch = async () => {

        logData = [];
        setLogData(logData)
        setLogsCount(0);
        nextPage = 10;
        setNextPage(nextPage);
        console.log("in the handleSearch function");
        await loadlogDetails(nextPage);
    }

    /*
    * PC_AL_48 - PC_AL_49 handleClos function gets invoked 
    */
    let handleClose = async () => {
        setQuickObj(quickOption)
        setFilter(filterObj)
        setCategory('');
        setSubcategory('');
        setShowFilter(true);
        setShowQuickAction(true)
        setCloseFilter(true)
    }

    

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="mt-custom">
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="stylesheet" type="text/Css" href="css/agov.css" />
                <link rel="stylesheet" type="text/css" href="css/bootstrap.css" />
                <title>Activity Logs</title>
                <div className="container-fluid mt-4">
                    <div className="col-md-12 px-3 px-md-4 px-lg-5">
                        <div className="d-md-flex justify-content-between align-items-center mt-4 mb-1">
                            <h1 className="font-bold font-24 primary-textcolor m-0">Activity Reports</h1>
                            <ul className="nav justify-content-end mt-2 m-lg-0">
                                <li>
                                    <div className="input-group flex-nowrap search-group me-2 mb-3 mb-md-0">
                                        <input type="search" onKeyDownCapture={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearch(e)
                                            }
                                        }} onChange={(e) => { setSearchValue(e.target.value) }}
                                            className="form-control search-align" placeholder="Search" maxLength={250} aria-label="Username" aria-describedby="addon-wrapping" />
                                        <button className="input-group-text btn btn-search-custom" onClick={() => handleSearch()} id="addon-wrapping"><img src="/images/search-icon.svg" alt="search-icon" /></button>
                                    </div>
                                </li>
                                {/* Search Ends here */}
                                <li className="ms-2">
                                    <button type="button" onClick={() => {setShowQuickAction(false); setShowFilter(true)}}
                                        className={showQuickAction == false ? "cust-filter-btn d-flex align-items-center mb-2 mb-md-0 show" : "cust-filter-btn d-flex align-items-center mb-2 mb-md-0"}>
                                        <img src="images/time-icon.svg" alt="filter-icon" className="filt-icon me-2" />Quick
                                        Actions
                                    </button>

                                    <div className={showQuickAction == false ? "dropdown-menu filter-right dropdown-menu-end custom-filter border-0 show style=position: absolute;inset: 0px auto auto 0px; margin: 0px; transform: translate(123px, 103px);data-popper-placement=bottom-end z-index: 1;"
                                        : "dropdown-menu dropdown-menu-end custom-filter border-0"}style={{zIndex:100}}>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="font-18 font-bold text-black mb-0">Quick Actions</h5>
                                                <button type="button" className="btn-close font-12" onClick={handleClose} />
                                            </div>
                                            <div className="mt-4">
                                                <h5 className="font-medium font-14 secondary-textcolor mb-3">Quick Filter</h5>
                                                <label className={quickObj.riskType === 'userRisk' ? "quickActions cursor-pointer active font-13 mb-2 w-100"
                                                    : "quickActions cursor-pointer font-13 mb-2 w-100"} onClick={() => handleLabelClick(["riskTaskManagement"], "userRisk", [])}>Identity Risks report</label>
                                                <label className={quickObj.category[0] === 'riskTaskManagement' && quickObj.riskType === "" ? "quickActions cursor-pointer active font-13 mb-2 w-100"
                                                    : "quickActions cursor-pointer font-13 mb-2 w-100"} onClick={() => handleLabelClick(["riskTaskManagement"], "", [])}>Risk Tasks logs</label>
                                                <label className={quickObj.event[0] === 'campaignOverdue' ? "quickActions cursor-pointer active font-13 mb-2 w-100"
                                                    : "quickActions cursor-pointer font-13 mb-2 w-100"} onClick={() => handleLabelClick(["lmsTaskManagement"], "", ["campaignOverdue"])}>LMS Overdue tasks</label>
                                                <label className={quickObj.event[0] === 'taskOverdueCompletion' ? "quickActions cursor-pointer active font-13 mb-2 w-100"
                                                    : "quickActions cursor-pointer font-13 mb-2 w-100"} onClick={() => handleLabelClick(["lmsTaskManagement"], "", ["taskOverdueCompletion"])}>Security awareness completion</label>
                                                <label className={quickObj.event[0] === 'controlAdded' ? "quickActions cursor-pointer active font-13 mb-2 w-100"
                                                    : "quickActions cursor-pointer font-13 mb-2 w-100"} onClick={() => handleLabelClick([], "", ["controlAdded", "policyMap", "controlMap", "controlUnmap", "PolicyUpload", "evidenceUpload", "evidenceInActive", "evidenceReccurenceChanage", "policyCreation", "controlActive", "controlInactive"])}>Compliance Logs</label>
                                                <label className={quickObj.event[0] === 'controlOwnerAssigned' ? "quickActions cursor-pointer active font-13 mb-2 w-100"
                                                    : "quickActions cursor-pointer font-13 mb-2 w-100"} onClick={() => handleLabelClick([], "", ["controlOwnerAssigned"])}>Control owner assignment</label>
                                                <label className={quickObj.event[0] === 'unAssign' ? "quickActions cursor-pointer active font-13 mb-2 w-100"
                                                    : "quickActions cursor-pointer font-13 mb-2 w-100"} onClick={() => handleLabelClick([], "", ["unAssign", "notApproved", "assign", "approve"])}>Access Review (Approve/Revoke)</label>
                                                <label className={quickObj.event[0] === 'toggleOn' ? "quickActions cursor-pointer active font-13 mb-2 w-100"
                                                    : "quickActions cursor-pointer font-13 mb-2 w-100"} onClick={() => handleLabelClick([], "", ["toggleOn", "toggleOff", "appOwnerAssigned", "appOwnerRemoved"])}>Admin configs change</label>
                                                <label className={isActive === 0 ? "quickActions cursor-pointer active font-13 mb-2 w-100" : "quickActions cursor-pointer font-13 mb-2 w-100"} onClick={() => handleLabelClick([], "", [])}>Terminated user Application access</label>
                                            </div>

                                            <div className="text-end mt-4 mb-2">
                                                <button type="button" className="primary-btn font-14 font-medium me-2"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </button>
                                                <button type="button" className="dark-btn font-14 font-medium px-4"
                                                    onClick={loadlogDetails}>
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="ms-2">
                                    <button className={showfilter ? "cust-filter-btn d-flex align-items-center" : "cust-filter-btn d-flex align-items-center show"} onClick={() => { handleFilterData() }} type="button">
                                        <img src="images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
                                    </button>
                                    {!showfilter ?
                                        <div className={showfilter ? `custom-filter-container custom-filter border-0` : `custom-filter-container custom-filter border-0 show style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(304px, 90px);" data-popper-placement="bottom-start""`}style={{zIndex:100}}>
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                                                    <button type="button" className="btn-close font-12" onClick={handleClose} />
                                                </div>
                                                {bindFilterData()}
                                                <div className="text-end mt-4 mb-2">
                                                    <button type="button" className="primary-btn font-14 font-medium me-2"
                                                        onClick={handleClose}>
                                                        Clear
                                                    </button>
                                                    <button type="button" className="dark-btn font-14 font-medium px-4"
                                                        onClick={() => { loadlogDetails() }}>
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div> : <></>}
                                </li>
                                <button className="advanced-filter-button medium-text customflip-btn ms-2" onClick={() => { handleExportLogs() }} type="button">
                                    <img src="images/excel-icon.svg" alt="Filter-icon" />
                                </button>

                            </ul>
                        </div>
                        {/* Log Reports Bind Data Starts here */}
                        {bindlogReport()}
                        {/* Log Reports Bind Data ends Here  */}
                    </div>
                    <div className="mt-4">
                        <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor">{logData.length}</span> out of <span className="font-medium primary-textcolor">{logsCount}</span> items</p>
                    </div>
                    <div className="text-center my-3 mb-5">
                        <button className="primary-btn font-medium" hidden={logsCount > logData.length ? false : true} onClick={() => setNextPage(nextPage + 10)}>Load More</button>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Activitylogs