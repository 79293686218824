import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getMylearningsDetails } from '../../Services/LMS/LmsService';
import NoDataFound from './NoDataFound';
import NoRecordsPage from './NoRecordsPage';
import { Loader } from '../Loader/Loader';
import { agovContext } from '../../Constant';

//PS_ML_FE_1.9 to 1.72
const MyLearningsGrid = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, roles } = useContext(agovContext);
  const mylearningsDetails = location?.state?.value;


  const requestDetails = {
    userId: userId,
    limit: 8,
    searchCampaignName: '',
    filterValue: {
      status:  mylearningsDetails?.status || '',
      timeline:  mylearningsDetails?.timeline || ''
    }
  };

  //PS_ML_FE_1.11 
  const [taskSearchRequest, setTaskSearchRequest] = useState(requestDetails);
  const [isLoadMoreVisible, setIsLoadMoreVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isNoRecordPageVisible, setIsNoRecordPageVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignSearchName, setCampaignSearchName] = useState("");
  const [isFilterPopup, setIsFilterPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(mylearningsDetails?.status||'');
  const [selectedTimeline, setSelectedTimeline] = useState('');
  const [totalResultCount, setTotalResultCount] = useState(0);
  const[navigatedFilterData,setNavigatedFilterData]=useState('All')


  //PS_ML_FE_1.12
  useEffect(() => {
    if (roles.includes("Super Admin") || roles.includes("LMS Admin")) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
   
        fetchMyLearningsDetails();
  }, [taskSearchRequest,mylearningsDetails]);



  // PS_ML_FE_1.63 to 1.67 To handle the apply filter
  const handleApplyFilter = () => {
    setTaskSearchRequest((prevState) => ({
      ...prevState,
      filterValue: {
        status: selectedStatus,
        timeline: selectedTimeline,
      },
    }));
    setIsFilterPopup(false);
    // Clear the selected values
    setSelectedStatus('');
    setSelectedTimeline('');
  };

  // PS_ML_FE_1.21 to 1.45  To fecth the assigned campaign details aginst the user details
  const fetchMyLearningsDetails = async () => {
    let result;
    setIsLoading(true);
    try {
      setIsLoading(true)
      result = await getMylearningsDetails(taskSearchRequest);
      setIsLoading(false)

      if (result.statusCode !== 200) {
        setIsLoading(false);
        navigate('/error');
        return;
      }
      setCampaignDetails(result.response);
      setTotalResultCount(result.totalResultCount);

      if (result?.response?.length === 0) {
        // Based request's searchCampaignName 
        if (taskSearchRequest.searchCampaignName || taskSearchRequest.filterValue) {
          setNoDataFound(true)
          // setTaskSearchRequest(requestDetails)
        }
        else {
          setIsNoRecordPageVisible(true)
        }
      }
      else {
        setNoDataFound(false)
      }


      // To display the load more 
      if (result.totalResultCount > 0 && result.response.length != result.totalResultCount) {
       
        setIsLoadMoreVisible(true);
      }
      else {
        setIsLoadMoreVisible(false)
      }
    }
    catch (error) {
      console.error("An error occurred:", error);
    }
    setIsLoading(false);
  };

  //Funstion to handle clear button in advanced filter.
  const handleClearFilter = () => {
    setSelectedStatus('');
    setSelectedTimeline('');
    setTaskSearchRequest((prevState) => ({
      ...prevState,
      filterValue: {
        status: '',
        timeline: '',
      },
    }));
    setIsFilterPopup(false);
  };

  //PS_ML_FE_1.70  Routes to detailed media view page 
  const handleCampaignClick = (campaignId, taskDetailsId) => {
    navigate('/lmsmanagement/viewmedia', { state: { value: { courseId: "", videoId: "", campaignId: campaignId, taskDetailsId: taskDetailsId } } })
  }

  // PS_ML_FE_1.47 to bind the campaign details 
  const bindData = () => {
    return campaignDetails?.map((item, index) => (
      <div key={item.taskId} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5 ">
        <div className="card border-0 h-100">
          {/* Your card content here */}
          <span className="card-bg-image d-flex mb-5">
            <img src={item.campaignThumbnail} alt="lms-learning-image" className="card-image-cover w-100" />
            {/* <img src="/images/sample-image.svg" alt="lms-learning-image" className="card-image-cover w-100" /> */}
          </span>
          <span className="card-body text-decoration-none learning-card-pos p-0  w-100 pointer" onClick={() => handleCampaignClick(item.campaignId, item.taskDetailsId)}>
            <div className="mx-2 learning-card cursor-pointer">
              <h5 className="font-14 font-medium primary-textcolor ">{item.campaignName}</h5>

              <p className="font-12 font-regular mb-0 grey-light  mb-2 linehig description pointer">{item.campaignDescription}</p>
              <div className="mt-auto">
                <span className="d-flex align-items-center">
                  <span className='mr-4'>
                    <img src="/images/clock-icon.svg" alt="clock-icon" />
                    <span className="ms-1 grey-primary font-12 font-regular">{item.campaignDuration}</span>
                  </span>
                  <span>
                    <img src="/images/calender-icon.svg" alt="calender-icon" />
                    <span className="ms-1 grey-primary font-12 font-regular">{item.campaignEndDate}</span>
                  </span>
                  {/* Add more spans for other properties as needed */}
                </span>
              </div>
            </div>
          </span>
        </div>
      </div>
    ));
  };


  // PS_ML_FE_1.50 to 1.52 to handle when user clicks on load more button
  const handleLoadMore = () => {
    setTaskSearchRequest(prevState => ({ ...prevState, limit: prevState.limit + 8 }));
  };

  //PS_ML_FE_1.53 to 1.60 To handle the search bar 
  const handleOnKeyDownSearch = (event) => {
    setCampaignSearchName(event.target.value.trim() === "" ? "" : event.target.value);
    if (event.key === "Enter") {
      setTaskSearchRequest({
        ...taskSearchRequest,
        searchCampaignName: event.target.value.trim() === "" ? "" : event.target.value,
        limit: 8
      });
    }
  };

  const handleOnclickSearch = () => {
    setTaskSearchRequest({
      ...taskSearchRequest,
      searchCampaignName: campaignSearchName,
      limit: 8
    });
  };

  // // PS_ML_FE_1.63 to 1.67 To handle the status filter
  // const handleFilterByStatus = (event) => {
  //   setCampaignSearchName("");
  //   setTaskSearchRequest(prevState => ({ ...prevState, searchCampaignName: "", filterValue: event.target.value, limit: 8 }));
  // };

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isNoRecordPageVisible ? <div className="container-fluid mt-4 mt-custom">
        <div className="col-md-12 px-5">
          <div className="d-flex justify-content-between mt-4">
            <h1 className="font-bold font-24 primary-textcolor">
              Learning Management System
            </h1>
          </div>
          {/* tabs starts here */}
          <div className="d-md-block d-lg-flex justify-content-between align-items-center ps-1 my-3 custom-tabs ">
            <ul className="nav mb-lg-0 mb-3 nav-pills" id="pills-tab" role="tablist">
              {isAdmin && (
                <li className="nav-item" role="presentation">
                  <button className="nav-link px-0  font-14 tab-style bold-text rounded-0" id="Overview-tab"
                   data-bs-toggle="pill" data-bs-target="#Overview" type="button" role="tab" aria-controls="Overview" aria-selected="true" onClick={() => navigate('/lmsmanagement')}>
                    <span className="tab-img"> 
                  <img src="images/lms-overview-active.svg" alt="filled-icon" className="me-2" />
                  <img src="/images/lms-overview.svg" alt="Overview-icon" className="me-2" />
                  </span>Overview</button>
                </li>
              )}
              <li className="nav-item ms-4" role="presentation">
                <button className="nav-link px-0 active font-14 tab-style rounded-0" id="MyLearning-tab" data-bs-toggle="pill" data-bs-target="#MyLearning" type="button" role="tab" aria-controls="MyLearning" aria-selected="true">
                  <span className="tab-img">
                    <img src="/images/my-learning-active.svg" alt="filled-icon" className="me-2" />
                    <img src="/images/my-learning-inactive.svg" alt="MyLearning-icon" className="me-2" />
                  </span>My Learning
                </button>
              </li>
              {isAdmin && (
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="Courses-tab" data-bs-toggle="pill" data-bs-target="#Courses" type="button" role="tab" aria-controls="Courses" aria-selected="false" tabIndex={-1} onClick={() => navigate('/lmsmanagement/course')}>
                    <span className="tab-img">
                      <img src="/images/course-active.svg" alt="filled-icon" className="me-2" />
                      <img src="/images/course-inactive.svg" alt="Courses-icon" className="me-2" />
                    </span>Courses
                  </button>
                </li>
              )}
              {isAdmin && (
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="Campaigns-tab" data-bs-toggle="pill" data-bs-target="#Campaigns" type="button" role="tab" aria-controls="Campaigns" aria-selected="false" tabIndex={-1} onClick={() => navigate('/lmsmanagement/campaign')}>
                    <span className="tab-img">
                      <img src="/images/campaign-active.svg" alt="Campaigns-icon" className="me-2" />
                      <img src="/images/campaign-inactive.svg" alt="filled-icon" className="me-2" />
                      Campaigns
                    </span>
                  </button>
                </li>
              )}
              {isAdmin && (
                <li className="nav-item ms-4" role="presentation">
                  <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="MediaLibrary-tab" data-bs-toggle="pill" data-bs-target="#MediaLibrary" type="button" role="tab" aria-controls="MediaLibrary" aria-selected="false" tabIndex={-1} onClick={() => navigate('/lmsmanagement/medialibrary')}>
                    <span className="tab-img">
                      <img src="/images/media-lib-active.svg" alt="MediaLibrary-icon" className="me-2" />
                      <img src="/images/media-lib-inactive.svg" alt="filled-icon" className="me-2" />
                      Media Library
                    </span>
                  </button>
                </li>
              )}
            </ul>
            {/* my learning tab filter starts here */}
            <div className="d-flex">
              <div className="input-group flex-nowrap search-group me-3">
                <input type="search" className="form-control search-align" placeholder="Search" aria-label="Username" aria-describedby="addon-wrapping"
                  value={campaignSearchName}
                  onChange={(e) => setCampaignSearchName(e.target.value)}
                  onKeyDownCapture={(e) => handleOnKeyDownSearch(e)}
                />
                <button className="input-group-text btn btn-search-custom pointer" id="addon-wrapping"><img src="/images/search-icon.svg" alt="search-icon" onClick={(e) => handleOnclickSearch(e)} /></button>
              </div>
              <div className="ms-2 position-relative">
                <button className="cust-filter-btn d-flex align-items-center" type="button" onClick={() => { setIsFilterPopup(!isFilterPopup) }}>
                  <img src="/images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
                </button>
                {isFilterPopup ? <div className="dropdown-menu filter-right custom-filter border-0 show">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                      <button type="button" className="btn-close font-12" onClick={() => { setIsFilterPopup(false) }} />
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6 col-sm-12">
                        <div className="mb-3">
                          <label className="form-label text-black font-12 font-medium">Status</label>
                          <select className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example" value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                            <option value="">All</option>
                            <option value={'In progress'}>In Progress</option>
                            <option value={'Completed'}>Completed</option>
                            <option value={'Overdue'}>Overdue</option>
                            <option value={'Yet to Start'}>Yet to Start</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="mb-3">
                          <label className="form-label text-black font-12 font-medium">Timeline
                          </label>
                          <select className="form-select cust-input-sty font-12 font-regular" aria-label="Default select example" value={selectedTimeline} onChange={(e) => setSelectedTimeline(e.target.value)}>
                            <option selected>Select</option>
                            <option value={'All'}>All</option>
                            <option value={'Today'}>Today</option>
                            <option value={'Weekly'}>Weekly</option>
                            <option value={'Monthly'}>Monthly</option>
                            <option value={'quarterly'}>Quarterly</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="text-end mt-4 mb-2">
                      <button type="button" className="primary-btn font-14 font-medium me-2" onClick={handleClearFilter}>
                        Clear
                      </button>
                      <button type="button" className="dark-btn font-14 font-medium px-4" onClick={handleApplyFilter}>
                        Apply
                      </button>
                    </div>
                  </div>
                </div> : <></>}
              </div>

            </div>
            {/* my learning tab filter ends here */}
          </div>
          {/* tabs ends here */}
          {totalResultCount === 0 ? (
            <NoDataFound />
          ) : (
            <div className="row py-3 position-relative">
              <div className="tab-content" id="pills-tabContent">
                {/* My Learning tab starts here */}
                <div className="tab-pane fade show active" id="MyLearning" role="tabpanel" aria-labelledby="MyLearning-tab">
                  <div className="row row-cols-1 g-4">
                    {bindData()}
                  </div>
                  
                </div>
                <p class="font-12 mt-2 grey-primary">Showing <span class="font-medium primary-textcolor"> {campaignDetails.length} </span>out of <span className="font-medium primary-textcolor">{totalResultCount}</span><span class="font-medium primary-textcolor"></span> items</p>
                {/* My Learning tab ends here */}
                <div className="text-center mt-5 mb-4">
                  {isLoadMoreVisible && (
                    <button className="primary-btn font-medium" onClick={handleLoadMore}>Load More</button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div> : <NoRecordsPage
        message={"Sorry,no videos have been allocated to you."}
        isButtonVisible={false}
        buttonName={" "}
        handleButtonSubmit=''
        isMyLearningTab={true}
        isCourseTab={false}
        isCampaignTab={false}
        isMediaLibraryTab={false}
      />}
    </>
  );
}

export default MyLearningsGrid;
