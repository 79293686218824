import { client } from "../../Client/Client";
import { backendUrl as URL, taskService } from "../../Constant";

/**
 * PC_TG_14 - PC_TG_23
 * getAssignedTask is used to hit the '/task/getAssignedTask' API
 */
export const getAssignedTask = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.getAssignedTask,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  console.log("URL: ", config.url);
  return response;
};

/**
 * PC_TG_25 - PC_TG_33
 * getMyRequestTask is used to hit the '/task/getMyRequestTask' API
 */
export const getMyRequestTask = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.getMyRequestTask,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_TG_36 - PC_TG_44
 * getDirectReporteesTask is used to hit the '/task/getDirectReporteesTask' API
 */
export const getDirectReporteesTask = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.getDirectReporteesTask,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_TG_48 - PC_TG_55
 * getAllTasks is used to hit the '/task/getAllTasks' API
 */
export const getAllTasks = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.getAllTask,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_TF_15 - PC_TF_30
 * getTaskDynamicData is used to hit the '/task/getTaskDynamicData' API
 */
export const getTaskDynamicData = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.getTaskDynamicData,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_TF_39 - PC_TF_54
 * getViewTask is used to hit the '/task/getViewTask' API
 */
export const getViewTask = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.getViewTask,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_SF_31 - PC_SF_39
 * PC_TF_108 - PC_TF_123
 * getTaskSource is used to hit the '/task/getTaskSource' API
 */
export const getTaskSource = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.getTaskSource,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_SF_12 - PC_SF_20
 * PC_TF_103 - PC_TF_121
 * getTaskType is used to hit the '/task/getTaskType' API
 */
export const getTaskType = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.getTaskType,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_SF_48 - PC_SF_57
 * PC_TF_138 - PC_TF_151
 * getTaskType is used to hit the '/task/getTaskDependency' API
 */
export const getTaskDependency = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.getTaskDependency,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 *
 * PC_TF_168 - PC_TF_181
 * getTaskAssignee is used to hit the '/task/getTaskAssignee' API
 */
export const getTaskAssignee = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.getTaskAssignee,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_TG_271 - PC_TG_286
 * createTask is used to hit the '/task/createTask' API
 */
export const createTask = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.createTask,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_TG_251 - PC_TG_264
 * updateTask is used to hit the '/task/updateTask' API
 */
export const updateTask = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.updateTask,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_TG_99 - PC_TG_107
 * exportTaskDetails is used to hit the '/task/exportTaskDetails' API
 */
export const exportTaskDetails = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.exportTaskDetails,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_SF_75 - PC_SF_83
 * getTaskType is used to hit the '/task/createSubtask' API
 */
export const generateSubtask = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.createSubtask,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_TF_353 - PC_TF_372
 * addComments is used to hit the '/task/addComments' API
 */
export const addComments = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.addComments,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_TF_329 - PC_TF_345
 * downloadPolicyTemplate is used to hit the '/task/downloadPolicyTemplate' API
 */
export const downloadPolicyTemplate = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.downloadPolicyTemplate,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};

/**
 * PC_TF_305 - PC_TF_320
 * approveDocument is used to hit the '/task/approveDocument' API
 */
export const approveDocument = async (data) => {
  let config = {
    method: "POST",
    url: URL + taskService.approveDocument,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  let response = await client(config);
  return response;
};
