import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Country } from 'country-state-city'; // PS_REG_FE_1.1
import { getHeadCountOptions, postRegisterDetails } from '../../Services/Registration/RegistrationService';
import { Loader } from '../Loader/Loader';
import OTPVerificationPopUp from './OtpPopUp';

// PS_REG_FE_1.6

const RegisterPage = () => {
    // PS_REG_FE_1.7
    let [registerData, setRegisterData] = useState({
        firstName: '',
        lastName:'',
        email: '',
        companyName: '',
        headQuarters: '',
        headCount: null
    });
    const [headCountOptions, setHeadCountOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRegisterButtonEnable, setIsRegisterButtonEnable] = useState(false);
    let [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [registerId, setRegisterId] = useState('');
    const [isPopShow, setIsPopShow] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const[isName,setIsName]=useState(true)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const alphaNumRegex=/^[A-Za-z\s]*$/;  

    const navigate = useNavigate();

    // Getting all countries using the `Country` class from `country-state-city` package
    const allCountries = Country.getAllCountries();

    // PS_REG_FE_1.9
    useEffect(() => {
        fetchHeadCountOptions();
        console.log("resgiterUd",registerId)
    }, []);

    // PS_REG_FE_1.5 & PS_REG_FE_1.10
    const fetchHeadCountOptions = async () => {

        setIsLoading(true);
        const response = await getHeadCountOptions(); // PS_REG_FE_1.11
       // PS_REG_FE_1.15 & PS_REG_FE_1.19
       setIsLoading(false);
       
        if (response.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        } // PS_REG_FE_1.16 & PS_REG_FE_1.21
        else{
            setHeadCountOptions(response.responseData); 
            setIsLoading(false);    
        }

    };
    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
    
        // Handle email validation
        if (id === 'email') {
          if (!validateEmail(value)) {
            setErrorMessage('Invalid email address');
            return;
          } else {
            setErrorMessage(false);
          }
        }
    
        // Handle checkbox
        if (id === 'flexCheckDefault') {
          setIsPrivacyPolicyAccepted(checked);
        } else {
          // Restrict numeric input for firstName and lastName
          if (id === 'firstName' || id === 'lastName') {
            // Allow only alphabetic characters and spaces
            if (alphaNumRegex.test(value)) {
              setRegisterData(prevState => ({ ...prevState, [id]: value }));
            }
          } else {
            // Handle other fields, including headCount
            setRegisterData(prevState => ({ ...prevState, [id]: value }));
          }
        }
    
        console.log(registerData);
        validateForm(); // Add your form validation logic here
      };
    // const handleTextChange = (e) => {
    //     const { name, value } = e.target;
    //     const regex=/^[a-zA-Z\s]*$/;
    //     // Only allow alphabetic characters and spaces
    //     if (regex.test(value)) {
    //       setRegisterData((prevState) => ({
    //         ...prevState,
    //         [name]: value
    //       }));
    //     }
    //   };

    const validateForm = () => {
        console.log("In the validateForm function",isPrivacyPolicyAccepted, "isPrivacyPolicyAccepted");
        const { firstName, email, headCount, headQuarters } = registerData;
        // Checking all mandatory fields
        if (firstName && email && headCount && headQuarters && isPrivacyPolicyAccepted) {
            setIsRegisterButtonEnable(true);
        } else {
            setIsRegisterButtonEnable(false);
        }
    };

    // PS_REG_FE_1.26
    const handleSignUp = async () => {
        try {
            setIsLoading(true);
            const response = await postRegisterDetails(registerData); // PS_REG_FE_1.27
            setIsLoading(false);
            if (response.statusCode === 200) {
                console.log("reposne ",response.responseData);
                if(response.responseData === 'Already Exist'){
                    setErrorMessage('Email Address is already registered');
                    setIsEmailValid(false);
                }
                else{
                    console.log(response.responseData,"response.responseData");
                    setRegisterId(response.responseData)// PS_REG_FE_1.17 & PS_REG_FE_1.40
                    setIsPopShow(true);                
                }
            } else {
                navigate('/error', { state: { error: response.data } }); // PS_REG_FE_1.38
            }
        } catch (error) {
            console.error('Registration failed:', error.response || error); // PS_REG_FE_1.39
        }
    };


    // PS_REG_FE_1.41
    const handlePrivacyPolicyClick = () => {
        navigate('/privacy-policy');
    };

    // PS_REG_FE_1.42
    const redirectToLogIn = () => {
        navigate('/azure');
    };

    const validateEmail = (email, firstName, lastName) => {
        // Regular expression for validating email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        // Regular expression for validating alphanumeric names
        const alphaNumRegex = /^[a-zA-Z]+$/;
    
        if (emailRegex.test(email)) {
            setIsEmailValid(true);
            setErrorMessage('');
            return true;
        } else if (alphaNumRegex.test(firstName) && alphaNumRegex.test(lastName)) {
            setIsName(true);
            setErrorMessage('');
        } else if (!alphaNumRegex.test(firstName) || !alphaNumRegex.test(lastName)) {
            setIsName(false);
            setErrorMessage('Numbers cannot be entered in names');
        } else {
            setIsEmailValid(false);
            setErrorMessage('Invalid email address');
            return false;
        }
        
        return true; // Ensure the function returns a boolean value if it reaches this point
    };
    const handleKeyDown = (e) => {
        // Allow only alphabetic characters and spaces
        if (!alphaNumRegex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
          e.preventDefault();
        }
    }                    
  
    // PS_REG_FE_1.22
    return (
        <>
         <Loader isLoading={isLoading} />
            {/* Login page starts here */}
            <Loader isLoading={isLoading} />
            <div className="container-fluid">
                <div className="row ">
                    {/*Left container starts here */}
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 ">
                        <div className="row justify-content-center">
                            <div className="col-md-12 p-4">
                                <span>
                                    <img src="/images/agov-logo.svg" alt="agov-logo-login" className="login-logo-sty mb-3" />
                                </span>
                            </div>
                            <div className="col-md-11 col-sm-12 login-scroll p-5 pt-3">
                                <div className="login-content-height w-100 d-flex p-5">
                                    <div className="form-signup my-auto w-100">
                                        <h5 className="mb-2 font-24 primary-textcolor font-medium d-flex align-items-center">
                                            <a onClick={()=>{navigate(-1);}}><img src="images/back-arrow.svg" alt="back-arrow" className="me-3" /></a>
                                            Register
                                        </h5>
                                        <p className="mb-4  font-14 primary-textcolor font-regular">
                                            Register now to access A-Gov application, designed to streamline compliance audits and improve
                                            efficiency by integrating compliance controls and reducing audit durations.
                                        </p>
                                        <div className="row">
                                            <div className="col-md-6 mt-2 mb-3">
                                                <label htmlFor="firstname" className="form-label font-medium font-14 primary-textcolor mb-2" >
                                                    First Name
                                                </label>
                                                <input type="text" className="form-control font-regular font-14 custom-form-control py-2 login-custom-border-color" 
                                                id="firstName" 
                                                maxLength="200" 
                                                pattern="[A-Za-z\s]*"  // allows only alphabets and spaces
                                                onKeyDown={(e) => handleKeyDown(e)}
                                                onChange={(e) => handleChange(e)} placeholder="Enter First Name" />
                                            </div>
                                            <div className="col-md-6 mt-2 mb-3">
                                                <label htmlFor="lastname" className="form-label font-medium font-14 primary-textcolor mb-2">
                                                    Last Name
                                                </label>
                                                <input type="text" className="form-control font-regular font-14 custom-form-control py-2 login-custom-border-color"
                                                 id="lastName" 
                                                 maxLength="200"  
                                                 pattern="[A-Za-z\s]*"  // allows only alphabets and spaces
                                                 onKeyDown={(e) => handleKeyDown(e)}
                                                  onChange={(e) => handleChange(e)} placeholder="Enter Last Name" />
                                            </div>
                                            <div className="col-md-12 mt-2 mb-3">
                                                <label htmlFor="email" className="form-label font-medium font-14 primary-textcolor mb-2">
                                                    Work Email Address
                                                </label>
                                                <input type="text" className="form-control font-regular font-14 custom-form-control py-2 login-custom-border-color"
                                                 id="email" 
                                                 maxLength="200"
                                                  onChange={(e) => handleChange(e)} placeholder="Enter Email" />
                                                {errorMessage?<span class= "cls-error-message" style={{color:'red'}}>{errorMessage}</span>: null}
                                                {/* {!isEmailValid && <div className="invalid-feedback">Invalid email address.</div>} */}
                                                </div>
                                            <div className="col-md-12 mt-2 mb-3">
                                                <label htmlFor="CompanyName" className="form-label font-medium font-14 primary-textcolor mb-2">
                                                    Company Name
                                                </label>
                                                <input type="text" className="form-control font-regular font-14 custom-form-control py-2 login-custom-border-color"
                                                 id="companyName" maxLength="200"
                                                  onChange={(e) => handleChange(e)} placeholder="Enter Company Name " />
                                            </div>
                                            <div className="col-md-6 mt-2 mb-3">
                                                <label htmlFor="CompanyHeadCount" className="form-label font-medium font-14 primary-textcolor mb-2">Company Headcount</label>
                                                <select className="form-select cust-input-sty font-14 font-regular login-custom-border-color" aria-label="Default select example"
                                                 id="headCount" name='headCount' value={registerData.headCount} onChange={(e) => handleChange(e)}>
                                                    <option value="">Select</option>
                                                    {headCountOptions.map((option) => (
                                                        <option key={option.countId} value={option.countId}>
                                                            {option.countValue}
                                                        </option>
                                                    ))}
                                                    {/* <option selected>Select</option>
                                                    <option value={1}>One</option>
                                                    <option value={2}>Two</option>
                                                    <option value={3}>Three</option> */}
                                                </select>
                                            </div>
                                            <div className="col-md-6 mt-2 mb-3">
                                                <label htmlFor="CompanyHeadquartered" className="form-label font-medium font-14 primary-textcolor mb-2">Company Headquartered?</label>
                                                <select className="form-select cust-input-sty font-14 font-regular login-custom-border-color" aria-label="Default select example" id="headQuarters" name='headQuarters' value={registerData.headQuarters} onChange={(e) => handleChange(e)}>
                                                    <option value="">Select</option>
                                                    {allCountries.map((country) => (
                                                        <option key={country.isoCode} value={country.name}>
                                                            {country.name}
                                                        </option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="d-flex align-items-start mt-4">
                                                <input className="form-check-input custom-checkbox me-2" type="checkbox" defaultValue id="flexCheckDefault"  onChange={(e) => handleChange(e)} />
                                                <label  
                                                className="font-14 primary-textcolor font-regular ">Subject to AGov's <span className="secondary-textcolor font-medium">Privacy Policy</span>, you agree to allow AGov to contact you via the email provided for scheduling and marketing purposes.</label>
                                            </div>
                                            <div className="d-flex justify-content-center mt-5 mb-5">
                                                <button type="button" className={isRegisterButtonEnable ? "dark-btn w-100" : "disabled-btn w-100 mb-3 mb-md-0 font-18 font-medium px-4"} disabled={!isRegisterButtonEnable} onClick={()=>{handleSignUp()}} >
                                                    Sign up to AGov
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* login footer starts here */}
                        <div className="fixed-bottom p-0 ">
                            <div className="text-center login-custom-width footer-bg p-2 w-50">
                                <span className=" font-regular font-16 mb-0 ">
                                    Already have an account?<a href="" className="text-decoration-none font-medium " onClick={()=> redirectToLogIn()}> Log in</a>
                                </span>
                            </div>
                        </div>
                        {/* login footer ends here */}
                    </div>
                    {/*Left container ends here */}
                    {/*Right container starts here */}
                    <div className="px-4 py-3 col-lg-12 col-xl-6 right-container-login">
                        <div className="login-bg-height login-bg-img w-100 h-100 p-5">
                            <img src="images/open-quote-img.svg" alt="open-quote-img" />
                            <p className="font-16 font-regular color-white mt-4"><span className="font-medium lh-lg">Unlock Security: </span>Attain Your SOC Certificate with Confidence!</p>
                        </div>
                    </div>
                    {/*Right container ends here */}
                </div>
            </div>

            {isPopShow && <OTPVerificationPopUp
                setIsPopShow={setIsPopShow}
                registerDetails = {{
                    registerId: registerId,
                    headCount : registerData.headCount,
                    firstName : registerData.firstName,
                    email: registerData.email
                }}
            />}
            {/* Login page ends here */}

        </>
    );
};

export default RegisterPage;