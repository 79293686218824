const NoRecordFoundForAG = () => {
    return (
        <div className="row justify-content-center py-5">
            <div className="col-md-10 col-lg-5 col-sm-12 text-center py-4">
                <span>
                    <img src="/images/No Data Found - Risk.png" alt="no-rec-icon" />
                </span>
                <h5 className="font-24 font-medium primary-textcolor mt-4">No Records Found</h5>
            </div>
        </div>
    );
}

export default NoRecordFoundForAG;