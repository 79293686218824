import React from 'react';

const UploadMediaSection = ({ handleUpload, allowedExtensions, error, fileValue = "", isValidating = false }) => {
    return (
        <div>
            {isValidating ? <span className="upload-btn-wrapper me-2">
                <button
                    type="button"
                    className="cust-filter-btn font-14 py-2 font-medium primary-color upload-btn pointer"
                >
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>   Validating...
                </button>
            </span> : <span className="upload-btn-wrapper me-2">
                <button
                    type="button"
                    className="cust-filter-btn font-14 py-2 font-medium primary-color upload-btn pointer"
                >
                    <img src="/images/upload.svg" alt="browse" className="me-2" />
                    Upload
                </button>
                <input
                    type="file"
                    className="cursor-pointer py-1 upload-btn-input pointer"
                    id="upload-video"
                    name="videoBlobUrl"
                    value = {fileValue !== "" ? fileValue : ""}
                    accept={allowedExtensions}
                    onChange={(e) => handleUpload(e)}
                />
            </span>}
            <div>
                <span style={{ color: 'red' }}>{error}</span>
            </div>
        </div>
    );
};

export default UploadMediaSection;