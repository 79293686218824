import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { validatePolicyFileType, getFileNameByBlobUrl } from "../../Constant";
import { handleUpload } from '../../Configs/BlobContainer';
import { getAuditorTimelines } from "../../Services/GRC/GrcService";
import { Loader } from "../Loader/Loader";
import ToastMessageComponent from "../ToastMessageComponent/ToastMessageComponent";

const UploadReportPopup = ({ onOpen, data, auditCycleId, onClose, onSave, userId, reportLink }) => {
    // PC_UR_02
    // create the respective state variables
    const [uploadData, setUploadData] = useState({ selectedFile: null, selectedDate: auditCycleId })
    const [auditorTimelines, setAudtorTimelines] = useState([])
    const [isToastVisible, setIsToastVisible] = useState(false)
    const [fileName, setFileName] = useState(null);
    const [fileSize, setFileSize] = useState(0)
    const [reportUrl, setReportUrl] = useState(reportLink)
    const [toastMessage, setToastMessage] = useState({
        path: null,
        header: null,
        message: null,
        color: null
    })
    const [isLoading, setIsLoading] = useState(false);
    // const userId = "eb863e7c-0bbf-4f53-bfdf-eb46f01f0388";

    let response;

    /**
   * PC_UR_04
   * The useEffect triggers the function to get the auditors list
   */
    useEffect(() => {
        if (onOpen === 'new') {
            fecthAuditorTimelines()
        }
        if (reportUrl) {
            getFileName(reportUrl)
            fetchFileSizes(reportUrl)
        }
    }, []);

    /**
  * PC_UR_06
  * Makes the API call to get the auditors list
  */
    const fecthAuditorTimelines = async () => {
        setIsLoading(true)
        try {
            response = await getAuditorTimelines({ auditorId: userId })
            console.log(response.responseData[0], "response.responseData[0]");
            setAudtorTimelines(response.responseData[0])
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchFileSizes = async (fileUrl) => {
        setIsLoading(true)
        try {
            const response = await fetch(fileUrl, { method: 'HEAD' });
            if (response.ok) {
                const size = (response.headers.get('content-length') / (1024 * 1024)).toFixed(2);
                setFileSize(size)
            } else {
                throw new Error('Unable to fetch file size. Status code: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching file size:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    /**
   * PC_UR_14
   * Makes the API call to get the auditors list
   */
    const handleAdd = async () => {
        const fileSizeCheck = (uploadData.selectedFile.size/(1024 * 1024)).toFixed(2)

        if (uploadData.selectedDate && uploadData.selectedFile && fileSizeCheck <= 50) {
            setIsLoading(true)
            try {
                const blobUrl = await handleUpload(uploadData.selectedFile);
                onSave(uploadData, blobUrl);
            }
            catch (error) {
                console.log(error, "error");
            }
            finally {
                setIsLoading(false)
            }
        }
        else {
            setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Missing Fields', message: 'Please fill all the required fields', color: 'error-toast' })
            setIsToastVisible(true)
        }
    };

    const getFileName = (fileUrl) => {
        const constFileName = getFileNameByBlobUrl(fileUrl)
        setFileName(constFileName)
    }

    /**
 * PC_UR_08
 * formate the date to display
 */
    const formatTimeline = (startDate, endDate) => {
        return `${format(startDate, "MM/dd/yyyy")} - ${format(
            endDate,
            "MM/dd/yyyy"
        )}`;
    };

    /**
 * PC_UR_41
 * formate the date to display
 */
    const handleFileUpload = (event) => {
        const fileSizeCheck = (event.target.files[0].size/(1024 * 1024)).toFixed(2)
        if (validatePolicyFileType(event) && fileSizeCheck <= 50) {
            setUploadData({ ...uploadData, selectedFile: event.target.files[0] })
        }
        else {
            setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Invalid File', message: 'Please select a valid file', color: 'error-toast' })
            setIsToastVisible(true)
            console.log("File is Invalid");
        }
    }

    const deleteFile = () => {
        setUploadData({ ...uploadData, selectedFile: null })
        setReportUrl(null)
    }

    return (
        <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
            <Loader isLoading={isLoading} />
            <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                <div className="modal-content">
                    {/*modal content starts here*/}
                    <div className="modal-header border-0 pb-2 px-4">
                        <h5 className="modal-title font-bold" id="exampleModalLabel">Upload Report</h5>
                        <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => onClose()} />
                    </div>
                    <div className="modal-body border-0 font-16 px-4">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <p className="font-14 grey-secondary">Upload your SOC 2 Type 2 compliance report</p>
                            </div>
                            <div className="col-md-12 mb-4">
                                {!uploadData.selectedFile && !reportUrl ? <div className="upload-btn-wrapper-popup w-100">
                                    <div className="upload-btn-popup font-12 font-medium primary-textcolor w-100">
                                        <img src="/images/upload-file-icon.svg" alt="upload" className="me-2 mb-3" />
                                        Drag and drop, or select a file
                                        <label htmlFor="upload-btn" className="btn-upload-outline bg-white px-3 py-2 font-12 font-regular d-flex align-items-center mt-3"><img className="me-2 custom-upload-btnimg-style" src="/images/upload-btn-icon.svg" alt="upload-btn-icon" />Upload</label>
                                    </div>
                                    <input className="w-100" id="upload-btn" type="file" name="myfile" accept=".docx, .pdf" onChange={(e) => handleFileUpload(e)} />
                                </div> :
                                    <div className="col-md-12 mt-4">
                                        <div>
                                            <p className="font-16 font-medium">Files</p>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className="d-inline-flex justify-content-between align-items-center border border-radius-8 p-3">
                                                <div className="d-flex align-items-center">
                                                    <span className="course-thumbnail d-flex">
                                                        <img src="/images/pdf-icon.svg" className="w-100" alt="Thumbnail" />
                                                    </span>
                                                    <div className="ps-3 align-items-center vertical-align-middle">
                                                        <span className="font-medium font-12 primary-textcolor d-block">{uploadData.selectedFile?.name || fileName}</span>
                                                        <span className="grey-light font-regular font-12">{uploadData.selectedFile ? (uploadData.selectedFile?.size / (1024 * 1024)).toFixed(2) : fileSize}MB</span>
                                                    </div>
                                                </div>
                                                <div className="btn-group dropend ms-5">
                                                    <button className="btn border-0 p-0 align-top" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="/images/kebab-right.svg" alt="kebab-icon" className=" align-top pt-1" />
                                                    </button>
                                                    <ul className="dropdown-menu custom-dropdown-style custom-dropdown-policy shadow border-0 p-2">
                                                        <li>
                                                            <span className="dropdown-item py-2 font-14 d-flex align-items-center font-regular primary-textcolor px-2 custom-dropdown-li cursor-pointer" onClick={() => deleteFile()}>
                                                                <img src="/images/delete-icon.svg" alt="eye-icon" className="me-2" />Delete
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div className="col-md-12">
                                <label className="font-18 font-medium primary-textcolor mb-3">Audit Timeline</label>
                            </div>
                            {onOpen === 'Edit' ? <div className="col-md-12 mb-3">
                                <label htmlFor="timeline" className="form-label font-medium font-14 primary-textcolor mb-1 w-100">Timeline<span className="required-text">*</span></label>
                                <span className="font-14 font-regular">{data}</span>
                            </div> :
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="timeline" className="form-label font-medium font-14 primary-textcolor mb-1 w-100">Timeline<span className="required-text">*</span></label>
                                    <select className="form-control font-regular font-14 custom-form-control py-2" onChange={(e) => setUploadData({ ...uploadData, selectedDate: e.target.value })}>
                                        <option value="">Select</option>
                                        {auditorTimelines?.map((data) =>
                                            <option value={data.auditCycleId}>{formatTimeline(data.startDate, data.endDate)}</option>
                                        )}
                                    </select>
                                </div>}
                        </div>
                    </div>
                    <div className="modal-footer border-0 mt-2 mb-3 px-4">
                        <button type="button" className="primary-btn font-14 font-medium py-2" data-bs-dismiss="modal" onClick={() => onClose()}>Cancel</button>
                        <button type="button" className="dark-btn font-14 font-medium py-2" onClick={() => handleAdd()}>Upload</button>
                    </div>
                    {/*modal content ends here*/}
                </div>
            </div>
            {isToastVisible && <ToastMessageComponent
                toastResponse={toastMessage}
                setIsShowToastMessage={setIsToastVisible}
            />}
        </div>
    )
}

export default UploadReportPopup;