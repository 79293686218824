//PC_RTC_1 to PC_RTC_4
import React, { useState, useEffect, useContext } from 'react';
import { Select } from 'antd';
import { Loader } from '../Loader/Loader';
import { getRiskTaskConfig, insertRiskTaskConfig, updateRiskTaskConfig } from '../../Services/Admin/AdminService';
import { agovContext } from '../../Constant';
const { Option } = Select;

//PC_RTC_5 to PC_RTC_10
const SecurityRiskTaskConfig = () => {
  const { employeeName } = useContext(agovContext);

  let riskTaskConfigObj = {
    taskConfigId: null,
    riskTaskConfig: [],
    createdBy: employeeName,
    modifiedBy: employeeName,
  };

  let riskTaskConfigErrorObj = {
    riskTaskConfigError: false,
  }

  const [riskTaskData, setRiskTaskData] = useState(riskTaskConfigObj);
  const [department, setDepartment] = useState([]);
  const [errors, setErrors] = useState(riskTaskConfigErrorObj);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  //PC_RTC_17 to PC_RTC_21
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRiskTaskData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  //PC_RTC_11 to PC_RTC_16
  const validateRiskTaskConfig = () => {
    let isValid = true;
    let riskTaskConfigErrorObj = { ...errors };
    if (riskTaskData.riskTaskConfig.length == 0) {
      riskTaskConfigErrorObj.riskTaskConfigError = true;
      isValid = false;
    }
    setErrors(riskTaskConfigErrorObj);
    return isValid;
  }
  //PC_RTC_67 to PC_RTC_70
  const handleSave = async () => {

    // if (riskTaskData.taskConfigId) {
      updateRiskTaskConfiguration();
    // } else {
    //   insertRiskTaskConfiguration();
    // }
  };
  //PC_RTC_71 to PC_RTC_73
  const handleCancel = async () => {
    let riskTaskConfigObj = {
      taskConfigId: null,
      riskTaskConfig: ""
    }
    setRiskTaskData(riskTaskConfigObj)
    setErrors(riskTaskConfigErrorObj)
  };

  //PC_RTC_35 to PC_RTC_51
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const result = await getRiskTaskConfig();
      console.log(result, "getRiskTaskConfig");

      if (result.statusCode === 200 && result.responseData.recordsets[1]) {
        const departmentOptions = result.responseData.recordsets[1].map(item => ({
          label: item.employee_domain,
          value: item.employee_domain,
        }));
        setDepartment(departmentOptions); // Set options for Select dropdown

        const fetchedRiskTaskConfig = result.responseData.recordsets[0].map(item => item.risk_task_config);
        const editRiskTaskConfigObj = {
          taskConfigId: result.responseData.recordsets[0][0]?.task_config_id, // Safely access with optional chaining
          riskTaskConfig: fetchedRiskTaskConfig,
          createdBy: employeeName, // Set the user context data
          modifiedBy: employeeName, // Set the user context data
        };
        setRiskTaskData(editRiskTaskConfigObj); // Set fetched data for Select dropdown
      } else {
        console.log("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  //PC_RTC_22 to PC_RTC_34
  const insertRiskTaskConfiguration = async () => {
    try {
      setIsLoading(true);
      if (validateRiskTaskConfig()) {
        const result = await insertRiskTaskConfig(riskTaskData);
        if (result.statusCode === 200) {
          console.log("RiskTask Configuration Information inserted successfully");
        } else {
          console.log("Something went wrong!");
        }
      }
    }
    catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  //PC_RTC_52 to PC_RTC_66
  const updateRiskTaskConfiguration = async () => {
    try {
      setIsLoading(true);
      let riskTaskConfigObj = {
        riskTaskConfig: riskTaskData.riskTaskConfig,
      };
      setRiskTaskData(riskTaskConfigObj)
      if (validateRiskTaskConfig()) {
        let result = await updateRiskTaskConfig(riskTaskConfigObj);
        console.log(result, "result of risk updation");
        if (result.statusCode === 200) {
          console.log("RiskTask Configuration updated successfully");
          fetchData()
        } else {
          console.log("Something went wrong!");
        }
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
   
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between mt-4 pt-2 align-items-center">
                <div className="col-md-8">
                  <h2 className="font-bold font-20 primary-textcolor">Risk
                    Task Config</h2>
                  <p className="font-14 grey-light mt-3">You will be
                    assigning the department in order to assign risk
                    tasks.</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label className="font-bold font-14 primary-textcolor mt-3" htmlFor="dept">
                Department</label>
              <div className="col-md-12 d-flex align-items-center">
                <div className="col-md-6 d-flex gap-4">
                  <div className="col-md-7">
                    <Select
                      mode="tags"
                      style={{ width: '100%', overflowY: 'auto' }}
                      onChange={(value) => {
                        const validValues = value.filter(val => department.some(option => option.value === val));
                        handleInputChange({ target: { name: "riskTaskConfig", value: validValues } });
                      }}
                      tokenSeparators={[',']}
                      value={riskTaskData.riskTaskConfig}
                      placeholder="Select Department"
                    >
                      {/* Render options based on 'options' data */}
                      {department.map(option => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    <h6>
                      {errors.riskTaskConfigError ? (
                        <span id="alertMessage" style={{ color: "red", font: "small-caption" }}>
                          Choose Department
                        </span>) : null}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 py-5">
              <div className="d-flex justify-content-end">
                <button type="button" className="primary-btn font-14 font-medium py-2 me-3 cursor-pointer" dismiss="modal" onClick={() => { handleCancel() }}>Cancel</button>
                <button type="button" className="dark-btn font-14 font-medium py-2 cursor-pointer" onClick={() => { handleSave() }}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  )

}
export { SecurityRiskTaskConfig }