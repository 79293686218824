import React, { useEffect, useState } from 'react'
import { getSummaryDetails } from '../../Services/GRC/GrcService';
import { encryptStorage } from '../../Constant';

const ControlAuditStatus = ({ setAuditStatus, isAuditor=true }) => {

    useEffect(() => {
        loadSummaryDetails()
    }, [])



    const formatDate = (inputDate) => {
        const parsedDate = new Date(inputDate);
        const formattedDate = `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}/${parsedDate.getFullYear()}`;
        return formattedDate;
    }


    const [completedPercentage, setCompletedPercentage] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');


    /**
     * PC_CC_26 - PC_CC_29
     * The values of the summary details in the header is shown 
     */
    const loadSummaryDetails = async () => {
        let result = await getSummaryDetails({ "startDate": encryptStorage.getItem('commencer'), "endDate": encryptStorage.getItem('fin'), "auditCycleId": encryptStorage.getItem('identifiant'), "frameworkId" : 1 });
        setCompletedPercentage(result.responseData)
        setStartDate(formatDate(encryptStorage.getItem('commencer')));
        setEndDate(formatDate(encryptStorage.getItem('fin')));
        setAuditStatus((result.auditStatus).toLowerCase());
    }


    return (
        <div className="border border-radius-4 py-2 px-4 me-3 mb-xl-0 mb-3 d-flex">
            {!isAuditor ? <span className="d-flex mt-1">
                <span className="font-regular grey-tag-color font-13 me-3">
                    Control Completion Status
                </span>
                <span className="font-medium font-14 green-textcolor me-4">
                    {completedPercentage}%
                </span>
            </span> : null}
            <span className="d-flex mt-1">
                <span className="font-regular grey-tag-color font-13 me-3">
                    Audit Timeline
                </span>
                <span className="font-medium font-14 primary-textcolor">
                    {startDate} - {endDate}
                </span>
            </span>
        </div>
    )
}

export default ControlAuditStatus