import React, { useEffect, useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { agovContext } from '../../Constant';
const NoRecordsPage = (Props) => {

    const { message, isButtonVisible, buttonName, handleButtonSubmit, isMyLearningTab, isCourseTab, isMediaLibraryTab, isCampaignTab } = Props
    const [isInputTypeAsFile, setIsInputTypeAsFile] = useState(false);
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const { userId, roles, employeeName } = useContext(agovContext);

    useEffect(() => {
        if (buttonName == 'Upload') {
            setIsInputTypeAsFile(true)
        }
        else {
            setIsInputTypeAsFile(false)
        }
       
        
    }, [])


    return (
        <>
            <div className="container-fluid mt-4 mt-custom">
                <div className="col-md-12 px-5">
                    <div className="d-flex justify-content-between mt-4">
                        <h1 className="font-bold font-24 primary-textcolor">
                            Learning Management System
                        </h1>
                    </div>

                    {/*tabs starts here*/}
                    <div className="d-md-block d-lg-flex justify-content-between align-items-center ps-1 my-3 custom-tabs">
                        <ul className="nav mb-lg-0 mb-3 nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item  ms-4  me-3 " role="presentation">
                                <button className="nav-link px-0  font-14 tab-style bold-text rounded-0"
                                 id="Overview-tab" data-bs-toggle="pill" data-bs-target="#Overview"
                                  type="button" role="tab" aria-controls="Overview" aria-selected="true"
                                   onClick={() => isAdmin ? navigate('/lmsmanagement') : navigate('/lmsmanagement/myLearnings')}>

                                    <span className="tab-img"> <img src="images/lms-overview-active.svg" alt="filled-icon" className="me-2" />
                                        <img src="/images/lms-overview.svg" alt="Overview-icon" className="me-2" /></span >Overview</button>
                            </li>
                            
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link px-0 font-14 tab-style rounded-0 ${isMyLearningTab ? "active" : ""}`}
                                    id="MyLearning-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#MyLearning"
                                    type="button"
                                    role="tab"
                                    aria-controls="MyLearning"
                                    aria-selected="true"
                                    onClick={() => navigate('/lmsmanagement/myLearnings')}
                                >
                                    <span className="tab-img">
                                        {" "}
                                        <img
                                            src="/images/my-learning-active.svg"
                                            alt="filled-icon"
                                            className="me-2"
                                        />
                                        <img
                                            src="/images/my-learning-inactive.svg"
                                            alt="MyLearning-icon"
                                            className="me-2"
                                        />
                                    </span>
                                    My Learning
                                </button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button
                                    className={`nav-link px-0 font-14 tab-style rounded-0 ${isCourseTab ? "active" : ""}`}
                                    id="Courses-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Courses"
                                    type="button"
                                    role="tab"
                                    aria-controls="Courses"
                                    aria-selected="false"
                                    tabIndex={-1}
                                    onClick={() => navigate('/lmsmanagement/course')}
                                >
                                    <span className="tab-img">
                                        {" "}
                                        <img
                                            src="/images/course-active.svg"
                                            alt="filled-icon"
                                            className="me-2"
                                        />{" "}
                                        <img
                                            src="/images/course-inactive.svg"
                                            alt="Courses-icon"
                                            className="me-2"
                                        />
                                    </span>
                                    Courses
                                </button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button
                                    className={`nav-link px-0 font-14 tab-style rounded-0 ${isCampaignTab ? "active" : ""}`}
                                    id="Campaigns-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Campaigns"
                                    type="button"
                                    role="tab"
                                    aria-controls="Campaigns"
                                    aria-selected="false"
                                    tabIndex={-1}
                                    onClick={() => navigate('/lmsmanagement/campaign')}
                                >
                                    <span className="tab-img">
                                        <img
                                            src="/images/campaign-active.svg"
                                            alt="Campaigns-icon"
                                            className="me-2"
                                        />
                                        <img
                                            src="/images/campaign-inactive.svg"
                                            alt="filled-icon"
                                            className="me-2"
                                        />
                                        Campaigns
                                    </span>
                                </button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button
                                    className={`nav-link px-0 font-14 tab-style rounded-0 ${isMediaLibraryTab ? "active" : ""}`}
                                    id="MediaLibrary-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#MediaLibrary"
                                    type="button"
                                    role="tab"
                                    aria-controls="MediaLibrary"
                                    aria-selected="false"
                                    tabIndex={-1}
                                    onClick={() => navigate('/lmsmanagement/medialibrary')}
                                >
                                    <span className="tab-img">
                                        <img
                                            src="/images/media-lib-active.svg"
                                            alt="MediaLibrary-icon"
                                            className="me-2"
                                        />
                                        <img
                                            src="/images/media-lib-inactive.svg"
                                            alt="filled-icon"
                                            className="me-2"
                                        />
                                        Media Library
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="row py-3 position-relative" style={{ display: "block" }}>
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane active"
                                id="MyLearning"
                                role="tabpanel"
                                aria-labelledby="MyLearning-tab"
                            >
                                <div className="row justify-content-center py-5">
                                    <div className="col-md-10 col-lg-5 col-sm-12 text-center py-4">
                                        <span>
                                            <img src="/images/no-rec-icon.svg" alt="no-rec-icon" />
                                        </span>
                                        <h5 className="font-24 font-medium primary-textcolor mt-4">Oops..</h5>
                                        <h5 className="font-18 font-regular primary-textcolor mt-4 linehig-primary">
                                            {message}
                                        </h5>
                                        {
                                            !isInputTypeAsFile ? (
                                                isButtonVisible ? (
                                                    <button type="button" className="dark-btn mt-4 font-14 font-medium py-2 text-nowrap align-items-center" onClick={()=>handleButtonSubmit()}>
                                                        {buttonName}
                                                    </button>
                                                ) : (
                                                    <></>
                                                )
                                            ) : (
                                                <label className="dark-btn font-14 font-medium py-2 text-nowrap align-items-center pointer">
                                                    <input type="file" style={{ display: 'none' }} onChange={(event) => handleButtonSubmit(event)} />
                                                    <span>
                                                        <img src="/images/create-course-icon.svg" alt="create-course-icon" className="me-1" />
                                                    </span>
                                                    Upload
                                                </label>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NoRecordsPage;