import React, { useState } from 'react';
import { encryptStorage } from '../../Constant';
import { handleUpload } from '../../Configs/BlobContainer';
import { markAuditAsComplete } from '../../Services/GRC/GrcService';
import ToastMessageComponent from '../ToastMessageComponent/ToastMessageComponent';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';

const CompleteAuditPopup = () => {

    // PC_CC_390
    // create the respective state variables
    const [formData, setFormData] = useState({
        comments: '',
        file: null
    });
    const [uploadFinalReport, setUploadFinalReport] = useState(true);
    const [errors, setErrors] = useState({});
    const [fileRequired, setFileRequired] = useState(true);
    const [showToast, setShowToast] = useState(false);
    const [completedAuditPopupResponse, setCompletedAuditPopupResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    /**
     * PC_CC_391 - PC_CC_395
     * These fucntions handle the change in auditor comments and the file upload
     */
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({ ...prevState, [id]: value }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
            setFormData(prevState => ({ ...prevState, file }));
            setErrors(prevState => ({ ...prevState, file: null }));
        } else {
            setErrors(prevState => ({ ...prevState, file: 'Only PDF and DOCX files are allowed.' }));
        }
    };

    const handleRadioChange = (event) => {
        setUploadFinalReport(event.target.id === 'yes');
        if (event.target.id === 'no') {
            setFormData(prevState => ({ ...prevState, file: null })); setFileRequired(false);
            setErrors(errors.comments ? { "comments": errors.comments } : '');
        }
        else if (event.target.id === 'yes') {
            setFileRequired(true)
        }
    };


    /**
     * PC_CC_396 - PC_CC_408
     * It marks the audit cycle complete and stores the report  
     */
    const handleSubmit = async () => {

        const newErrors = {};
        if (!formData.comments) newErrors.comments = 'Comments are required.';
        if(fileRequired && !formData.file){
            newErrors.file = 'File is required.'; 
        }

        setErrors(newErrors);

        if (!newErrors.comments && !newErrors.file) {
            let reportBlobLink = null;
            if (formData.file != null) {
                reportBlobLink = await handleUpload(formData.file);
            }
            setIsLoading(true);
            let result = await markAuditAsComplete({ auditCycleId: encryptStorage.getItem('identifiant'), auditorComments: formData.comments, reportLink: reportBlobLink });
            
            if (result?.statusCode == 200) {
                setCompletedAuditPopupResponse({
                    path: "/images/Success-icon.svg",
                    header: "Audit has been marked as completed",
                    message: "Audit Completed",
                    color: "success-toast"
                });
            } else if (result?.statusCode != 200) {
                setCompletedAuditPopupResponse({
                    path: "/images/error-toast-icon.svg",
                    header: "Failed to mark audit as complete",
                    message: "The audit has not yet marked as complete",
                    color: "error-toast"
                }
                );
            }
            setIsLoading(false);
            setShowToast(true);
            setTimeout(() => {
                navigate(0);
            }, 3500);
        }
    };

    const handleDelete = () => {
        // Delete file logic here
        setFormData(prevState => ({ ...prevState, file: null }));
    };

    return (
        <>
            <div className="modal fade" id="complete-audit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 px-4">
                            <h5 className="modal-title font-bold mt-3" id="exampleModalLabel"><img src="/images//file-approve.png" className="file-img me-3" />Complete Audit</h5>
                            <button type="button" className="btn-close ms-auto font-12 mb-3" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body border-0 font-16 primary-textcolor px-4">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label className="font-16 font-regular">Are you sure you want to finish this audit?</label>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label htmlFor="comments" className="form-label font-medium font-14 primary-textcolor w-100">Enter Comments<span className="required-text">*</span></label>
                                    <textarea type="text" className="form-control font-regular font-14 custom-form-control py-2 resize-none" rows={4} id="comments" placeholder="Enter your comments" defaultValue={""} onChange={handleInputChange} />
                                    {errors.comments && <p className="font-13 font-regular required-text mt-2 mb-2">{errors.comments}</p>}
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label font-medium font-14 primary-textcolor w-100">Upload Final Report</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input custom-radio-input" type="radio" name="inlineRadioOptions" id="yes" defaultValue="option1" defaultChecked onChange={handleRadioChange} />
                                        <label className="form-check-label" htmlFor="yes">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input custom-radio-input" type="radio" name="inlineRadioOptions" id="no" defaultValue="option2" onChange={handleRadioChange} />
                                        <label className="form-check-label" htmlFor="no">No</label>
                                    </div>
                                    {uploadFinalReport && !formData.file && (
                                        <div className="upload-btn-wrapper-popup w-100 mt-3">
                                            <div className="upload-btn-popup font-12 font-medium primary-textcolor w-100">
                                                <img src="/images/upload.svg" alt="upload" className="me-2 mb-3 upload-image-audit"/>
                                                Drag and drop, or select a file
                                                <label htmlFor="upload-btn" className="btn-upload-outline bg-white px-3 py-2 font-12 font-regular d-flex align-items-center mt-3"><img className="me-2 custom-upload-btnimg-style" src="/images//upload-btn-icon.svg" alt="upload-btn-icon" />Upload</label>
                                            </div>
                                            <input className="w-100" id="upload-btn" type="file" name="myfile" onChange={handleFileChange} />

                                        </div>

                                    )}
                                    {uploadFinalReport && formData.file && (
                                        <div className="d-inline-flex justify-content-between align-items-center border border-radius-8 p-3 marginFile">
                                            <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => window.open(URL.createObjectURL(formData.file))}>
                                                <span className="course-thumbnail d-flex">
                                                    <img src="/images/pdf-icon.svg" className="w-100" alt="Thumbnail" />
                                                </span>
                                                <div className="ps-3 align-items-center vertical-align-middle">
                                                    <span className="font-medium font-12 primary-textcolor d-block">{formData.file.name}</span>
                                                    <span className="grey-light font-regular font-12">{(formData.file.size / 1024 / 1024).toFixed(2)} Mb</span>
                                                </div>
                                            </div>
                                            <div className="btn-group dropend ms-5">
                                                <button className="btn border-0 p-0 align-top" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="/images/kebab-right.svg" alt="kebab-icon" className=" align-top pt-1" />
                                                </button>
                                                <ul className="dropdown-menu custom-dropdown-style custom-dropdown-policy shadow border-0 p-2" style={{}}>
                                                    <li>
                                                        <span className="dropdown-item py-2 font-14 d-flex align-items-center font-regular primary-textcolor px-2 custom-dropdown-li cursor-pointer" onClick={handleDelete}>
                                                            <img src="/images/delete-icon.svg" alt="eye-icon" className="me-2" />Delete
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {errors.file && <p className="font-13 font-regular required-text mt-2 mb-2">{errors.file}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-4">
                            {/* PC_CC_409 - PC_CC_411
                            It closes the popup */}
                            <button type="button" className="primary-btn font-medium py-2 me-2" data-bs-dismiss="modal">No</button>
                            <button type="button" disabled={(fileRequired && !formData.file) || !formData.comments} className="btn dark-btn font-14 font-medium py-2" data-bs-dismiss={formData.comments == '' ? "" : "modal"} onClick={handleSubmit}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            <Loader isLoading={isLoading} />
            {showToast && <ToastMessageComponent toastResponse={completedAuditPopupResponse} setIsShowToastMessage={setShowToast} />}
        </>
    );
};

export default CompleteAuditPopup;
