//PC_APC_1 to PC_APC_4
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getPolicyConfig,
  insertPolicyConfig,
  getPolicyDynamicData,
} from "../../Services/Admin/AdminService";
import { agovContext } from "../../Constant";
import { Loader } from "../Loader/Loader";

//PC_APC_5 to PC_APC_16
const PolicyConfigPopUp=() =>{
  const navigate = useNavigate();
  const { userId, employeeName, reportsToId } = useContext(agovContext);

  let policyConfigObj = {
    policyConfigId: 1,
    eventTitle: "",
    eventDescription: "",
    policyTitle: "",
    userId: userId ? userId :"test123",
    managerId: reportsToId,
    appliedBy: employeeName,
    appliedOn: employeeName,
  };

  const [policyConfig, setPolicyConfig] = useState(policyConfigObj);
  const [openIndex, setOpenIndex] = useState(null);
  const [data, setData] = useState([]);
  const [disableValue, setDisableValue] = useState(false);
  const [showPolicyTitle, setShowPolicyTitle] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const riskTypes = [...new Set(data.map((item) => item.risk_type))];

  const [errors, setErrors] = useState({
    policyConfigIdError: false,
    policyTitleError: false,
  });

  useEffect(() => {
    policyDynamicBind();
  }, []);
  //PC_APC_51 to PC_APC_66
  const policyDynamicBind = async () => {
    try {
      setIsLoading(true);
      let result = await getPolicyDynamicData();
      if (result.statusCode === 200) {
        let riskData = result.responseData.recordsets[0];
        setData(riskData);
        console.log("Policy Configuration fetched successfully");
      } else {
        console.log("Something went wrong!");
      }
    } catch (error) {
      console.error("Error in formDynamicBind", error);
    }
    setIsLoading(false);
  };
  //PC_APC_33 to PC_APC_50
  const fetchPolicyConfig = async (selectedRiskType, selectedEventName) => {
    try {
      setIsLoading(true);
      let result = await getPolicyConfig({
        riskType: selectedRiskType,
        eventName: selectedEventName,
      });
      if (result.statusCode === 200) {
        let getPolicyConfigObj = {
          policyConfigId: result.responseData.recordsets[0][0].policy_config_id,
          eventTitle: result.responseData.recordsets[0][0].event_title,
          eventDescription:
            result.responseData.recordsets[0][0].event_description,
          policyEvent: result.responseData.recordsets[0][0].policy_event,
          policyTitle: result.responseData.recordsets[0][0].policy_title,
          userId: userId,
        };
        setPolicyConfig(getPolicyConfigObj);
        if (getPolicyConfigObj.policyConfigId) {
          setDisableValue(true);
        }
        console.log("Policy Configuration fetched successfully");
      } else {
        console.log("Something went wrong!");
      }
    } catch (error) {
      console.error("Error editing admin information:", error);
      console.log("Something went wrong!");
    }
    setIsLoading(false);
  };
  //PC_APC_20 to PC_APC_32
  const createPolicyConfig = async () => {
    try {
      setIsLoading(true);
      console.log(policyConfig,"policyconfig");
      const result = await insertPolicyConfig(policyConfig);
      if (result.statusCode === 200) {
        console.log("Admin Information added successfully");
      } else {
        console.log("Something went wrong!");
      }
    } catch (error) {
      console.error("Error inserting policy configuration:", error);
    }
    setIsLoading(false);
  };
  //PC_APC_17 to PC_APC_19
  const handleInputChange = (e) => {
   
    const { name, value } = e.target;
    setPolicyConfig((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRadioChange = (e) => {
    setShowPolicyTitle(e.target.value === "option1"); // Show Policy Title if "Yes" is selected
    if (e.target.value === "option2") {
      setShowPolicyTitle(false);
      // Reset policyTitle when "No" is selected
      setPolicyConfig({ policyTitle: "" });
    }
  };
  //PC_APC_68 to PC_APC_74
  const validatePolicyConfigForm = () => {

    let isValid = true;
    const errorObj = { ...errors };
    if (policyConfig.policyConfigId === "") {
      errorObj.policyConfigIdError = true;
      isValid = false;
    }
    if (policyConfig.policyTitle === "") {
      errorObj.policyTitleError = true;
      isValid = false;
    }

    setErrors(errorObj);
    return isValid;
  };
  //PC_APC_75 to PC_APC_78
  const handleConnectButton = () => {
    if (validatePolicyConfigForm()) {
      createPolicyConfig();
    }
  };
  //PC_APC_79 to PC_APC_80
  const handleCancel = () => {
    setPolicyConfig(policyConfigObj);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <h1 className="font-medium font-20 primary-textcolor my-3">
        Configure Event{" "}
      </h1>
      <div className="row">
        <div className="col-md-6 mb-3 mt-2">
          <label
            htmlFor="Title"
            className="form-label font-medium font-14 primary-textcolor mb-1"
          >
            Title <span className="required-text">*</span>
          </label>
          <input
            type="text"
            className="form-control font-regular font-14 custom-form-control py-2"
            id="Title"
            placeholder="Enter Title"
            name="eventTitle"
            value={policyConfig.eventTitle}
            onChange={(e) => {
              handleInputChange(e);
            }}
            disabled={disableValue}
          />
        </div>
        <div className="col-md-12 my-3">
          <label
            htmlFor="Description"
            className="form-label font-medium font-14 primary-textcolor mb-1"
          >
            Description <span className="required-text">*</span>
          </label>
          <textarea
            rows={4}
            className="form-control resize-none font-regular font-14 custom-form-control py-2"
            id="Description"
            name="eventDescription"
            value={policyConfig.eventDescription}
            onChange={(e) => {
              handleInputChange(e);
            }}
            disabled={disableValue}
            placeholder="Enter Description"
            defaultValue={""}
          />
        </div>
        <h1 className="font-medium font-20 primary-textcolor mt-4 mb-3">
          Policy Title
        </h1>
        <div className="col-md-12 mb-3 mt-2">
          <label className="form-label font-medium font-14 primary-textcolor mb-2">
            Do you have <span className="font-bold">"Event name"</span> policy
            configured in the tenant?
          </label>
          <div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-radio-input" type="radio" name="admineventname" id="admineventnameyes"
                                value="option1"
                                defaultChecked
                                onChange={handleRadioChange} />
                              <label className="form-check-label" htmlFor="admineventnameyes">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-radio-input" type="radio" name="admineventname" id="admineventnameno"
                                value="option2"
                                onChange={handleRadioChange} />
                              <label className="form-check-label" htmlFor="admineventnameno">No</label>
                            </div>
                          </div>
        </div>
        {showPolicyTitle && (
          <div className="col-md-6 my-3">
            <label
              htmlFor="PolicyTitle"
              className="form-label font-medium font-14 primary-textcolor mb-1"
            >
              Policy Title<span className="required-text">*</span>
            </label>
            <input
              type="text"
              className="form-control font-regular font-14 custom-form-control py-2"
              id="PolicyTitle"
              placeholder="Enter Title"
              name="policyTitle"
              value={policyConfig.policyTitle}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
            <h6 className="margin5Admin">
              {errors.policyTitleError ? (
                <span id="alertMessage"style={{color:"red",font:"small-caption"}}>Enter PolicyTitle</span>
              ) : null}
            </h6>
          </div>
        )}
        <div className="col-md-12">
          {showPolicyTitle && (
            <p className="font-12 font-regular primary-textcolor">
              <span className="font-medium primary-color">Note:</span> Please
              confirm that the policy title set in the cloud app security
              matches the intended title. Any discrepancy could impact the risk
              events alerts.
            </p>
          )}

          {!showPolicyTitle && (
            <p className="font-12 font-regular primary-textcolor">
              <span className="font-medium primary-color">Note:</span> Note:
              Please create your policy in the cloud apps portal.
              <a href="#" className="font-medium text-decoration-none ">
                portal.cloudappsecurity.com/#/policy
              </a>
            </p>
          )}
        </div>

        <div className="d-flex justify-content-end my-5">
          <button
            type="button"
            className="font-14 primary-btn font-medium"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="font-14 dark-btn font-medium ms-3"
            onClick={() => handleConnectButton()}
          >
            Connect
          </button>
        </div>
      </div>
    </>
  );
}

export {PolicyConfigPopUp}