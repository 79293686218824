import React from 'react';

const DeletePopup = ({onDelete, onClose, heading, subHeading, content}) => {

    return (
        <div className="modal fade show" aria-modal="true" role="dialog" style={{ display: "block",backgroundColor: "rgba(0,0,0,0.6)", "z-index": 1 }}>
            <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                <div className="modal-content">
                    {/*modal content starts here*/}
                    <div className="modal-header border-0 pb-2 px-4">
                        <h5 className="modal-title font-bold" id="exampleModalLabel"><img className="me-3" src="/images/delete-popup-icon.svg" alt="delete-icon" />{heading}</h5>
                        <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={()=> onClose()}/>
                    </div>
                    <div className="modal-body border-0 font-16 px-4">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <p className="font-16 grey-secondary custom-line-height">{content}</p>
                            </div>
                            {subHeading ? <div className="col-md-12 mb-3">
                                <span className="font-16 font-regular">Mapped Controls:</span> <span className="font-16 font-medium primary-bluecolor">{subHeading}</span>
                            </div> : null}
                        </div>
                    </div>
                    <div className="modal-footer border-0 mb-3 px-4">
                        <button type="button" className="primary-btn font-14 font-medium py-2" data-bs-dismiss="modal" onClick={()=> onClose()}>Cancel</button>
                        <button type="button" className="btn revoke-btn font-14 font-medium py-2" onClick={()=>onDelete()}>Delete</button>
                    </div>
                    {/*modal content ends here*/}
                </div>
            </div>
        </div>
    )
}

export default DeletePopup;