import React, { useState, useEffect,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';
import { getActiveCampaignApiData, getExportExcelApidata } from '../../Services/LMS/LmsService';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import { agovContext } from '../../Constant';
import NoRecordFound from '../Dashboard/dashboardNoRecordFound';
import { Loader } from '../Loader/Loader';


const ActiveCampaign = () => {
  const navigate = useNavigate();
  const { userId, roles } = useContext(agovContext);

  // State variables
  const [activeCampaign, setActiveCampaign] = useState({
    totalCampaigns: 0,
    yetToStart: 0,
    inProgress: 0,
    completed: 0,
    overDue: 0,
    activeCampaigns: 0,
    upcomingCount: 0// Example value, replace with actual fetched data
  });

  const [reqDuration, setReqDuration] = useState('Today');
  const [exportExcelData, setExportExcelData] = useState([]);
  ;
  const [isLoading, setIsLoading] = useState('false')

  // Colors for the pie chart
  const COLORS = ["#8CD8E8", "#78DC8E", "#ADADE8", "#FB8484"];

  // Function to navigate with campaign status
  const onNavigate = (status) => {
    navigate('/lmsmanagement/campaign', {
      state: {
        value: {
          status: status,
          reqDuration: reqDuration
        }
      }
    });
  };

  // Fetch active campaigns data based on duration
  const getActiveCampaign = async () => {
    setIsLoading(true)
    try {
      const payload = {
        userId: userId,
        reqDuration: reqDuration
      };

      const response = await getActiveCampaignApiData(payload);

      if (response && response.statusCode === 200) {
        setActiveCampaign(response.responseData);
      } else {
        console.error('Error fetching activeCampaign data: ' + response.statusCode);
      }
    } catch (error) {
      console.error('Error fetching activeCampaign data:', error);
    } finally {
      setIsLoading(false)
    }
  };

  // Fetch data for Excel export    PC_ActiveCampaign_37 gets  the data  for the excel 

  const getExportExcelData = async () => {
    setIsLoading(true)

    const exportExcelPayload = {
      "userId": 1,
      "widget": 'activeCampaign',
      "reqDuration": reqDuration,

    }
    try {
      const response = await getExportExcelApidata(exportExcelPayload);

      if (response && response.statusCode === 200) {
        const data = response.responseData;
        setExportExcelData(data);
        handleExportToExcel(data);
      } else {
        console.error('Error:', response ? response.statusCode : 400, 'Error message');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    finally {
      setIsLoading(false)
    }
  };

  // Handle Excel export  PC_ActiveCampaign_38 to PC_ActiveCampaign_53


  const handleExportToExcel = async (data) => {
    try {
      let excelData = data;
      let header = ["CampaignName", "ReccurisiveConditions", "Assignees", "StartDate", "EndDate","Status"];
      let tabName = "activeCampaigns";

      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('LMSReport');

      worksheet.addRow(header);

      excelData.forEach((dataItem) => {
        let rowData = header.map(column => dataItem[column]);
        worksheet.addRow(rowData);
      });

      let filename = `${tabName}_${new Date().toISOString().slice(0, 10).replace(/-/g, "")}.xlsx`;
      let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let buffer = await workbook.xlsx.writeBuffer();
      let dataBlob = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(dataBlob, filename);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  // // Handle widget change for Excel export
  // const onWidgetExcel = async (widgetValue) => {
  //   setWidget({ ...widget, widget: widgetValue });
  //   await getExportExcelData();
  // };

  // Fetch data on component mount and when reqDuration changes
  useEffect(() => {
    getActiveCampaign();
  }, [reqDuration]);

  // Data for the pie chart
  const data = [
    { name: 'activeCampaigns', value: activeCampaign.activeCampaigns },
    { name: 'Completed', value: activeCampaign.completed },
    { name: 'Upcoming', value: activeCampaign.upcomingCount },
    { name: 'Overdue', value: activeCampaign.overDue }
  ];
console.log("dataa fro ActiveCampaign",data)
console.log("setState fro ActiveCampaign",activeCampaign)
  return (
    <>
      <div className="bg-white py-2 px-3 border-radius-8 rm-dashboard-card">
        <Loader isLoading={isLoading} />
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font-14 font-medium primary-textcolor mb-0">Active Campaign Progress</h3>
          <div className="align-items-center d-flex gap-2">
            <div className="d-flex mt-2">
              <button
                className="advanced-filter-button ms-2 me-2 medium-text customflip-btn mb-2 mb-md-0"
                type="button"
                onClick={() => getExportExcelData()}
              >
                <img src="images/excel-icon.svg" alt="Filter-icon" />
              </button>
              <select
                className="form-select custom-form-control py-2 font-12 font-regular"
                onChange={(e) => setReqDuration(e.target.value)}
                value={reqDuration}
              >
                <option value="Today">Today</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
              </select>
            </div>
          </div>
        </div>
        {activeCampaign.totalCampaigns === 0 ? (
          <NoRecordFound   data={"No campaigns Assigned "}/>
        ) : (
          <div className="row align-items-center px-4">
            <div className="col-md-6">
              <div className="user-risk-image align-items-center">
                <div className="donut-chart">
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={data}
                       
                        innerRadius={80}
                        outerRadius={100}
                        fill="#8884d8"
                        strokeWidth={2}
                        cornerRadius={4}
                        dataKey="value"
                      >
                         {console.log("responsiveContainer",data)}
                        <Label value="Total Campaigns" position="center" fill="#808080" dy={-5} />
                        <Label value={activeCampaign.totalCampaigns || 0} position="center" fill="#000000" dy={15} />
                        {data.map((entry, index) => (
                          <>
                            
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          </>
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                  <div className="align-items-center d-flex align-items-baseline">
                    <span className="legends legend-cat-2 me-2 cursor-pointer" />
                    <span className="font-12 grey-light text-decoration-none cursor-pointer">Active Campaigns</span>
                  </div>
                  <div onClick={() => onNavigate('Active')}>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer">
                      {activeCampaign.activeCampaigns}
                    </label>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                  <div className="align-items-center d-flex align-items-baseline">
                    <span className="legends legend-completed me-2" />
                    <span className="font-12 grey-light text-decoration-none cursor-pointer">Completed</span>
                  </div>
                  <div onClick={() => onNavigate('Completed')}>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer">
                      {activeCampaign.completed}
                    </label>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                  <div className="align-items-center d-flex align-items-baseline">
                    <span className="legends legend-cat-1 me-2" />
                    <span className="font-12 grey-light text-decoration-none cursor-pointer">Upcoming</span>
                  </div>
                  <div onClick={() => onNavigate('Upcoming')}>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer">
                      {activeCampaign.upcomingCount}
                    </label>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-1 justify-content-between cursor-pointer">
                  <div className="align-items-center d-flex align-items-baseline">
                    <span className="legends legend-overdue me-2" />
                    <span className="font-12 grey-light text-decoration-none cursor-pointer">Overdue</span>
                  </div>
                  <div onClick={() => onNavigate('Overdue')}>
                    <label className="font-12 secondary-textcolor font-medium cursor-pointer">
                      {activeCampaign.overDue}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ActiveCampaign;
