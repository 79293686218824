/**
 * AG_PC_QH_02 - AG_PC_QH_07
 * Import statements of this component has been declared here
 */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getQuestionaryHistory, insertLogs } from '../../Services/GRC/GrcService';
import { saveAs } from 'file-saver';
import { Loader } from '../Loader/Loader';
import { agovContext } from '../../Constant';
import NotFound from '../NotFound/NotFound';


/**
 * AG_PC_QH_08 - AG_PC_QH_013
 * Define the export function and state variable declarations
 */
export function QuestionaryHistory() {
    const { employeeId, employeeName, roles, userId } = useContext(agovContext);
    const [isLoading, setIsLoading] = useState(false)
    const [historyData, setHistoryData] = useState([]);
    const [vendorGridId, setVendorGridId] = useState()
    const [vendorName, setVendorName] = useState('')
    const [clickFrom, setClickFrom] = useState('')
    const navigate = useNavigate();
    const location = useLocation();

 /** 
  * AG_PC_QH_14 - AG_PC_QH_28
  * Check for the vendorId , vendorname and clickfrom from the location state
  * Fetch the questionary histiry data of the specified vendor
  * Set state the values to the respective state variables
 */
    useEffect(() => {
        const vendor_id = location?.state?.vendor_id;
        const vendor_name = location?.state?.vendorname;
        const clickFrom = location?.state?.clickFrom
        setVendorGridId(vendor_id)
        setVendorName(vendor_name)
        setClickFrom(clickFrom)
        if (vendor_id) {
            getQuestionaryData(vendor_id);
        } else {
            navigate('/error')
        }
    }, []);

    const getQuestionaryData = async (Id) => {
        const requestBody = {
            vendorId: Id
        }

        setIsLoading(true)
        const data = await getQuestionaryHistory(requestBody);
        setIsLoading(false)

        if (data.statusCode !== 200) {
            navigate('/error');
        } else {
            setHistoryData(data.responseData);
        }
    }

/**
 * AG_PC_QH_48 - AG_PC_QH_49
 * Navigate to the Vendor Form  component
 */
    const handleNavigatePrimaryInfo = async () => {

        navigate('/compliance/vendorForm', {
            state: {
                vendor_id: vendorGridId,
                clickFrom: clickFrom
            }

        })
    }
/**
 * AG_PC_QH_50 - AG_PC_QH_51
 * Navigate to the Vendor Questionarie Page
 */
    const handleNavigateQuestionarie = async () => {

        navigate('/compliance/VendorQuestions', {
            state: {
                vendor_id: vendorGridId,
                clickFrom: clickFrom,
                vendorname: vendorName
            }

        })
    }
/**
 * AG_PC_QH_32 - AG_PC_QH_47
 * Download the questionary history file
 * Insert the reportDonwload log to viewlog table
 */
const handleDownload = async (file) => {
    setIsLoading(true);
    let fileUrl = file;
    saveAs(fileUrl, `${vendorName}_Questionaries.xlsx`);
    setIsLoading(false)
    const requestBody = {
        userId: userId,
        isDownload : true
    }
    await insertLogs(requestBody)
}

/**
 * AG_PC_QH_29 - AG_PC_QH_31
 * Check for the length and bind the questionary history data from the state variable
 */
const bindTable = () => {
    // Otherwise, map over historyData to create table rows
    return historyData?.map((rowData, index) => (
        <tr key={index}>
            <td className="font-12 font-medium">
                <p className="text-decoration-none wrap-text">{rowData?.questionaryHistoryName}</p>
            </td>
            <td className="font-12">{rowData?.createdDate}</td>
            <td className="font-12">
                <div className="d-flex align-items-center">
                    <img
                        src={rowData?.profileURL}
                        className="table-prof"
                        alt="profile"
                    />
                    <p className="font-12 primary-textcolor mb-0 ms-2">
                        {rowData?.createdBy}
                    </p>
                </div>
            </td>
            <td className="text-center">
                <button type="button" className="btn shadow-none border-0 p-0"  onClick={() => handleDownload(rowData?.historyFile)}><img src="/images/download-icon.svg" alt="download-icon" /></button>
            </td>
        </tr>
    ));
}

    return (
        <> <Loader isLoading={isLoading} />
            <div className="container-fluid mt-4 mt-custom">
                <div className="px-5">
                    <div className="row">
                        <div className="col-md-12 mb-4 d-flex justify-content-between">
                            <h5 className="font-bold font-24 cursor-pointer" style={{ 'word-break': 'break-all' }}><img className="me-3 mb-1" src="/images/back-arrow.svg" alt="back-arrow" onClick={()=>navigate('/compliance/vendor')} />{vendorName}</h5>
                        </div>
                        <div>
                            <div className="d-flex justify-content-between">
                                <ul className="nav nav-tabs border-0 mb-4" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link border-0 font-14 px-0 me-4 compliance-custom-tab " id="Primaryinfo-tab" data-bs-toggle="tab" data-bs-target="#Primaryinfo" type="button" role="tab" aria-controls="Primaryinfo" aria-selected="false" onClick={() => { handleNavigatePrimaryInfo() }}>
                                            Primary Information
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link border-0 font-14 px-0 compliance-custom-tab me-4" id="Questionnaire-tab" data-bs-toggle="tab" data-bs-target="#Questionnaire" type="button" role="tab" aria-controls="Questionnaire" aria-selected="false" onClick={() => { handleNavigateQuestionarie() }}>
                                            Questionnaire
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link border-0 font-14 px-0 compliance-custom-tab me-4 active" id="Queshistory-tab" data-bs-toggle="tab" data-bs-target="#Queshistory" type="button" role="tab" aria-controls="Queshistory" aria-selected="true">
                                            Questionnaire History
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mt-3">
                            {historyData?.length ?  <table className="table mb-0 custom-table-grid table-borderless mt-3">
                                    <thead>
                                        <tr>
                                            <th className="font-14 px-4 position-relative">Report Name
                                                <span className="position-absolute">
                                                    <a className="sort-up position-absolute ms-2"></a>
                                                </span>
                                            </th>
                                            <th className="font-14 position-relative">Uploaded Date
                                                <span className="position-absolute">
                                                    <a className="sort-up position-absolute ms-2"></a>
                                                </span>
                                            </th>
                                            <th className="font-14 position-relative">Uploaded by
                                                <span className="position-absolute">
                                                    <a className="sort-up position-absolute ms-2"></a>
                                                </span>
                                            </th>
                                            <th className="font-14 text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bindTable()}
                                    </tbody>
                                </table>:<NotFound/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
