export default function TaskAlertpopup(props) {

    const { isAlreadyAssigned, setIsAlreadyAssigned, saveTask, controlDetails, type, isSubtask, createSubtask } = props.props
    console.log(props.props, "props");
    return (
        <div>
            <div className="modal" id="add-audit" tabIndex={-1} aria-labelledby="exampleModalLabel" data-bs-backdrop="static" style={{ display: isAlreadyAssigned ? "block" : "none" }} aria-modal="true" role="dialog">
                {/* <div className="show" id="add-audit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static"> */}
                <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                    <div className="modal-content pt-3 px-2">
                        {/*modal content starts here*/}
                        <div className="modal-header border-0 px-4 d-flex">
                            <img src="images/alert-icon.svg" className="alert-icon me-3" />
                            <h5 className="modal-title font-bold" id="exampleModalLabel">Alert</h5>
                            <button type="button" className="btn-close ms-auto mb-4" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => { setIsAlreadyAssigned(false) }} />
                        </div>
                        <div className="modal-body border-0 font-16 px-4 pb-0 pt-0">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="mb-3">
                                        <p className="font-regular font-16 primary-textcolor">This {type[0]?.categoryName} <span className="font-medium font-16 primary-textcolor">({controlDetails[0]?.sourceName}) </span>
                                            document is already assinged
                                            to another user, Do you still want to proceed?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 pt-0 mb-3 px-4">
                            <button type="button" className="primary-btn font-14 font-medium py-2" onClick={() => { setIsAlreadyAssigned(false); }}>No</button>
                            <button type="button" className="btn dark-btn font-14 font-medium py-2" onClick={() => {
                                setIsAlreadyAssigned(false);
                                if (isSubtask) {
                                    createSubtask(true);
                                }
                                else {
                                    saveTask(true);
                                }
                            }} data-bs-dismiss="modal" aria-label="Close" >Yes</button>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>
            {isAlreadyAssigned && <div class="modal-backdrop fade show"></div>}
        </div>
    );
}