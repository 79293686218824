import { client } from "../../Client/Client";
import { backendUrl as URL, lmsService } from "../../Constant";

//  import { lmsUrl as URL,lmsService} from "../../Constant";
 

// my local dashboard code starts here 
export const gettileAPIdata =  async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getTileData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
export const getActiveCampaignApiData =  async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getActiveCampaign,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getUpcomingCampaignApiData =  async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getupcomingCampaigns,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getmultiInfoAPIdata =  async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getmultiTabInfo,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getExportExcelApidata =  async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getExportExcelData,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
} 

export const getUserInfoAPIdata =  async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getUserLearningInfo,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
} 


export const  getUserInfoPopupAPIdata=  async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getuserPopupInfo,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
} 
// existing code starts here 
export const getMylearningsDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getMyAssignedCampaign,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getCourseDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getCourseDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_PVDFE_NO_80 - PC_PVDFE_NO_89
* Get all the course type
*/
export const getCourseType = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getCourseType,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getVideosByType = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getVideosByType,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const postCourseDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.postCourseDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const updateCourseDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.updateCourseDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getDataForCourseId = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getDataForCourseId,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_GCDFE_NO_19 - PC_GCDFE_NO_29
* Fetching all the campaign details based on the request.
*/
export const getCampaignDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getCampaignDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_GCDFE_NO_67 - PC_GCDFE_NO_77
* Deleting the campaign, user lms progress, campaign user mapping details and changing the task status to closed.
*/
export const deleteCampaignDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.deleteCampaignDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_PVDFE_NO_32 - PC_PVDFE_NO_41
* Fetching the video details based on the video id.
*/
export const getVideoDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getVideoDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_PVDFE_NO_169 - PC_PVDFE_NO_178
* Post the video details against the video id.
*/
export const postVideoDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.postVideoDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const generateSasToken = async (data) => {

    let config = {
        method: 'POST',
        url: URL + lmsService.generateSasToken,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getMediaDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getMediaDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_GVMDFE_NO_48 - PC_GVMDFE_NO_63
* Get media details to watched the videos based on the task id, campaign id, course id and video id.
*/
export const getViewMediaDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getViewMediaDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getCourseByMediaType = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getCourseByMediaType,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_GVMDFE_NO_144 - PC_GVMDFE_NO_153
* Post the quiz completion status progress.
*/
export const postUserLmsProgress = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.postUserLmsProgress,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const postAddOnCourseDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.postAddOnCourseDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_GVMDFE_NO_34 - PC_GVMDFE_NO_46
* Track the video watched duration against the user.
*/
export const trackUserLmsProgress = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.trackUserLmsProgress,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getAllUserDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getUserDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_GCDFE_NO_180 - PC_GCDFE_NO_189
* Fetching all the courses details to bind it in the dropdown.
*/
export const getAllCourses = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getAllCourses,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/*
* PC_GCDFE_NO_164 - PC_GCDFE_NO_174
* Fetching all the Recurrence conditions details to bind it in the dropdown.
*/
export const getAllRecurrenceConditions = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getAllRecurrenceConditions,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_GCDFE_NO_113 - PC_GCDFE_NO_122
* Fetching all the priority details to bind it in the dropdown.
*/
export const getAllPriorityDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getAllPriorityDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const getTaskAssigneeDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.getTaskAssigneeDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

export const deleteCourse = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.deleteCourse,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

/*
* PC_PCDFE_NO_123 - PC_PCDFE_NO_133
* Validate the assignee details and fetch the user ids if the assignee details are valid
*/
export const validateAssigneeDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.validateAssigneeDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}


/*
* PC_GCDFE_NO_227 - PC_GCDFE_NO_236
* Creating / updating the campaign details and task details.
*/
export const postCampaignDetails = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.postCampaignDetails,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}

// validate JWT Token
export const validateJWT = async (data) => {
    let config = {
        method: 'POST',
        url: URL + lmsService.validateJWT,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    let response = await client(config);
    return response;
}
