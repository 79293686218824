import { useEffect } from "react";

export function Loader(props) {
    useEffect(() => {
    const body = document.body;
    if (props.isLoading) {
      body.classList.add('loading');
    } else {
      body.classList.remove('loading');
    }

        return () => {
        body.classList.remove('loading');
        };
    }, [props.isLoading]);

    if (props.isLoading) {
        return (
            <div className="container-fluid" style={{ "z-index": 2}}>
                <div className="overlay">
                <div class="vh-100 w-100 d-flex align-items-center justify-content-center bg-blue flex-column">
        <div class="loader spin">
            <div class="spin__blocker"></div>
            <div class="spin__bottom-left"></div>
            <div class="spin__bottom-right"></div>
            <div class="spin__top-left"></div>
        </div>
        <h3 class="mt-4 font-24 font-medium">Loading...</h3>
                </div>
                </div>
            </div>)
    }
    else {              
        return (
            <></>
        )
    }
}