import { Workbook } from "exceljs";
import FileSaver from "file-saver";

async function exportDataToExcel(data, activeTab) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Non Compliant Details");

  const headerTemplates = {
    overdue: [
      { header: "User Name", key: "column1", width: 20 },
      { header: "Campaigns", key: "column2", width: 28 },
      { header: "Days", key: "column3", width: 8 },
    ],
    risk: [
      { header: "User Name", key: "column1", width: 20 },
      { header: "Risk", key: "column2", width: 24 },
    ],
  };

  const headers = headerTemplates[activeTab];
  worksheet.columns = headers;

  data?.forEach((row) => {
    let rowWithoutExcludedColumns;

    if (activeTab === "overdue") {
      rowWithoutExcludedColumns = Object.fromEntries(
        Object.entries(row).filter(
          ([key]) => key !== "campaign_end_date" && key !== "profileImages"
        )
      );
    } else if (activeTab === "risk") {
      rowWithoutExcludedColumns = Object.fromEntries(
        Object.entries(row).filter(
          ([key]) => key !== "taskPriorityId" && key !== "profileImages"
        )
      );
    }

    worksheet.addRow(Object.values(rowWithoutExcludedColumns));
  });

  const excelBuffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const timestamp = new Date().toISOString().slice(0, 10);
  const fileName = `Non-Compliant-Details-${timestamp}.xlsx`;
  FileSaver.saveAs(blob, fileName);
}

export default exportDataToExcel;