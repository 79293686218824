import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { getVideosByType } from '../../Services/LMS/LmsService';
import { useNavigate } from 'react-router-dom';
import { agovContext } from '../../Constant';
import UploadMediaSection from './UploadMediaSection';
import UploadedMediaSection from './UploadedMediaSection';
import { Loader } from "../Loader/Loader";

const CreateCoursePopUp = (props) => {
    const { setIsPopShow, onSave, editCourseDetails, courseType, setEditCourseDetails, mediaData, setMediaData } = props;

    const navigate = useNavigate();
    const { userId, employeeName } = useContext(agovContext);

    const initialFormData = {
        userId: userId,
        userName: employeeName,
        courseId: '',
        courseName: '',
        courseDescription: '',
        courseTypeId: '',
        selectedVideos: [],
        deletedVideos: [],
        courseThumbnail: '',
        totalCourseDuration: 0,
        totalVideoCount: 0,
    };
    const [initialSelectedVideoIds, setInitialSelectedVideoIds] = useState('')
    const [isVideoDropDownVisible, setIsVideoDropDownVisible] = useState(false);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [videoOptions, setVideoOptions] = useState([]);
    const [isSubmitButtonEnable, setIsSubmitButtonEnable] = useState(false);
    const [fileError, setFileError] = useState('');
    const [selectedVideoDurations, setSelectedVideoDurations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedThumbnailValue, setUploadedThumbnailValue] = useState("");


    //PS_CF_FE_1.10 
    useEffect(() => {
        if (editCourseDetails.courseId && editCourseDetails.courseTypeId) {
            setInitialSelectedVideoIds(editCourseDetails.selectedVideos.map(video => video.value));
            fetchVideoOptions();
            setIsVideoDropDownVisible(true);
        }
    }, []);

    useEffect(() => {
        const keysToExclude = ["courseId", "deletedVideos", "courseThumbnail", "totalCourseDuration", "totalVideoCount"];
        const isValid = Object.entries(editCourseDetails)
            .filter(([key]) => !keysToExclude.includes(key))
            .every(([_, value]) => value);

        const isValidSelectedVideos = editCourseDetails.selectedVideos.length > 0;
        setIsSubmitButtonEnable(isValid && isValidSelectedVideos);
    }, [editCourseDetails]);


    //PS_CF_FE_1.13 to 1.24 to get the video details by course type 
    const fetchVideoOptions = async () => {
        setIsLoading(true);
        const request = { "courseTypeId": editCourseDetails.courseTypeId };
        const courseVideosResult = await getVideosByType(request);
        if (courseVideosResult.statusCode !== 200) {
            setIsLoading(false);
            navigate('/error');
            return;
        }

        setVideoOptions(courseVideosResult.response.map((video) => {
            return {    
                label: video.videoName,
                value: video.videoId,
                duration: video.videoDuration
            }
        }));
        setIsLoading(false);
    };

    //PS_CF_FE_6.0 to 6.5 To handle selected options on video drop down 
    const handleVideoSelected = async (selectedOptions) => {
        setSelectedVideos(selectedOptions);
        const selectedValues = selectedOptions.map(option => option);
        setEditCourseDetails(prevState => ({
            ...prevState,
            selectedVideos: selectedValues
        }));
        setSelectedVideoDurations([]);
        if (selectedOptions.length > 0) {
            setSelectedVideoDurations(selectedOptions.map(video => (video.duration)));
        }
    };

    const getTotalDuration = (videoDurations) => {
        function timeToSeconds(time) {
            const [hours, minutes, seconds] = time.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        }

        // Calculate total duration in seconds
        const totalSeconds = videoDurations.reduce((total, duration) => {
            return total + timeToSeconds(duration);
        }, 0);

        // Convert total seconds back to HH:MM:SS format
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    //PS_CF_FE_1.25 to 1.39 To store the user's forma data
    const handleInputChange = async (e) => {
        const { name, value } = e.target;

        if (value.trim() === "") {
            setEditCourseDetails({ ...editCourseDetails, [name]: "" });
            return;
        }
        setEditCourseDetails({ ...editCourseDetails, [name]: value });

        if (name == "courseTypeId") {
            setIsVideoDropDownVisible(false);
            setSelectedVideos([]);
            setEditCourseDetails(prevState => ({
                ...prevState,
                selectedVideos: []
            }));
            if (value.trim() !== "") {
                const request = { "courseTypeId": value };
                const courseVideosResult = await getVideosByType(request);
                if (courseVideosResult.statusCode !== 200) {
                    navigate('/error');
                    return;
                }
                setVideoOptions(courseVideosResult.response.map((video) => ({
                    label: video.videoName,
                    value: video.videoId,
                    duration: video.videoDuration
                })));
                setIsVideoDropDownVisible(true);
            }
        }
    }


    //PS_CF_FE_1.41 to 1.56 To validate the file type and size
    const validateFile = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileSize = Math.round((file.size / (1024 * 1024)) * 100) / 100;
            const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
            if (!allowedTypes.includes(file.type)) {
                setFileError("Invalid file format. Please upload a valid file (e.g., JPEG, PNG, JPG)");
                setMediaData(null);
                setUploadedThumbnailValue("");
                setEditCourseDetails({ ...editCourseDetails, courseThumbnail: "" });
                return;
            }
            else if (fileSize > 5) {
                setFileError("Please choose a file that is smaller than [5] MB");
                setMediaData(null);
                setUploadedThumbnailValue("");
                setEditCourseDetails({ ...editCourseDetails, courseThumbnail: "" });
                return;
            }
            setFileError('');
            const imageExtension = file.name.split('.').pop().toLowerCase();
            const imageSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
            const reader = new FileReader();
            reader.onload = (e) => {
                const uploadFile = e.target.result;
                setUploadedThumbnailValue(uploadFile);
                setMediaData({ name: file.name, size: imageSizeInMB, extension: imageExtension, src: uploadFile });
            };
            reader.readAsDataURL(file);
            setEditCourseDetails({ ...editCourseDetails, courseThumbnail: file });
        }
    };

    const handleDeleteMedia = () => {
        setUploadedThumbnailValue("");
        setEditCourseDetails(prevFormData => ({ ...prevFormData, courseThumbnail: "" }));
        setMediaData(null)
    };

    //PS_CF_FE_1.59 to 1.63
    const handleCancelClick = () => {
        setEditCourseDetails(initialFormData);
        setFileError('');
        setMediaData(null);
        setIsVideoDropDownVisible(false);
        setSelectedVideos([]);
        setEditCourseDetails({
            ...editCourseDetails,
            selectedVideos: [],
            courseTypeId: 0,
            courseId: "",
            courseName: "",
            courseDescription: "",
            courseThumbnail: "",
            totalCourseDuration: "",
            totalVideoCount: ""
        });
        setUploadedThumbnailValue("");
        setIsSubmitButtonEnable(false);
        setIsPopShow(false);
    };

    //PS_CF_FE_1.86 to 1.91 To handle when user clicks on cancel icon
    const handleCancelIconClick = () => {
        setIsPopShow(false);
        setEditCourseDetails(prevFormData => ({
            ...prevFormData,
            selectedVideos: [], // Reset selectedVideos field to an empty array
            courseTypeId: ''
        }));
        setEditCourseDetails(initialFormData);
        setUploadedThumbnailValue("");
        setIsVideoDropDownVisible(false);
        setMediaData(null);
    };

    //PS_CF_FE_1.70 to 1.84 when user clicks on create button
    const handleCreateClick = () => {
        let newVideos = [];
        let removedVideos = [];
        let req = editCourseDetails;
        if (initialSelectedVideoIds.length > 0) {
            const currentVideos = editCourseDetails.selectedVideos.map(video => video.value);

            // Find newly added videos
            newVideos = currentVideos.filter(video => !initialSelectedVideoIds.includes(video));
            // Find removed videos
            removedVideos = initialSelectedVideoIds.filter(video => !currentVideos.includes(video));

            // Update formData with new and removed videos, and subtract removed videos duration from totalCourseDuration
            setEditCourseDetails(prevState => ({
                ...prevState,
                selectedVideos: newVideos,
                deletedVideos: removedVideos,
                // totalCourseDuration: prevState.totalCourseDuration - removedVideosDuration
            }));
            req.selectedVideos = newVideos;
            req.deletedVideos = removedVideos;
        }
        // Calculate total video count
        const totalVideoCount = selectedVideos.length;
        setEditCourseDetails(prevState => ({
            ...prevState,
            totalVideoCount: totalVideoCount
        }));

        // Calculate total duration of selected videos
        const totalDuration = getTotalDuration(selectedVideoDurations);
        // Set total course duration
        setEditCourseDetails(prevState => ({
            ...prevState,
            totalCourseDuration: totalDuration
        }));

        req.totalCourseDuration = totalDuration;
        req.totalVideoCount = totalVideoCount;

        onSave(req);
        setIsPopShow(false);
        setEditCourseDetails(prevState => ({
            ...prevState,
            courseThumbnail: ''
        }))
        setEditCourseDetails(initialFormData)
        // setInitialSelectedVideoIds('')
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="modal" id="Courses-tab" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-0 pb-3 px-4">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">{editCourseDetails.courseId !== "" ? editCourseDetails.courseName : "Create Course"}</h5>
                            <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCancelIconClick()} />
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <label htmlFor="course-name" className="form-label font-medium font-14 primary-textcolor">Course Name<span className="required-text">*</span></label>
                                    <input type="text" className="form-control font-regular font-14 custom-form-control py-2" id="course-name" placeholder="Enter Course Name" name="courseName" value={editCourseDetails.courseName} onChange={(e) => handleInputChange(e)} />
                                </div>
                                <div className="col-md-12 mb-4">
                                    <label htmlFor="description" className="form-label font-medium font-14 primary-textcolor">Description<span className="required-text">*</span></label>
                                    <textarea className="form-control font-regular font-14 custom-form-control py-2 resize-none" rows={5} id="description" placeholder="Enter Description" name="courseDescription" value={editCourseDetails.courseDescription} onChange={(e) => handleInputChange(e)} />
                                </div>
                                <div className="col-md-12 mb-4">
                                    <div className="dropdown">
                                        <label className="form-label text-black font-14 font-medium">Type<span className="required">*</span>
                                        </label>
                                        <select className="form-select custom-form-control py-2 font-12 font-regular" aria-label="Default select example" name="courseTypeId" onChange={(e) => handleInputChange(e)}>
                                            <option value=''>Select</option>
                                            {
                                                courseType?.map((type, index) => (
                                                    <option key={index} value={type.courseTypeId} selected={type.courseTypeId == editCourseDetails.courseTypeId}>{type.courseType}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                {(isVideoDropDownVisible && editCourseDetails.courseId !== "") &&
                                    <div className="col-md-12 mb-4">
                                        <div className="dropdown">
                                            <label className="form-label text-black font-14 font-medium">
                                                Selected Videos<span className="required">*</span>
                                            </label>
                                            <Select
                                                options={videoOptions}
                                                isMulti
                                                onChange={(selectedOptions) => handleVideoSelected(selectedOptions)}
                                                name="selectedVideos"
                                                defaultValue={editCourseDetails.selectedVideos ?? []}
                                            />
                                        </div>
                                    </div>
                                }
                                {(isVideoDropDownVisible && editCourseDetails.courseId === "") &&
                                    <div className="col-md-12 mb-4">
                                        <div className="dropdown">
                                            <label className="form-label text-black font-14 font-medium">
                                                Selected Videos<span className="required">*</span>
                                            </label>
                                            <Select
                                                options={videoOptions}
                                                isMulti
                                                onChange={(selectedOptions) => handleVideoSelected(selectedOptions)}
                                                name="selectedVideos"
                                                value={selectedVideos}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="col-md-12 mb-4">
                                    <label htmlFor="upload-thumbnail" className="form-label font-medium font-14 primary-textcolor">Upload Thumbnail</label>
                                    {mediaData ? (
                                        <div><UploadedMediaSection mediaData={mediaData} onDelete={handleDeleteMedia} isMedia={false} /></div>
                                    ) : (
                                        <UploadMediaSection
                                            handleUpload={validateFile}
                                            allowedExtensions=".jpg, .png, .jpeg"
                                            error={fileError}
                                            fileValue={uploadedThumbnailValue}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 mt-2 mb-3 px-4">
                            <button type="button" className="primary-btn font-14 font-medium py-2" data-bs-dismiss="modal" onClick={() => handleCancelClick()}>Cancel</button>
                            <button type="button" className={isSubmitButtonEnable ? "dark-btn" : "disabled-btn" + " font-14 font-medium py-2"} onClick={isSubmitButtonEnable ? handleCreateClick : null} disabled={!isSubmitButtonEnable} >{editCourseDetails.courseId === "" ? "Create" : "Update"}</button>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </>
    );
}
export default CreateCoursePopUp;