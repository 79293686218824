import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { agovContext, encryptStorage, getFileIcon, getFileNameByBlobUrl, validatePolicyFileType } from "../../Constant";
import { handleUpload } from '../../Configs/BlobContainer';
import { deleteParticularDocument, deletePolicyOrEvidence, generateSasToken, getPolicyDetails, insertLog, uploadPolicy } from "../../Services/GRC/GrcService";
import { Loader } from "../Loader/Loader";
import ToastMessageComponent from "../ToastMessageComponent/ToastMessageComponent";
import AssignPopupComponent from "./AssignPopupComponent";
import DeletePopup from "./DeletePopup";
import RemovePopup from "./RemovePopup";
import { saveAs } from "file-saver";
import TaskForm from "../Task/TaskForm";

const PolicyDetails = () => {
    // PC_PD_02
    // create the respective state variables
    const navigate = useNavigate()
    const location = useLocation();
    const policyId = location?.state?.policyId
    const [policyInformation, setPolicyInformation] = useState({ policyId: '', policyName: '' });
    const [policyDetails, setPolicyDetails] = useState(null)
    const [fileSize, setFileSize] = useState(0)
    const [fileType, setFileType] = useState('')
    const [fileName, setFileName] = useState(null);
    const [identifier, setIdentifier] = useState('policyControl');
    const [popupName, setPopupName] = useState('Controls');
    const [reRender, setReRender] = useState(true);
    const [controlInformation, setControlInformation] = useState('');
    const [unmapId, setUnmapId] = useState('');
    const [unmapName, setUnmapName] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState('')
    const [deletePopupData, setDeletePopupData] = useState({ heading: null, subHeading: null, content: null })
    const [mappedControlNames, setMappedControlNames] = useState(null)
    const [isToastVisible, setIsToastVisible] = useState(false)
    const [toastMessage, setToastMessage] = useState({
        path: null,
        header: null,
        message: null,
        color: null
    })
    const [isLoading, setIsLoading] = useState(false);
    const [isViewTask, setIsViewTask] = useState('');
    const [viewTaskId, setViewTaskId] = useState('');
    const [isCreateTask, setIsCreateTask] = useState(false);
    const [isUpdateTask, setIsUpdateTask] = useState(false);
    const [isSuccessToastMessage, setIsSuccessToastMessage] = useState(false)

    const endDate = encryptStorage.getItem('fin')
    const startDate = encryptStorage.getItem('commencer')
    const { roles, userId } = useContext(agovContext);

    const isAdmin = roles.includes('Super Admin') || roles.includes('GRC Admin')
    const viewAsAuditor = encryptStorage.getItem('viewAsAuditor') == "true" ? true : false

    let response;
    /**
     * PC_PD_05
     * The useEffect calls the API to get the data for page if policyId value is there when ever the reRender changes
     */
    useEffect(() => {
        if (policyId) {
            fetchPolicyDetails()
        }
        else {
            navigate('/compliance/policyGrid')
        }
    }, [reRender]);

    /**
 * PC_PD_06
 * Get the policy details form the DB
 */
    const fetchPolicyDetails = async () => {
        setIsLoading(true)
        try {
            response = await getPolicyDetails({ policyId, startDate, endDate })
            setPolicyInformation({ policyId: response?.responseData?.policyDetails?.policyId, policyName: response?.responseData?.policyDetails?.policyName })
            setPolicyDetails(response.responseData)
            if (response.responseData.policyDetails?.policyBlobLink) {
                fetchFileSizes(response?.responseData?.policyDetails)
                getFileName(response?.responseData?.policyDetails?.policyBlobLink)
            }
            const allPrincipleId = response.responseData.mappedControls.flatMap(obj => obj.PrincipleId);
            const uniquePrins = [...new Set(allPrincipleId)];
            const result = uniquePrins.join(', ');
            setMappedControlNames(result)
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }
    /**
     * PC_PD_62
     * Get the file name by using the file URL
     */
    const getFileName = (fileUrl) => {
        const constFileName = getFileNameByBlobUrl(fileUrl)
        setFileName(constFileName)
        fetchFileTypes(constFileName)
    }

    /**
 * PC_PD_55
 * Get the file size based on the file url
 */
    const fetchFileSizes = async (fileUrl) => {
        setIsLoading(true)
        try {
            const response = await fetch(fileUrl?.policyBlobLink, { method: 'HEAD' });
            if (response.ok) {
                const size = (response.headers.get('content-length') / (1024 * 1024)).toFixed(2);
                setFileSize(size)
            } else {
                throw new Error('Unable to fetch file size. Status code: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching file size:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    /**
 * PC_PD_68
 * Get the file extention based on the file url
 */
    const fetchFileTypes = async (fileName) => {
        const extention = getFileIcon(fileName)
        setFileType(extention)
    };

    /**
 * PC_PD_215
 * Call the Upload API and pass the uploaded file
 */
    const handleFileUpload = async (event) => {
        const fileSizeCheck = (event.target.files[0].size/(1024 * 1024)).toFixed(2)

        if (validatePolicyFileType(event) && fileSizeCheck <= 50) {
            setIsLoading(true)
            try {
                const selectedFile = await handleUpload(event.target.files[0])
                // try {
                await uploadPolicy({ blobLink: selectedFile, blobName: event.target.files[0].name, policyId: policyId, userId: userId })
                await fetchPolicyDetails()
            }
            catch (error) {
                setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Invalid File', message: 'Please select a valid file', color: 'error-toast' })
                setIsToastVisible(true)
            }
            finally {
                setIsLoading(false)
            }
        }
        else {
            setToastMessage({ ...toastMessage, path: '/images/error-toast-icon.svg', header: 'Invalid File', message: 'Please select a valid file', color: 'error-toast' })
            setIsToastVisible(true)
        }
    }

    /**
     * PC_PD_156
     * Download the selected file
     */
    const handleDownload = async (type) => {
        let fileUrl = '';
        setIsLoading(true)
        if (type === 'Template') {
            try {
                let response = await generateSasToken({ resourceTypes: "o", permission: "r" });
                const sasToken = response.responseData;
                fileUrl = policyDetails?.policyDetails?.policyTemplateBlobLink + sasToken
                const file = fileUrl.split('?')
                const fileExtension = getFileExtension(file[0]);

                saveAs(fileUrl, fileName);
            }
            catch (error) {
                console.log(error, "error")
            }
            finally {
                setIsLoading(false)
            }
        }
        else {
            try {
                fileUrl = policyDetails?.policyDetails?.policyBlobLink
                const file = fileUrl.split('?')
                const fileExtension = getFileExtension(file[0]);

                saveAs(fileUrl, fileName);
                await insertLog({ event: 'policyDownload', eventDescription: policyDetails.policyDetails.policyName, userId: userId, category: 'grcManagement', riskType: 'download', result: 'Sucess' })
            }
            catch (error) {
                console.log(error, "error");
            }
            finally {
                setIsLoading(false)
            }
        }
    }

    /**
 * PC_PD_71
 * Get the file type
 */
    const getFileExtension = (url) => {
        const filename = url.substring(url.lastIndexOf("/") + 1);
        const extension = filename.split(".").pop();
        return extension;
    };

    /**
 * PC_PD_72
 * Delete the selected document
 */
    const deleteDocument = async () => {
        setIsLoading(true)
        const blobLink = policyDetails.policyDetails?.policyBlobLink?.split('?')
        try {
            setShowDeletePopup(false)
            await deleteParticularDocument({ userId: userId, blobLink: blobLink[0], deletionId: policyId, identifier: "policy" })
            await fetchPolicyDetails()
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
   * PC_PD_280
   * Open the selected file in new tab
   */
    const openFileInNewWindow = async (file, fileName) => {
        setIsLoading(true)
        try {
            response = await generateSasToken({ resourceTypes: "o", permission: "r" })
            const sasToken = response.responseData;
            const fileUrl = file + sasToken
            saveAs(fileUrl, fileName);
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    };

    /**
 * PC_PD_74
 * formate the date to display
 */
    const formatTimeline = (date) => {
        return `${format(date, "MM/dd/yyyy")}`;
    };

    /**
 * PC_PD_261
 * Delete the hole policy
 */
    const deletePolicy = async () => {
        setIsLoading(true)
        try {
            await deletePolicyOrEvidence({ identifier: 'policy', deletionId: policyId })
            navigate(-1)
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }

    /**
 * PC_PD_255
 * Call the delete function according to the user click
 */
    const handleDeleteClick = () => {
        if (showDeletePopup === 'doc') {
            deleteDocument()
        }
        else {
            deletePolicy()
        }
    }

    /**
 * PC_PD_250
 * Call the delete popup component by sending the required inputs
 */
    const handleDeleteDocument = () => {
        setDeletePopupData({ ...deletePopupData, heading: 'Delete Policy Document', content: 'Please unmap any controls associated with this policy document before deleting to avoid data inconsistencies.', subHeading: mappedControlNames })
        setShowDeletePopup('doc')
    }

    /**
* PC_PD_250
* Call the delete popup component by sending the required inputs
*/
    const handleDeletePolicy = () => {
        setDeletePopupData({ ...deletePopupData, heading: 'Delete Policy', content: 'Are you sure you want to delete this Policy? This action will permanently remove the selected Policy contact information.', subHeading: null })
        setShowDeletePopup('policy')
    }

    /**
   * PC_PD_270
   * Close the delete popup
   */
    const handleCloseDeletePopup = () => {
        setShowDeletePopup('')
    }

    const handleUnmap = (data) => {
        setControlInformation({
            controlDetails: {
                controlIdentity: data?.controlIdentity,
                controlName: data?.ControlName,
                controlId: data?.ControlId
            }
        });
        setUnmapId(policyDetails?.policyDetails?.policyId);
        setUnmapName(policyDetails?.policyDetails?.policyName);
        setIdentifier("controlPolicy");
    }

    const navigateTaskAsAuditor = () => {
        setViewTaskId(policyDetails?.policyDetails?.policyTaskId)
        setIsViewTask(true)
    }


    return (
        <div className="container-fluid mt-4 mt-custom">
            <Loader isLoading={isLoading} />
            <div className="col-md-12 px-5">
                <div className="d-lg-flex justify-content-between mt-4">
                    <div className="d-flex align-items-center">
                        <img src="/images/left-arrow.svg" alt="back" className="me-3 pointer" style={{cursor : 'pointer'}} onClick={() => navigate(-1)} />
                        <h1 className="font-bold font-24 primary-textcolor mb-0">
                            {policyDetails?.policyDetails?.policyName}
                        </h1>
                    </div>
                    {isAdmin ? <div className="d-flex justify-content-end align-items-center mt-2 mt-lg-0">
                        <p className="font-regular font-13 mb-0 me-3"><span className={policyDetails?.policyDetails?.policyStatus === "Accepted" ? "green-dot big-dot me-2" : policyDetails?.policyDetails?.policyStatus === "Upload Pending" ? "orange-dot big-dot me-2" : "grey-dot big-dot me-2"}
                        />{policyDetails?.policyDetails?.policyStatus}
                        </p>
                        <button type="button" className="cust-filter-btn font-14  font-regular primary-textcolor download-btn " onClick={() => handleDeletePolicy()}>
                            <img src="/images/delete-icon-grey.svg" alt="browse" className="me-2" />Delete Policy
                        </button>
                    </div> : null}
                </div>
                {!policyDetails?.policyDetails?.policyBlobLink && isAdmin && !viewAsAuditor ? <div className="col-md-12">
                    <div className="upload-files mt-4 p-4">
                        <div className="col-md-12 col-sm-12 p-0">
                            <span className="upload-btn-wrapper upload-wrapper d-block text-center">
                                <img src="/images/upload-btn-icon.svg" alt="dropfiles" className="drop-file text-center mb-2" />
                                <span className="font-regular font-13 d-block text-center primary-textcolor mb-3">
                                    Drag and drop, or select a file
                                </span>
                                <span className="text-center upload-btn-wrapper mb-3">
                                    <button type="button" className="cust-filter-btn font-14  font-medium primary-color upload-btn ">
                                        <input type="file" accept=".docx, .jpg, .pdf, .png, .webp, .xlsx, .csv, .txt" onChange={(e) => handleFileUpload(e)} />
                                        <img src="/images/upload.svg" alt="upload" className="me-2" />Upload
                                    </button>
                                </span>
                                <span className="font-regular font-10 d-block text-center position-relative grey-light">
                                    Upload files up to 50 MB of the following types: .docx, .pdf
                                </span>
                                <input type="file" name="myfile" className="cursor-pointer cust-file" id="OrganizationLogo" />
                            </span>
                        </div>
                    </div>
                </div> :
                    <div className="col-md-12 mt-4">
                        <div>
                            <p className="font-16 font-medium">Files</p>
                        </div>
                        <div className="col-md-12 mb-4">
                            <div className="d-inline-flex justify-content-between align-items-center border border-radius-8 p-3">
                                <div className="d-flex align-items-center">
                                    <span className="course-thumbnail d-flex">
                                        <img src={fileType} className="w-100" alt="Thumbnail" />
                                    </span>
                                    <div className="ps-3 align-items-center vertical-align-middle">
                                        <span className="font-medium font-12 primary-textcolor d-block">{fileName}</span>
                                        <span className="grey-light font-regular font-12">{fileSize}</span>
                                    </div>
                                </div>
                                <div className="btn-group dropend ms-5">
                                    <button className="btn border-0 p-0 align-top" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="/images/kebab-right.svg" alt="kebab-icon" className=" align-top pt-1" />
                                    </button>
                                    <ul className="dropdown-menu custom-dropdown-style custom-dropdown-policy shadow border-0 p-2">
                                        <li>
                                            <span className="dropdown-item  font-14 d-flex align-items-center font-regular primary-textcolor custom-dropdown-li py-2 px-2 cursor-pointer" onClick={() => handleDownload('File')}>
                                                <img src="/images/download-light-blue-icon.svg" alt="download-icon" className="me-2" />Download
                                            </span>
                                        </li>
                                        {isAdmin ? <li>
                                            <span className="dropdown-item py-2 font-14 d-flex align-items-center font-regular primary-textcolor px-2 custom-dropdown-li cursor-pointer" onClick={() => !viewAsAuditor ? handleDeleteDocument() : null}>
                                                <img src="/images/delete-icon.svg" alt="eye-icon" className="me-2" />Delete
                                            </span>
                                        </li> : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>}
                {isAdmin && policyDetails?.policyDetails?.policyType != 'custom' ? <div className="col-md-12">
                    <div className="template-container p-4 mt-4">
                        <div className=" d-flex justify-content-between align-items-center">
                            <div>
                                <div className="d-flex align-items-center mb-3"><img src="/images/info-icon-unfilled.svg" className="me-2" />
                                    <p className="font-16 font-medium primary-textcolor mb-0">Use an Editable Template</p>
                                </div>
                                <p className="font-13 font-regular primary-textcolor ps-4 mb-0">If you don't already have a
                                    template, download one of our compliance-ready editable templates.</p>
                            </div>
                            <div>
                                <button type="button" className="cust-filter-btn font-14 font-regular primary-color upload-btn " onClick={() => handleDownload('Template')}>
                                    <img src="/images/download-blue-icon.svg" alt="upload" className="me-2" />Download Template
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {/* Accordion structure starts here */}
                <ul className="list-unstyled ps-0">
                    <li className=" py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <button className="p-0 d-flex align-items-center border-0 shadow-none orgtodo-accbutton-custom bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#details" aria-expanded="true" aria-controls="details">
                                <span className="font-16 font-medium mb-0 primary-textcolor ms-3">Details</span>
                            </button>
                        </div>
                        <div id="details" className="accordion-collapse table-parent-accordion collapse show pt-3">
                            <div className="rounded-4 cust-acc-box-shadow py-2">
                                <div className="cus-tab-border violet-tab-bg py-3 px-4">
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="CompanyName" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Description
                                        </label>
                                        <p className="font-12 font-regular mb-2 data-color">
                                            {policyDetails?.policyDetails?.policyDescription}
                                        </p>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="Companybackground" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Task ID
                                        </label>
                                        <p className={policyDetails?.policyDetails?.policyTaskId ? "font-12 font-medium secondary-textcolor mb-2 data-color pointer" : "font-12 font-medium secondary-textcolor mb-2 data-color"} style={policyDetails?.policyDetails?.policyTaskId ? { color: "blue" } : {}} onClick={() => policyDetails?.policyDetails?.policyTaskId ? (!isAdmin ? navigateTaskAsAuditor() : navigate('/taskmanagement', { state: { viewTaskId: policyDetails?.policyDetails?.policyTaskId } })) : null}>
                                            {policyDetails?.policyDetails?.policyTaskId || 'Nil'}
                                        </p>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label htmlFor="Companybackground" className="form-label font-medium font-14 primary-textcolor mb-1">
                                            Assigned To
                                        </label>
                                        <div className="d-flex align-items-center">
                                            {policyDetails?.policyDetails?.assignedTo ? <img src={policyDetails?.policyDetails?.assignedToProfileUrl} className="table-prof" /> : null}
                                            <p className="font-12 primary-textcolor mb-0 ms-2">{policyDetails?.policyDetails?.assignedTo || 'Unassigned'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className=" py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <button className="p-0 d-flex align-items-center border-0 shadow-none orgtodo-accbutton-custom bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#controls" aria-expanded="false" aria-controls="controls">
                                <span className="font-16 font-medium mb-0 primary-textcolor ms-3">Controls</span>
                            </button>
                            {isAdmin ? <div className="d-flex align-items-center">
                                <button className="create-task-button medium-text customflip-btn" type="button" disabled={viewAsAuditor} onClick={() => { setIdentifier('policyControl') }} data-bs-toggle="modal" data-bs-target="#add-policy" >
                                    <img src="/images/Plus-icon.svg" alt="Plus-icon" className="plus-icon me-2" />Map Control
                                </button>
                            </div> : null}
                        </div>
                        <div id="controls" className="accordion-collapse table-parent-accordion collapse pt-3">
                            <div className="rounded-4 cust-acc-box-shadow py-2">
                                <div className="cus-tab-border violet-tab-bg py-1 px-4">
                                    <div className="col-md-12 mb-3">
                                        <div className="table-responsive">
                                            <table className="table text-nowrap mb-0 table-borderless mt-3">
                                                <thead>
                                                    <tr>
                                                        <th className="font-14 font-medium position-relative">Section Code</th>
                                                        <th className="font-14 font-medium position-relative">Control</th>
                                                        {isAdmin ? <th className="font-14 font-medium position-relative text-center">Action</th> : null}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {policyDetails?.mappedControls?.map((data) =>
                                                        <tr>
                                                            <td className="font-12 font-regular">{data?.PrincipleId?.join(', ')}</td>
                                                            <td className="font-12 font-regular">{data?.ControlName}</td>
                                                            {isAdmin ? <td data-bs-toggle="modal" data-bs-target="#unmap-control" className="text-center" onClick={() => viewAsAuditor ? null : handleUnmap(data)}><img src="/images/link-icon.svg" alt="link" className="cursor-pointer" /></td> : null}
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className=" py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <button className="p-0 d-flex align-items-center border-0 shadow-none orgtodo-accbutton-custom bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#versionHistory" aria-expanded="false" aria-controls="versionHistory">
                                <span className="font-16 font-medium mb-0 primary-textcolor ms-3">Version History</span>
                                <div className="tooltip-container">

                                    <img src="/images/info-icon.svg" className="ms-1" />
                                    <div className="tooltip">Download Version History</div>
                                </div>
                            </button>
                        </div>
                        <div id="versionHistory" className="accordion-collapse table-parent-accordion collapse pt-3">
                            <div className="rounded-4 cust-acc-box-shadow py-2">
                                <div className="cus-tab-border violet-tab-bg py-1 px-4">
                                    <div className="col-md-12 mb-3">
                                        <div className="table-responsive">
                                            <table className="table text-nowrap mb-0 table-borderless mt-3">
                                                <thead>
                                                    <tr>
                                                        <th className="font-14 font-medium position-relative">Document Name</th>
                                                        <th className="font-14 font-medium position-relative">Uploader Name</th>
                                                        <th className="font-14 font-medium position-relative">Audit Timeline</th>
                                                        <th className="font-14 font-medium position-relative">Uploaded Date</th>
                                                        <th className="font-14 font-medium position-relative">Approver Name</th>
                                                        <th className="font-14 font-medium position-relative">Approved Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {policyDetails?.policyHistory?.map((data) =>
                                                        <tr>
                                                            <td className="font-12 font-regular secondary-textcolor cursor-pointer" style={{ color: "blue" }} onClick={() => openFileInNewWindow(data?.uploadedPolicyBlobLink, data?.uploadedPolicyName)}>{data?.uploadedPolicyName}</td>
                                                            <td className="font-12 font-regular">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={data?.uploaderProfileUrl} className="table-prof" />
                                                                    <p className="font-12 primary-textcolor mb-0 ms-2">{data.policyUploadedBy}</p>
                                                                </div>
                                                            </td>
                                                            <td className="font-12 font-regular">{data?.policyAuditTimeline}</td>
                                                            <td className="font-12 font-regular">{formatTimeline(data?.policyUploadedOn)}</td>
                                                            <td className="font-12 font-regular">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={data?.approverProfileUrl} className="table-prof" />
                                                                    <p className="font-12 primary-textcolor mb-0 ms-2">{data?.policyApproverId}</p>
                                                                </div></td>
                                                            <td className="font-12 font-regular">{formatTimeline(data?.policyApprovedOn)}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                {/* Accordion structure ends here */}
                {policyDetails ? <AssignPopupComponent identifier={identifier} popupName={popupName} mappingInformation={policyInformation} reRender={reRender} setReRender={setReRender} mappingFrom={'policy'} /> : null}
                <RemovePopup identifier={identifier} controlInformation={controlInformation} mappingId={unmapId} mappingName={unmapName} render={reRender} setReRender={setReRender} />
                {showDeletePopup ? <DeletePopup onClose={handleCloseDeletePopup} onDelete={handleDeleteClick} heading={deletePopupData.heading} subHeading={deletePopupData.subHeading} content={deletePopupData.content} /> : null}
                {isToastVisible && <ToastMessageComponent
                    toastResponse={toastMessage}
                    setIsShowToastMessage={setIsToastVisible}
                />}
                {isViewTask ? <TaskForm props={
                    {
                        taskId: viewTaskId,
                        isViewTask: isViewTask,
                        setIsViewTask: setIsViewTask,
                        setIsCreateTask: setIsCreateTask,
                        setIsUpdateTask: setIsUpdateTask,
                        setIsSuccessToastMessage: setIsSuccessToastMessage
                    }} /> : <></>
                }
            </div>
        </div >
    )
}

export default PolicyDetails;