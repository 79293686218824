import React from 'react';

const QuizCompletionPopUp = ({ setIsQuizCompletionVisible, totalScoreResult }) => {
    return (
        <>
            {/* Modal */}
            <div
                className="modal"
                id="delete"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                style={{ display: 'block' }}
            >
                <div className="modal-dialog modal-dialog-centered quiz-question-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-0 pb-3 px-4">
                            <button
                                type="button"
                                className="btn-close ms-auto pointer"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setIsQuizCompletionVisible(false);
                                    window.location.reload();
                                }}
                            />
                        </div>
                        <div className="modal-body border-0 font-16 px-4 quiz-bg position-relative" style={{ zIndex: 1 }}>
                            <img
                                src="/images/gif-quiz.svg"
                                className="position-absolute quiz-bg"
                            />
                            <div className="row mt-5 mb-5">
                                <div className="col-md-12 mt-5 text-center">
                                    <span className="mb-5">
                                        <img
                                            src="/images/quiz-completed.svg"
                                            alt="Quiz"
                                            className="mb-4"
                                        />
                                    </span>
                                    <h3 className="font-bold font-24 mb-3">
                                        Quiz has been completed successfully
                                    </h3>
                                    <p className="font-16 font-regular primary-textcolor text-center mb-4 px-5">
                                        Total Score:
                                        <span className={"font-medium " + (totalScoreResult.isPass ? "secondary-textcolor" : "red-textcolor")}>
                                            {totalScoreResult.obtainedScore}
                                        </span>{" "}
                                    </p>
                                    <p className="font-12 primary-textcolor font-regular text-center mb-2">
                                        <span className="font-medium">Note:</span> To successfully
                                        finish the task, achieving a 100% score is necessary.{" "}
                                    </p>
                                    <p className="font-12 primary-textcolor font-regular text-center mb-4">
                                        Please revisit the video and complete the quiz accordingly.
                                    </p>
                                    <button
                                        type="button"
                                        className="dark-btn font-14 font-medium py-2 mt-2 mb-5 pointer position-relative"
                                        onClick={() => {
                                            setIsQuizCompletionVisible(false);
                                            window.location.reload();
                                        }}
                                        style={{ zIndex: 2 }}
                                    >
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </>
    );
};

export default QuizCompletionPopUp;