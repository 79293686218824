import { useContext, useEffect, useState } from "react"

export default function SuccessToastMessage(props) {


    const { isSuccessToastMessage, setIsSuccessToastMessage, toastMessage, setToastMessage } = props.props

    useEffect(() => {
        setTimeout(() => {
            setIsSuccessToastMessage(false);
        }, 3000);
    })

    return (
        isSuccessToastMessage ?
            <div className="position-fixed  top-0 start-50 translate-middle-x mt-custom">
                <div id="taskToast" className="toast task-created-toast show" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="toast-body d-flex align-items-center">
                        <img src="images/Success-icon.svg" className="me-2" alt="success-icon" />
                        <p className="font-regular font-14 me-2 mb-0">{toastMessage}</p>
                    </div>
                </div>
            </div> :
            <></>
    )
}