import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { agovContext, encryptStorage } from '../../Constant';
import {
    createEvidenceOrPolicy,
    getStatusDetails,
    getPoliciesGrid,
    getUserLists
} from "../../Services/GRC/GrcService";
import { Loader } from "../Loader/Loader";
import AddPolicyPopup from "./AddPolicyPopup";
import ControlAuditStatus from "./ControlAuditStatus";
import NoDataFound from "../LMS/NoDataFound";
import RiskNoDataFound from "../Risk/RiskNoDataFound";

const PolicyGrid = () => {
    // PC_PG_02
    // create the respective state variables

    const navigate = useNavigate();
    const [policyGridData, setPolicyGridData] = useState([]);
    const [filterData, setFilterData] = useState({
        sortColumn: "policy.created_on",
        sortOrder: "desc",
        limit: 10,
        startDate: encryptStorage.getItem('commencer'),
        endDate: encryptStorage.getItem('fin'),
        searchTerm: "",
        status: null,
        assignedTo: null,
        approvedBy: null
    });
    const [filterPopupData, setFilterPopupData] = useState({
        status: null,
        assignedTo: null,
        approvedBy: null
    })
    const [count, setCount] = useState(0);
    const [showAddPolicyPopup, setShowAddPolicyPopup] = useState(false);
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [auditorList, setAuditorList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [auditStatus, setAuditStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { roles, userId } = useContext(agovContext);

    const isAdmin = roles.includes('Super Admin') || roles.includes('GRC Admin')
    const viewAsAuditor = encryptStorage.getItem('viewAsAuditor') == "true" ? true : false

    let response;

    /**
     * PC_PG_03
     * The useEffect calls the API to get the data for the grid when ever the filterData changes
     */
    useEffect(() => {
        fetchPolicyGridData();
    }, [filterData]);
    /**
     * PC_PG_04
     * The useEffect calls the API to get the data for the dropdown
     */
    useEffect(() => {
        // fetchDropdownDetails();
    }, []);

    /**
     * PC_PG_07
     * Make an API call to get the table data
     */
    const fetchPolicyGridData = async () => {
        
        try {
            setIsLoading(true)
            response = await getPoliciesGrid(filterData);
            setPolicyGridData(response.responseData.recordsets[0]);
            setCount(response.responseData.recordsets[1][0].count);
             const response1 = await getUserLists()
            const response2 = await getStatusDetails({identifier: 'policy'})
            setAuditorList(response1.responseData[0]);
            setStatusList(response2.responseData[0]);
            setIsLoading(false)
        } catch (error) {
            console.log(error, "error");
        }
        finally {
            
        }
    };

    /**
     * PC_PG_20
     * Make the API calls to get all the user lists, and evidence status list to bind it in the dropdown
     */
    const fetchDropdownDetails = async () => {
        setIsLoading(true)
        try {
            const response1 = await getUserLists()
            const response2 = await   getStatusDetails({identifier: 'policy'})
            
            setAuditorList(response1.responseData[0]);
            setStatusList(response2.responseData[0]);
        } catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    };

    /**
     * PC_PG_52
     * -Filter the table data according to the searched value
     */
    const handleSearch = () => {
        setFilterData({ ...filterData, searchTerm: filterData.searchTerm.trim() });
    };

    /**
 * PC_PG_38
 * formate the date to display
 */
    const formatTimeline = (startDate) => {
        const date = new Date(startDate);
        return format(date, "MM/dd/yyyy");
    };

    /**
 * PC_PG_40
 * Close the add policypopup
 */
    const closeAddNewPolicyPopup = () => {
        setShowAddPolicyPopup(false)
    }

    /**
 * PC_PG_73
 * Create a new custom policy
 */
    const handleAddPolicy = async (data) => {
        setIsLoading(true)
        const addEvidenceData = {
            evidenceName: data?.policyTitle,
            userId: userId,
            startDate: filterData.startDate,
            endDate: filterData.endDate,
            evidenceDescription: data?.description,
            evidenceType: 'Policy',
            startDate: filterData.startDate
        }
        try {
            setShowAddPolicyPopup(false)
            await createEvidenceOrPolicy(addEvidenceData)
            await fetchPolicyGridData()
        }
        catch (error) {
            console.log(error, "error");
        }
        finally {
            setIsLoading(false)
        }
    }
    /**
     * PC_PG_97
     * Filter the grid data according to the data entered in the filter by the user
     */
    const handleFilter = () => {
        setFilterData({ ...filterData, assignedTo: filterPopupData.assignedTo || null, approvedBy: filterPopupData.approvedBy || null, status: filterPopupData.status || null })
        setShowFilterPopup(false)
    }

    /**
 * PC_PG_92
 * Clear all the filters in the grid
 */
    const handleClearFilter = () => {
        setFilterPopupData({ ...filterPopupData, status: null, assignedTo: null, approvedBy: null })
        setFilterData({ ...filterData, status: null, assignedTo: null, approvedBy: null })
        setShowFilterPopup(false)
    }

    return (
        <div className="container-fluid mt-4 mt-custom">
            <Loader isLoading={isLoading} />
            <div className="col-md-12 px-lg-5">
                <div className="d-xl-flex d-block justify-content-between mt-4 mb-3">
                    <h1 className="font-bold font-24 primary-textcolor d-flex  align-items-center mb-xl-0 mb-3">
                        <a><img src="/images/back-arrow.svg" alt="Back" className="me-3 pointer" style={{cursor : 'pointer'}} onClick={() => navigate(-1) } /></a> <span>SOC 2</span>
                    </h1>
                    <div className="me-2">
                        <div className="d-lg-flex d-block">
                            <ControlAuditStatus setAuditStatus={setAuditStatus} isAuditor={!isAdmin} />

                            <div className="d-flex">
                                <div className="input-group flex-nowrap search-group me-2">
                                    <input type="text" className="form-control search-align" placeholder="Search Policy Name" aria-label="search" aria-describedby="addon-wrapping" onChange={(e) => filterData.searchTerm = e.target.value}
                                        onKeyDownCapture={(e) => {
                                            if (e.key == "Enter") {
                                                handleSearch();
                                            }
                                        }} />
                                    <button className="input-group-text btn btn-search-custom" id="addon-wrapping"><img src="/images/search-icon.svg" alt="search-icon" onClick={() => handleSearch()}/></button>
                                </div>
                            </div>
                            <div className="me-2 position-relative">
                                <button className="cust-filter-btn d-flex align-items-center" type="button" onClick={() => setShowFilterPopup(!showFilterPopup)}>
                                    <img src="/images/filter-icon.svg" alt="filter-icon" className="filt-icon me-2" />Filter
                                </button>
                                {showFilterPopup ? <div className="z-index-9 custom-filter custom-filter-container border-0">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="font-18 font-bold text-black mb-0">Advanced Filter</h5>
                                            <button type="button" className="btn-close font-12" aria-label="Close" onClick={() => setShowFilterPopup(false)} />
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label className="form-label text-black font-12 font-medium">Assigned
                                                        To</label>
                                                    <select className="form-select cust-input-sty py-2 font-14 font-regular" aria-label="Default select example" value={filterPopupData.assignedTo} onChange={(event) => setFilterPopupData({ ...filterPopupData, assignedTo: event.target.value })}>
                                                        <option selected value="">Select</option>
                                                        {auditorList.map((data) => (
                                                            <option value={data.userId}>
                                                                {data.employeename}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label className="form-label text-black font-12 font-medium">Approved
                                                        By</label>
                                                    <select className="form-select cust-input-sty font-14 py-2 font-regular" aria-label="Default select example" value={filterPopupData.approvedBy} onChange={(event) => setFilterPopupData({ ...filterPopupData, approvedBy: event.target.value })}>
                                                        <option selected value="">Select</option>
                                                        {auditorList.map((data) => (
                                                            <option value={data.userId}>
                                                                {data.employeename}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="mb-3">
                                                    <label className="form-label text-black font-12 font-medium">Status
                                                    </label>
                                                    <select className="form-select cust-input-sty font-14 py-2 font-regular" aria-label="Default select example" value={filterPopupData.status} onChange={(event) => setFilterPopupData({ ...filterPopupData, status: event.target.value })}>
                                                        <option selected value="">Select</option>
                                                        {statusList.map((data) => (
                                                            <option value={data.policyStatus}>
                                                                {data.policyStatus}
                                                            </option>))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end mt-4 mb-2">
                                            <button type="button" className="primary-btn font-14 font-medium me-2" onClick={() => handleClearFilter()}>
                                                Clear
                                            </button>
                                            <button type="button" className="dark-btn font-14 font-medium px-4" onClick={() => handleFilter()}>
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                            <div>
                                {isAdmin ? <button className="create-task-button medium-text customflip-btn" type="button" disabled={viewAsAuditor} onClick={() => setShowAddPolicyPopup(!showAddPolicyPopup)}>
                                    <img src="/images/Plus-icon.svg" alt="Plus-icon" className="plus-icon me-2" />Add Policy
                                </button> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="d-flex justify-content-between align-items-center ps-1 my-3 mb-4 custom-tabs">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item me-2" role="presentation">
                                <button
                                    className="nav-link px-0 bold-text font-14 tab-style rounded-0"
                                    id="Compliancecontrols-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Compliancecontrols"
                                    type="button"
                                    role="tab"
                                    aria-controls="Compliancecontrols"
                                    aria-selected="false"
                                    onClick={() => navigate("/compliance/controls")}
                                >
                                    <span className="tab-img">
                                        {" "}
                                        <img
                                            src="/images/control-active-icon.svg"
                                            className="me-2"
                                        />{" "}
                                        <img src="/images/control-icon.svg" className="me-2" />
                                    </span>
                                    Controls
                                </button>
                            </li>
                            <li className="nav-item ms-4 me-2" role="presentation">
                                <button
                                    className="nav-link px-0 font-14 tab-style active rounded-0"
                                    id="Compliancepolicies-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Compliancepolicies"
                                    type="button"
                                    role="tab"
                                    aria-controls="Compliancepolicies"
                                    aria-selected="true"
                                >
                                    <span className="tab-img">
                                        {" "}
                                        <img
                                            src="/images/policy-active-icon.svg"
                                            className="me-2"
                                        />{" "}
                                        <img src="/images/policies-icon.svg" className="me-2" />{" "}
                                    </span>
                                    Policies
                                </button>
                            </li>
                            <li className="nav-item ms-4 me-2" role="presentation">
                                <button
                                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                                    id="Complianceevidences-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Complianceevidences"
                                    type="button"
                                    role="tab"
                                    aria-controls="Complianceevidences"
                                    aria-selected="false"
                                    onClick={() => navigate('/compliance/evidenceGrid')}
                                >
                                    <span className="tab-img">
                                        {" "}
                                        <img
                                            src="/images/evidence-active-icon.svg"
                                            className="me-2"
                                        />{" "}
                                        <img src="/images/evidence-icon.svg" className="me-2" />{" "}
                                    </span>
                                    Evidences
                                </button>
                            </li>
                            <li className="nav-item ms-4" role="presentation">
                                <button
                                    className="nav-link px-0 font-14 tab-style bold-text rounded-0"
                                    id="Complianceotherdocs-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Complianceotherdocs"
                                    type="button"
                                    role="tab"
                                    aria-controls="Complianceotherdocs"
                                    aria-selected="false"
                                    onClick={() => navigate('/compliance/otherDocumentsGrid')}
                                >
                                    <span className="tab-img">
                                        {" "}
                                        <img
                                            src="/images/other-docs-active-icon.svg"
                                            className="me-2"
                                        />{" "}
                                        <img src="/images/other-docs-icon.svg" className="me-2" />{" "}
                                    </span>
                                    Other Documents
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="col-md-12">
                            {/*Policies table*/}
                            <h5 className="font-bold font-20 primary-textcolor">
                                Policies
                            </h5>
                            <div className="table-responsive">
                                {policyGridData?.length ? <table className="table mb-0 custom-table-grid table-borderless mt-3">
                                    <thead>
                                        <tr>
                                            <th className="font-14 px-4 position-relative">Policy Name
                                                <span className="position-absolute">
                                                    <a className="sort-up position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'PolicyName', sortOrder: 'asc' })}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'PolicyName', sortOrder: 'desc' })}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                </span>
                                            </th>
                                            <th className="font-14 position-relative">Date Uploaded
                                                <span className="position-absolute">
                                                    <a className="sort-up position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'PolicyUploadedOn', sortOrder: 'asc' })}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'PolicyUploadedOn', sortOrder: 'desc' })}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                </span>
                                            </th>
                                            <th className="font-14 position-relative">Assigned to
                                                <span className="position-absolute">
                                                    <a className="sort-up position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'AssignedToEmployeeName', sortOrder: 'asc' })}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'AssignedToEmployeeName', sortOrder: 'desc' })}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                </span>
                                            </th>
                                            <th className="font-14 position-relative">Approved Date
                                                <span className="position-absolute">
                                                    <a className="sort-up position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'LastApprovedVersion', sortOrder: 'asc' })}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'LastApprovedVersion', sortOrder: 'desc' })}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                </span>
                                            </th>
                                            <th className="font-14 position-relative">Approved By
                                                <span className="position-absolute">
                                                    <a className="sort-up position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'ApprovedByEmployeeName', sortOrder: 'asc' })}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'ApprovedByEmployeeName', sortOrder: 'desc' })}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                </span>
                                            </th>
                                            <th className="font-14 position-relative">Status
                                                <span className="position-absolute">
                                                    <a className="sort-up position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'PolicyStatus', sortOrder: 'asc' })}><img className="sort-img-size" src="/images/sort-up.svg" alt="sort-up-icon" /></a> <a className="sort-down position-absolute ms-2 pointer" onClick={() => setFilterData({ ...filterData, sortColumn: 'PolicyStatus', sortOrder: 'desc' })}><img className="sort-img-size" src="/images/sort-down.svg" alt="sort-down-icon" /></a>
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {policyGridData.map((data) =>
                                            <tr>
                                                <td className="font-12 font-medium secondary-textcolor d-flex align-items-center">
                                                    <a className="text-decoration-none me-3 pointer wrap-text" style={{cursor : 'pointer'}} onClick={() =>
                                                        navigate("/compliance/policyDetails", {
                                                            state: {
                                                                policyId: data.policyId,
                                                            },
                                                        })
                                                    }>{data.PolicyName}</a>
                                                    {data.PolicyType === 'custom' ? <span className="policy-custom-btn d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">Custom
                                                    </span> : null}
                                                </td>
                                                <td className="font-12">{data.PolicyUploadedOn ? formatTimeline(data.PolicyUploadedOn) : 'Nill'}</td>
                                                <td className="font-12">
                                                    <div className="d-flex align-items-center">
                                                        {data.AssignedToEmployeeName ? <img src={data.AssignedToProfileUrl} className="table-prof" /> : null}
                                                        <p className="font-12 primary-textcolor mb-0 ms-2">{data.AssignedToEmployeeName || 'Unassigned'}</p>
                                                    </div>
                                                </td>
                                                <td className="font-12">{data.LastApprovedVersion ? formatTimeline(data.LastApprovedVersion) : 'Nill'}</td>
                                                <td className="font-12">
                                                    <div className="d-flex align-items-center">
                                                        {data.ApprovedByEmployeeName ? <img src={data.ApprovedByProfileUrl} className="table-prof" /> : null}
                                                        <p className="font-12 primary-textcolor mb-0 ms-2">{data.ApprovedByEmployeeName || 'Unassigned'}</p>
                                                    </div>
                                                </td>
                                                <td className="font-12"><span className={data?.PolicyStatus === "Accepted" ? "green-dot big-dot me-2" : data?.PolicyStatus === "Upload Pending" ? "orange-dot big-dot me-2" : "grey-dot big-dot me-2"} />{data.PolicyStatus}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table> : <RiskNoDataFound data={"No Records Found"}/>}
                            </div>
                            {showAddPolicyPopup ? <AddPolicyPopup onClose={closeAddNewPolicyPopup} onSave={handleAddPolicy} /> : null}
                            <div className="mt-3">
                                <p className="font-12 grey-primary">Showing <span className="font-medium primary-textcolor">{count >= filterData.limit ? filterData.limit : count}{" "}</span>
                                    out of <span className="font-medium primary-textcolor">{count}</span> items</p>
                            </div>
                            {count > filterData.limit ? <div className="text-center mt-3">
                                <button className="primary-btn font-medium" onClick={() => {
                                    setFilterData({
                                        ...filterData,
                                        limit: filterData.limit + 10,
                                    });
                                }}>Load More</button>
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicyGrid;