import React, { useState, useEffect, useContext } from 'react';
import { getTaskAssigneeDetails, validateAssigneeDetails } from '../../Services/LMS/LmsService';
import { agovContext, downloadTemplate } from '../../Constant';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import ExcelJS from 'exceljs';
import moment from 'moment';
import UploadedMediaSection from '../LMS/UploadedMediaSection';
import UploadMediaSection from '../LMS/UploadMediaSection';

const CreateCampaignWizardForm2 = ({ setCurrentForm, allPriorityDropdownValues, setTaskData, taskData, campaignData, handlePostCampaignDetails, close, excelData, setExcelData, isAssigneeTypeVisible, setIsAssigneeTypeVisible, isImportExcelVisible, setIsImportExcelVisible, isSelectAssigneeVisible, setIsSelectAssigneeVisible, selectAssigneeDropdownValues, setSelectedAssigneeDropdownValues, isAssigneeTypeIndividual, setIsAssigneeTypeIndividual, selectedAssigneeDetails, setSelectedAssigneeDetails, selectedUserType, setSelectedUserType, selectedAssigneeType, setSelectedAssigneeType }) => {

    //PC_PCDFE_NO_80
    const navigate = useNavigate();

    //PC_PCDFE_NO_81
    const { userId } = useContext(agovContext);

    //PC_PCDFE_NO_82
    const expectedHeaders = ["Employee name", "Primary email"];

    //PC_PCDFE_NO_83
    const validateErrorObject = {
        validateAssigneeError: "",
        validateStartDateError: "",
        validateEndDateError: ""
    }

    /*
    * PC_PCDFE_NO_84 - PC_PCDFE_NO_85
    * Initizaling the state variable
    */
    const [validateTaskError, setValidateTaskError] = useState(validateErrorObject);
    const [isValidTaskData, setIsValidTaskData] = useState(false);
    const [uploadedFileValue, setUploadedFileValue] = useState("");
    const [isExcelValidating, setIsExcelValidating] = useState("");

    /*
    * PC_PCDFE_NO_86 - PC_PCDFE_NO_88
    * Initizaling a useEffect with dependencies value of state variable taskData
    */
    useEffect(() => {
        if (taskData.priorityId === 0 || taskData.startDate === "" || taskData.endDate === "" ||
            !(taskData.assigneeDetails.userIds.length > 0 || taskData.assigneeDetails.domainNames.length > 0)) {
            setIsValidTaskData(false);
        } else {
            setIsValidTaskData(true);
            
        }

    }, [taskData]);


    /*
    * PC_PCDFE_NO_92 - PC_PCDFE_NO_90
    * dynamic dropdown generation for priority
    */
    const generatePriorityDropdown = () => {
        return allPriorityDropdownValues?.map((item) => {
            return <option key={item.taskPriorityId} value={item.taskPriorityId} selected={item.taskPriorityId === taskData.taskPriorityId}>{item.taskPriority}</option>;
        });
    }


    /*
    * PC_PCDFE_NO_89 - PC_PCDFE_NO_95
    * handle uploaded excel delete
    */
    const handleDeleteExcelFile = () => {
        setExcelData(null);
        setUploadedFileValue("");
        setIsExcelValidating(false);
        setTaskData({
            ...taskData,
            assigneeDetails: {
                ...taskData.assigneeDetails,
                userIds: [],
                domainNames: []
            }
        });
    }

    /*
    * PC_PCDFE_NO_89 - PC_PCDFE_NO_90
    * handle user input change in task form
    */
    const handleInputChange = (event) => {
        if (event.target.name === "startDate") {
            const startDate = new Date(event.target.value);
            startDate.setHours(0, 0, 0, 0);

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            const endDate = new Date(taskData.endDate);
            endDate.setHours(0, 0, 0, 0);

            if (startDate < currentDate || startDate > endDate) {
                setTaskData({ ...taskData, startDate: "" });
                setValidateTaskError({ ...validateTaskError, validateStartDateError: "Invalid start date" });
                return;
            } else {
                setValidateTaskError({ ...validateTaskError, validateStartDateError: "" });
            }
        }

        if (event.target.name === "endDate") {
            const endDate = new Date(event.target.value);
            endDate.setHours(0, 0, 0, 0);
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            const startDate = new Date(taskData.startDate);
            startDate.setHours(0, 0, 0, 0);

            if (endDate < currentDate || endDate < startDate) {
                setTaskData({ ...taskData, endDate: "" });
                setValidateTaskError({ ...validateTaskError, validateEndDateError: "Invalid end date" })
                return;
            } else {
                setValidateTaskError({ ...validateTaskError, validateEndDateError: "" })
            }
        }

        setTaskData({ ...taskData, [event.target.name]: event.target.value });
    }

    /*
    * PC_PCDFE_NO_97 - PC_PCDFE_NO_102
    * handle selected assingee details dropdown
    */
    const handleSelectedAssigneeDetails = (value) => {
        setSelectedAssigneeDetails(value);
        const assigneeDetails = value.map(item => item.value);
        if (isAssigneeTypeIndividual) {
            setTaskData({ ...taskData, assigneeDetails: { ...taskData.assigneeDetails, userIds: assigneeDetails } });
        } else {
            setTaskData({ ...taskData, assigneeDetails: { ...taskData.assigneeDetails, domainNames: assigneeDetails } });
        }
    }

    /*
    * PC_PCDFE_NO_165 - PC_PCDFE_NO_191
    * handle the dropdown select in task form
    */
    const handleDropdownChange = async (event) => {
        if (event.target.name === "selectedUserType") {
            setSelectedUserType(event.target.value);
            setSelectedAssigneeType("");
            setTaskData({
                ...taskData,
                assigneeDetails: {
                    ...taskData.assigneeDetails,
                    userIds: [],
                    domainNames: []
                }
            });
            setValidateTaskError({ ...validateTaskError, validateAssigneeError: "" });
            setSelectedAssigneeDropdownValues([]);
            setIsSelectAssigneeVisible(false);
            if (event.target.value.trim() !== "") {
                setIsAssigneeTypeVisible(event.target.value === "Select Manually" ? true : false);
                setIsImportExcelVisible(event.target.value === "Import from Excel" ? true : false);
            } else {
                setIsAssigneeTypeVisible(false);
                setIsImportExcelVisible(false);
            }
        }

        if (event.target.name === "assigneeType" && event.target.value.trim() !== "") {
            setIsSelectAssigneeVisible(false);
            setSelectedAssigneeType(event.target.value);
            setSelectedAssigneeDropdownValues([]);
            setSelectedAssigneeDetails([]);
            setTaskData({
                ...taskData,
                assigneeDetails: {
                    ...taskData.assigneeDetails,
                    userIds: [],
                    domainNames: []
                }
            });

            const req = {
                sourceId: campaignData.campaignId,
                assigneeType: event.target.value,
                userId: userId,
            };
            setIsAssigneeTypeIndividual(event.target.value === "Individual" ? true : false);
            const assigneeTypeResult = await getTaskAssigneeDetails(req);
            if (assigneeTypeResult.statusCode !== 200) {
                navigate('/error');
                return;
            }
            setSelectedAssigneeDropdownValues(assigneeTypeResult.response);
            setIsSelectAssigneeVisible(true);
        }
    }

    /*
    * PC_PCDFE_NO_103 - PC_PCDFE_NO_141
    * handle uploaded excel file valdiate for assignee
    */
    const handleFileUpload = (e) => {
        setValidateTaskError({ ...validateTaskError, validateAssigneeError: "" });
        setIsExcelValidating(true);
        const file = e.target.files[0];

        if (file) {
            // Check file size
            if (file.size > 50 * 1024 * 1024) {
                setValidateTaskError({ ...validateTaskError, validateAssigneeError: "File size exceeds 50 MB" });
                setUploadedFileValue("");
                setIsExcelValidating(false);
                setExcelData(null);
                return;
            }

            // Check file extension
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();
            const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);

            if (fileExtension !== 'xls' && fileExtension !== 'xlsx') {
                setValidateTaskError({ ...validateTaskError, validateAssigneeError: "Invalid file format. Please upload a valid file (e.g., xls, xlsx)" });
                setUploadedFileValue("");
                setIsExcelValidating(false);
                setExcelData(null);
                return;
            }

            const reader = new FileReader();

            reader.onload = async (event) => {
                try {
                    const workbook = new ExcelJS.Workbook();
                    await workbook.xlsx.load(event.target.result);

                    // Assuming only one sheet in the Excel file
                    const worksheet = workbook.getWorksheet(1);
                    const headers = worksheet.getRow(1).values;

                    // Check if expected headers are present
                    if (!validateHeaders(headers)) {
                        setValidateTaskError({ ...validateTaskError, validateAssigneeError: "Invalid headers. Please check the Excel file." });
                        setUploadedFileValue("");
                        setIsExcelValidating(false);
                        setExcelData(null);
                        return;
                    }

                    // Headers are valid, proceed with data extraction
                    const jsonData = [];
                    worksheet.eachRow((row, rowNumber) => {
                        if (rowNumber > 1) {
                            const rowData = {};
                            row.eachCell((cell, colNumber) => {
                                rowData[headers[colNumber]] = cell.value;
                            });
                            jsonData.push(rowData);
                        }
                    });

                    if (jsonData.length === 0) {
                        setValidateTaskError({ ...validateTaskError, validateAssigneeError: "No data. Please fill the Excel file." });
                        setUploadedFileValue("");
                        setIsExcelValidating(false);
                        setExcelData(null);
                        return;
                    }

                    const transformedData = jsonData.map(item => ({
                        employeeName: item["Employee name"],
                        primaryEmail: item["Primary email"]
                    }));

                    const req = {
                        assigneeDetails: transformedData,
                        campaignId: campaignData.campaignId
                    };

                    const validUserIds = await validateAssigneeDetails(req);
                    if (validUserIds.statusCode !== 200) {
                        setUploadedFileValue("");
                        setIsExcelValidating(false);
                        setExcelData(null);
                        navigate('/error');
                        return;
                    }

                    if (validUserIds.response.length === 0) {
                        setValidateTaskError({ ...validateTaskError, validateAssigneeError: "Invalid Assignee details" });
                        setUploadedFileValue("");
                        setIsExcelValidating(false);
                        setExcelData(null);
                        return;
                    }

                    setValidateTaskError({ ...validateTaskError, validateAssigneeError: "" });
                    setUploadedFileValue(file);
                    setTimeout(() => {
                        setIsExcelValidating(false);
                    }, 5000);
                    setExcelData({ name: fileName, size: fileSizeInMB, extension: fileExtension, src: "/images/excel-icon.svg" });

                    setTaskData({ ...taskData, assigneeDetails: { ...taskData.assigneeDetails, userIds: validUserIds.response } });
                } catch (error) {
                    navigate('/error');
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    /*
    * PC_PCDFE_NO_163 - PC_PCDFE_NO_164
    * validating the headers in excel
    */
    const validateHeaders = (actualHeaders) => {
        return Object.values(actualHeaders).every((header, index) => header === expectedHeaders[index]);
    };

    return (
        <>
            <div
                className="modal"
                id="add-audit"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-0 pb-3 px-4">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">
                                {campaignData.campaignId !== "" ? campaignData.campaignName : "Create Campaign"}
                            </h5>
                            <button
                                type="button"
                                className="btn-close ms-auto pointer"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { setUploadedFileValue(""); close(); }}
                            />
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="row">
                                {/* wizard starts here */}
                                <div className="d-flex position-relative justify-content-center mb-4">
                                    <div className="wizard-list completed">
                                        <div className="wizard-img font-medium font-12 mb-2">1</div>
                                        <div className="wizard-img wizard-img-completed font-medium font-12 mb-2 position-relative">
                                            <img src="/images/completed-vector.svg" />
                                        </div>
                                        <span className="wizard-text font-medium font-12">Campaign</span>
                                    </div>
                                    <div className="progress wizard-progress ">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                    <div className="wizard-list active">
                                        <div className="wizard-img font-medium font-12 mb-2">2</div>
                                        <div className="wizard-img wizard-img-completed font-medium font-12 mb-2 position-relative">
                                            <img src="/images/completed-vector.svg" />
                                        </div>
                                        <span className="wizard-text font-medium font-12">Task</span>
                                    </div>
                                </div>
                                {/* wizard sends here */}
                                <div className="col-md-6 mb-4">
                                    <div className="dropdown">
                                        <label className="form-label text-black font-14 font-medium">
                                            Select Users Type<span className="required">*</span>
                                        </label>
                                        <select
                                            className="form-select custom-select font-12 font-regular py-2"
                                            aria-label="Default select example" name="selectedUserType"
                                            onChange={(e) => handleDropdownChange(e)}
                                        >
                                            <option value="">Select</option>
                                            <option value="Select Manually" selected={selectedUserType === "Select Manually"}>Select Manually</option>
                                            <option value="Import from Excel" selected={selectedUserType === "Import from Excel"}>Import from Excel</option>
                                        </select>
                                    </div>
                                </div>
                                {isAssigneeTypeVisible && <div className="col-md-6 mb-4">
                                    <div className="dropdown">
                                        <label className="form-label text-black font-14 font-medium">
                                            Assignee Type<span className="required">*</span>
                                        </label>
                                        <select
                                            className="form-select custom-select font-12 font-regular py-2"
                                            aria-label="Default select example" name="assigneeType"
                                            onChange={(e) => handleDropdownChange(e)}
                                        >
                                            <option value="" selected={selectedAssigneeType === ""}>Select</option>
                                            <option value="Individual" selected={selectedAssigneeType === "Individual"}>Individual</option>
                                            <option value="Department" selected={selectedAssigneeType === "Department"}>Department</option>
                                        </select>
                                    </div>
                                </div>}
                                {isSelectAssigneeVisible && <div className="col-md-6 mb-4">
                                    <div className="dropdown">
                                        <label
                                            htmlFor="assignee"
                                            className="form-label font-medium font-14 primary-textcolor mb-1"
                                        >
                                            Select Assignee<span className="required">*</span>
                                        </label>
                                        <Select
                                            options={selectAssigneeDropdownValues}
                                            isMulti
                                            onChange={(selectedOptions) => handleSelectedAssigneeDetails(selectedOptions)}
                                            name="selectedAssigneeDetails"
                                            value={selectedAssigneeDetails}
                                        />
                                    </div>
                                </div>}
                                <div className="col-md-6 mb-4">
                                    <div className="dropdown">
                                        <label
                                            htmlFor="assignee"
                                            className="form-label font-medium font-14 primary-textcolor mb-1"
                                        >
                                            Priority<span className="required">*</span>
                                        </label>
                                        <select
                                            className="form-select custom-select font-12 font-regular py-2"
                                            aria-label="Default select example"
                                            name="priorityId"
                                            value={taskData.priorityId}
                                            onChange={(e) => handleInputChange(e)}
                                        >
                                            <option value="">Select</option>
                                            {generatePriorityDropdown()}
                                        </select>
                                    </div>
                                </div>
                                {(!campaignData.isRecursive && campaignData.campaignId === "") ? <div className="col-md-6 mb-4">
                                    <label
                                        htmlFor="start-date"
                                        className="form-label font-medium font-14 primary-textcolor"
                                    >
                                        Start Date<span className="required-text">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control font-regular font-14 custom-form-control py-2"
                                        id="start-date"
                                        placeholder="Enter Campaign Name"
                                        value={taskData.startDate}
                                        name="startDate"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <div>
                                        <span style={{ color: 'red' }}>{validateTaskError.validateStartDateError}</span>
                                    </div>
                                </div> :
                                    <div className="col-md-6 mb-4">
                                        <label
                                            htmlFor="start-date"
                                            className="form-label font-medium font-14 primary-textcolor"
                                        >
                                            Start Date
                                        </label>
                                        <span className="d-block font-13 font-regular py-2">
                                            {moment(taskData.startDate).format('MM/DD/YYYY')}
                                        </span>

                                    </div>}
                                {(!campaignData.isRecursive && campaignData.campaignId === "") ? <div className="col-md-6 mb-4">
                                    <label
                                        htmlFor="end-date"
                                        className="form-label font-medium font-14 primary-textcolor"
                                    >
                                        End Date<span className="required-text">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control font-regular font-14 custom-form-control py-2"
                                        id="end-date"
                                        value={taskData.endDate}
                                        name="endDate"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <div>
                                        <span style={{ color: 'red' }}>{validateTaskError.validateEndDateError}</span>
                                    </div>
                                </div> :
                                    <div className="col-md-6 mb-4">
                                        <label
                                            htmlFor="end-date"
                                            className="form-label font-medium font-14 primary-textcolor"
                                        >
                                            End Date
                                        </label>
                                        <span className="d-block font-13 font-regular py-2">
                                            {moment(taskData.endDate).format('MM/DD/YYYY')}
                                        </span>
                                    </div>}
                                {isImportExcelVisible && <div className="col-md-6 mb-4">
                                    <label
                                        htmlFor="upload-thumbnail"
                                        className="form-label font-medium font-14 primary-textcolor"
                                    >
                                        Upload<span className="required">*</span>
                                    </label>
                                    {excelData ? (
                                        <UploadedMediaSection mediaData={excelData} onDelete={handleDeleteExcelFile} isMedia={false} />
                                    ) : (
                                        <UploadMediaSection
                                            handleUpload={handleFileUpload}
                                            allowedExtensions=".xls, .xlsx"
                                            error={validateTaskError.validateAssigneeError}
                                            fileValue={uploadedFileValue}
                                            isValidating={isExcelValidating}
                                        />
                                    )}
                                </div>}
                                {isImportExcelVisible && <div className="col-md-12 mb-4">
                                    <div className="border-radius-8 grey-200 d-flex justify-content-between align-items-center p-3 grey-bg-v1">
                                        <div>
                                            <p className="font-medium font-14">
                                                Import User using Template
                                            </p>
                                            <p className="font-regular grey-v1 font-12 mb-0">
                                                Don't have user's list format, download one of our editable
                                                templates.
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="cust-filter-btn text-nowrap font-14  font-medium primary-color upload-btn pointer"
                                                onClick={() => downloadTemplate()}
                                            >
                                                <img
                                                    src="/images/download-blue-icon.svg"
                                                    alt="browse"
                                                    className="me-2"
                                                />
                                                Download Template
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer border-0 mt-2 mb-3 px-4 d-flex justify-content-between">
                            <div>
                                <button
                                    type="button"
                                    className="primary-btn font-14 font-medium py-2"
                                    data-bs-dismiss="modal"
                                    onClick={() => setCurrentForm(0)}
                                >
                                    Back
                                </button>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="primary-btn font-14 font-medium py-2 me-2"
                                    data-bs-dismiss="modal"
                                    onClick={() => { setUploadedFileValue(""); close(); }}
                                >
                                    Cancel
                                </button>
                                <button disabled={!isValidTaskData} type="button" className={isValidTaskData ? "dark-btn" : "disabled-btn" + " font-14 font-medium py-2 pointer"} onClick={() => handlePostCampaignDetails()}>
                                    {campaignData.campaignId !== "" ? "Update" : "Create"}
                                </button>
                            </div>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </>
    );
};

export default CreateCampaignWizardForm2;