import React from 'react';

const ErrorPage = () => {
    return (
        <div class="col-md-12 float-start mb-5">
            <div class="col-md-12 text-center">
                <a><img class="no-search" src="/images/error-page.png" alt="Noserachresults" /> </a>
            </div>
            
        </div>
    );
};

export default ErrorPage;
