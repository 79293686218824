import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { generateSasToken, insertSystemDescriptionDetails, systemDescriptionQuestions } from '../../Services/GRC/GrcService';
import SystemDescriptionPopup from './SystemDescriptionPopup';
import { useNavigate } from 'react-router';
import { Loader } from '../Loader/Loader';



/**
 * GRC_SD_20 - GRC_SD_24
 * The user input values are updated to the state variables here 
 */
const DynamicInput = ({ id, initialAnswers, onAddDynamicField, onInputChange }) => {
    let initialAnswersArray = initialAnswers.length != 0 ? initialAnswers : [];
    let [dynamicInputs, setDynamicInputs] = useState(initialAnswersArray);
    if (dynamicInputs.length == 0) {
        dynamicInputs = initialAnswersArray;
    }

    const handleInputChange = (index, value) => {
        const updatedInputs = [...dynamicInputs];
        updatedInputs[index] = value;
        setDynamicInputs(updatedInputs);
        onInputChange(updatedInputs);
    };

    const handleAddField = () => {
        setDynamicInputs([...dynamicInputs, '']);
        onAddDynamicField();
    };

    const handleDeleteField = (index) => {
        const updatedInputs = [...dynamicInputs];
        updatedInputs.splice(index, 1);
        setDynamicInputs(updatedInputs);
        onInputChange(updatedInputs);
    }

    return (
        <div>
            {dynamicInputs.map((input, index) => (
                <div className='input-group mb-3' key={`${id}_${index}`}>
                    <input type="text" class="form-control font-regular font-14 custom-form-control pe-5 py-2" value={input} onChange={(e) => handleInputChange(index, e.target.value)}></input>

                    <span class="input-close-icon cursor-pointer">
                        <img src="/images/input-close-icon.svg" alt="input-close-icon" onClick={() => handleDeleteField(index)} />
                    </span>
                </div>
            ))}
            <button type="button" onClick={handleAddField} class=" me-3 border-0 shadow-none p-0 mt-4 bg-transparent blue-btn d-flex align-items-center font-14 font-medium">
                <span><img src="/images/plus-icon-2.svg" alt="plus-icon-2" class="me-2 mb-1" /></span>
                Add Option
            </button>
        </div>
    );
};


const SystemDescription = () => {


    const [editedAnswers, setEditedAnswers] = useState({});
    const [categoryCompletionStatus, setCategoryCompletionStatus] = useState({});
    const [sysDesQuestions, setResponseData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [notAnsweredQuestions, setNotAnsweredQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    /**
     * GRC_SD_03 - GRC_SD_19
     * The useEffect populates all the data of the systemDescription
     */
    const loadSystemDescription = async () => {
        setIsLoading(true);
        let transformedData = {};
        let result = await systemDescriptionQuestions();
        console.log(result.responseData);
        setResponseData(result.responseData);
        result.responseData.map(category => {
            category.questionsandanswer.map(question => {
                const questionId = question.system_description_questions_id;
                const value = question.answers.length === 1 ? question.answers[0] : question.answers;
                transformedData[questionId] = {
                    questionId,
                    value,
                };
            });
        });
        setEditedAnswers(transformedData);
        setIsLoading(false);
    }


    useEffect(() => {
        loadSystemDescription();
    }, [])

    useEffect(() => {
        const updatedCategoryCompletionStatus = {};
        sysDesQuestions.forEach((category) => {
            const totalFields = category.questionsandanswer.length;
            const filledFields = category.questionsandanswer.filter(
                (question) => editedAnswers[question.system_description_questions_id]?.value?.length > 0
            ).length;
            const dotColor = filledFields === totalFields ? "green" : "orange";
            const completionStatus = (<>
                <span className={`${dotColor}-dot`} style={{ "margin-right": "5px" }}>
                </span><span>{`${filledFields}/${totalFields} Completed`}</span></>
            );
            updatedCategoryCompletionStatus[category.system_description_category_name] = completionStatus;
        });
        setCategoryCompletionStatus(updatedCategoryCompletionStatus);
    }, [editedAnswers]);



    function convertData(data) {
        let result = { insertionValues: [] };

        for (let key in data) {
            if (Array.isArray(data[key].value) && data[key].value.length > 0) {
                result.insertionValues.push({
                    system_description_questions_id: data[key].questionId,
                    system_description_answer: data[key].value
                });
            } else if (typeof data[key].value === 'string' && data[key].value.trim() !== '') {
                result.insertionValues.push({
                    system_description_questions_id: data[key].questionId,
                    system_description_answer: data[key].value
                });
            }
        }

        return result;
    }

    /**
     * GRC_SD_37 - GRC_SD_56
     * This function generates the system description 
     */
    const generateDocument = async () => {
        setIsLoading(true);
        let value = convertData(editedAnswers);
        let result = await insertSystemDescriptionDetails(value);

        const keysArray = Object.keys(editedAnswers);
        console.log(editedAnswers[keysArray[0]].value);
        let sasToken = await generateSasToken({ "resourceTypes": "o", "permission": "rw" });
        sasToken = sasToken?.responseData;

        let url = process.env.REACT_APP_SYSTEMDESCRIPTIONFILEURL + sasToken;
        loadFile(url, function (error, content) {
            if (error) {
                throw error;
            }
            const zip = new PizZip(content);
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
            });

            doc.render({
                company_name: editedAnswers[keysArray[0]].value.length == 0 ? "[ADD COMPANY NAME]" : editedAnswers[keysArray[0]].value,
                company_background: editedAnswers[keysArray[1]].value.length == 0 ? "[ADD COMPANY BACKGROUND]" : editedAnswers[keysArray[1]].value,
                name_of_product_service: editedAnswers[keysArray[2]].value.length == 0 ? "[ADD NAME OF PRODUCT/SERVICE]" : editedAnswers[keysArray[2]].value,
                product_overview: editedAnswers[keysArray[3]].value.length == 0 ? "[ADD PRODUCT OVERVIEW]" : editedAnswers[keysArray[3]].value,
                features_and_customer_use_cases: editedAnswers[keysArray[4]].value.length == 0 ? "[ADD FEATURES AND CUSTOMER USECASES]" : editedAnswers[keysArray[4]].value,
                soc_2_trust_service_criteria_tscs: editedAnswers[keysArray[5]].value.length == 0 ? "[ADD SOC_2_TRUST_SERVICE_CRITERIA_TSCS]" : editedAnswers[keysArray[5]].value,
                communication_methods: editedAnswers[keysArray[6]].value.length == 0 ? "[ADD COMMUNICATION_METHODS]" : editedAnswers[keysArray[6]].value,
                security_commitments: editedAnswers[keysArray[7]].value.length == 0 ? "[ADD SECURITY_COMMITMENTS]" : editedAnswers[keysArray[7]].value,
                cloud_service_providers_subservices: editedAnswers[keysArray[8]].value.length == 0 ? "[ADD CLOUD_SERVICE_PROVIDERS_SUBSERVICES]" : editedAnswers[keysArray[8]].value,
                infrastructure_services: editedAnswers[keysArray[9]].value.length == 0 ? "[ADD INFRASTRUCTURE_SERVICES]" : editedAnswers[keysArray[9]].value,
                software: editedAnswers[keysArray[10]].value.length == 0 ? "[ADD SOFTWARE]" : editedAnswers[keysArray[10]].value,
                functional_teams: editedAnswers[keysArray[11]].value.length == 0 ? "[ADD FUNCTIONAL_TEAMS]" : editedAnswers[keysArray[11]].value,
                organization_size: editedAnswers[keysArray[12]].value.length == 0 ? "[ADD ORGANIZATION_SIZE]" : editedAnswers[keysArray[12]].value,
                data_types: editedAnswers[keysArray[13]].value.length == 0 ? "[ADD DATA_TYPES]" : editedAnswers[keysArray[13]].value,
                data_categorization: editedAnswers[keysArray[14]].value.length == 0 ? "[ADD DATA_CATEGORIZATION]" : editedAnswers[keysArray[14]].value,
                process_management: editedAnswers[keysArray[15]].value.length == 0 ? "[ADD PROCESS_MANAGEMENT]" : editedAnswers[keysArray[15]].value,
                access_control: editedAnswers[keysArray[16]].value.length == 0 ? "[ADD ACCESS_CONTROL]" : editedAnswers[keysArray[16]].value,
                access_review: editedAnswers[keysArray[17]].value.length == 0 ? "[ADD ACCESS_REVIEW]" : editedAnswers[keysArray[17]].value,
                access_provisioning: editedAnswers[keysArray[18]].value.length == 0 ? "[ADD ACCESS_PROVISIONING]" : editedAnswers[keysArray[18]].value,
                access_provisioning_sla: editedAnswers[keysArray[19]].value.length == 0 ? "[ADD ACCESS_PROVISIONING_SLA]" : editedAnswers[keysArray[19]].value,
                access_provisioning_sla_units: editedAnswers[keysArray[20]].value.length == 0 ? "[ADD ACCESS_PROVISIONING_SLA_UNITS]" : editedAnswers[keysArray[20]].value,
                access_deprovisioning: editedAnswers[keysArray[21]].value.length == 0 ? "[ADD ACCESS_DEPROVISIONING]" : editedAnswers[keysArray[21]].value,
                access_deprovisioning_sla: editedAnswers[keysArray[22]].value.length == 0 ? "[ADD ACCESS_DEPROVISIONING_SLA]" : editedAnswers[keysArray[22]].value,
                access_deprovisioning_sla_units: editedAnswers[keysArray[23]].value.length == 0 ? "[ADD ACCESS_DEPROVISIONING_SLA_UNITS]" : editedAnswers[keysArray[23]].value,
                data_backup: editedAnswers[keysArray[24]].value.length == 0 ? "[ADD DATA_BACKUP]" : editedAnswers[keysArray[24]].value,
                vulnerability_detection: editedAnswers[keysArray[25]].value.length == 0 ? "[ADD VULNERABILITY_DETECTION]" : editedAnswers[keysArray[25]].value,
                integrity_and_ethical_values: editedAnswers[keysArray[26]].value.length == 0 ? "[ADD INTEGRITY_AND_ETHICAL_VALUES]" : editedAnswers[keysArray[26]].value,
                commitment_to_competence: editedAnswers[keysArray[27]].value.length == 0 ? "[ADD COMMITMENT_TO_COMPETENCE]" : editedAnswers[keysArray[27]].value,
                management_philosophy_and_operating_style: editedAnswers[keysArray[28]].value.length == 0 ? "[ADD MANAGEMENT_PHILOSOPHY_AND_OPERATING_STYLE]" : editedAnswers[keysArray[28]].value,
                organizational_structure: editedAnswers[keysArray[29]].value.length == 0 ? "[ADD ORGANIZATIONAL_STRUCTURE]" : editedAnswers[keysArray[29]].value,
                human_resources_policies_and_practices: editedAnswers[keysArray[30]].value.length == 0 ? "[ADD HUMAN_RESOURCES_POLICIES_AND_PRACTICES]" : editedAnswers[keysArray[30]].value,
                changes_to_the_system_type_1_past_3_months: editedAnswers[keysArray[31]].value.length == 0 ? "[ADD CHANGES_TO_THE_SYSTEM_TYPE_1_PAST_3_MONTHS]" : editedAnswers[keysArray[31]].value,
                changes_to_the_system_type_2_during_the_review_period_or_since_the_organizations_last_review: editedAnswers[keysArray[32]].value.length == 0 ? "[ADD CHANGES_TO_THE_SYSTEM_TYPE_2_DURING_THE_REVIEW_PERIOD_OR_SINCE_THE_ORGANIZATIONS_LAST_REVIEW]" : editedAnswers[keysArray[32]].value,
                incidents_type_1_past_3_months: editedAnswers[keysArray[33]].value.length == 0 ? "[ADD INCIDENTS_TYPE_1_PAST_3_MONTHS]" : editedAnswers[keysArray[33]].value,
                incidents_type_2_during_the_review_period_or_since_the_organizations_last_review: editedAnswers[keysArray[34]].value.length == 0 ? "[ADD INCIDENTS_TYPE_2_DURING_THE_REVIEW_PERIOD_OR_SINCE_THE_ORGANIZATIONS_LAST_REVIEW]" : editedAnswers[keysArray[34]].value,
                subservice_organization_control_monitoring: editedAnswers[keysArray[35]].value.length == 0 ? "[ADD SUBSERVICE_ORGANIZATION_CONTROL_MONITORING]" : editedAnswers[keysArray[35]].value

            });

            const blob = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });

            saveAs(blob, "System Description.docx");
        });
        setIsLoading(false);
    };

    const loadFile = (url, callback) => {
        PizZipUtils.getBinaryContent(url, callback);
    };

    /**
     * GRC_SD_25 - GRC_SD_33
     * The validation and the showing the popup happens here
     */
    let handleSubmit = async () => {
        console.log(editedAnswers);
        const resultArray = Object.values(editedAnswers)
            .filter(item => item.value.length > 0)
            .map(({ questionId, value }) => ({ questionId, value }));

        console.log(resultArray);

        let unansweredQuestions = [];

        sysDesQuestions.forEach((category) => {
            category.questionsandanswer.forEach((question) => {
                const questionId = question.system_description_questions_id;
                const value = editedAnswers[questionId]?.value;
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    unansweredQuestions.push({
                        category: category.system_description_category_name,
                        question: question.question,
                    });
                }
            });
        });

        if (unansweredQuestions.length > 0) {
            console.log("Unanswered Questions:", unansweredQuestions);
            setNotAnsweredQuestions(unansweredQuestions);
            setShowPopup(true)
        }
        else {
            await generateDocument();
        }
    }


    const handleInputChange = (questionId, fieldType, value) => {
        setEditedAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: { questionId, value },
        }));
    };




    return (
        <>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 px-5 my-5 text-start">
                        <div className="d-block w-100">
                            <div className="d-flex mb-3 justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <a className="text-decoration-none me-3 pointer" onClick={() => { navigate(-1) }}>
                                        <img src="/images/left-arrow.svg" alt="left-arrow" style={{cursor : 'pointer'}} />
                                    </a>
                                    <h1 className="font-bold font-24 primary-textcolor m-0">
                                        System Description Template
                                    </h1>
                                </div>
                                <button
                                    type="button"
                                    className="font-14 font-medium dark-btn text-end"
                                    // data-bs-toggle="modal" data-bs-target="#missing-form"
                                    onClick={() => { handleSubmit() }}
                                >
                                    Generate System Description
                                </button>
                            </div>
                            <p className="font-14 font-regular data-color">
                                The System Description is a detailed overview of the scope of your SOC
                                2 report. It describes your company, product, and the controls you
                                have in place to meet the standard. It also outlines which areas of
                                security you manage directly, and which areas are managed by a third
                                party (such as your cloud service provider).
                            </p>
                        </div>
                        {sysDesQuestions.map((category, index) => (
                            <div className=" py-3 " key={index}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <button
                                        className="p-0 d-flex align-items-center border-0 shadow-none orgtodo-accbutton-custom bg-transparent"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#CompanyInformation_${index}`} // Use unique ID for each category
                                        aria-expanded="true"
                                        aria-controls={`CompanyInformation_${index}`} // Corresponding unique ID
                                    >
                                        <span className="font-16 font-medium mb-0 primary-textcolor ms-3">
                                            {category.system_description_category_name}
                                        </span>
                                    </button>
                                    <div className="d-flex align-items-center">
                                        {categoryCompletionStatus[category.system_description_category_name]}
                                    </div>
                                </div>

                                <div
                                    id={`CompanyInformation_${index}`} // Unique ID for each category
                                    className={`accordion-collapse table-parent-accordion collapse ${index === 0 ? 'show' : ''}`} // Show the first one initially
                                >
                                    <div className="rounded-4 cust-acc-box-shadow py-2">
                                        <div className="cus-tab-border position-relative violet-tab-bg py-3 px-4">
                                            {category.questionsandanswer.map((question, qIndex) => (
                                                <div className="col-md-12 mb-4" key={qIndex}>

                                                    <label className="form-label font-medium font-14 primary-textcolor mb-1" >
                                                        {question.question}
                                                    </label>
                                                    <p className="font-12 font-regular mb-2 data-color">
                                                        {question.description}
                                                    </p>
                                                    {(() => {
                                                        const fieldId = `field_${question.system_description_questions_id}`;
                                                        switch (question.fieldType) {
                                                            case 'field':
                                                                return (
                                                                    <input
                                                                        type="text"
                                                                        className="form-control font-regular font-14 custom-form-control py-2"
                                                                        id={fieldId}
                                                                        placeholder={question.placeholder}
                                                                        value={editedAnswers[question.system_description_questions_id]?.value || ''}
                                                                        onChange={(e) =>
                                                                            handleInputChange(
                                                                                question.system_description_questions_id,
                                                                                question.fieldType,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                );
                                                            case 'radio':
                                                                return (
                                                                    <div class="form-check form-check-inline">
                                                                        {question.options.map((option, index) => (
                                                                            <label key={index} style={{ "width": "100%" }}>
                                                                                <input
                                                                                    type="radio"
                                                                                    class="form-check-input custom-radio-input"
                                                                                    id={`${fieldId}_${index}`}
                                                                                    checked={
                                                                                        editedAnswers[question.system_description_questions_id]?.value ===
                                                                                        option
                                                                                    }
                                                                                    onChange={() =>
                                                                                        handleInputChange(
                                                                                            question.system_description_questions_id,
                                                                                            question.fieldType,
                                                                                            option
                                                                                        )
                                                                                    }
                                                                                />
                                                                                {option}
                                                                            </label>
                                                                        ))}
                                                                    </div>
                                                                );
                                                            case 'multiSelect':
                                                                const selectOptions = question.options.map((option) => ({
                                                                    value: option,
                                                                    label: option,
                                                                }));
                                                                const editedAnswerValue = editedAnswers[question.system_description_questions_id]?.value;
                                                                const selectedValues = Array.isArray(editedAnswerValue) ? editedAnswerValue : [];
                                                                return (
                                                                    <Select
                                                                        id={fieldId}
                                                                        isMulti
                                                                        options={selectOptions}
                                                                        value={selectedValues.map((value) => ({ value, label: value }))}
                                                                        onChange={(selectedOptions) =>
                                                                            handleInputChange(
                                                                                question.system_description_questions_id,
                                                                                question.fieldType,
                                                                                selectedOptions.map((option) => option.value)
                                                                            )
                                                                        }
                                                                    />
                                                                );
                                                            case 'dynamicInput':
                                                                const initialAnswers = Array.isArray(editedAnswers[question.system_description_questions_id]?.value)
                                                                    ? editedAnswers[question.system_description_questions_id]?.value
                                                                    : [];

                                                                return (
                                                                    <DynamicInput
                                                                        id={fieldId}
                                                                        initialAnswers={initialAnswers}
                                                                        onAddDynamicField={() =>
                                                                            handleInputChange(
                                                                                question.system_description_questions_id,
                                                                                question.fieldType,
                                                                                initialAnswers
                                                                            )
                                                                        }
                                                                        onInputChange={(updatedInputs) =>
                                                                            handleInputChange(
                                                                                question.system_description_questions_id,
                                                                                question.fieldType,
                                                                                updatedInputs
                                                                            )
                                                                        }
                                                                    />
                                                                );
                                                            case 'textArea':
                                                                return (
                                                                    <textarea
                                                                        id={fieldId}
                                                                        class="form-control resize-none font-regular font-14 custom-form-control py-2"
                                                                        placeholder={question.placeholder}
                                                                        value={editedAnswers[question.system_description_questions_id]?.value || ''}
                                                                        onChange={(e) =>
                                                                            handleInputChange(
                                                                                question.system_description_questions_id,
                                                                                question.fieldType,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                );
                                                            default:
                                                                return null;
                                                        }
                                                    })()}
                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {showPopup ?
                    <SystemDescriptionPopup setShowPopup={setShowPopup} notAnsweredQuestions={notAnsweredQuestions} generateDocument={generateDocument} /> : ''}
            </div>
            <Loader isLoading={isLoading} />
        </>

    )
}

export default SystemDescription