import React from 'react';
//PC_DPU_1 to PC_DPU_3
const DeleteAdminPopUp = (props) => {
    console.log(props, "props in delete");
    const { handleDelete, setDelPopUp, deleteData, popUpHeader } = props;
    const handleConfirmDelete = () => {
        props.setDelPopUp(false);
        props.handleDelete(deleteData)
    };

    return (
        <>
            {/* Modal */}
            <div className={`modal ${setDelPopUp ?"dull-background":"transparent-background"}`} id="delete" tabindex="-1" aria-labelledby="exampleModalLabel"
                data-bs-backdrop="static" style={{ display: setDelPopUp ? "block" : "none" , backgroundColor :"rgba(0, 0, 0, 0.6)"}}>
                <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-0 pb-2 px-4">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">
                                <img
                                    className="me-3"
                                    src="/images/delete-popup-icon.svg"
                                    alt="delete-icon"
                                />
                                {popUpHeader}{" "}
                            </h5>
                            <button
                                type="button"
                                className="btn-close ms-auto"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => props.setDelPopUp(false)}
                            />
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <p className="font-16 font-regular mb-0">
                                        Are you sure you want to remove{" "}
                                        {deleteData.ipName && (
                                            <>
                                                <span className="font-medium">{deleteData.ipName}</span>
                                                {" from "}
                                                <span className="font-medium"> Security Ip Configuration</span>
                                            </>
                                        )}

                                        {deleteData.employeeName && deleteData.applicationName && (
                                            <>
                                                <span className="font-medium">{deleteData.employeeName}</span>
                                                {" as "}
                                                <span className="font-medium">{deleteData.applicationName}</span>
                                                owner?
                                            </>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 mb-3 px-4">
                            <button
                                type="button"
                                className="dark-btn font-14 font-medium py-2 me-2"
                                data-bs-dismiss="modal"
                                onClick={() => props.setDelPopUp(false)}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn revoke-btn font-14 font-medium py-2"
                                onClick={() => handleConfirmDelete()}
                            >
                                Delete
                            </button>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
            </div>
            {/* Modal */}
        </>

    )

};

export { DeleteAdminPopUp };
