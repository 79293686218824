
// PC_COM_ACT_8 to PC_COM_ACT_10 
// Import statements
import { useContext, useEffect, useState } from "react"
import { auditCycleTimelineApiResponse } from "../../Services/GRC/GrcService";
import GrcDetailsComponent from "./grcDashBoardDetails";
import { ResponsibilityOverView } from "./responsibilityOverView";
import { VendorAssessmentInfo } from "./vendorAssessmentInfo";
import { AuditorAndReqDocComponent } from "./auditorAndReqDocComponent";
import { ComplianceSummary } from "./complianceSummary";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import NoDataFound from "../LMS/NoDataFound";
import NoRecordFound from "./dashboardNoRecordFound";
import { encryptStorage } from '../../Constant';
import ComplianceTaskSummary from "./complianceTaskSummary";
import NoDataScreen from "./widgets/NoDataScreen";
import { agovContext } from "../../Constant";


// PC_COM_ACT_11
// Component Createtion
export default function Dashboard() {

  // PC_COM_ACT_12 to PC_COM_ACT_13 
  // State variables creation
  const [payload, setPayload] = useState({ frameworkId: 1, startDate: '', endDate: '', profileUrl: '', auditorName: '' });
  const [auditCycledata, setAuditCycleData] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const { roles, userId, profileUrl } = useContext(agovContext);

  const hasRole = (rolesToCheck) => {
    for (const role of rolesToCheck) {
      if (roles.includes(role)) {
        return true;
      }
    }
    return false;
  };

  // PC_COM_ACT_14 to PC_COM_ACT_15
  // Declaration of useNavigate
  const auditNavigation = useNavigate()
  const vendorPageNavigation = useNavigate()
  const navigate = useNavigate()

  //PC_COM_ACT_16 to PC_COM_ACT_17
  // useEffect declarartion
  useEffect(() => {
    auditCycleTimelines()
  }, [])
  // PC_COM_ACT_18 - Function declaration
  const auditCycleTimelines = async () => {
    try {

      // PC_COM_ACT_19 - API function call 
      setIsLoading(true);
      let result = await auditCycleTimelineApiResponse(payload)

      // PC_COM_ACT_30 to PC_COM_ACT_31
      // set state a variable payload if status code is 200
      if (result.statusCode === 200) {
        if(result.responseData.length != 0){
        setAuditCycleData(result.responseData)
        setPayload(prevPayload => ({
          ...prevPayload,
          startDate: result.responseData[0].startDate,
          endDate: result.responseData[0].endDate,
          profileUrl: result.responseData[0].profileUrl,
          auditorName: result.responseData[0].auditorName,
          auditCycleId: result.responseData[0].auditCycleId
        }))
      }
      }
      // PC_COM_ACT_32 -> navigate to error page when statusCode in not equals to 200
      else {
        navigate('/error')
      }

      // PC_COM_ACT_33 -> set state a varibale isLoading
      setIsLoading(false);
    }

    // PC_COM_ACT_34 -> navigate to error page
    catch (error) {
      navigate('/error');
    }
  }

  // PC_COM_ACT_35 -> Data binding in a dropdown
  const bindAuditCycleTimelines = () =>{
    return auditCycledata.map((ele) => <li><a class="dropdown-item primary-textcolor font-13 font-regular px-2 py-2 custom-drop-item"
   onClick={() => updateAuditCycleTimeline(ele)}>{ele.auditCycleName}</a></li>)
  }

  // PC_COM_ACT_38 to PC_COM_ACT_40
  // update the dropdown value while using choose another option
  const updateAuditCycleTimeline = (event) => {
    let data = auditCycledata.filter((ele) => ele.auditCycleId === parseInt(event.auditCycleId));
    setPayload(data[0])
    encryptStorage.setItem("commencer", data.startDate);
    encryptStorage.setItem("fin", data.endDate);
    encryptStorage.setItem("identifiant", data.auditCycleId);
  }

  // PC_COM_ACT_36 -> Navigates to manage audit page
  const manageAuditNavigate = () => {
    encryptStorage.setItem("commencer", payload.startDate);
    encryptStorage.setItem("fin", payload.endDate);
    encryptStorage.setItem("identifiant", payload.auditCycleId);
    auditNavigation('/compliance/AuditCycleGrid')
  }

  // PC_COM_ACT_37 -> Navigates to vendor page
  const vendorGridNavigate = () => {
    vendorPageNavigation('/compliance/vendor')
  }

  // HTML part
  return (
    <div className="container-fluid pt-4 mt-4">
      <Loader isLoading={isLoading} />
      <div className="row">
            <div className="d-flex justify-content-between align-items-center px-xl-5 px-md-5" style={{ "margin-top": "55px" }}>
              {/*tabs starts here*/}
              <div className="custom-tabs ps-xl-4">
              {hasRole(["Super Admin", "GRC Admin"]) ? (
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-0 font-14 tab-style rounded-0 active" id="dashboard-tab" data-bs-toggle="pill" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="true">
                      <span className="tab-img"> <img src="images/dashboard-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="images/dashboard-icon.svg" alt="dashboard-icon" className="me-2" /></span>Overview</button>
                  </li>
                  <li className="nav-item ms-4" role="presentation">
                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="audit-tab" data-bs-toggle="pill" data-bs-target="#audit" type="button" role="tab" aria-controls="audit" aria-selected="false" tabIndex={-1} onClick={() => { manageAuditNavigate() }}>
                      <span className="tab-img"> <img src="images/manage-audit-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="images/manage-audit-icon.svg" alt="manage-audit-icon" className="me-2" /> </span>Manage Audit</button>
                  </li>
                  <li className="nav-item ms-4" role="presentation">
                    <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="vendors-tab" data-bs-toggle="pill" data-bs-target="#vendors" type="button" role="tab" aria-controls="vendors" aria-selected="false" tabIndex={-1} onClick={() => { vendorGridNavigate() }}>
                      <span className="tab-img"> <img src="images/vendor-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="images/vendor-icon.svg" alt="vendor-icon" className="me-2" />Vendors</span></button>
                  </li>
                </ul>) : (          
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item ms-4" role="presentation">
                      <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="audit-tab" data-bs-toggle="pill" data-bs-target="#audit" type="button" role="tab" aria-controls="audit" aria-selected="false" tabIndex={-1} onClick={() => { manageAuditNavigate() }}>
                        <span className="tab-img"> <img src="images/manage-audit-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="images/manage-audit-icon.svg" alt="manage-audit-icon" className="me-2" /> </span>Manage Audit</button>
                    </li>
                    <li className="nav-item ms-4" role="presentation">
                      <button className="nav-link px-0 font-14 tab-style bold-text rounded-0" id="vendors-tab" data-bs-toggle="pill" data-bs-target="#vendors" type="button" role="tab" aria-controls="vendors" aria-selected="false" tabIndex={-1} onClick={() => { vendorGridNavigate() }}>
                        <span className="tab-img"> <img src="images/vendor-icon-filled.svg" alt="filled-icon" className="me-2" /> <img src="images/vendor-icon.svg" alt="vendor-icon" className="me-2" />Vendors</span></button>
                    </li>
                  </ul>)
                } 
              </div>
              {auditCycledata.length === 0 ? null :
                <div class="d-flex dropdown pe-xl-2">
                        {payload.auditCycleName ? <p class="font-13 font-medium primary-textcolor me-2 mb-0 d-flex align-items-center">
                            <span class="pointer-completed me-2"></span>
                            {payload.auditCycleName}<span></span>
                        </p> :
                        <p class="font-13 font-medium primary-textcolor me-2 mb-0 d-flex align-items-center">
                        <span class="pointer-completed me-2"></span>
                        {auditCycledata[0].auditCycleName}<span></span>
                    </p>}
                    <div class="dropdown ms-2">
                            <a class="btn p-0 d-flex align-items-center cust-dropdown" href="#" role="button"
                                id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="images/down-arrow-icon.svg" alt="down-arrow-icon"/>
                            </a>

                            <ul class="dropdown-menu p-2 soc-2-dropdown" style={{ zIndex:100}} aria-labelledby="dropdownMenuLink">
                                {bindAuditCycleTimelines()}
                            </ul>
                        </div>
                    </div>}
              {/* Audit cycle dropdown ends here */}
            </div>
            {auditCycledata.length === 0 ? <NoDataScreen data={"No Audit Timeline Created"}></NoDataScreen> :
              <div className="tab-pane fade active show px-5 py-2" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab" style={{background : '#F8F8FF'}}>
              {payload.startDate ? <GrcDetailsComponent data={payload}></GrcDetailsComponent> : null}
                        <div className="row pe-0">
                          <div className="col-md-12 pe-0">
                            <div className="row pe-0 dashboard-widgets">
                              {payload.startDate ? <ResponsibilityOverView data={payload}></ResponsibilityOverView> : <NoRecordFound></NoRecordFound>}
                              {payload.startDate ? <VendorAssessmentInfo></VendorAssessmentInfo> : <NoRecordFound></NoRecordFound>}
                              {payload.startDate ? <AuditorAndReqDocComponent data={payload}></AuditorAndReqDocComponent> : <NoRecordFound></NoRecordFound>}
                              {payload.startDate ? <ComplianceSummary data={payload}></ComplianceSummary> : <NoRecordFound></NoRecordFound>}
                              {payload.startDate ? <ComplianceTaskSummary data={payload}></ComplianceTaskSummary> : <NoRecordFound></NoRecordFound>}
                            </div>
                          </div>
                        </div>
                      </div>
                  }
      </div>
    </div>

  );
}