import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { agovContext } from "../../Constant";
import { generateSubtask, getTaskDependency, getTaskSource, getTaskType } from '../../Services/Task/TaskService';
import TaskAlertpopup from './TaskAlertPopup';
import { Loader } from '../Loader/Loader';
import Select from 'react-select';

export default function SubtaskForm(props) {

    //PC_SF_07 Create required variables
    let { employeeId, employeeName, roles, userId } = useContext(agovContext);

    let { taskData, isCreateSubtask, setIsCreateSubtask, setViewTaskId,
        setIsSuccessToastMessage, toastMessage, loadViewTask, setToastMessage , onSubtask } = props.props

    const validateSubtaskObj = {
        categoryId: false,
        sourceId: false,
        title: false,
        description: false,
        startDate: false,
        endDate: false,
        taskTypeId: false,
        priorityId: false,
        assigneeType: false,
        assignee: false,
        recurrenceType: false
    };

    const subtaskDataConfig = {
        taskDetailsId: taskData?.taskDetailsId,
        categoryId: taskData?.categoryName === 'Risk' ? taskData?.categoryId : '',
        sourceId: taskData?.categoryName === 'Risk' ? taskData?.sourceId : '',
        title: taskData?.categoryName === 'Risk' ? taskData?.title : '',
        description: '',
        startDate: '',
        endDate: '',
        priorityId: taskData?.priorityId,
        assigneeType: '',
        assignee: taskData?.categoryName === 'Risk' ? taskData?.performedById : '',
        taskTypeId: '',
        recurrenceType: '',
        userId: userId,
        taskId: taskData?.taskId
    };

    //PC_SF_08 Create required state variables
    const [validateSubtaskData, setValidateSubtaskData] = useState(validateSubtaskObj);
    const [subtaskData, setSubtaskData] = useState(subtaskDataConfig);
    const [isAlreadyAssigned, setIsAlreadyAssigned] = useState(false);
    const [subtaskDynamicData, setSubtaskDynamicData] = useState();
    const [isDropdownSelected, setIsDropdownSelected] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [taskAssigneeData, setTaskAssigneeData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [subtaskAssignee, setSubtaskAssignee] = useState()
    const [closeTab,setCloseTab] = useState(true)

    // PC_SF_10 UseEffect will be triggerred if categoryName is equal to compliance
    useEffect(() => {
        if (taskData?.categoryName === 'Compliance') {
            loadTaskType();
        }
    }, []);

    /**
     * PC_SF_11 - PC_SF_21
     * loadTaskType() method is used to get the type of the task whether it is policy or evidence task
     */
    const loadTaskType = async () => {
        setIsLoading(true)
        let response = await getTaskType({ userId: userId, categoryId: taskData?.taskCategoryId });
        setSubtaskDynamicData(prevState => ({ ...prevState, taskType: response?.data }));
        setIsLoading(false)
    };

    /**
     * PC_SF_27 - PC_SF_28
     * In handleSubtaskData() method, store the field data in the state variable subtaskData
     */
    const handleSubtaskData = (e) => {
        setSubtaskData(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    /**
     * PC_SF_29 - PC_SF_41
     * In loadTaskSource() get the Policy Name or Appplication Name based on the task type
     */
    const loadTaskSource = async (taskTypeId = '') => {
        try {
            setIsLoading(true)
            let subtaskSourceConfig = { userId: userId, taskTypeId: taskTypeId, sourceId: taskData?.sourceId };
            let response = await getTaskSource(subtaskSourceConfig)
            setSubtaskDynamicData(prevState => ({ ...prevState, taskSource: response?.data }));
            setIsLoading(false)
        } catch (error) {
            console.log(error.message, "loadTaskSource")
        }
    };

    /**
     * PC_SF_70 - PC_SF_73
     * In validateSubtask() method, check if mandatory fields are filled
     */
    const validateSubtask = () => {
        if (taskData?.categoryName === 'Compliance' && (!subtaskData?.taskTypeId || subtaskData?.taskTypeId == 'Select')) {
            validateSubtaskObj.taskTypeId = true
        }
        if (!subtaskData?.sourceId || subtaskData?.sourceId == 'Select') {
            validateSubtaskObj.sourceId = true
        }
        if (!(subtaskData?.title?.trim())) {
            validateSubtaskObj.title = true
        }
        if (!(subtaskData?.description?.trim())) {
            validateSubtaskObj.description = true
        }
        if (!(subtaskData?.startDate)) {
            validateSubtaskObj.startDate = true
        }
        if (!(subtaskData?.endDate)) {
            validateSubtaskObj.endDate = true
        }
        if (!subtaskData?.assignee || subtaskData?.assignee == 'Select') {
            validateSubtaskObj.assignee = true
        }

        setValidateSubtaskData(validateSubtaskObj);

        if (validateSubtaskObj.taskTypeId || validateSubtaskObj.sourceId ||
            validateSubtaskObj.title || validateSubtaskObj.description || validateSubtaskObj.startDate ||
            validateSubtaskObj.endDate || validateSubtaskObj.priorityId || validateSubtaskObj.assignee ||
            validateSubtaskData.assignee) {

            return false
        }

        return true;
    };

    /**
     * PC_SF_69 - PC_SF_89
     * In createSubtask() when user clicks on the create method, API call will be triggered and subtask will 
     * be created
     */
    const createSubtask = async (alreadyAssigned = false) => {
        try {
            setIsLoading(true)
            if (validateSubtask()) {
                let response = await generateSubtask({ ...subtaskData, alreadyAssigned });
                if (response.data === 'Already Assigned') {
                    setIsAlreadyAssigned(true);
                } else if (response.data == 'Success') {
                    setIsCreateSubtask(false);
                    loadViewTask(taskData?.taskId);
                    setIsSuccessToastMessage(true);
                    setToastMessage("Subtask Created Successfully")
                }
            }
            setIsLoading(false)
            onSubtask()
        }
        catch (error) {
            console.log(error.message, "createSubtask")
        }
    };

    /**
     * PC_SF_47 - PC_SF_60
     * In loadTaskDependency() method make an API call to get the title, description of the 
     * task based on the source and bind the value into the variable subtaskData
     */
    const loadTaskDependency = async (sourceId) => {
        try {
            setIsLoading(true)
            let subtaskDependencyConfig = {
                userId: userId,
                categoryId: subtaskData?.taskTypeId,
                sourceId: sourceId
            }
            let response = await getTaskDependency(subtaskDependencyConfig);
            setSubtaskData({
                ...subtaskData,
                title: response?.data?.taskData[0]?.title,
                description: response?.data?.taskData[0]?.description,
                recurrenceType: response?.data?.taskData[0]?.recurrenceType,
                sourceId: sourceId,
                assignee: response?.data?.taskData[0]?.userId
            })
            const assingeeData = []
            response?.data?.taskAssignee?.map((value) => {
                assingeeData.push(
                    {
                        value: value?.userId,
                        label: value?.employeeName ? value.employeeName : value?.userId,
                        imageUrl: value?.profileUrl
                    }
                )
            })
            setTaskAssigneeData(assingeeData);
            setIsLoading(false)
        }
        catch (error) {
            console.log(error.message, "loadTaskDependency")
        }
    }
    /**
     * PC_SF_93 - PC_SF_94
     * When user select the assignee, changeDropDown method will be triggered and selected user
     * details wil be stored
     */
    const changeDropDown = (userId, profileUrl, employeeName) => {
        setSelectedItem({
            ...selectedItem,
            profileUrl: profileUrl,
            employeeName: employeeName,
        });
        setSubtaskData(prevState => ({
            ...prevState,
            assignee: userId
        }))
        setIsDropdownSelected(false);
    };

    /**
     * PC_SF_23
     * bindTaskType() method is used to bind the HTML part of the type field
     */
    const bindTaskType = () => {
        return subtaskDynamicData?.taskType?.map((value) => {
            return (
                <option value={value?.taskCategoryId} id={value?.categoryName}>{value?.categoryName}</option>
            )
        })
    }

    /**
     * PC_SF_42
     * bindTaskSource() method is used to bind the HTML part of the Policy/Evidence field
     */
    const bindTaskSource = () => {
        return subtaskDynamicData?.taskSource?.map((value) => {
            return (
                <option value={value?.sourceId} id={value?.sourceName}>{value?.sourceName}</option>
            )
        })
    }

    return (
        <>
            <Loader isLoading={isLoading} />
             <div className={closeTab?"modal fade show":""} id="add-audit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-modal="true" style={{ display: 'block'}} role='dialog'>
                <div className="modal-dialog modal-dialog-centered audit-custom-popup-width">
                    <div className="modal-content">
                        {/*modal content starts here*/}
                        <div className="modal-header border-0 pb-3 px-4">
                            <h5 className="modal-title font-bold" id="exampleModalLabel">Create Subtask</h5>
                            <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={() => { setIsCreateSubtask(false) }} />
                        </div>
                        <div className="modal-body border-0 font-16 px-4">
                            <div className="row">
                                {taskData?.categoryName === 'Compliance' ? <div className="col-md-6 mb-4">
                                    <div className="dropdown">
                                        <label className="form-label text-black font-14 font-medium">Type<span className="required">* {validateSubtaskData.taskTypeId ? 'Select Type' : ''}</span>
                                        </label>
                                        <select className="form-select cust-input-sty font-13 font-regular" aria-label="Default select example" name='taskTypeId'
                                            value={subtaskData?.taskTypeId} onChange={(e) => {
                                                handleSubtaskData(e);
                                                e.target.value !== 'select' && e.target.value ? loadTaskSource(e.target.value) : <></>;
                                                e.target.value && e.target.value != 'Select' ? setValidateSubtaskData((pre) => ({ ...pre, ['taskTypeId']: false })) : setValidateSubtaskData((pre) => ({ ...pre, ['taskTypeId']: true }))
                                            }}>
                                            <option selected>Select</option>
                                            {bindTaskType()}
                                        </select>
                                    </div>
                                </div> : <></>}
                                {subtaskData?.taskTypeId && subtaskData?.taskTypeId != 'Select' ? <div className="col-md-6 mb-4">
                                    <div className="dropdown">
                                        <label className="form-label text-black font-14 font-medium">{subtaskDynamicData?.taskType?.some(item => item.taskCategoryId == subtaskData?.taskTypeId && item.categoryName == "Policy") ?
                                            'Policy Name' : subtaskDynamicData?.taskType?.some(item => item.taskCategoryId == subtaskData?.taskTypeId && item.categoryName == "Evidence") ?
                                                'Evidence Name' : 'Hi'}<span className="required">* {validateSubtaskData.sourceId ? subtaskDynamicData?.taskType?.some(item => item.taskCategoryId == subtaskData?.taskTypeId && item.categoryName == "Policy") ?
                                                    'Policy Name' : subtaskDynamicData?.taskType?.some(item => item.taskCategoryId == subtaskData?.taskTypeId && item.categoryName == "Evidence") ?
                                                        'Evidence Name' : 'Hi' : ''}</span>
                                        </label>
                                        <select className="form-select cust-input-sty font-13 font-regular" aria-label="Default select example" name='sourceId' value={subtaskData.sourceId}
                                            onChange={(e) => {
                                                handleSubtaskData(e);
                                                loadTaskDependency(e.target.value);
                                                e.target.value && e.target.value != 'Select' ? setValidateSubtaskData((pre) => ({ ...pre, ['sourceId']: false })) : setValidateSubtaskData((pre) => ({ ...pre, ['sourceId']: true }))
                                            }}>
                                            <option selected>Select</option>
                                            {bindTaskSource()}
                                        </select>
                                    </div>
                                </div> : <></>}
                                <div className="col-md-12 mb-4">
                                    <label htmlFor="title" className="form-label font-medium font-14 primary-textcolor">Title<span className="required-text">* {validateSubtaskData.title ? 'Please enter title' : ''}</span></label>
                                    <input type="text" maxLength={200} className="form-control font-regular font-13 custom-form-control py-2" id="title" placeholder="Enter Ticket Title"
                                        name='title' value={subtaskData?.title} onChange={(e) => {
                                            handleSubtaskData(e);
                                            e.target.value.trim() ? setValidateSubtaskData((pre) => ({ ...pre, ['title']: false })) : setValidateSubtaskData((pre) => ({ ...pre, ['title']: true }))
                                        }} />
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label text-black font-14 font-medium">Description<span className="required-text">* {validateSubtaskData.description ? 'Please enter description' : ''}</span>
                                        </label>
                                        <textarea type="textarea" className="form-control theme-form font-regular no-resize font-13" rows={3} id="description" placeholder="Enter Description" defaultValue={""}
                                            name='description' value={subtaskData?.description} onChange={(e) => {
                                                handleSubtaskData(e);
                                                e.target.value.trim() ? setValidateSubtaskData((pre) => ({ ...pre, ['description']: false })) : setValidateSubtaskData((pre) => ({ ...pre, ['description']: true }))
                                            }} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label htmlFor="StartDate" className="form-label text-black font-14 font-medium">Start Date<span className="required">* {validateSubtaskData.startDate ? 'Select Start Date' : ''}</span></label>
                                        <input type="date" className="form-control cust-input-sty font-12 font-regular" id="StartDate" name='startDate' value={subtaskData.startDate} min={taskData?.startDate.split('T')[0]}
                                            max={taskData?.endDate.split('T')[0]}
                                            onChange={(e) => {
                                                handleSubtaskData(e);
                                                e.target.value && e.target.value != 'Select' ? setValidateSubtaskData((pre) => ({ ...pre, ['startDate']: false })) : setValidateSubtaskData((pre) => ({ ...pre, ['startDate']: true }))
                                            }} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label htmlFor="endDate" className="form-label text-black font-14 font-medium">End Date<span className="required">* {validateSubtaskData.endDate ? 'Select End Date' : ''}</span></label>
                                        <input type="date" className="form-control cust-input-sty font-12 font-regular" id="endDate" name='endDate' value={subtaskData?.endDate} min={subtaskData?.startDate.split('T')[0]}
                                            max={taskData?.endDate.split('T')[0]} onChange={(e) => {
                                                handleSubtaskData(e);
                                                e.target.value && e.target.value != 'Select' ? setValidateSubtaskData((pre) => ({ ...pre, ['endDate']: false })) : setValidateSubtaskData((pre) => ({ ...pre, ['endDate']: true }))
                                            }} />
                                    </div>
                                </div>
                                {subtaskData?.recurrenceType ? <div className="col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label htmlFor="recurrence" className="form-label text-black font-14 font-medium">Recurrence</label>
                                        <p className="font-regular font-13 mt-2">{subtaskData.recurrenceType}</p>
                                    </div>
                                </div> : <></>}
                                <label className="form-label text-black font-14 font-medium">Assignee<span className="required">* {validateSubtaskData.assignee ? 'Select Assignee' : ''}</span>
                                </label>
                                {taskData?.categoryName != 'Risk' ? <div className="dropdown">
                                    <Select
                                        style={{ width: '100%' }}
                                        options={taskAssigneeData}
                                        value={subtaskAssignee}
                                        onChange={(e) => {
                                            setSubtaskAssignee(e);
                                            setSubtaskData((pre) => ({ ...pre, ['assignee']: e?.value ? [e.value] : "" }))
                                            e ? setValidateSubtaskData((pre) => ({ ...pre, ['assignee']: false })) : setValidateSubtaskData((pre) => ({ ...pre, ['assignee']: true }));
                                        }}
                                        isSearchable
                                        isClearable
                                        closeMenuOnSelect={true}
                                    />
                                </div> : <p className="font-regular font-13 mt-2">{taskData?.performedBy}</p>}
                            </div>
                        </div>
                        <div className="modal-footer border-0 mt-2 mb-3 px-4">
                            <button type="button" className="primary-btn font-14 font-medium py-2" data-bs-dismiss="modal"  onClick={() => { setIsCreateSubtask(false) }}>Cancel</button>
                            <button type="button" className="dark-btn font-14 font-medium py-2 "
                                onClick={() => {
                                    createSubtask();
                                    setCloseTab(false)
                                    
                                }}
                            >Create</button>
                        </div>
                        {/*modal content ends here*/}
                    </div>
                </div>
                {isAlreadyAssigned ? <TaskAlertpopup props={
                    {
                        isAlreadyAssigned: isAlreadyAssigned,
                        setIsAlreadyAssigned: setIsAlreadyAssigned,
                        createSubtask: createSubtask,
                        controlDetails: subtaskDynamicData?.taskSource?.filter(item => { return item.sourceId == subtaskData.sourceId }),
                        type: subtaskDynamicData?.taskType?.filter(item => { return item.taskCategoryId == subtaskData.taskTypeId }),
                        isSubtask: true
                    }
                } /> : <></>}
            </div>
        </>
    );
}
